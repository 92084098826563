import axios from "axios";
import constant from "../constant";
import { HEADERS } from "../pages/ENUM";

export const PAYOUT = {
  GET_PAYOUT_LISTING: (
    startDate,
    endDate,
    paymentStatus,
    limit = 20,
    page = 1,
    storeId = [],
    order_by,
    order_type
  ) => {
    let url = constant.BASE_URL + "/payout-detail/get-payout-listing";
    url += "?limit=" + limit + "&page=" + page;
    url += startDate ? "&start_date=" + startDate : "";
    url += endDate ? "&end_date=" + endDate : "";
    url += paymentStatus ? "&payment_status=" + paymentStatus : "";
    url += order_by ? "&order_type=" + order_by : "";
    url += order_type ? "&order_by=" + order_type : "";
    if (storeId.length > 0) { 
      storeId.forEach((element) => {
        url += "&store_id=" + element.code;
      });
    }
    return new Promise((resolve, reject) => {
      axios
        .get(url, { headers: HEADERS })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  GET_PAYOUT_DETAILS_ID: (id, sortBy, orderBy) => {
    let url = constant.BASE_URL + "/payout-detail/get-payout-by-id";
    let param = {
      payout_id: id,
      sort_by: sortBy,
      order_by: orderBy,
    };
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params: param, headers: HEADERS })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  GET_STORE: (order_by, order_type) => {
    // let url = constant.BASE_URL + "/payout-detail/get-stores-list";
    let url = `${constant.BASE_URL}/payout-detail/get-stores-list?order_by=${order_by}&order_type=${order_type}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url, { headers: HEADERS })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  UPDATE_PAYOUT_STATUS: (obj) => {
    let url = constant.BASE_URL + "/payout-detail/update-payout-status";
    return new Promise((resolve, reject) => {
      axios
        .put(url, obj, { headers: HEADERS })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  GET_SERVER_TIMEZONE: (obj) => {
    let url = constant.BASE_URL + "/auth/get-time-zone";
    return new Promise((resolve, reject) => {
      axios
        .get(url, { headers: HEADERS })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  EXPORT_PAYOUT: (startDate, paymentStatus, storeId = 0) => {
    let url = constant.BASE_URL + "/payout-detail/get-payout-listing-csv";
    let param = {};
    if (startDate) {
      param["start_date"] = startDate;
    }
    if (paymentStatus) {
      param["payment_status"] = paymentStatus;
    }
    if (storeId) {
      param["store_id"] = storeId;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params: param, headers: HEADERS })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
};
