import React, { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import GlobalService from "../../../../services/GlobalService";
import { Typography, Box, Pagination, Divider } from "@mui/material";
import plusIcon from "../../../../assets/images/structure/plus-icon.svg";
import minusIcon from "../../../../assets/images/structure/minus-icon.svg";
import { API } from "../../../ENUM";
import { ThirtyFps } from "@mui/icons-material";
import socket from "../../../../socket";
import _debounce from "lodash/debounce";
import moment from "moment";

const Rows = ({ row }) => {
  const [open, setOpen] = useState(false);
  const [LastOrder, setLastOrder] = useState("");
  const Details_Delayed = async (id) => {
    try {
      let url = API.PICKER_TRACKING.Details_Delayed(id);
      const response = await GlobalService(url);
      setLastOrder(response?.order?.orders_details);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{
          borderWidth: "2px",
          borderColor: row.TrafficColor ? row?.TrafficColor : "white",
        }}
      >
        <TableCell component="th" scope="row">
          <div className="table-img-text">
            <img
              src="https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"
              alt=".."
              className="round36"
            />
            <Typography variant="body2" component="div">
              {row?.name}
            </Typography>
          </div>
        </TableCell>
        <TableCell>{row.OrderID}</TableCell>
        <TableCell>{row.NoOfItems}</TableCell>
        <TableCell>
          <span className="chips chips-inactive">{row.Priority}</span>
        </TableCell>
        <TableCell>
          {/* {moment(row.PlacementDateTime).format("DD MMMM YYYY hh:mm a")} */}
          {moment
            .tz(row?.PlacementDateTime, "Asia/Qatar")
            .subtract(30, "minutes")
            .format("DD MMMM YYYY hh:mm a")}
        </TableCell>
        <TableCell>
          <span className={row?.OrderDateTime ? "chips chips-inactive" : ""}>
            {" "}
            {row.OrderDateTime}
          </span>
        </TableCell>
        <TableCell>{row?.status}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              Details_Delayed(row?.OrderID);
              setOpen(!open);
            }}
          >
            {open ? (
              <img src={minusIcon} alt="plus-icon" />
            ) : (
              <img src={plusIcon} alt="plus-icon" />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <Divider className="my-1" />
      <TableRow>
        <TableCell className="p-0 px-2" colSpan={7}>
          <Collapse in={open}>
            <Box sx={{ margin: 1, backgroundColor: "#f9f9f9" }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>S no.</TableCell>
                    <TableCell>Product name</TableCell>
                    <TableCell>Item code</TableCell>
                    <TableCell>Picked Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {LastOrder.length > 0 &&
                    LastOrder.map((productListing, i) => (
                      <TableRow key={productListing.SNo}>
                        <TableCell component="th" scope="row">
                          {(i + 1).toString()}
                        </TableCell>
                        <TableCell>
                          {productListing?.product?.products_locales[0]?.title}
                        </TableCell>
                        <TableCell>
                          {productListing?.product?.itemCode}
                        </TableCell>
                        <TableCell>
                          <span className="chips chips-pending">
                            {productListing?.status}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Rows;
