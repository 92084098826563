import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography, Box, Table, TableBody, TableCell, TableContainer,
	TableHead, TableRow, Pagination, Tooltip, TextField
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { currentPermission } from "../../../redux/reducers/permission";
import {API} from "../../../pages/ENUM";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import UpdateAdminSetting from "../../../dialog/UpdateAdminSetting";
import GlobalService from "../../../services/GlobalService";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";

export default function SettingList() {

  const permission = useSelector(currentPermission);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const limit = 10;
  
  let [canEdit, setCanEdit] = useState(true);
  let [page, setPage] = useState(1);
  let [count, setCount] = useState(0);
	let [order_by, setOrder_by] = useState("id");
  let [order_type, setOrder_type] = useState("desc");
	let [search, setSearch] = useState('');
	let [settings, setSettings] = useState([]);
	let [editData, setEditData] = useState({
		name: '',
		value: '',
		isActive: false,
	});

  const getAllSettings = async () => {
		dispatch(changeLoader(true));
		let url = `${API.GET_ALL_SETTINGS}?limit=${limit}&page=${page}&order_by=${order_by}&order_type=${order_type}`;
		url += search !== '' ? `&search=${search}` : '';
		const response = await GlobalService(url);
		settings = response.results;
		setSettings(settings);
		count = Math.ceil(response.count / limit)
		setCount(count);
		dispatch(changeLoader(false));
	};

  const changeOrderBy = (value, order) => {
		page = 1; setPage(page);
    order_by = value; setOrder_by(order_by);
		order_type = order; setOrder_type(order_type);
		getAllSettings();
  };

  useEffect(() => {getAllSettings()}, []);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanEdit(permission?.adminSetting?.edit === 1)
    } else {
      setCanEdit(true);
    }
  }, [permission]);

	useEffect(() => {
    const getData = setTimeout(() => {
      page = 1; setPage(page);
      getAllSettings();
    }, 500);
    return () => clearTimeout(getData);
  }, [search]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>Admin Settings</Typography>
              <div className="right-filter">
								<TextField
									id="outlined-size-small" size="small" placeholder="Search here.."
									value={search} onChange={(e) => setSearch(e.target.value)}
									InputProps={{
										startAdornment: <SearchIcon sx={{ marginRight: "3px" }} />,
										endAdornment: search !== '' ? <CloseIcon onClick={() => setSearch('')}/>: null,
									}}
								/>
              </div>
            </div>

            {settings?.length > 0? <TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
                        Key
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => { changeOrderBy("name", "asc"); }}/>
                          <ArrowDropDownIcon onClick={() => {changeOrderBy("name", "desc");}}/>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell><div className="short-div">Value</div></TableCell>
                    {canEdit?<TableCell><div className="short-div">Action</div></TableCell>: null}
                  </TableRow>
                </TableHead>
                <TableBody>
									{settings.map((row, index) => (
										<TableRow key={index}>
											<TableCell>{row.name}</TableCell>
											<TableCell>{row.value}</TableCell>
											{canEdit?<TableCell>
												<Tooltip title="Edit">
													<BorderColorIcon
														sx={{ color: "#1976d2", cursor: "pointer" }}
														onClick={() => setEditData({...row, isActive: true})}
													/>
												</Tooltip>
											</TableCell>: null}
										</TableRow>
									))}
                </TableBody>
              </Table>
            </TableContainer>:<NoDataFound text="No Settings Found" />}
            {count > 1 && (
              <Pagination
                count={count}
								page={page}
								onChange={(e, page) => setPage(page)}
                color="primary"
              />
            )}
          </Box>
        </Box>
      </Box>
			{editData.isActive?
				<UpdateAdminSetting
					handleClose={() => setEditData({...editData, isActive: false})}
					editData={editData}
					handleSuccess={() => {
						setEditData({...editData, isActive: false});
						getAllSettings();
					}}
				/>: null
			}
    </>
  );
}
