import React from "react";
import { Boxed } from "../../../../Styles-Elements/Box";
import PropTypes from "prop-types";
import "./../OrderManage.scss";
import { Box, Button, Dialog, DialogContent, Grid, TextField, Typography } from "@mui/material";
import modalClose from "../../../../assets/images/structure/close-modal.png";
import Header from "../../../layout/header/Header";
import Aside from "../../../layout/aside/Aside";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Boxed>{children}</Boxed>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const OrderManageDetail = () => {
  const [openCancelOrder, setOpenCancelOrder] = React.useState(false);
  const OpenCancelOrder = () => {
    setOpenCancelOrder(true);
  };
  const handleClose1 = () => {
    setOpenCancelOrder(false);
  };

  return (
    <Box className="dashboard-parent">
      <Header />
      <Aside />
      <Box className="main-wrapper">
        <Box className="white-box-20-30">
          <div className="order-listing-wrapper">
            <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} mb={"20px"}>
              <div className="store-detail-head" style={{ marginBottom: "0px" }}>
                <Typography variant="h6" component="h6" mb={0} fontWeight={500}>
                  Orders Detail
                </Typography>
              </div>
              <div className="search-box">
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  className="secondary-gray"
                  onClick={OpenCancelOrder}
                >
                  Cancel Order
                </Button>
              </div>
            </Boxed>
            <div className="">
              <div className="tabs-wrap">
                <div className="">
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
      
      <Dialog open={openCancelOrder} onClose={handleClose1}>
        <img src={modalClose} alt="..." onClick={handleClose1} className='modal-close' />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Cancel Order
          </Typography>

          <form>
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <TextField required label="Rejection Reason" multiline rows={2} />
              </Grid>
              <Grid item xs={12}>
                <TextField required label="Customer Refund Amount" />
              </Grid>
              <Grid item xs={12}>
                <TextField required label="Store Payout Amount" />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button variant="contained" size="large" sx={{ width: 1, marginTop: '16px' }}>Submit</Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default OrderManageDetail;