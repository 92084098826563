import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Autocomplete
} from "@mui/material";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import modalClose from "../../../assets/images/structure/close-black.svg";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import uploadImg from "../../../assets/images/structure/upload-black-icon.svg";
import cancelRed from "../../../assets/images/structure/cancel-red.svg";
import getBase64 from "../../../global-modules/getBase64";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { amountMask, wholeNumberMask } from "../../../global-modules/MaskedInputs";
import services from "../../../services";
import { Theme, useTheme } from "@mui/material/styles";
import {getdataByKey} from '../../../pages/helper/custom.js';
import constant from "../../../constant";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
const AddUOM = ({
  isOpen = false,
  unitData = [],
  handleClose,
  updateValue,
  updateEditValue,
  uom,
  uomsCount,
  editProductData,
  adminCommission = 0,
  isEdit = false,
  AddonsList
}) => {
  const dispatch = useDispatch();
  const [canEdit, setCanEdit] = useState(true);
  let [productId, setProductId] = useState(0);
  let [quantity, setQuantity] = useState("");
  let [quantityError, setQuantityError] = useState("");
  let [uomNameEr, setUomNameEr] = useState("");
  let [uomNameErError, setUomNameErError] = useState("");
  let [unit, setUnit] = useState("");
  let [unitError, setUnitError] = useState("");
  let [inStock, setInStock] = useState(true);
  let [status, setStatus] = useState(true);
  let [barcode, setBarcode] = useState("");
  let [barcodeOld, setBarcodeOld] = useState("");
  let [barcodeError, setBarcodeError] = useState("");
  let [relationBase, setRelationBase] = useState("");
  let [relationBaseError, setRelationBaseError] = useState("");
  let [price, setPrice] = useState(null);
  let [priceError, setPriceError] = useState("");
  let [salePrice, setSalePrice] = useState(null);
  let [salePriceError, setSalePriceError] = useState("");
  let [platformFee, setPlatformFee] = useState(null);
  let [youEarn, setYouEarn] = useState(null);
  let [featureImgErr, setFeatureImgErr] = useState("");
  let [removedImages, setRemovedImages] = useState([]);
  let [selectedAddOns,setSelectedAddOns]=useState([]);
  let addOnOption=AddonsList;
  let [productNCondition, setProductNCondition] = useState([
    {
      name: "Front View (Featured)",
      type: "front",
      ifUploaded: false,
      product_image: null,
      isRequired: true,
      isUploaded: false,
      path: "",
      percentage: 1,
    },
    {
      name: "Open View",
      type: "open",
      ifUploaded: false,
      product_image: null,
      isRequired: false,
      isUploaded: false,
      path: "",
      percentage: 1,
    },
    {
      name: "Close-Up View",
      type: "closeup",
      ifUploaded: false,
      product_image: null,
      isRequired: false,
      isUploaded: false,
      path: "",
      percentage: 1,
    },
    {
      name: "Top View",
      type: "top",
      ifUploaded: false,
      product_image: null,
      isRequired: false,
      isUploaded: false,
      path: "",
      percentage: 1,
    },
    {
      name: "Side View",
      type: "side",
      ifUploaded: false,
      product_image: null,
      isRequired: false,
      isUploaded: false,
      path: "",
      percentage: 1,
    },
  ]);
  let [isDefault, setIsDefault] = useState(true);
  let [isUomEdit, setIsUomEdit] = useState(false);

  const Timer = ({ type }) => {
    const [time, setTime] = useState(0);
    const f = () => {
      setTime(time + 4);
    };

    useEffect(() => {
      f();
    }, []);

    useEffect(() => {
      if (time < 100) {
        let find = productNCondition.find((a) => a.type == type);
        if (find) {
          if (find.path) {
            setTime(100);
          } else {
            setTimeout(() => {
              f();
            }, 1500);
          }
        } else {
          setTimeout(() => {
            f();
          }, 1500);
        }
      }
    }, [time, productNCondition]);

    return (
      <>
        {time < 100 ? (
          <div className="progress-img">
            <span className="progress-count">{time}%</span>
            <div className="progress-box">
              <span
                className="progress-content"
                style={{ width: `${time}%` }}
              ></span>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const uploadSingleImage = async (type, productImage, dataCallback) => {
    const { data, status } = await services.Products.UPLOAD_UOM_IMAGE({
      image: productImage,
    });
    if (data?.ack === 1) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: `${type} image Uploaded Successfully`,
          state: "success",
        })
      );
      dataCallback(data?.imageName);
    } else {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: `${type} image Upload failed`,
          state: "error",
        })
      );
    }
  };

  const beforeUploads = (file, type = "frontView") => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type == "image/jpg";
    if (!isJpgOrPng) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: "Uploaded Image",
          state: "error",
        })
      );
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: "Uploaded Image should be Smaller 5MB",
          state: "error",
        })
      );
    }
    let f = isJpgOrPng && isLt5M;
    let a = [];
    productNCondition.forEach((obj) => {
      if (obj.type === type) {
        a.push({ ...obj, ifUploaded: f });
      } else {
        a.push(obj);
      }
    });
    setProductNCondition(a);
    return f;
  };

  const deleteImage = async (type) => {
    let a = [];
    let removedImage = [];
    let productNConditionS = productNCondition.map((obj) => {
      if (obj.type === type) {
        if (obj.path !== "") {
          removedImage.push({ name: obj.path, type: type });
        }
        obj.product_image = null;
        obj.path = "";
      }
      return obj;
    });
    setRemovedImages([...removedImages, removedImage[0]]);
    setProductNCondition(productNConditionS);
  };

  const onImageChange = async ({ fileList }, type) => {
    setFeatureImgErr("");
    let base64 = null;
    if (fileList.length > 0) {
      base64 = await getBase64(fileList[fileList.length - 1]?.originFileObj);
    }
    let a = [];
    let isInserted = false;
    productNCondition.forEach((obj) => {
      if (obj.type === type) {
        a.push({ ...obj, product_image: base64 });
        isInserted = obj.ifUploaded;
      } else {
        a.push(obj);
      }
    });
    if (isInserted) {
      setProductNCondition(a);
      uploadSingleImage(type, base64, (data) => {
        let a = [];
        productNCondition.forEach((obj) => {
          if (obj.type === type) {
            a.push({ ...obj, path: data, product_image: base64 });
          } else {
            a.push(obj);
          }
        });
        setProductNCondition(a);
      });
    }
  };

  const resetInputs = () => {
    productId = 0;
    setProductId(productId);
    setQuantity("");
    setUnit("");
    setInStock(true);
    setBarcode("");
    setRelationBase("");
    setPrice(null);
    setSalePrice(null);
    setPlatformFee(null);
    setYouEarn(null);
    setIsDefault(false);
    setProductNCondition([
      {
        name: "Front View (Featured)",
        type: "front",
        ifUploaded: false,
        product_image: null,
        isRequired: true,
        isUploaded: false,
        path: "",
        percentage: 1,
      },
      {
        name: "Open View",
        type: "open",
        ifUploaded: false,
        product_image: null,
        isRequired: false,
        isUploaded: false,
        path: "",
        percentage: 1,
      },
      {
        name: "Close-Up View",
        type: "closeup",
        ifUploaded: false,
        product_image: null,
        isRequired: false,
        isUploaded: false,
        path: "",
        percentage: 1,
      },
      {
        name: "Top View",
        type: "top",
        ifUploaded: false,
        product_image: null,
        isRequired: false,
        isUploaded: false,
        path: "",
        percentage: 1,
      },
      {
        name: "Side View",
        type: "side",
        ifUploaded: false,
        product_image: null,
        isRequired: false,
        isUploaded: false,
        path: "",
        percentage: 1,
      },
    ]);
    setRelationBaseError("");
    setBarcodeError("");
    setQuantityError("");
    setUnitError("");
    setPriceError("");
    setFeatureImgErr("");
    if (uom === null) {
      isDefault = uomsCount === 0 ? true : false;
      setIsDefault(isDefault);
      setRelationBase(uomsCount === 0 ? "1" : "");
    } else {
      isDefault = false;
      setIsDefault(isDefault);
      setRelationBase("");
    }
    selectedAddOns = [];
    setSelectedAddOns(selectedAddOns);
  };

  const handleAddUom = async (e) => {
    e.preventDefault();
    let isValid = true;
    dispatch(changeLoader(true));
    if (relationBase === "") {
      setRelationBaseError("Relation with Base is required");
      isValid = false;
    }
    if (barcode === "") {
      setBarcodeError("Barcode is Required");
      isValid = false;
    }
    if (quantity === "") {
      setQuantityError("Quantity is required");
      isValid = false;
    } else if (unit === "") {
      setQuantityError("Unit is required");
      isValid = false;
    }
    if (uomNameEr === "") {
      setUomNameErError("Uom name Arabic is required");
      isValid = false;
    }
    if (price === null) {
      setPriceError("Price is required");
      isValid = false;
    }
    if (salePrice !== null) {
      if (parseFloat(price) < parseFloat(salePrice)) {
        setSalePriceError("Sale price should be less then price");
        isValid = false;
      }
    }
    productNCondition.forEach((obj) => {
      if (obj.type === "front" && obj.path === "") {
        setFeatureImgErr("Front View (Featured) is required");
        isValid = false;
        document
          .getElementById("imageSpan")
          .scrollIntoView({ behavior: "smooth" });
      }
    });
    if (isValid) {
      const images = productNCondition
        .map((image) => {
          if (image.path !== "") {
            return {
              name: image.path,
              type: image.type,
            };
          }
        })
        .filter((image) => image !== undefined);
      let uomData = {
        // productId: 0,
        index: uom? uom.index: null,
        barcode: barcode,
        relationWithBase: relationBase,
        uomName: quantity,
        uomNameEr: uomNameEr,
        unitId: unit,
        price: price,
        salePrice: salePrice,
        stock: inStock ? 1 : 0,
        isDefault: isDefault ? 1 : 0,
        image: images, // JSON.stringify(images)
        addons: selectedAddOns.map((obj) => { return { addonId: obj.id }; }),
        status: status ? "active" : "inactive",
      };
      if(editProductData) {
        if (uom?.id) {
          uomData.id = uom.id;
        }
      }
      if (uom) {
        updateEditValue(uomData);
        dispatch(changeLoader(false));
      } else {
        updateValue(uomData);
        dispatch(changeLoader(false));
      }
    } else {
      dispatch(changeLoader(false));
    }
  };

  const handleCloseDialog = () => {
    resetInputs();
    handleClose();
  };

  useEffect(() => {
    if (salePrice) {
      let p = (salePrice / 100) * adminCommission;
      setPlatformFee(p.toFixed(2));
      setYouEarn((salePrice - p).toFixed(2));
    } else {
      if (price) {
        let p = (price / 100) * adminCommission;
        setPlatformFee(p.toFixed(2));
        setYouEarn((price - p).toFixed(2));
      } else {
        platformFee = "";
        youEarn = "";
        setPlatformFee(platformFee);
        setYouEarn(youEarn);
      }
    }
  }, [price, salePrice]);

  useEffect(() => {
    resetInputs();
  }, []);

  useEffect(() => {
    isUomEdit = false;
    if (uom) {
      isUomEdit = true;
      let ids = uom?.uom_addon_relations?.map((obj) => obj.addonId); 
      selectedAddOns = (uom?.uom_addon_relations?.length > 0) ?
        addOnOption.filter((obj) => ids.includes(obj.id)):
        []
      setSelectedAddOns(selectedAddOns);
      productId = uom.productId;
      setProductId(productId);
      quantity = uom.uomName;
      setQuantity(quantity);
      uomNameEr = uom.uomNameEr;
      setUomNameEr(uomNameEr);
      unit = uom.unitId;
      setUnit(unit);
      // inStock = uom.stock === 1 ? true : false;
      // setInStock(inStock);
      status = uom.status === "active" ? true : false;
      setStatus(status);
      let barcodes = [];
      if (uom?.uom_barcode_relations) {
        barcodes = uom?.uom_barcode_relations?.map((obj) => obj.barcode);
        barcode = barcodes.join(',');
      } else {
        barcode = uom?.barcode
      }
      setBarcode(barcode);
      barcodeOld = barcode;
      setBarcodeOld(barcodeOld)
      relationBase = uom?.relationWithBase?.toString();
      setRelationBase(relationBase);
      price = uom?.price?.toString();
      setPrice(price);
      salePrice = uom?.salePrice?.toString();
      setSalePrice(salePrice);
      if (salePrice !== '' && salePrice !== '0') {
        let p = (salePrice / 100) * adminCommission;
        setPlatformFee(p.toFixed(2));
        setYouEarn((salePrice - p).toFixed(2));
      } else {
        if (price !== '') {
          let p = (price / 100) * adminCommission;
          setPlatformFee(p.toFixed(2));
          setYouEarn((price - p).toFixed(2));
        } else {
          platformFee = '';
          youEarn = '';
          setPlatformFee(platformFee);
          setYouEarn(youEarn);
        }
      }
      let frontImage = '';
      let openImage = '';
      let closeupImage = '';
      let topImage = '';
      let sideImage = '';
      if (uom?.uom_images?.length > 0) {
        uom?.uom_images?.forEach(element => {
          if (element.type === 'front') { frontImage = element.image; }
          if (element.type === 'open') { openImage = element.image; }
          if (element.type === 'closeup') { closeupImage = element.image; }
          if (element.type === 'top') { topImage = element.image; }
          if (element.type === 'side') { sideImage = element.image; }
        });
      } else if (uom?.image?.length > 0) {
        uom?.image?.forEach(element => {
          if (element.type === 'front') { frontImage = element.name; }
          if (element.type === 'open') { openImage = element.name; }
          if (element.type === 'closeup') { closeupImage = element.name; }
          if (element.type === 'top') { topImage = element.name; }
          if (element.type === 'side') { sideImage = element.name; }
        });
      }
      productNCondition = [{
        name: "Front View (Featured)",
        type: "front",
        ifUploaded: false,
        product_image: null,
        isRequired: true,
        isUploaded: false,
        path: frontImage,
        percentage: 1,
      },
      {
        name: "Open View",
        type: "open",
        ifUploaded: false,
        product_image: null,
        isRequired: false,
        isUploaded: false,
        path: openImage,
        percentage: 1,
      },
      {
        name: "Close-Up View",
        type: "closeup",
        ifUploaded: false,
        product_image: null,
        isRequired: false,
        isUploaded: false,
        path: closeupImage,
        percentage: 1,
      },
      {
        name: "Top View",
        type: "top",
        ifUploaded: false,
        product_image: null,
        isRequired: false,
        isUploaded: false,
        path: topImage,
        percentage: 1,
      },
      {
        name: "Side View",
        type: "side",
        ifUploaded: false,
        product_image: null,
        isRequired: false,
        isUploaded: false,
        path: sideImage,
        percentage: 1,
      },]
      setProductNCondition(productNCondition);
      isDefault = uom.isDefault === 1 ? true : false;
      setIsDefault(isDefault);
    }
    setIsUomEdit(isUomEdit)
  }, [uom]);
  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="main-modal maxwidth-600"
    >
      <img
        src={modalClose}
        alt="..."
        onClick={handleCloseDialog}
        className="modal-close"
      />
      <DialogContent>
        <h4 className="h4" style={{ marginBottom: 18 }}>
          {"UOM Info"}
        </h4>
        <div className="uom-content">
          <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: "24px" }}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className="form-group">
                <TextField
                  id="manageProducts_Barcode"
                  label="Barcode *"
                  value={barcode}
                  onChange={(e) => {
                    setBarcode(e.target.value);
                    setBarcodeError("");
                  }}
                />
                <span className="redspan">{barcodeError}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="form-group drop-input-group">
                <TextField
                  label="Unit name *"
                  id="uomName"
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                    setQuantityError("");
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="bg-pink">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={unit}
                          label="Unit"
                          
                          onChange={(e) => setUnit(e.target.value)}
                          MenuProps={MenuProps}
                        >
                          {/* <MenuItem value="" style={{ display: "none" }}>Unit</MenuItem> */}
                          {unitData.map((obj, index) =>
                            <MenuItem key={index} value={obj.id}>
                              {getdataByKey(obj?.unit_locales, 'en', 'name')}
                            </MenuItem>
                          )}
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
                <span className="redspan">{quantityError}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className="form-group">
                <TextField
                  label={"Relation With Base *"}
                  id="relationWithBase"
                  disabled={isUomEdit}
                  value={relationBase}
                  inputProps={{ maxLength: 5 }}
                  InputProps={{ inputComponent: wholeNumberMask }}
                  onChange={(e) => {
                    setRelationBase(isDefault ? "1" : e.target.value);
                    setRelationBaseError("");
                  }}
                />
                <span className="redspan">{relationBaseError}</span>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className="form-group">
                <TextField
                  label="Uom name Arabic *"
                  id="UomNameArabic"
                  value={uomNameEr}
                  onChange={(e) => {
                    setUomNameEr(e.target.value);
                    setUomNameErError("");
                  }}
                />
                <span className="redspan">{uomNameErError}</span>
              </div>
            </Grid>

          </Grid>
          <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: "24px" }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="form-group">
                <TextField
                  label="Price *"
                  value={price}
                  id="price"
                  onChange={(e) => {
                    setPrice(e.target.value);
                    setPriceError("");
                  }}
                  InputProps={{
                    inputComponent: amountMask,
                    endAdornment:
                      <InputAdornment position="end" className="bg-pink">QAR</InputAdornment>
                  }}
                />
                <span className="redspan">{priceError}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="form-group">
                <TextField
                  label="Sale Price"
                  value={salePrice}
                  id="salePrice"
                  onChange={(e) => {
                    setSalePrice(e.target.value);
                    setSalePriceError("");
                  }}
                  InputProps={{
                    inputComponent: amountMask,
                    endAdornment:
                      <InputAdornment position="end" className="bg-pink">QAR</InputAdornment>
                  }}
                />
                <span className="redspan">{salePriceError}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="form-group">
                <TextField
                  className="disabled50 outline-none"
                  placeholder={`${"Platform Fee"} (${adminCommission}%)`}
                  value={platformFee}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end" className="bg-pink">QAR</InputAdornment>
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="form-group">
                <TextField
                  className="disabled50"
                  placeholder="You'll Earn"
                  value={youEarn}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end" className="bg-pink">You'll Earn</InputAdornment>
                  }}
                />
              </div>
            </Grid>

            {/* <Grid item xs={12}>
              <div className="switch-box">
                <label className="h6">Stock<span className="required-star">*</span></label>
                <Switch
                  checked={inStock}
                  onChange={(e) => setInStock(e.target.checked)}
                />
              </div>
            </Grid> */}

            <Grid item xs={12}>
              <div className="switch-box">
                <label className="h6">Status<span className="required-star">*</span></label>
                <Switch
                  checked={status}
                  onChange={(e) => setStatus(e.target.checked)}
                />
              </div>
            </Grid>

            
          </Grid>
          <h6 className="h6 ">{"Product Images"}</h6>
          <p className="p3">{"File format JPG|JPEG|PNG"}</p>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={`uploads-row`}>
              {productNCondition.map((obj, index) => 
              <div className="" key={index}>
                <div className="upload-multiple">
                  {obj.product_image !== null ? <>
                    <img
                      src={cancelRed}
                      alt=".."
                      onClick={() => deleteImage(obj.type)}
                      className="delete-icon"
                    />
                    <img
                      src={obj.product_image}
                      alt=".."
                      className="id-img"
                    />
                    <Timer type={obj.type} />
                  </>: obj.path !== "" ? <>
                    <img
                      src={cancelRed}
                      alt=".."
                      onClick={() => deleteImage(obj.type)}
                      className="delete-icon"
                    />
                    <img
                      src={`${constant.IMG_BASE_URL}${obj.path}`}
                      alt=".."
                      className="id-img"
                    />
                  </>: <>
                    <Upload
                      listStyle={{ borderRadius: "50%" }}
                      listType="picture-card"
                      showUploadList={false}
                      beforeUpload={(files) =>
                        beforeUploads(files, obj.type)
                      }
                      accept={".jpg,.jpeg,.png"}
                      onChange={(file) => onImageChange(file, obj.type)}
                    >
                      <img
                        src={uploadImg}
                        alt=".."
                        className="upload-icon"
                      />
                      <p className="upload-text">{"Upload"}</p>
                    </Upload>
                    {/* <ImgCrop></ImgCrop> */}
                  </>}
                </div>
                <p className="p2">
                  {obj.name}{" "}{obj.isRequired ? <span className="required-star">*</span>: null}
                </p>
              </div>)}
              <span className="redspan" id="imageSpan">{featureImgErr}</span>
            </div>
          </Grid>
          <div className="">
            <h6 className="h5">{"Modifiers"}</h6>
            <p className="p4">{"Add modifire to this UOM which you want to add"}</p>
            <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: "24px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                <Autocomplete
                multiple
                fullWidth
                id="checkboxes-tags-demo"
                options={addOnOption}
                value={selectedAddOns}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <li {...props}>{option.label}</li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Select Add on" placeholder="Select Add on" />
                )}
                onChange={(event, value) => {
                  selectedAddOns = value;
                  setSelectedAddOns(selectedAddOns)
                }}
              />
                </div>
                {/* {AddonsList} */}
                {/* <Stack direction="row" spacing={1}>
                  <Chip label="Choose a topping" onDelete={handleDelete} />
                  <Chip label="Choose a size" onDelete={handleDelete} />
                </Stack> */}
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="uom-footer">
          <FormControlLabel
            control={<Checkbox
              hidden
              checked={isDefault}
              onChange={(e) => {
                setIsDefault(e.target.checked);
                setRelationBase(e.target.checked ? "1" : relationBase);
              }}
            />}
            // label="Set it as default base"
          />
          <Button
            variant="contained"
            size="large"
            type="button"
            className="min-width140"
            onClick={handleAddUom}
          >Save</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddUOM;
