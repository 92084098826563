import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
  GET_ALL_ADDONS: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Addons.GET_ALL_ADDONS(query),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_PRODUCT: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Products.GET_PRODUCT(id),
          { headers: {
            "x-api-key": constant.X_API_KEY,
            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
          }, }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_EDIT_REQUEST_PRODUCTS: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Products.GET_EDIT_REQUEST_PRODUCTS(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_EDIT_PRODUCT: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Products.GET_EDIT_PRODUCT(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  ADD_PRODUCT: (data) => {
    return new Promise(async (resolve, reject) => {

      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Products.ADD_PRODUCT(),
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
 
  UPDATE_PRODUCT: (id, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Products.UPDATE_PRODUCT(id),
          updatedData,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  
  UPDATE_STATUS_APPROVE: (id, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Products.UPDATE_STATUS_APPROVE(id),
          updatedData,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  UPDATE_EDIT_STATUS_APPROVE: (id, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Products.UPDATE_EDIT_STATUS_APPROVE(id),
          updatedData,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
 
  DELETE_PRODUCT: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.delete(
          constant.BASE_URL + api.Products.DELETE_PRODUCT(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  UPDATE_STATUS_BY_ID: (id, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Products.UPDATE_STATUS_BY_ID(id),
          updatedData,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  UPLOAD_UOM_IMAGE: (data) => {
    return new Promise(async (resolve, reject) => {

      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Products.UPLOAD_PRODUCT_IMAGE_UOM,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  ADD_UOM: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Products.ADD_UOM,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_UOM: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Products.GET_UOM(id),
          { headers: {
            "x-api-key": constant.X_API_KEY,
            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
          }, }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  DELETE_UOM: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.delete(
          constant.BASE_URL + api.Products.DELETE_UOM(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  UPDATE_UOM: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Products.UPDATE_UOM(data.id),
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

};
