import { Button, Typography, Grid, Box } from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../ENUM";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { decode } from "base-64";
import TextField from "@material-ui/core/TextField";
import arrowLeftBlackImg from "../../../assets/images/structure/arrow-left-black.svg";

export default function CuisineCategory() {
  const dispatch = useDispatch();
  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const navigate = useNavigate();

  const params = useParams();
  let data = params.data;
  const [existData, setExistData] = useState(null);

  useEffect(() => {
    if (data !== undefined) {
      let d = decodeURIComponent(decode(data));
      try {
        let json = JSON.parse(sessionStorage.getItem('cuisine-category'));
        setExistData(json);
      } catch (e) {
        navigate("/cuisine-category-listing");
      }
    }
  }, [data]);

  useEffect(() => {
    if (existData !== null) {
      setNameAr(existData?.name_ar);
      setNameEn(existData?.name_en);
    }
  }, [existData]);

  const addCuisine = () => {
    if (nameEn && nameAr) {
      dispatch(changeLoader(true));

      let url = API.CUISINE.ADD_CATEGORY;
      let body = {
        name_en: nameEn,
        name_ar: nameAr,
      };
      let method = "post";
      if (existData !== null) {
        url = API.CUISINE.EDIT_CATEGORY;
        body.cuisineCategoryId = existData?.id;
        method = "put";
      }

      GlobalService(url, body, method)
        .then((data) => {
          if (data?.ack == 1) {
            navigate("/cuisine-category-listing");
          }
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: data?.msg,
              state: data?.ack == 1 ? "success" : "error",
            })
          );
        })
        .catch((err) => {
          let msg = err.response.body.msg;
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: msg,
              state: "error",
            })
          );
        })
        .finally(() => {
          dispatch(changeLoader(false));
        });
    } else {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: "All field must be filled ",
          state: "error",
        })
      );
    }
  };

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30 min-h-89vh">
            <Grid container spacing={3} xs={12} md={6}>
              <Grid item xs={12}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={2} width={'100%'}>
                  <Box display={'flex'} alignItems={'center'} mb={2}>
                    <img
                      src={arrowLeftBlackImg} alt="back-icon" style={{ cursor: 'pointer' }}
                      onClick={() => navigate(-1)}
                    />
                    <Typography variant="h6" component="h6" style={{ marginLeft: "12px" }} fontWeight={500}>
                      {existData == null ? "Add" : "Edit"} Cuisine Category
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="outlined-required"
                  value={nameEn}
                  inputProps={{ maxLength: 80 }}
                  onChange={(e) => {
                    let value = e.target.value;
                    setNameEn(value);
                  }}
                  label="Cuisine Category English Name *"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => {
                    let value = e.target.value;
                    setNameAr(value);
                  }}
                  inputProps={{ maxLength: 80 }}
                  value={nameAr}
                  id="outlined-required"
                  label="Cuisine Category Arabic Name *"
                />
              </Grid>
              <Grid item xs={12}>
                <Button onClick={addCuisine} variant="contained" size="large" color="primary">
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
