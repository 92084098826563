import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Typography, TextField, Button, Alert, Switch, Grid, InputAdornment,
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Pagination, Tooltip, Select
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CrossIcon from "@mui/icons-material/Close";
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { useEffect, useState } from "react";
import services from "../../../services";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../../pages/ENUM";
import DeleteConfirmBox from "../../../dialog/DeleteConfirmBox";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { changeLoader } from "../../../redux/reducers/loader";
import { changePermission, currentPermission } from "../../../redux/reducers/permission";

// image
export default function ManageRole() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permission = useSelector(currentPermission);
  const limit = 10;

  let [canEdit, setCanEdit] = useState(true);
  let [canCreate, setCanCreate] = useState(true);
  let [canDelete, setCanDelete] = useState(true);

  let [search, setSearch] = useState("");
  let [page, setPage] = useState(1);
  let [total, setTotal] = useState(0);
  let [onOpenDeleteModal, setOpenDeleteModal] = useState(false);
  let [deleteId, setDeleteId] = useState(0);
  const [roleList, setRoleList] = useState([]);

  const getRoleList = async () => {
    dispatch(changeLoader(true));
    let url = API.GET_ALL_USER_ROLES;
    url += `?limit=${limit}&page=${page}`;
    url += search ? `&search=${search}` : "";
    const response = await GlobalService(url);
    setRoleList(response.data);
    dispatch(changeLoader(false));
  };

  const handleDelete = async () => {
    dispatch(changeLoader(true));
    setOpenDeleteModal(false);
    let url = API.DELETE_ROLE(deleteId);
    const response = await GlobalService(url, {}, "delete");
    if (response.ack === 1) {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response.msg,
        state: 'success'
      }));
      dispatch(changeLoader(false));
      getRoleList();
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response.msg,
        state: 'error'
      }));
      dispatch(changeLoader(false));
    }
  };

  const refreshAuthToken = async () => {
    const response = await services.auth.REFRESH_TOKEN();
    if (response?.data?.ack === 1) {
      localStorage.removeItem("access_token");
      localStorage.setItem("access_token", response?.data?.data);
      dispatch(changePermission());
    }
  };

  useEffect(() => {
    // getRoleList();
    refreshAuthToken();
  }, []);

  useEffect(() => {
    const getData = setTimeout(() => {
      page = 1; setPage(page); getRoleList();
    }, 500);
    return () => clearTimeout(getData);
  }, [search]);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanEdit(permission?.rolesPermissions.edit === 1);
      setCanCreate(permission?.rolesPermissions.create === 1);
      setCanDelete(permission?.rolesPermissions.delete === 1);
    } else {
      setCanEdit(true);
      setCanCreate(true);
      setCanDelete(true);
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>Manage Role</Typography>
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small" size="small" value={search}
                    placeholder="Search here.."
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      startAdornment: <SearchIcon sx={{ marginRight: "3px" }} />,
                      endAdornment: search!==''?<InputAdornment position="end"> <CrossIcon
                        className="cursor-pointer"
                        onClick={() => {
                          search = ''; setSearch(search);
                        }}
                      /> </InputAdornment>: null,
                    }}
                  />
                </div>
                { canCreate?
                  <Button
                  variant="contained" size="medius" startIcon={<AddCircleOutlineIcon />}
                  onClick={() => navigate("/roles-permissions")}
                > Add Roles & Permissions </Button>: null}
              </div>
            </div>
            {roleList?.length > 0?<TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Roles</TableCell>
                    <TableCell>Users</TableCell>
                    <TableCell className="nowrap" align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roleList?.map((role, index) =>
                  <TableRow key={`role-${index}`}>
                    <TableCell component="th" scope="row">{role?.name}</TableCell>
                    <TableCell>{role?.count}</TableCell>
                    <TableCell align="center" className="nowrap">
                      <Tooltip title="Edit" placement="top" sx={{ marginRight: 1 }}>
                        <BorderColorIcon
                          color="action" fontSize="small"
                          onClick={() => navigate(`/roles-permissions/${role?.id}`)}
                        />
                      </Tooltip>
                      <Tooltip title="Delete" placement="top" sx={{ marginRight: 1 }}>
                        <DeleteForever
                          color="error" fontSize="small"
                          onClick={() => {
                            setDeleteId(role?.id);
                            setOpenDeleteModal(true);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>)}
                </TableBody>
              </Table>
            </TableContainer>: <NoDataFound text="No Role Found" />}
            {total > 1?<Pagination count={total} page={page} color="primary" />: null}
          </Box>
        </Box>
      </Box>
      { onOpenDeleteModal ? <DeleteConfirmBox
          handleClose={() => setOpenDeleteModal(false)}
          title="Delete Role"
          description="Are you sure you want to delete this role?"
          handleConfirm={() => handleDelete()}
        />: null }
    </>
  );
}
