import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Button, Typography, TextField, Grid, Divider, Alert, Box, InputLabel, Menu, MenuItem, Switch,
  FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, Chip
} from "@mui/material";
import "./AddProduct.scss";
import {getdataByKey} from "../../helper/custom"
import { CKEditor } from "@ckeditor/ckeditor5-react";
import editBlack from "../../../assets/images/structure/edit-black.svg";
import deleteBlack from "../../../assets/images/structure/delete-black.svg";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Aside from "../../layout/aside/Aside";
import { useState, useEffect } from "react";
import Header from "../../layout/header/Header";
import services from "../../../services";
import { stateWithValidation } from "../../helper/Validation";
import { getAllBarcode, priceHandler } from "../../helper/custom"
import uomEmpty from "../../../assets/images/structure/uom-empty.png";
import AddUOM from "./AddUOM";
import threeDotsBlack from "../../../assets/images/structure/three-dots-black.svg";
import { currentPermission } from "../../../redux/reducers/permission";
import { useSelector, useDispatch } from "react-redux";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../../pages/ENUM";
import CategoryComponent from "./ProductComponent/CategoryComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ActiveInactiveStoreUom from "../../../dialog/ActiveInactiveStoreUom";
import ProductUomInactiveModal from "../../../dialog/ProductUomInactiveModal";
import { currentBaladiInfo } from "../../../redux/reducers/baladiInfo";
import { changeLoader } from "../../../redux/reducers/loader";

export default function AddProduct() {

  const dispatch = useDispatch();
  const permission = useSelector(currentPermission);
  const baladiInfo = useSelector(currentBaladiInfo);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { product_id } = useParams();
  const tempIdAddUom = `_${(new Date()).getTime()}`;
  let [canEdit, setCanEdit] = useState(true);
  let [categoryArray, setCategoryArray] = useState([]);
  let [storeList, setStoreList] = useState([]);
  let [tag, setTag] = useState([]);
  let [uoms, setUoms] = useState([]);
  let [uom, setUom] = useState(null);
  let [editProductData, setEditProductData] = useState(null);
  let [categoryData, setCategoryData] = useState([]);
  let [subCategoryList, setSubCategoryList] = useState([]);
  let [subSubCategoryList, setSubSubCategoryList] = useState([]);
  let [adminCommission, setAdminCommission] = useState(0);
  let [deletedUom, setDeletedUom] = useState([]);
  let [categoryEditData, setCategoryEditData] = useState([]);
  let [openStatusActiveInactive, setOpenStatusActiveInactive] = useState(false);
	let [openUomInactiveModal, setOpenUomInactiveModal] = useState(false);
  let [storeForActiveInactive, setStoreForActiveInactive] = useState([]);
  let [selectedUom, setSelectedUom] = useState(null);
  let [selectedStatus, setSelectedStatus] = useState(null);
  
  const [toaster, setToaster] = useState({ visibilty: false, status: "", text: "" });
  const [tagValue, setTagValue] = useState("");
  const [tagArabic, setTagArabic] = useState([]);
  const [tagValueArabic, setTagValueArabic] = useState("");
  const [AddonsList,setAddonsList]=useState([]);
  const [productTitle, setProductTitle] = useState({
    val: "",
    err: "please enter product title",
    isValid: false,
  });
  const [productTitleArabic, setProductTitleArabic] = useState({
    val: "",
    err: "please enter arabic product title",
    isValid: false,
  });
  const [price, setPrice] = useState({
    val: "",
    err: "please enter product price",
    isValid: false,
  });
  let [store, setStore] = useState({
    val: null,
    err: "please select brand",
    isValid: false,
  });
  const [paragraph, setParagraph] = useState({
    val: "",
    err: "please enter product description",
    isValid: false,
  });
  const [paragraphArabic, setParagraphArabic] = useState({
    val: "",
    err: "please enter arabic product description",
    isValid: false,
  });
  const [itemCode, setItemCode] = useState({
    val: "",
    err: "please enter product item code",
    isValid: false,
  });
  const [quantity, setQuantity] = useState({
    val: "",
    err: "please enter product Quantity",
    isValid: false,
  });
  const [stock, setStock] = useState(true);
  const [unitData, setUnitData] = useState([]);
  const [category, setCategory] = useState({
    val: "",
    err: "please select category",
    isValid: false,
  });
  const [subCategory, setSubCategory] = useState({
    val: "",
    err: "please select sub category",
    isValid: false,
  });
  const [subSubCategory, setSubSubCategory] = useState({
    val: "",
    err: "please select sub sub category",
    isValid: false,
  });
  const [isValidate, setIsValidate] = useState(false);
  const [openAddUnitMeasurement, setOpenAddUnitMeasurement] = useState(false);

  const routerProductListing = () => {
    const path = `/product-listing?${searchParams.toString()}`;
    // navigate(path);
    window.location.href = path;
  };

  useEffect(() => {
    // console.log(searchParams.get('all'));
    console.log(searchParams.toString());
    getUnit();
    getAllStores();
  }, []);

  const getAllBarcodes  = (barcodes) => {
    let allBarcodes = [];
    barcodes?.forEach((barcode) => {
      allBarcodes.push(barcode.barcode);
    });
    return allBarcodes.join(", ");
  }

  const BasicMenu = ({ index, canEdit }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleDeleteUOM = async (index) => {
      const selectedUom = uoms[index];
      if (selectedUom?.isDefault === 1) {
        setToaster({
          status: "error",
          text: "Default UOM can not be deleted",
          visibilty: true,
        });
        setTimeout(() => {setToaster({ ...toaster, visibilty: false });}, 7000);

      } else {
        if (selectedUom?.id) {
          deletedUom.push(selectedUom.id);
          setDeletedUom(deletedUom);
        }
        const allUoms = [...uoms];
        allUoms.splice(index, 1);
        uoms = allUoms;
        setUoms(uoms)
      }
    }
  
    const handleEditUOM = async (index) => {
      const selectedUom = uoms[index];
      let uomId = '';
      if (selectedUom?.id) {
        uomId = selectedUom.id;
        const uomData =  await services.Products.GET_UOM(uomId)
        if(uomData.data.ack === 1) {
          uom = uomData?.data?.uomData
          uom.index = index;
          setUom(uom)
          setOpenAddUnitMeasurement(true);
        } else {
          setToaster({
            status: "error",
            text: uomData.data.msg,
            visibilty: true,
          });
          setTimeout(() => {setToaster({ ...toaster, visibilty: false });}, 7000);
        }
      } else {
        uom = selectedUom
        uom.index = index;
        setUom(uom)
        setOpenAddUnitMeasurement(true);
      }
    }

    return (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <img src={threeDotsBlack} alt="" />
        </Button>
        <Menu
          id={"basic-menu"}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {canEdit?
          <MenuItem onClick={() => { handleEditUOM(index); handleClose(); }}>
            <img src={editBlack} alt="" />
            <span className="p2-drop-menu">Edit</span>
          </MenuItem>: null}
          {canEdit?<MenuItem onClick={() => { handleDeleteUOM(index); handleClose(); }}>
            <img src={deleteBlack} alt="" />
            <span className="p2-drop-menu">Delete</span>
          </MenuItem>: null}
        </Menu>
      </div>
    );
  };

  const productHandle = (e) => {
    if (e.target.value) {
      if (e.target.value.length > 80) {
        setProductTitle({
          val: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
          err: "max 80 character allowed",
          isValid: false,
        });
      } else {
        setProductTitle({ val: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1), err: "", isValid: true });
      }
    } else {
      setProductTitle({ val: "", err: "Required", isValid: false });
    }
    setIsValidate(false);
  }

  const handleCategory = (id) => {
    setCategory({
      val: id,
      err: "",
      isValid: true,
    });
    if (store?.data?.business_type?.category_level > 1) {
      setSubCategory({
        val: "",
        err: "please select sub category",
        isValid: false,
      });
      setSubSubCategory({
        val: "",
        err: "please select sub sub category",
        isValid: false,
      });
      getSubCategory(id);
      setSubSubCategoryList([]);
    }
  }

  function handleSubCategory(id) {
    setSubCategory({ val: id, err: "", isValid: true });
    setSubSubCategory({
      val: "",
      err: "please select sub sub category",
      isValid: false,
    });
    if (store?.data?.business_type?.category_level > 2) {
      getSubSubCategory(id);
    }
  }

  function handleSubSubCategory(id) {
    setSubSubCategory({ val: id, err: "", isValid: true });
  }

  function handleParagraph(e, editor) {
    const val = editor
      .getData()
      .toString()
      .replace(/(<([^>]+)>)/gi, "");
    if (val) {
      if (val.length < 1) {
        setParagraph({
          val: editor.getData(),
          err: "Please enter product description",
          isValid: false,
        });
      } else {
        setParagraph({ val: editor.getData(), err: "", isValid: true });
      }
    } else {
      setParagraph({
        val: editor.getData(),
        err: "Paragraph Required",
        isValid: false,
      });
    }
  }

  const getUnit = async () => {
    try {
      const data = (await services.Unit.GET_ALL_UNITS()).data.units.rows;
      if (data) {
        setUnitData(data.map((item) => {
          item.name = item.unit_locales[0].name;
          return item;
        }).sort((a, b) => a.name.localeCompare(b.name)));
      }
    } catch (error) {
      console.log("Error_In_product_type", error);
    }
  };

  const getAllStores = async () => {
    try {
      // const data = (await services.Stores.GET_ALL_STORE()).data.stores;
      const response = await GlobalService(API.GET_ALL_BRANDS_DROPDOWN);

      if (response?.stores) {
        storeList = response?.stores.map(
          store => {
            let item = store;
            item.value = store.id;
            item.name = getdataByKey(store?.stores_locales,'en','business_name')
            return item
          }
        );
        setStoreList(storeList);
        if (product_id) {getProductById(product_id);}
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const getCategory = async (businessTypeId) => {
    if (businessTypeId && businessTypeId != "") {
      try {
        const query = new URLSearchParams({
          parent_id: 0,
          businessTypeId,
          storeId: store.val.id,
        }).toString();
        const newData = await GlobalService(API.STORE_CATEGORIES(store.val.id))
        const categoriesGet = newData.results.map((item) => {
          item.category_locales = [{ "name": item.name, "description": "", "locale": "en" }]
          return item;
        });
        const data = categoriesGet
        // (await services.Category.GET_CATEGORIES(query)).data
        //   .categories;
        categoryData = data;
        setCategoryData(categoryData);
        setCategoryArray([{ parent: '', subCategory: '', subSubCategory: '' }])
        if (product_id) {
          getCategoriesForEdit();
        }
      } catch (er) {
        console.log(er);
      }
    }
  };

  const getSubCategory = async (id) => {
    try {
      const query = new URLSearchParams({parent_id: id,}).toString();
      const data = (await services.Category.GET_CATEGORIES(query)).data.categories;
      setSubCategoryList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAddons = async (id) => {
    try {
      const query = new URLSearchParams({
        store_id: id,
      }).toString();
      const data = (await services.Addons.GET_ALL_ADDONS(query)).data;
      if(data.ack==1){
        let addondata=[];
        data.addons.map((item)=>{
          addondata.push({label:getdataByKey(item?.addons_locales,'en','name'),id:item?.id});
        })
        setAddonsList(addondata);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubSubCategory = async (id) => {
    try {
      const query = new URLSearchParams({parent_id: id,}).toString();
      const data = (await services.Category.GET_CATEGORIES(query)).data.categories;
      setSubSubCategoryList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const insertTag = (e, type = "english") => {
    if (e.key == "Enter") {
      if (type == "arabic") {
        setTagArabic((tagArabic) => [
          ...tagArabic,
          { name: tagValueArabic, index: parseInt(Math.random() * 9999) },
        ]);
        setTagValueArabic("");
      } else {
        setTag((tag) => [
          ...tag,
          { name: tagValue, index: parseInt(Math.random() * 9999) },
        ]);
        setTagValue("");
      }
    }
  };

  const deleteTag = (index, type = "english") => {
    if (type == "arabic") {
      setTagArabic(tagArabic.filter((a) => a.index !== index));
    } else {
      setTag(tag.filter((a) => a.index !== index));
    }
  };

  const onSaveHandler = async () => {
    dispatch(changeLoader(true));
    let categoryId = [];
    switch (store?.data?.business_type?.category_level) {
      case 1: {
        categoryId = categoryArray.filter((obj) => obj.parent !== "").map((obj) => obj.parent);
        break;
      }
      case 2: {
        categoryId = categoryArray.filter((obj) => obj.subCategory !== "").map((obj) => obj.subCategory);
        break;
      }
      case 3: {
        categoryId = categoryArray.filter((obj) => obj.subSubCategory !== "").map((obj) => obj.subSubCategory);
        break;
      }
      default:
        categoryId = [];
        break;
    }
    if (categoryId.length === 0) {
      setToaster({
        status: "error",
        text: "Please select at-least one category",
        visibilty: true,
      });
      setTimeout(() => {setToaster({ ...toaster, visibilty: false });}, 7000);
    }
    let uonIds = uoms.map((obj) => obj.id);
    if (uonIds.length === 0) {
      setToaster({
        status: "error",
        text: "Please select at-least one UOM",
        visibilty: true,
      });
      setTimeout(() => {setToaster({ ...toaster, visibilty: false });}, 7000);
    }
    if (
      store.isValid &&
      productTitle.isValid &&
      productTitleArabic.isValid &&
      paragraph.isValid &&
      paragraphArabic.isValid &&
      uonIds.length > 0 &&
      categoryId.length > 0
    ) {
      try {
        let tagging = [];
        tag.concat(tagArabic).forEach((obj) => { tagging.push(obj.name); });
        uoms = uoms.map((obj) => {
          if (obj?.old) {
            return {
              id: obj.id,
              barcode: getAllBarcodes(obj.uom_barcode_relations),
              uomName: obj.uomName,
              unitId: obj.unitId,
              relationWithBase: obj.relationWithBase,
              price: obj.price,
              salePrice: obj.salePrice,
              stock: obj.stock,
              isDefault: obj.isDefault,
              image: obj.uom_images.map((obj) => {
                return { name: obj?.image, type: obj?.type }
              }),
            }
          }
          return obj;
        });

        const saveData = {
          store_id: store.val.id,
          title: productTitle.val,
          title_arabic: productTitleArabic.val,
          itemCode: itemCode.val,
          description: paragraph.val,
          description_arabic: paragraphArabic.val,
          tags: tagging.join(","),
          availability_start_time: "",
          availability_end_time: "",
          uoms: uoms,
          categoryId: categoryId.join(","),
        };
        let response = {};
        if(product_id) {
          response = await services.Products.UPDATE_PRODUCT(product_id, saveData) 
        } else {
          response = await services.Products.ADD_PRODUCT(saveData);
        }

        if (response?.data?.ack === 1) {
          setToaster({
            status: "success",
            text: `Product Added Successfully`,
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({ ...toaster, visibilty: false });
          }, 2500);
          // navigate(`/product-listing?${searchParams.toString()}`);
          window.location.href = `/product-listing?${searchParams.toString()}`;
        } else {
          let errMsg = response?.data?.msg;

          if (response?.data?.errMsg) {
            response?.data?.errMsg.forEach(element => {
              for (const [key, value] of Object.entries(element)) {
                if (typeof value === 'object') {
                  for (const [key1, value1] of Object.entries(value)) {
                    if (typeof value1 === 'object') {
                      for (const [key2, value2] of Object.entries(value1)) {
                        errMsg = `${value2}`;
                      }
                    } else {
                      errMsg = `${value1}`;
                    }
                  }
                } else {
                  errMsg = `${value}`;
                }
              }
            });
          }
          setToaster({
            status: "error",
            text: errMsg || `Something Went Wrong`,
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({ ...toaster, visibilty: false });
          }, 2500);
        }
      } catch (error) {
        setToaster({
          status: "error",
          text: `Something Went Wrong`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 2500);
      }
    } else if (!isValidate) {
      setIsValidate(true);
    }
    dispatch(changeLoader(false));
  };
  
  const OpenAddUnitMeasurement = () => {
      setOpenAddUnitMeasurement(true);
      uom = null;
      setUom(uom)
  };

  const handleSelectStore = (e, value) => {
    if (!value) {
      setStore({
        val: value,
        err: "please select brand",
        isValid: false,
      });
      categoryArray = [];
      setCategoryArray(categoryArray);
      setCategoryData([]);
      setSubCategoryList([]);
      setSubSubCategoryList([]);
      setCategory({
        val: "",
        err: "",
        isValid: false,
      });
      setSubCategory({
        val: "",
        err: "",
        isValid: false,
      });
      setSubSubCategory({
        val: "",
        err: "",
        isValid: false,
      });
      setSubCategory([]);
      setSubSubCategory([]);
      return;
    }
    const stores = storeList.filter((item) => item.id === value?.id)[0]
    store = {
      val: value,
      data: stores,
      err: "",
      isValid: true,
    }
    setStore(store);
    getCategory(stores?.businessTypeId);
    getAddons(stores?.id);
    setCategory({
      val: "",
      err: "please select category",
      isValid: false,
    });
    setSubCategory({
      val: "",
      err: "please select sub category",
      isValid: false,
    });
    setSubSubCategory({
      val: "",
      err: "please select sub sub category",
      isValid: false,
    });
    setSubCategory([]);
    setSubSubCategory([]);
    adminCommission = stores?.admin_commission;
    setAdminCommission(adminCommission);
    setIsValidate(false);
  }

  const getCategoriesForEdit = async () => {
    dispatch(changeLoader(true));
    categoryArray = [];
    const categoryLevel = editProductData.store.business_type.category_level;
    categoryEditData.forEach(element => {
      const parent = element?.find((item) => item?.parent_id === 0)?.id;
      const subCategory = categoryLevel > 1?element?.find((item) => item?.parent_id === parent)?.id: '';
      const subSubCategory = categoryLevel > 2?element?.find((item) => item?.parent_id === subCategory)?.id: '';
      categoryArray.push({ parent, subCategory, subSubCategory });
    });
    setCategoryArray(categoryArray);
    dispatch(changeLoader(false));
  }

  const getProductById = async (id) => {
    dispatch(changeLoader(true));
    try {
      const requestData = await services.Products.GET_PRODUCT(id); 
      const getData = requestData.data.product;
      categoryEditData = requestData?.data.categories;
      setCategoryEditData(categoryEditData)
      editProductData = getData;
      setEditProductData(editProductData);
      store = {
        val: storeList.find((item) => item.id === getData?.brandId),
        data: storeList.find((item) => item.id === getData?.brandId),
        err: "",
        isValid: true,
      };
      getAddons(getData?.brandId);
      setStore(store);
      setProductTitle({
        val: getdataByKey(getData?.products_locales,'en','title'),
        err: "please enter product title",
        isValid: true,
      });
      setProductTitleArabic({
        val: getdataByKey(getData?.products_locales,'ar','title'),
        err: "please enter arabic product title",
        isValid: true,
      });
      setParagraph({
        val: getdataByKey(getData?.products_locales,'en','description'),
        err: "please enter product description",
        isValid: true,
      });
      setParagraphArabic({
        val: getdataByKey(getData?.products_locales,'ar','description'),
        err: "please enter arabic product description",
        isValid: true,
      });
      setItemCode({
        val: getData?.itemCode,
        err: "please enter product item code",
        isValid: true,
      });
      uoms = getData?.uoms;
      uoms.map((obj) => {
        obj.old = true;
        return obj;
      })
      setUoms(uoms)
      tag = getData?.product_tag_relations.map((item, index) => {
        return { name: item?.tag?.tag, index: index }
      });
      setTag(tag);
      const storeEdit = storeList.find((item) => item.id === editProductData?.brandId);
      const business_type_id = storeEdit?.business_type?.id;
      getCategory(business_type_id);
    } catch (error) {
      console.log(error);
    }
    dispatch(changeLoader(false));
  };

  const AddCategory = () => {
    setCategoryArray([...categoryArray, { parent: '', subCategory: '', subSubCategory: '' }])
  }

  useEffect(() => {
    if (permission.role !== "admin") {
      if (product_id) {
        setCanEdit(permission?.addProduct?.edit === 1);
      } else {
        setCanEdit(permission?.addProduct?.create === 1);
      }
    } else {
      setCanEdit(true)
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty ? 
            <Alert severity={toaster.status}>{toaster.text}</Alert>: null}
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  {product_id?'Update':'Add'} Product
                </Typography>
              </Grid>

              <Grid item xs={10}>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={4}
                  style={{ pointerEvents: product_id?'none': '' }}
                  >
                    <FormControl fullWidth required>
                      {/* <InputLabel id="demo-simple-select-label">Select Store</InputLabel> */}
                      {/* <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={store.val}
                        label="Select Store"
                        onChange={handleSelectStore}
                        disabled={!canEdit}
                      >
                        {storeList &&
                          storeList.length > 0 ?
                          storeList.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {getdataByKey(item?.stores_locales,'en','business_name')}
                            </MenuItem>
                          )): null}
                      </Select> */}
                      <Autocomplete
                        value={store.val}
                        onChange={handleSelectStore}
                        options={storeList}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Select Brand" />}
                      />
                      {!store.isValid && isValidate && (<div className="error">{store.err}</div>)}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Product Title"
                      value={productTitle.val}
                      inputProps={{ maxLength: 80 }}
                      onChange={productHandle}
                      disabled={!canEdit}
                    />
                    {!productTitle.isValid && isValidate && (
                      <div className="error">{productTitle.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Arabic Product Title"
                      value={productTitleArabic.val}
                      inputProps={{ maxLength: 80 }}
                      disabled={!canEdit}
                      onChange={(e) => {
                        setProductTitleArabic(
                          stateWithValidation((e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)), 'title')
                        );
                        setIsValidate(false);
                      }}
                    />
                    {!productTitleArabic.isValid && isValidate && (
                      <div className="error">{productTitleArabic.err}</div>
                    )}
                  </Grid>

                  {categoryData.length > 0?
                  <Grid item xs={12}>

                    {store.data.id === baladiInfo.BALADI_BRAND_Id?
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
                      <Typography variant="h6" component="h6" fontWeight={500}>Add Category</Typography>
                      {canEdit?
                        <Button
                          type="button" variant="contained" size="small" className="min-width148"
                          onClick={AddCategory}
                      > Add Category {}</Button>: null}
                    </Box>
                    : null}
                      {categoryArray.length > 0 && categoryArray.map((item, index) => 
                      <Grid container spacing={3} mb={2} key={`CategoryComponent-${index}`}>
                        <Grid item xs={11}>
                          <CategoryComponent
                            categoryArray={categoryArray}
                            categoryIndex={index}
                            categoryItem={item}
                            isEdit={product_id ? true : false}
                            categoryLevel={store?.data?.business_type?.category_level}
                            store={store}
                            categories={categoryData}
                            updateParent={(catId) => {
                              const categoryArrayCopy = [...categoryArray];
                              categoryArrayCopy[index].parent = catId;
                              setCategoryArray(categoryArrayCopy);
                              setIsValidate(false);
                            }}
                            updateSubCategory={(catId) => {
                              const categoryArrayCopy = [...categoryArray];
                              categoryArrayCopy[index].subCategory = catId;
                              setCategoryArray(categoryArrayCopy);
                              setIsValidate(false);
                            }}
                            updateSubSubCategory={(catId) => {
                              const categoryArrayCopy = [...categoryArray];
                              categoryArrayCopy[index].subSubCategory = catId;
                              setCategoryArray(categoryArrayCopy);
                              setIsValidate(false);
                            }}
                        />
                        </Grid>
                        {store.data.id === baladiInfo.BALADI_BRAND_Id?
                        <Grid item xs={1}>
                          <Button variant="contained" size="small" type="button" className="min-width148"
                            style={{ color: "#fff", textTransform: "capitalize", }}
                            // onClick={() => {
                            //   const categoryArrayCopy = [...categoryArray];
                            //   categoryArrayCopy.splice(index, 1);
                            //   setCategoryArray(categoryArrayCopy);
                            //   setIsValidate(false);
                                //     }}
                                onClick={() => {
                                  const categoryArrayCopy = [...categoryArray];
                                  categoryArrayCopy.splice(index, 1);
                                  console.log("categoryArrayCopy", categoryArrayCopy);
                                  setCategoryArray([]);
                                  setTimeout(() => {
                                      setCategoryArray(categoryArrayCopy);
                                      setIsValidate(false);
                                  }, 20); // Adjust the delay time as needed
                              }}
                              >
                                Remove </Button>
                        </Grid>
                        : null}
                      </Grid>
                      )}
                  </Grid>: null}
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Item Code"
                      value={itemCode.val}
                      disabled={!canEdit}
                      onChange={(e) => {
                        setItemCode(stateWithValidation(e.target.value));
                        setIsValidate(false);
                      }}
                    />
                    {!itemCode.isValid && isValidate && (
                      <div className="error">{itemCode.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <CKEditor
                      config={{
                        toolbar: {
                          items: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "underline",
                            "|",
                            "link",
                            "|",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "undo",
                            "redo",
                          ],
                        },
                      }}
                      onChange={(e, editor) => {
                        handleParagraph(e, editor);
                        setIsValidate(false);
                      }}
                      editor={ClassicEditor}
                      data={paragraph.val}
                      disabled={!canEdit}
                    />
                    {!paragraph.isValid && isValidate && (
                      <div className="error">{paragraph.err}</div>
                    )}
                  </Grid>
                  {/*arabic paragraph */}
                  <Grid item xs={12}>
                    <CKEditor
                      config={{
                        toolbar: {
                          items: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "underline",
                            "|",
                            "link",
                            "|",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "undo",
                            "redo",
                          ],
                        },
                      }}
                      disabled={!canEdit}
                      onChange={(e, editor) => {
                        const value = editor
                          .getData()
                          .toString()
                          .replace(/(<([^>]+)>)/gi, "");
                        setParagraphArabic({
                          ...stateWithValidation(value, "paragraph"),
                          val: editor.getData(),
                        });
                        setIsValidate(false);
                      }}
                      editor={ClassicEditor}
                      data={paragraphArabic.val}
                    />
                    {!paragraphArabic.isValid && isValidate && (
                      <div className="error">{paragraphArabic.err}</div>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <div className="form-group">
                      <TextField
                        value={tagValue}
                        onChange={(e) => {
                          setTagValue(e.target.value)
                          setIsValidate(false);
                        }}
                        onKeyDown={(e) => insertTag(e)}
                        label={'Tags'}
                        disabled={!canEdit}
                      />
                      {tag.length
                        ? tag.map((obj, ind) => {
                            return (
                              <Chip
                                key={`tag-${ind}`}
                                className={`select-chips`}
                                label={obj.name}
                                onDelete={(e) => deleteTag(obj.index)}
                              />
                            );
                          })
                        : null}
                    </div>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
                        <Typography variant="h6" component="h6" fontWeight={500}>Add Unit of Measurement</Typography>

                        {canEdit?
                          <Button
                            type="button" variant="contained" size="small" className="min-width148"
                            onClick={OpenAddUnitMeasurement}
                        > Add UOM </Button>: null}
                      </Box>
                        {uoms.length === 0?<>
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} className="card-white uom-empty">
                          <img src={uomEmpty} alt=".." style={{marginBottom: '16px'}} />
                          {canEdit?<Button
                            type="button"
                            variant="contained"
                            size="small"
                            className="min-width148"
                            onClick={OpenAddUnitMeasurement}
                          >Add UOM</Button>: null}
                        </Box>
                      </>:
                      <div className="table-block mb-30">
                      <div className="table-wrapper">
                        <TableContainer component={Paper}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell><div className="short-div">Barcode</div></TableCell>
                                <TableCell><div className="short-div">UOM Code And Name</div></TableCell>
                                <TableCell><div className="short-div">Relation With Base</div></TableCell>
                                <TableCell><div className="short-div">Sell Price</div></TableCell>
                                {/* <TableCell><div className="short-div">Stock</div></TableCell> */}
                                <TableCell><div className="short-div">Inactive</div></TableCell>
                                <TableCell><div className="short-div">Status</div></TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {uoms.map((uom, index) => (
                                <TableRow
                                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                  key={`uom-${index}`}
                                >
                                  <TableCell>
                                    <span style={{
                                      maxWidth: "150px", display: "inline-block", overflow: "hidden",
                                      textOverflow: "ellipsis", whiteSpace: "nowrap"
                                    }}>#{(uom?.uom_barcode_relations)? getAllBarcode(uom?.uom_barcode_relations): uom?.barcode}</span>
                                    {uom?.isDefault === 1?<sup style={{
                                      marginLeft: "5px", color: "#000000", fontSize: "12px",
                                      padding: "3px", backgroundColor: "rgb(187 229 223)", borderRadius: "5px"
                                    }}>Default</sup>:null}
                                  </TableCell>
                                  <TableCell>{uom?.uomName} {uom?.uomNameEr !== ''?`(${uom?.uomNameEr})`: ''}</TableCell>
                                  <TableCell>{uom?.relationWithBase} </TableCell>
                                  <TableCell>QAR {uom?.salePrice && uom?.salePrice !== 0 ? priceHandler(uom?.salePrice) : priceHandler(uom?.price)}</TableCell>
                                  {/* <TableCell>
                                    {store.val !== ''?<>{store.val === 1?
                                      uom?.stockQuantity > 0 ? "In-stock" : "Out-of-stock":
                                      uom?.uom?.status === 'active' ? "In-stock" : "Out-of-stock"
                                    }</>: null}
                                  </TableCell> */}
                                  <TableCell
                                    style={uom?.manage_store_products?.length === 0?{
                                      pointerEvents: "none",
                                    }:{}}
                                  >
                                    <span className="cursor-pointer" 
                                    onClick={() => {
                                      storeForActiveInactive = uom?.manage_store_products;
                                      setStoreForActiveInactive(storeForActiveInactive)
                                      selectedUom = uom;
                                      setSelectedUom(selectedUom)
                                      setOpenStatusActiveInactive(true);
                                    }}
                                    >{uom?.manage_store_products?.length}</span>
                                  </TableCell>
                                  <TableCell>
                                    <Switch
                                      checked={uom?.status === 'active'? true: false}
                                      disabled={uom?.isDefault === 1}
                                      onClick={(e) => {
                                        if (uom?.isDefault !== 1) {
                                          selectedUom = uom;
                                          setSelectedUom(selectedUom)
                                          selectedStatus = e.target.checked ? "active" : "inactive"
                                          setSelectedStatus(selectedStatus)
                                          setOpenUomInactiveModal(true)
                                        }
                                      }}
                                    />
                                  </TableCell>
                                  { canEdit?
                                    <TableCell>
                                      <BasicMenu
                                        obj={uom} index={index}
                                        canEdit={canEdit}
                                      />
                                    </TableCell>: null}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>}
                  </Grid>
                  {editProductData?.is_approved === 2?<Grid item xs={12} mt={2}>
                    <Typography variant="h6" component="h6" fontWeight={500}>Reason for Rejection</Typography>
                    <Typography variant="p" component="p" fontWeight={500}>{editProductData?.reason_of_reject}</Typography>
                  </Grid>: null}
                </Grid>
                <Divider />
                <div className="bottom-btn-row">
                  <Button
                    type="button" variant="standard" size="large"
                    sx={{ marginRight: "10px" }} onClick={routerProductListing}
                  > Back</Button>
                  {canEdit?<Button
                    variant="contained"
                    type="button"
                    size="large"
                    color="primary"
                    onClick={() => onSaveHandler()}
                    disabled={
                      (!productTitle.isValid ||
                        !tag.isValid ||
                        !price.isValid ||
                        !paragraph.isValid) &&
                      isValidate
                    }
                  > Save </Button>: null}
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {openAddUnitMeasurement?<AddUOM
        updateEditValue={(uom) => {
          if (uom.isDefault === 1) {
            uoms = uoms.map((obj) => {
              obj.isDefault = 0;
              return obj;
            })
          }
          uoms = uoms.map((obj, index) => {
            if (uom.index === index) { obj = uom; }
            return obj;
          });
          setUoms(uoms);
          setOpenAddUnitMeasurement(false);
          setIsValidate(false);
        }}
        updateValue={(newUon) => {
          if (newUon.isDefault === 1) {
            uoms = uoms.map((obj) => { obj.isDefault = 0; return obj; })
          }
          let allUoms = [...uoms];
          allUoms.push(newUon);
          uoms = allUoms;
          setUoms(uoms);
          setOpenAddUnitMeasurement(false);
          setIsValidate(false);
        }}
        uom={uom}
        uomsCount={uoms.length}
        editProductData={editProductData}
        isOpen={openAddUnitMeasurement}
        unitData={unitData}
        tempIdAddUom={tempIdAddUom}
        adminCommission={adminCommission}
        handleClose={() => {
          setOpenAddUnitMeasurement(false);
        }}
        AddonsList={AddonsList}
      />: null}

      {openStatusActiveInactive?<ActiveInactiveStoreUom
        handleClose={() => {
          setOpenStatusActiveInactive(false);
        }}
        storeForActiveInactive={storeForActiveInactive}
        selectedUom={selectedUom}
        storeId={store.val.id}
        getProduct={() => getProductById(product_id)}
      />: null}
      {openUomInactiveModal?<ProductUomInactiveModal
        handleClose={() => {
          setOpenUomInactiveModal(false);
        }}
        selectedUom={selectedUom}
        selectedStatus={selectedStatus}
        storeId={store.val.id}
        getProduct={() => getProductById(product_id)}
      />: null}
    </>
  );
}
