export default {
  ADD_BANNER: `/site-setting/add-banner-image`,
  GET_ALL_BANNER: (query, page, limit, order_by, order_type) =>
  `/site-setting/get-banner-list-admin?store_id=${query}&page=${page}&limit=${limit}&order_by=${order_by}&order_type=${order_type}`,
  GET_BUSINESSTYPE: `/business-type/get-all-list`,
  UPDATE_BANNER: `/site-setting/update-banner-image`,
  DELETE_BANNER: (id) => `/site-setting/delete-banner/${id}`,
  UPDATE_BANNER_STATUS: `/site-setting/update-banner-status`,
  GLOBAL_JSON_SYNC: `/products/globalJsonUpdate`,
  ADD_BANNER_NEW: `/site-setting/add`,
  UPDATE_BANNER_NEW: (bannerId) => `/site-setting/edit/${bannerId}`,
};
