import "./dashboard.scss";
import { useEffect, useState } from "react";
import { Box, Typography, Grid, ButtonGroup, Button } from "@mui/material";
import { Chart } from "react-google-charts";
import { API } from "../../../pages/ENUM";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import totalProduct from "./../../../assets/images/structure/total-product.svg";
import inStock from "./../../../assets/images/structure/in-stock.svg";
import outStock from "../../../assets/images/structure/out-of-stock.svg";
import pendingRequest from "../../../assets/images/structure/pending-product-request.svg";
import pendingOrder from "../../../assets/images/structure/pending-order.svg";
import processingOrder from "../../../assets/images/structure/processing-order.svg";
import readyPick from "../../../assets/images/structure/ready-to-pick.svg";
import shift from "../../../assets/images/structure/shift.svg";
import completed from "../../../assets/images/structure/order-completed.svg";
import newOrder from "../../../assets/images/structure/new-order.svg";
import rejected from "../../../assets/images/structure/rejected.svg";
import cancelled from "../../../assets/images/structure/cancelled.svg";
import lastMonth from "../../../assets/images/structure/last-month-order.svg";
import thisMonth from "../../../assets/images/structure/this-month-order.svg";
import driver from "../../../assets/images/structure/driver.svg";
import customer from "../../../assets/images/structure/customers.svg";
import GlobalService from "../../../services/GlobalService";
import constant from "../../../constant";
import defaultImg from "../../../assets/images/structure/default.jpeg";
import { changePermission } from "../../../redux/reducers/permission";
// import images

export const options = {
  title: null,
  hAxis: { title: null, titleTextStyle: { color: "#333" } },
  vAxis: null,
  chartArea: { width: "90%", height: "90%" },
};

export default function Dashboard() {
  const dispatch = useDispatch();
  let [dashboardData, setDashboardData] = useState({});
  let [graphData, setGraphData] = useState([]);

  const getDashboardData = async () => {
    dispatch(changePermission());
    dispatch(changeLoader(true));
    const response = await GlobalService(API.DASHBOARD);
    dashboardData = response.ack === 1 ? response.data : {};
    setDashboardData(dashboardData);
    getChartData("year");
    dispatch(changeLoader(false));
  };

  const getChartData = async (type) => {
    graphData = [];
    switch (type) {
      case "week":
        const revenueWeekData = dashboardData?.weekData || [];
        graphData = [["Week", "Expenses"]];
        revenueWeekData.forEach((item) => {
          graphData.push([item.day, parseFloat(item.total_sum)]);
        });
        break;
      case "month":
        const revenueMonthData = dashboardData?.monthData || [];
        graphData = [["Month", "Expenses"]];
        revenueMonthData.forEach((item) => {
          graphData.push([
            item.month,
            parseFloat(item.total_sum ? item.total_sum : 0)]);
        });
        break;
      case "year":
        const revenueData = dashboardData?.yearData || [];
        if (revenueData.length > 0) {
          graphData = [["Year", "Expenses"]];
          revenueData.forEach((item) => {
            graphData.push([item.year, parseFloat(item.total_sum ? item.total_sum : 0)]);
          });
        }
        break;
      default:
        break;
    }
    setGraphData(graphData);
  };

  const getProductCount = async () => {
    const response = await GlobalService(API.DASHBOARD.PRODUCT_COUNT);
    dashboardData = response.ack === 1 ? { ...dashboardData, ...response.data } : {...dashboardData};
    setDashboardData(dashboardData);
  }

  const getOrdersCount = async () => {
    const response = await GlobalService(API.DASHBOARD.ORDERS_COUNT);
    dashboardData = response.ack === 1 ? { ...dashboardData, ...response.data } : {...dashboardData};
    setDashboardData(dashboardData);
  }

  const getDriverCount = async () => {
    const response = await GlobalService(API.DASHBOARD.DRIVER_COUNT);
    dashboardData = response.ack === 1 ? { ...dashboardData, ...response.data } : {...dashboardData};
    setDashboardData(dashboardData);
  }

  const getRevenue = async () => {
    const response = await GlobalService(API.DASHBOARD.REVENUE);
    dashboardData = response.ack === 1 ? { ...dashboardData, ...response.data } : {...dashboardData};
    setDashboardData(dashboardData);
    getChartData("year");
  }

  useEffect(() => {
    // getDashboardData();
    getProductCount();
    getOrdersCount();
    getDriverCount();
    getRevenue();
  }, []);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30" sx={{ minHeight: "75vh" }}>
            <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
              Product
            </Typography>
            <Grid container spacing={2} className="product-box-row">
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      Total Products
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.total_product}
                    </Typography>
                  </div>
                  <img
                    src={totalProduct}
                    alt="..."
                    className="product-box-icon"
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      In Stock Products
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.in_stock_product}
                    </Typography>
                  </div>
                  <img src={inStock} alt="..." className="product-box-icon" />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      Out of Stock Products
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.out_of_stock_product}
                    </Typography>
                  </div>
                  <img src={outStock} alt="..." className="product-box-icon" />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      Pending Products Request
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.pending_product_request}
                    </Typography>
                  </div>
                  <img
                    src={pendingRequest}
                    alt="..."
                    className="product-box-icon"
                  />
                </Box>
              </Grid>
            </Grid>

            <Typography
              variant="h6"
              component="h6"
              mb={2}
              mt={4}
              fontWeight={500}
            >
              Orders
            </Typography>
            <Grid container spacing={2} className="product-box-row">
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      Pending Orders
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.pending_order}
                    </Typography>
                  </div>
                  <img
                    src={pendingOrder}
                    alt="..."
                    className="product-box-icon"
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      Processing Orders
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.processing_order}
                    </Typography>
                  </div>
                  <img
                    src={processingOrder}
                    alt="..."
                    className="product-box-icon"
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      Ready To Pick
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.ready_to_pick}
                    </Typography>
                  </div>
                  <img src={readyPick} alt="..." className="product-box-icon" />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      Shipped
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.shipped}
                    </Typography>
                  </div>
                  <img src={shift} alt="..." className="product-box-icon" />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      Completed
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.completed}
                    </Typography>
                  </div>
                  <img src={completed} alt="..." className="product-box-icon" />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      New Order
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.new_order}
                    </Typography>
                  </div>
                  <img src={newOrder} alt="..." className="product-box-icon" />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      Rejected
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.rejected}
                    </Typography>
                  </div>
                  <img src={rejected} alt="..." className="product-box-icon" />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      Cancelled
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.cancelled}
                    </Typography>
                  </div>
                  <img src={cancelled} alt="..." className="product-box-icon" />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      Last Month Total Order
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.last_month_total_order}
                    </Typography>
                  </div>
                  <img src={lastMonth} alt="..." className="product-box-icon" />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      This Month Total Order
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.this_month_total_order}
                    </Typography>
                  </div>
                  <img src={thisMonth} alt="..." className="product-box-icon" />
                </Box>
              </Grid>
            </Grid>

            <Typography
              variant="h6"
              component="h6"
              mb={2}
              mt={4}
              fontWeight={500}
            >
              Drivers & Customers
            </Typography>

            <Grid container spacing={2} className="product-box-row" mb={4}>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      Drivers
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.drivers}
                    </Typography>
                  </div>
                  <img src={driver} alt="..." className="product-box-icon" />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="product-box">
                  <div className="relative">
                    <Typography variant="p" component="p" fontWeight={500}>
                      Customers
                    </Typography>
                    <Typography variant="h6" component="h6" fontWeight={700}>
                      {dashboardData?.customers}
                    </Typography>
                  </div>
                  <img src={customer} alt="..." className="product-box-icon" />
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box className="rouded-border">
                  <Typography
                    variant="h6"
                    component="h6"
                    py={"8px"}
                    px={2}
                    mb={1}
                    borderBottom={"1px solid #E5E5E5"}
                    fontWeight={500}
                  >
                    Top Products
                  </Typography>
                  {dashboardData?.top_producs?.length > 0
                    ? dashboardData?.top_producs.map((product, index) => (
                        <Box
                          className="top-product-box"
                          key={`product-${index}`}
                        >
                          <img
                            src={
                              constant.IMG_BASE_URL +
                              product?.product_data?.image
                            }
                            alt="product img"
                            className="product-img"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = defaultImg;
                            }}
                          />
                          <div className="relative">
                            <Typography
                              variant="p"
                              component="p"
                              fontWeight={500}
                            >
                              {product?.product_data?.title}
                            </Typography>
                            <Typography
                              variant="p"
                              component="p"
                              color={"#333"}
                              fontWeight={500}
                            >
                              {product?.product_count} Times
                            </Typography>
                          </div>
                        </Box>
                      ))
                    : null}
                </Box>
              </Grid>

              <Grid item xs={8}>
                <Box className="rouded-border">
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    py={"8px"}
                    px={2}
                    mb={1}
                    borderBottom={"1px solid #E5E5E5"}
                  >
                    <Typography
                      variant="h6"
                      component="h6"
                      m={0}
                      fontWeight={500}
                    >
                      Revenue
                    </Typography>
                    <ButtonGroup
                      variant="outlined"
                      size="small"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        type="button"
                        onClick={() => getChartData("week")}
                      >
                        Week
                      </Button>
                      <Button
                        type="button"
                        onClick={() => getChartData("month")}
                      >
                        Month
                      </Button>
                      <Button
                        type="button"
                        onClick={() => getChartData("year")}
                      >
                        Year
                      </Button>
                    </ButtonGroup>
                  </Box>
                  <Box>
                    {graphData.length > 0 ? (
                      <Chart
                        chartType="AreaChart"
                        width="100%"
                        height="400px"
                        data={graphData}
                        options={options}
                      />
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
