import * as React from 'react';
import { Button, Box, Dialog, DialogContent } from "@mui/material";
import { Boxed } from "../Styles-Elements/Box";
import modalClose from "../assets/images/structure/close-black.svg";
import uploadImg from "../assets/images/structure/upload-img.png";
import EditIcon from "@mui/icons-material/Edit";
import GlobalService from "../services/GlobalService";
import { API } from "../pages/ENUM";
import { useDispatch } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import { changeSnackbar } from "../redux/reducers/snackbar";
import constant from "../constant";
import defaultImage from "../assets/images/structure/default.jpeg";
import { blobToBase64, base64toFile } from "../pages/helper/custom";
import imageCompression from 'browser-image-compression';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  alwaysKeepResolution: true
}


const UploadImage = ({ handleClose, uploadImageData, storeId, handleSuccess }) => {

  const dispatch = useDispatch();
	let [imageData, setImageData] = React.useState({
		file: "",
		imagePreviewUrl: '',
	});

	const editImageChangeHandler = async (e) => {
		if (e.target.files[0]) {
      const file = e.target.files[0];
      const compressedFile = await imageCompression(file, options);
      const imageUrl = await blobToBase64(compressedFile);
			imageData = {
				file: base64toFile(imageUrl),
				imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
			};
			setImageData(imageData);
		} else {
			imageData = { file: '', imagePreviewUrl: '' };
			setImageData(imageData);
		}
    document.getElementById('upload-image').value = '';
    // document.getElementById('upload-image2').value = '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
		dispatch(changeLoader(true));
    let formData = new FormData();
		formData.append("image", imageData.file);
		formData.append("categoryId", uploadImageData.id);
    formData.append("storeId", storeId);
    try {
      const response = await GlobalService(API.UPLOAD_IMAGE_TO_CATEGORY, formData, 'post');
      if (response.ack === 1) {
        dispatch(changeSnackbar({
          isOpen: true,
          message: response?.msg,
          state: "success",
        }));
        handleSuccess();
      } else {
        dispatch(changeSnackbar({
          isOpen: true,
          message: response?.msg? response?.msg: 'Something went wrong',
          state: "error",
        }));
      }
    } catch (error) {
      dispatch(changeSnackbar({
        isOpen: true,
        message: 'Something went wrong',
        state: "error",
      }));
    }
		dispatch(changeLoader(false));
  };

  React.useEffect(() => {
    if (uploadImageData?.image) {
      imageData = {
        file: '',
        imagePreviewUrl: constant.IMG_BASE_URL + uploadImageData?.image,
      };
      setImageData(imageData);
    } else {
      imageData = { file: '', imagePreviewUrl: '' };
      setImageData(imageData);
    }
  }, [uploadImageData]);

  return (
    <>
      <Dialog open={true} onClose={handleClose} className="main-modal theme-dialog maxwidth-430">
        <DialogContent>
          <Boxed display={'flex'} alignItems={'center'} justifyContent={'space-between'} style={{ marginBottom: "16px" }}>
            <h2 className="h4" style={{ marginBottom: "8px" }}>Upload Category Image</h2>
            <img
              src={modalClose} alt="..." style={{width: '24px', height: '24px'}} className="modal-close"
              onClick={() => { handleClose();}}
            />
          </Boxed>
					<Boxed>
					  <Box mt={'12px'}>
							<p>Upload Image for {uploadImageData?.name}.</p>
            </Box>
          </Boxed>
          <Boxed>
            <Box mt={'12px'}>
							<div className='upload-img'>
								{imageData.imagePreviewUrl === ''?
                <div className="before-upload"
                  style={{ cursor: "pointer", }}
                  onClick={() => { document.getElementById('upload-image').click(); }}
                >
									<img src={uploadImg} alt=".." className="upload-icon" />
									<p className="upload-text">Upload Image</p>
									<input
										type="file" accept="image/png, image/jpeg"
										onChange={(e) => editImageChangeHandler(e)}
                    id='upload-image'
                    style={{ position: "absolute", left: '-10000px', }}
									/>
								</div>: <div className="after-upload">
                  <EditIcon
                    className="delete-icon"
                    onClick={() => {
                      imageData = { file: '', imagePreviewUrl: '' };
											setImageData(imageData);
                    }}
                  />
                  <img src={imageData.imagePreviewUrl} alt=".." className="upload-icon"
                    style={{ cursor: "pointer", }}
                    onClick={() => { document.getElementById('upload-image2').click(); }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultImage;
                    }}
                  />
                  <input
										type="file" accept={`image/png, image/jpeg`}
										onChange={(e) => editImageChangeHandler(e)}
                    id='upload-image2'
                    style={{ position: "absolute", left: '-10000px', }}
                  />
                </div>}
							</div>
            </Box>
          </Boxed>
          <Boxed textAlign="center" display={"flex"} justifyContent={"space-between"} mt={'20px'}>
            <Button variant="contained" size="medius" color="error" type='button' onClick={handleSubmit}>Save</Button>
          </Boxed>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default UploadImage;