import { configureStore } from "@reduxjs/toolkit";
import loader from "./reducers/loader";
import snackbar from "./reducers/snackbar";
import payoutManagement from "./reducers/payoutManagement";
import manageUser from "./reducers/manageUser";
import faq from "./reducers/faq";
import driver from "./reducers/driver";
import manageCustomer from "./reducers/manageCustomer";
import permission from "./reducers/permission";
import socket from "./reducers/socket";
import baladiInfo from "./reducers/baladiInfo";

export default configureStore({
  reducer: {
    loader: loader,
    snackbar: snackbar,
    payoutManagement: payoutManagement,
    manageUser: manageUser,
    faq: faq,
    driver: driver,
    manageCustomer: manageCustomer,
    permission: permission,
    socket: socket,
    baladiInfo: baladiInfo,
  },
});
