import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  TextField,
  Grid,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Button,
} from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import plusIcon from "../../../assets/images/structure/plus-icon.svg";
import screenShot from "../../../assets/images/product/category-img3.png";
import screenShot2 from "../../../assets/images/product/category-img2.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import homeScreen1 from "../../../assets/images/product/home-screen1.png"
import homeScreen2 from "../../../assets/images/product/home-screen2.png"
import homeScreen3 from "../../../assets/images/product/home-screen3.png"
import homeScreen4 from "../../../assets/images/product/home-screen4.png"
import homeScreen5 from "../../../assets/images/product/home-screen5.png"
import homeScreen6 from "../../../assets/images/product/home-screen6.png"
import homeScreen7 from "../../../assets/images/product/home-screen7.png"
import homeScreen8 from "../../../assets/images/product/home-screen8.png"
import homeScreen9 from "../../../assets/images/product/home-screen9.png"
import homeScreen10 from "../../../assets/images/product/home-screen10.png"
import homeScreen11 from "../../../assets/images/product/home-screen11.png"

export default function HomePage() {
  const [selectedParentCategory, setSelectedParentCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState([]);
  const [ProductType, setProductType] = useState([]);
  const [activeIndexes, setActiveIndexes] = useState([]);

  const handleClick = (index) => {
    if (activeIndexes.includes(index)) {
      setActiveIndexes(activeIndexes.filter((i) => i !== index));
    } else {
      setActiveIndexes([...activeIndexes, index]);
    }
  };

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Home Page Management System
                </Typography>
                <Divider />
              </Grid>
              
                  {/* section 1 */}
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 1 - Banner
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Choose Banner
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Choose Banner
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Choose Banner"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Fruits & Vegetables"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <img
                      src={homeScreen1}
                      alt=""
                      className=""
                      style={{
                        width: 400,
                        height: 'auto',
                        objectFit: "contain",
                        borderRadius: 16,
                      }}
                    />
                  </Grid>
                </Grid>                
              </Grid>

              {/* section 2 */}
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 2 - Trending Baladi Mart Category
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Choose Categories
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Parent Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Parent Category"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sub Category"
                          multiple={true}
                          value={selectedSubCategory}
                          onChange={(e) =>
                            setSelectedSubCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Sub Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sub Sub Category"
                          multiple={true}
                          value={selectedSubSubCategory}
                          onChange={(e) =>
                            setSelectedSubSubCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Fruits & Vegetables"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={homeScreen2}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: 'auto',
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 3 */}
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 3 - Featured Restaurants
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="p" component="p" mb={0} fontWeight={500}>
                    Choose Business Type
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <div className="d-flex items-center">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Business Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Business Type"
                        multiple={true}
                        value={selectedParentCategory}
                        onChange={(e) =>
                          setSelectedParentCategory(e.target.value)
                        }
                      >
                        <MenuItem value={0}>one</MenuItem>
                        <MenuItem value={1}>one</MenuItem>
                        <MenuItem value={2}>one</MenuItem>
                        <MenuItem value={3}>one</MenuItem>
                        <MenuItem value={4}>one</MenuItem>
                      </Select>
                    </FormControl>
                    <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={9}>
                  <Grid item xs={5}>
                    <div className="ranking-box">
                      <div className="ranking-head">
                        <Typography
                          variant="h6"
                          component="h6"
                          fontSize={"16px"}
                          fontWeight={500}
                        >
                          Available Brands
                        </Typography>
                      </div>
                      <div className="search-box">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          value={""}
                          placeholder="Search.."
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <ClearIcon sx={{ marginLeft: "8px" }} />
                            ),
                          }}
                        />
                      </div>
                      <div className="ranking-content">
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(0) ? "active" : ""
                          }`}
                          onClick={() => handleClick(0)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(1) ? "active" : ""
                          }`}
                          onClick={() => handleClick(1)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(2) ? "active" : ""
                          }`}
                          onClick={() => handleClick(2)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(3) ? "active" : ""
                          }`}
                          onClick={() => handleClick(3)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(4) ? "active" : ""
                          }`}
                          onClick={() => handleClick(4)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(5) ? "active" : ""
                          }`}
                          onClick={() => handleClick(5)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(6) ? "active" : ""
                          }`}
                          onClick={() => handleClick(6)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(7) ? "active" : ""
                          }`}
                          onClick={() => handleClick(7)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(8) ? "active" : ""
                          }`}
                          onClick={() => handleClick(8)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <ArrowForwardIcon />
                    <ArrowBackIcon />
                  </Grid>
                  <Grid item xs={5}>
                    <div className="ranking-box">
                      <div className="ranking-head">
                        <Typography
                          variant="h6"
                          component="h6"
                          fontSize={"16px"}
                          fontWeight={500}
                        >
                          Selected Brands
                        </Typography>
                      </div>
                      <div className="search-box">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          value={""}
                          placeholder="Search.."
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <ClearIcon sx={{ marginLeft: "8px" }} />
                            ),
                          }}
                        />
                      </div>
                      <div className="ranking-content">
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(9) ? "active" : ""
                          }`}
                          onClick={() => handleClick(9)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(10) ? "active" : ""
                          }`}
                          onClick={() => handleClick(10)}
                        >
                          <p className="ranking-name">De Brazia</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={homeScreen3}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: 'auto',
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 4 */}
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 4 - Banner
                    </Typography>
                  </FormControl>
                </Grid>
                
                
                <Grid container spacing={2} item xs={12}>
                <Grid item xs={12}>
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Choose Banner
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Choose Banner
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Choose Banner"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Fruits & Vegetables"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={homeScreen4}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: 'auto',
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 5 */}
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 5 - Shop by Pharmacy Category
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                        <InputLabel id="demo-simple-select-label">
                          Choose Business Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Choose Business Type"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Choose Categories
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Parent Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Parent Category"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sub Category"
                          multiple={true}
                          value={selectedSubCategory}
                          onChange={(e) =>
                            setSelectedSubCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Sub Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sub Sub Category"
                          multiple={true}
                          value={selectedSubSubCategory}
                          onChange={(e) =>
                            setSelectedSubSubCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Fruits & Vegetables"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    /><Chip
                    label="Snacks & meals"
                    sx={{ mr: 1, mb: 2 }}
                    onDelete={() => {
                      setSelectedParentCategory(allProduct);
                    }}
                  /><Chip
                  label="Fresh Fruits"
                  sx={{ mr: 1, mb: 2 }}
                  onDelete={() => {
                    setSelectedParentCategory(allProduct);
                  }}
                /><Chip
                label="Apple"
                sx={{ mr: 1, mb: 2 }}
                onDelete={() => {
                  setSelectedParentCategory(allProduct);
                }}
              />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={homeScreen5}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: 'auto',
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

             {/* section 6 */} 
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 6 - Exclusive Pharmacy Stores
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="p" component="p" mb={0} fontWeight={500}>
                    Choose Business Type
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <div className="d-flex items-center">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Business Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Business Type"
                        multiple={true}
                        value={selectedParentCategory}
                        onChange={(e) =>
                          setSelectedParentCategory(e.target.value)
                        }
                      >
                        <MenuItem value={0}>one</MenuItem>
                        <MenuItem value={1}>one</MenuItem>
                        <MenuItem value={2}>one</MenuItem>
                        <MenuItem value={3}>one</MenuItem>
                        <MenuItem value={4}>one</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={9}>
                  <Grid item xs={5}>
                    <div className="ranking-box">
                      <div className="ranking-head">
                        <Typography
                          variant="h6"
                          component="h6"
                          fontSize={"16px"}
                          fontWeight={500}
                        >
                          Available Brands
                        </Typography>
                      </div>
                      <div className="search-box">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          value={""}
                          placeholder="Search.."
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <ClearIcon sx={{ marginLeft: "8px" }} />
                            ),
                          }}
                        />
                      </div>
                      <div className="ranking-content">
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(0) ? "active" : ""
                          }`}
                          onClick={() => handleClick(0)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(1) ? "active" : ""
                          }`}
                          onClick={() => handleClick(1)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(2) ? "active" : ""
                          }`}
                          onClick={() => handleClick(2)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(3) ? "active" : ""
                          }`}
                          onClick={() => handleClick(3)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(4) ? "active" : ""
                          }`}
                          onClick={() => handleClick(4)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(5) ? "active" : ""
                          }`}
                          onClick={() => handleClick(5)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(6) ? "active" : ""
                          }`}
                          onClick={() => handleClick(6)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(7) ? "active" : ""
                          }`}
                          onClick={() => handleClick(7)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(8) ? "active" : ""
                          }`}
                          onClick={() => handleClick(8)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <ArrowForwardIcon />
                    <ArrowBackIcon />
                  </Grid>
                  <Grid item xs={5}>
                    <div className="ranking-box">
                      <div className="ranking-head">
                        <Typography
                          variant="h6"
                          component="h6"
                          fontSize={"16px"}
                          fontWeight={500}
                        >
                          Selected Brands
                        </Typography>
                      </div>
                      <div className="search-box">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          value={""}
                          placeholder="Search.."
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <ClearIcon sx={{ marginLeft: "8px" }} />
                            ),
                          }}
                        />
                      </div>
                      <div className="ranking-content">
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(9) ? "active" : ""
                          }`}
                          onClick={() => handleClick(9)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(10) ? "active" : ""
                          }`}
                          onClick={() => handleClick(10)}
                        >
                          <p className="ranking-name">De Brazia</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={homeScreen6}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: 'auto',
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 7 */} 
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 7 - Popular Pharmacy Stores
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="p" component="p" mb={0} fontWeight={500}>
                    Choose Business Type
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <div className="d-flex items-center">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Business Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Business Type"
                        multiple={true}
                        value={selectedParentCategory}
                        onChange={(e) =>
                          setSelectedParentCategory(e.target.value)
                        }
                      >
                        <MenuItem value={0}>one</MenuItem>
                        <MenuItem value={1}>one</MenuItem>
                        <MenuItem value={2}>one</MenuItem>
                        <MenuItem value={3}>one</MenuItem>
                        <MenuItem value={4}>one</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={9}>
                  <Grid item xs={5}>
                    <div className="ranking-box">
                      <div className="ranking-head">
                        <Typography
                          variant="h6"
                          component="h6"
                          fontSize={"16px"}
                          fontWeight={500}
                        >
                          Available Brands
                        </Typography>
                      </div>
                      <div className="search-box">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          value={""}
                          placeholder="Search.."
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <ClearIcon sx={{ marginLeft: "8px" }} />
                            ),
                          }}
                        />
                      </div>
                      <div className="ranking-content">
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(0) ? "active" : ""
                          }`}
                          onClick={() => handleClick(0)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(1) ? "active" : ""
                          }`}
                          onClick={() => handleClick(1)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(2) ? "active" : ""
                          }`}
                          onClick={() => handleClick(2)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(3) ? "active" : ""
                          }`}
                          onClick={() => handleClick(3)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(4) ? "active" : ""
                          }`}
                          onClick={() => handleClick(4)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(5) ? "active" : ""
                          }`}
                          onClick={() => handleClick(5)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(6) ? "active" : ""
                          }`}
                          onClick={() => handleClick(6)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(7) ? "active" : ""
                          }`}
                          onClick={() => handleClick(7)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(8) ? "active" : ""
                          }`}
                          onClick={() => handleClick(8)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <ArrowForwardIcon />
                    <ArrowBackIcon />
                  </Grid>
                  <Grid item xs={5}>
                    <div className="ranking-box">
                      <div className="ranking-head">
                        <Typography
                          variant="h6"
                          component="h6"
                          fontSize={"16px"}
                          fontWeight={500}
                        >
                          Selected Brands
                        </Typography>
                      </div>
                      <div className="search-box">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          value={""}
                          placeholder="Search.."
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <ClearIcon sx={{ marginLeft: "8px" }} />
                            ),
                          }}
                        />
                      </div>
                      <div className="ranking-content">
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(9) ? "active" : ""
                          }`}
                          onClick={() => handleClick(9)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(10) ? "active" : ""
                          }`}
                          onClick={() => handleClick(10)}
                        >
                          <p className="ranking-name">De Brazia</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={homeScreen7}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: 'auto',
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 8 */}
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 8 - Banner
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                <Grid item xs={12}>
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Choose Banner
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Choose Banner
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Choose Banner"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Fruits & Vegetables"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={homeScreen8}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: 'auto',
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 9 */} 
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 5 - Exclusive Deals
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                        <InputLabel id="demo-simple-select-label">
                          Choose Business Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Choose Business Type"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Choose Categories
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Parent Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Parent Category"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sub Category"
                          multiple={true}
                          value={selectedSubCategory}
                          onChange={(e) =>
                            setSelectedSubCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Sub Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sub Sub Category"
                          multiple={true}
                          value={selectedSubSubCategory}
                          onChange={(e) =>
                            setSelectedSubSubCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Fruits & Vegetables"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    /><Chip
                    label="Snacks & meals"
                    sx={{ mr: 1, mb: 2 }}
                    onDelete={() => {
                      setSelectedParentCategory(allProduct);
                    }}
                  /><Chip
                  label="Fresh Fruits"
                  sx={{ mr: 1, mb: 2 }}
                  onDelete={() => {
                    setSelectedParentCategory(allProduct);
                  }}
                /><Chip
                label="Apple"
                sx={{ mr: 1, mb: 2 }}
                onDelete={() => {
                  setSelectedParentCategory(allProduct);
                }}
              />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={homeScreen9}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: 'auto',
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 10 */} 
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 10 - Featured Flower Stores
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="p" component="p" mb={0} fontWeight={500}>
                    Choose Business Type
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <div className="d-flex items-center">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Business Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Business Type"
                        multiple={true}
                        value={selectedParentCategory}
                        onChange={(e) =>
                          setSelectedParentCategory(e.target.value)
                        }
                      >
                        <MenuItem value={0}>one</MenuItem>
                        <MenuItem value={1}>one</MenuItem>
                        <MenuItem value={2}>one</MenuItem>
                        <MenuItem value={3}>one</MenuItem>
                        <MenuItem value={4}>one</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={9}>
                  <Grid item xs={5}>
                    <div className="ranking-box">
                      <div className="ranking-head">
                        <Typography
                          variant="h6"
                          component="h6"
                          fontSize={"16px"}
                          fontWeight={500}
                        >
                          Available Brands
                        </Typography>
                      </div>
                      <div className="search-box">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          value={""}
                          placeholder="Search.."
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <ClearIcon sx={{ marginLeft: "8px" }} />
                            ),
                          }}
                        />
                      </div>
                      <div className="ranking-content">
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(0) ? "active" : ""
                          }`}
                          onClick={() => handleClick(0)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(1) ? "active" : ""
                          }`}
                          onClick={() => handleClick(1)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(2) ? "active" : ""
                          }`}
                          onClick={() => handleClick(2)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(3) ? "active" : ""
                          }`}
                          onClick={() => handleClick(3)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(4) ? "active" : ""
                          }`}
                          onClick={() => handleClick(4)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(5) ? "active" : ""
                          }`}
                          onClick={() => handleClick(5)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(6) ? "active" : ""
                          }`}
                          onClick={() => handleClick(6)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(7) ? "active" : ""
                          }`}
                          onClick={() => handleClick(7)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(8) ? "active" : ""
                          }`}
                          onClick={() => handleClick(8)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <ArrowForwardIcon />
                    <ArrowBackIcon />
                  </Grid>
                  <Grid item xs={5}>
                    <div className="ranking-box">
                      <div className="ranking-head">
                        <Typography
                          variant="h6"
                          component="h6"
                          fontSize={"16px"}
                          fontWeight={500}
                        >
                          Selected Brands
                        </Typography>
                      </div>
                      <div className="search-box">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          value={""}
                          placeholder="Search.."
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <ClearIcon sx={{ marginLeft: "8px" }} />
                            ),
                          }}
                        />
                      </div>
                      <div className="ranking-content">
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(9) ? "active" : ""
                          }`}
                          onClick={() => handleClick(9)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(10) ? "active" : ""
                          }`}
                          onClick={() => handleClick(10)}
                        >
                          <p className="ranking-name">De Brazia</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={homeScreen10}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: 'auto',
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 11 */} 
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 11 - Popular Flower Stores
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="p" component="p" mb={0} fontWeight={500}>
                    Choose Business Type
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <div className="d-flex items-center">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Business Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Business Type"
                        multiple={true}
                        value={selectedParentCategory}
                        onChange={(e) =>
                          setSelectedParentCategory(e.target.value)
                        }
                      >
                        <MenuItem value={0}>one</MenuItem>
                        <MenuItem value={1}>one</MenuItem>
                        <MenuItem value={2}>one</MenuItem>
                        <MenuItem value={3}>one</MenuItem>
                        <MenuItem value={4}>one</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={9}>
                  <Grid item xs={5}>
                    <div className="ranking-box">
                      <div className="ranking-head">
                        <Typography
                          variant="h6"
                          component="h6"
                          fontSize={"16px"}
                          fontWeight={500}
                        >
                          Available Brands
                        </Typography>
                      </div>
                      <div className="search-box">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          value={""}
                          placeholder="Search.."
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <ClearIcon sx={{ marginLeft: "8px" }} />
                            ),
                          }}
                        />
                      </div>
                      <div className="ranking-content">
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(0) ? "active" : ""
                          }`}
                          onClick={() => handleClick(0)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(1) ? "active" : ""
                          }`}
                          onClick={() => handleClick(1)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(2) ? "active" : ""
                          }`}
                          onClick={() => handleClick(2)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(3) ? "active" : ""
                          }`}
                          onClick={() => handleClick(3)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(4) ? "active" : ""
                          }`}
                          onClick={() => handleClick(4)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(5) ? "active" : ""
                          }`}
                          onClick={() => handleClick(5)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(6) ? "active" : ""
                          }`}
                          onClick={() => handleClick(6)}
                        >
                          <p className="ranking-name">Papa John's</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(7) ? "active" : ""
                          }`}
                          onClick={() => handleClick(7)}
                        >
                          <p className="ranking-name">Pizza hut</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(8) ? "active" : ""
                          }`}
                          onClick={() => handleClick(8)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <ArrowForwardIcon />
                    <ArrowBackIcon />
                  </Grid>
                  <Grid item xs={5}>
                    <div className="ranking-box">
                      <div className="ranking-head">
                        <Typography
                          variant="h6"
                          component="h6"
                          fontSize={"16px"}
                          fontWeight={500}
                        >
                          Selected Brands
                        </Typography>
                      </div>
                      <div className="search-box">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          value={""}
                          placeholder="Search.."
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <ClearIcon sx={{ marginLeft: "8px" }} />
                            ),
                          }}
                        />
                      </div>
                      <div className="ranking-content">
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(9) ? "active" : ""
                          }`}
                          onClick={() => handleClick(9)}
                        >
                          <p className="ranking-name">Dominos</p>
                        </div>
                        <div
                          className={`ranking-row ${
                            activeIndexes.includes(10) ? "active" : ""
                          }`}
                          onClick={() => handleClick(10)}
                        >
                          <p className="ranking-name">De Brazia</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={homeScreen11}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: 'auto',
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
