import {
  Autocomplete,
  Box,
  Button,
  Select,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import Header from "../../layout/header/Header";
import Aside from "../../layout/aside/Aside";
import arrowLeftBlackImg from "../../../assets/images/structure/arrow-left-black.svg";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../ENUM";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { useParams, useNavigate } from "react-router-dom";

const AssociateCuisine = () => {
  const dispatch = useDispatch();
  const route = useParams();
  const id = route.id;
  const navigate = useNavigate();
  let [options, setOptions] = useState([]);
  let [selectedOption, setSelectedOption] = useState([]);
  let [storeData, setStoreData] = useState({});

  const filterOptions = createFilterOptions({
    stringify: (option) => option.name,
  });

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
  };

  const getData = async () => {
    dispatch(changeLoader(true));
    let url = API.GET_CUISINE
    const businessTypeId = storeData?.businessTypeId
    if (businessTypeId) {
      url = API.GET_CUISINE + `?businessTypeId=${businessTypeId}`
    }
    const response = await GlobalService(url);
    let cuisine = [];
    response.data
      .filter((da) => da.cuisines.length > 0)
      .map((item) => {
        const cuisi = item.cuisines.map((cui) => {
          cui.categoryName = item.name;
          cui.cuisineName = cui.cuisines_locales[0].name;
          return cui;
        });
        cuisine.push(...cuisi);
      });
    options = cuisine;
    setOptions(options);
  };

  const getCousin = async () => {
    const response = await GlobalService(API.GET_STORE_BY_ID(id));
    storeData = response.store;
    setStoreData(storeData)
    await getData();
    const itemIds = response.store.cuisineId
      .split(",")
      .filter((item) => item !== "");
    selectedOption = options.filter((item) =>
      itemIds.includes(item.id.toString())
    );
    setSelectedOption(selectedOption);
    
    dispatch(changeLoader(false));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(changeLoader(true));
    if (selectedOption.length > 0) {
      const data = {
        storeId: id,
        cuisineId: selectedOption.map((item) => item.id),
      };
      const response = await GlobalService(
        API.CUISINE.ADD_ASSOCIATES,
        data,
        "put"
      );
      if (response.ack === 1) {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: response?.msg,
            state: "success",
          })
        );
        dispatch(changeLoader(false));
        navigate("/store-listing");
      } else {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: response?.msg,
            state: "error",
          })
        );
        dispatch(changeLoader(false));
      }
    } else {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: "Please select at least one cuisine",
          state: "error",
        })
      );
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    getCousin();
  }, []);

  return (
    <Box className="dashboard-parent">
      <Header />
      <Aside />
      <Box className="main-wrapper">
        <Box className="white-box-20-30">
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={2}
            width={"100%"}
          >
            <Box display={"flex"} alignItems={"center"} mb={2}>
              <img
                src={arrowLeftBlackImg}
                alt="back-icon"
                style={{
                  cursor: "pointer",
                  float: "left",
                  marginRight: "10px",
                }}
                onClick={() => navigate(-1)}
              />
              <Typography
                variant="h6"
                component="h6"
                style={{ marginLeft: "12px" }}
                fontWeight={500}
              >
                Add Cuisine to Store
              </Typography>
            </Box>
          </Box>
          <div className="grid-item">
            <Autocomplete
              multiple={true}
              value={selectedOption}
              onChange={handleOptionChange}
              options={options}
              getOptionLabel={(option) => option.cuisineName}
              groupBy={(option) => option.categoryName}
              // filterOptions={filterOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select cuisine"
                  variant="outlined"
                />
              )}
              disableCloseOnSelect={true}
            />
          </div>
          <div
            className="grid-item"
            style={{ textAlign: "right", marginTop: "20px" }}
          >
            <Button variant="contained" type="button" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default AssociateCuisine;
