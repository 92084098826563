import {
	FormControlLabel, Checkbox, TableCell, TableRow
} from "@mui/material";

import ModuleActions from "./ModuleActions";

const PermissionRow = ({ permission, index }) => {

	const modules = {
		'StoreRequest': 'Store request',
		'StoreListing': 'Store listing',
		'StoreType': 'Store type',
		'BusinessCategory': 'Business category',
		'UOM': 'UOM',
		'ProductRequest': 'Product request',
		'AddProduct': 'Add product',
		'OrderListing': 'Order listing',
		'CMS': 'CMS',
		'CustomerManagement': 'Customer management',
		'WalletManagement': 'Wallet management',
		'ManageUsers': 'Manage users',
		'RolesPermissions': 'Roles & permissions',
		'PayoutManagement': 'Payout management',
		'DriverOnboarding': 'Driver onboarding',
		'SectionManagement': 'Section management',
		'Zone': 'Zone',
		'Banner': 'Banner',
		'Coupon': 'Coupon management',
		'Cuisine': 'Cuisine management',
		'OfferDiscount': 'Offer & discount management',
		'PickerTracking': 'Picker tracking management'
	}

  return (
		<>
			<TableRow>
				<TableCell component="th" scope="row">{index}</TableCell>
				<TableCell>{modules[permission?.moduleName]}</TableCell>
				<TableCell >
					{permission?.create == 1?
						<FormControlLabel control={<Checkbox checked={true} disabled />} label="Create" />: null}
					{permission?.read == 1?
						<FormControlLabel control={<Checkbox checked={true} disabled />} label="Read" />: null}
					{permission?.edit == 1?
						<FormControlLabel control={<Checkbox checked={true} disabled />} label="Edit" />: null}
					{permission?.delete == 1?
						<FormControlLabel control={<Checkbox checked={true} disabled />} label="Delete" />: null}
						<ModuleActions moduleActions={permission?.actions} />
				</TableCell>
			</TableRow>
		</>
	)
};

export default PermissionRow;