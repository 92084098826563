import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  Typography,
  TextField,
  Grid,
  Divider,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
} from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";

export default function ProductRanking() {
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [ProductType, setProductType] = useState([]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Baladi Product Ranking
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="p"
                  component="p"
                  mt={2}
                  mb={0}
                  fontWeight={600}
                  textTransform={"uppercase"}
                >
                  Season's Fav
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Search Product
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Search Product"
                    multiple={true}
                    value={selectedProduct}
                    onChange={(e) => setSelectedProduct(e.target.value)}
                  >
                    {ProductType.length > 0
                      ? ProductType.map((Product, index) => (
                          <MenuItem value={Product.id} key={`Product-${index}`}>
                            {getdataByKey(
                              Product?.Product_type_locales,
                              "en",
                              "name"
                            )}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={9}>
                <Chip
                  label="product search1"
                  sx={{ mr: 1, mb: 2 }}
                  onDelete={() => {
                    setSelectedProduct(allProduct);
                  }}
                />
                {/* {selectedProduct?.map((select, key) => {
                  let Product = ProductType.find((Product) => Product.id === select);
                  return (
                    <Chip
                      label={getdataByKey(Product?.Product_type_locales,"en","name")}
                      key={`Product-${key}`} sx={{ mr: 1, mt: 2 }}
                      onDelete={() => {
                        const allProduct = [...selectedProduct,];
                        allProduct.splice(key, 1);
                        setSelectedProduct(allProduct);
                      }}
                    />
                  );
                })} */}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="p"
                  component="p"
                  mt={2}
                  mb={0}
                  fontWeight={600}
                  textTransform={"uppercase"}
                >
                  Household Needs
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Search Product
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Search Product"
                    multiple={true}
                    value={selectedProduct}
                    onChange={(e) => setSelectedProduct(e.target.value)}
                  >
                    <MenuItem value={"0"}>Bisleri Bottle</MenuItem>
                    <MenuItem value={"1"}>Bisleri Bottle</MenuItem>
                    <MenuItem value={"2"}>Bisleri Bottle</MenuItem>
                    <MenuItem value={"3"}>Bisleri Bottle</MenuItem>
                    <MenuItem value={"4"}>Bisleri Bottle</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={9}>
                <Chip
                  label="Bisleri Bottle"
                  sx={{ mr: 1, mb: 2 }}
                  onDelete={() => {
                    setSelectedProduct(true);
                  }}
                />
                <Chip
                  label="Bisleri Bottle"
                  sx={{ mr: 1, mb: 2 }}
                  onDelete={() => {
                    setSelectedProduct(true);
                  }}
                />
                <Chip
                  label="Bisleri Bottle"
                  sx={{ mr: 1, mb: 2 }}
                  onDelete={() => {
                    setSelectedProduct(true);
                  }}
                />
                <Chip
                  label="Bisleri Bottle"
                  sx={{ mr: 1, mb: 2 }}
                  onDelete={() => {
                    setSelectedProduct(true);
                  }}
                />
                <Chip
                  label="Bisleri Bottle"
                  sx={{ mr: 1, mb: 2 }}
                  onDelete={() => {
                    setSelectedProduct(true);
                  }}
                />
                <Chip
                  label="Bisleri Bottle"
                  sx={{ mr: 1, mb: 2 }}
                  onDelete={() => {
                    setSelectedProduct(true);
                  }}
                />
                <Chip
                  label="Bisleri Bottle"
                  sx={{ mr: 1, mb: 2 }}
                  onDelete={() => {
                    setSelectedProduct(true);
                  }}
                />
                <Chip
                  label="Bisleri Bottle"
                  sx={{ mr: 1, mb: 2 }}
                  onDelete={() => {
                    setSelectedProduct(true);
                  }}
                />
                <Chip
                  label="Bisleri Bottle"
                  sx={{ mr: 1, mb: 2 }}
                  onDelete={() => {
                    setSelectedProduct(true);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
