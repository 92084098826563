import { useNavigate } from "react-router-dom";
import {
  Typography, Button, Switch, Grid, Tooltip, Box, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Pagination, Dialog, DialogContent
} from "@mui/material";
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import modalClose from "../../../assets/images/structure/close-modal.png";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { useEffect, useState } from "react";
import { changeLoader } from "../../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import GlobalService from "../../../services/GlobalService";
import {API} from "../../../pages/ENUM";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { currentPermission } from "../../../redux/reducers/permission";

// image
export default function StoreListing() {

  const permission = useSelector(currentPermission);
  let [canCreate, setCanCreate] = useState(true);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);
  let [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [order_by, setOrder_by] = useState("id");
  let [order_type, setOrder_type] = useState("desc");
  let [count, setCount] = useState();
  const [limit, setLimit] = useState(10);
  let [pages, setPages] = useState(1);
  const [search, setSearch] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: "" });

  const routerCouponDetail = () => {
    navigate(`/add-coupon`);
  };

  const handlePagination = (e, value) => {
    pages = value;
    setPages(pages);
    getCouponListing();
  }

  const getCouponListing = async () => {
    dispatch(changeLoader(true)); 
    const data = {
      sort_by: order_by,
      order_by: order_type,
      limit: limit,
      page: pages,
      search: search,
    };
    const response = await GlobalService(API.GET_ALL_COUPONS, data)
    if (response.ack === 1) {
      setCoupons(response?.data.rows);
      count = Math.ceil(response?.data.count / limit);
      setCount(count);
      dispatch(changeLoader(false));
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response?.msg,
        state: "error",
      }));
      dispatch(changeLoader(false));
    }
  };

  const handleStatusChange = async (e, coupon_id) => {
    dispatch(changeLoader(true)); 
    const response = await GlobalService(API.UPDATE_COUPON_STATUS(coupon_id, e.target.checked ? "active" : "inactive"))
    if (response.ack === 1) {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response?.msg,
        state: "success",
      }));
      getCouponListing();
      dispatch(changeLoader(false));
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response?.msg,
        state: "error",
      }));
      dispatch(changeLoader(false));
    }
  }

  const handelSort = (column, type) => {
    order_by = column;
    setOrder_by(order_by);
    order_type = type;
    setOrder_type(order_type);
    pages = 1;
    setPages(pages);
    getCouponListing();
  };

  const handleOnDelete = async () => {
    dispatch(changeLoader(true));
    const response = await GlobalService(API.UPDATE_COUPON_STATUS(deleteModal.id, "deleted"))
    if (response.ack === 1) {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response?.msg,
        state: "success",
      }));
      getCouponListing();
      dispatch(changeLoader(false));
      setDeleteModal({ isOpen: false, id: "" })
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response?.msg,
        state: "error",
      }));
      setDeleteModal({ isOpen: false, id: "" })
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    getCouponListing();
  }, []);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanCreate(permission?.coupon?.create === 1)
      setCanEdit(permission?.coupon?.edit === 1)
      setCanDelete(permission?.coupon?.delete === 1)
      setCanUpdateStatus(permission?.coupon?.actions?.status === 1)
    } else {
      setCanCreate(true)
      setCanEdit(true)
      setCanDelete(true)
      setCanUpdateStatus(true)
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>Coupon Listing</Typography>
              <div className="right-filter">
                { canCreate?
                  <Button
                  variant="contained"
                  size="medius"
                  onClick={routerCouponDetail}
                  startIcon={<AddCircleOutlineIcon />}
                > Create Coupon </Button>: null}
              </div>
            </div>
            {coupons?.length > 0 ? <TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
                        Coupon Code
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => handelSort("coupon_code", "asc") } />
                          <ArrowDropDownIcon onClick={() => handelSort("coupon_code", "desc")} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                      Applicable On
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => handelSort("type", "asc") } />
                          <ArrowDropDownIcon onClick={() => handelSort("type", "desc")} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell><div className="short-div">Applied On</div></TableCell>
                    <TableCell>
                      <div className="short-div">
                        Value
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => handelSort("amount", "asc") } />
                          <ArrowDropDownIcon onClick={() => handelSort("amount", "desc")} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>Status</TableCell>

                    { canEdit || canDelete?
                      <TableCell className="nowrap" align="center">Action</TableCell>: null}

                  </TableRow>
                </TableHead>
                <TableBody>
                  {coupons?.map((coupon, index) => 
                  <TableRow key={`coupon-${index}`}>
                    <TableCell component="th" scope="row" >{coupon?.coupon_code}</TableCell>
                    <TableCell style={{textTransform: "capitalize"}}>{coupon?.type}</TableCell>
                    <TableCell>
                      {coupon?.apply_on === "all" ? "All" : `${coupon?.coupon_code_relations.length} Items`}
                    </TableCell>
                    <TableCell>{coupon?.amount} { coupon?.discount_type === "percentage" ? "%" : "QAR" }</TableCell>
                    <TableCell>
                      <Switch
                        checked={coupon?.status === "active" ? true : false}
                        onChange={(e) => handleStatusChange(e, coupon?.id) }
                        disabled={!canUpdateStatus}
                      />
                    </TableCell>

                    { canEdit || canDelete?<TableCell align="center" className="nowrap">
                      {canEdit?<Tooltip title="Edit" placement="top" sx={{ marginRight: 1 }}>
                        <BorderColorIcon color="action" fontSize="small"
                          onClick={() => navigate(`/edit-coupon/${coupon?.id}`) } />
                      </Tooltip>: null}
                      {canDelete?<Tooltip title="Delete" placement="top">
                        <DeleteForever
                          onClick={() => setDeleteModal({ isOpen: true, id: coupon?.id }) }
                          color="error" fontSize="small" />
                      </Tooltip>: null}
                    </TableCell>: null}
                  </TableRow>)}
                </TableBody>
              </Table>
              </TableContainer>: <NoDataFound text={"No Coupon Found"} /> }
              {count > 1 ? <Pagination
                count={count} page={pages} color="primary"
                onChange={(e, value) => handlePagination(e, value)}
              />: null}
          </Box>
        </Box>
      </Box>
      {/* delete Modal */}
      <Dialog
        open={deleteModal.isOpen}
        onClose={() => setDeleteModal({ ...deleteModal, isOpen: false })}
        className="alert-delete"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setDeleteModal({ ...deleteModal, isOpen: false })}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Alert
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  Are you sure you want to delete this Coupon?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                  onClick={() =>
                    setDeleteModal({ ...deleteModal, isOpen: false })
                  }
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                  onClick={() => handleOnDelete()}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
