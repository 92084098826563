import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { currentBaladiInfo } from "../../../../redux/reducers/baladiInfo";

const TrackingComponent = ({
	orderTracker,
	status,
	tracking
}) => {
	
	const baladiInfo = useSelector(currentBaladiInfo);

	let orderLogsOfBaladi = [
		{
			status: 'placed',
			allLogs: [
				{ title: 'Order Received', status: 'placed', class: 'done', date: '-', },
				{ title: 'Picking Complete', status: 'picker', class: '', date: '-', }
			],
		}, 
		{
			status: 'processing',
			allLogs: [
				{ title: "Order Received", status: 'placed', class: 'done', date: '-', },
				{ title: 'Picking Complete', status: 'picker', class: 'done', date: '-', },
				{ title: "QC Complete", status: 'qc', class: '', date: '-', },
				{ title: "Shipped", status: 'shipped', class: '', date: '-', }
			],
		},
		{
			status: 'readytopick',
			allLogs: [
				{ title: "Order Received", status: 'placed', class: 'done', date: '-', },
				{ title: 'Picking Complete', status: 'picker', class: 'done', date: '-', },
				{ title: "QC Complete", status: 'qc', class: 'done', date: '-', },
				{ title: "Shipped", status: 'shipped', class: '', date: '-', }
			],
		},
		{
			status: 'shipped',
			allLogs: [
				{ title: 'Order Received', status: 'placed', class: 'done', date: '-', },
				{ title: 'Picking Complete', status: 'picker', class: 'done', date: '-', },
				{ title: "QC Complete", status: 'qc', class: 'done', date: '-', },
				{ title: 'Shipped', status: 'shipped', class: 'done', date: '-', },
				{ title: 'Completed', status: 'completed', class: '', date: '-', }
			],
		},
		{
			status: 'completed',
			allLogs: [
				{ title: 'Order Received', status: 'placed', class: 'done', date: '-', },
				{ title: 'Picking Complete', status: 'picker', class: 'done', date: '-', },
				{ title: "QC Complete", status: 'qc', class: 'done', date: '-', },
				{ title: 'Shipped', status: 'shipped', class: 'done', date: '-', },
				{ title: 'Completed', status: 'completed', class: 'done', date: '-', },
			],
		},
		{
			status: 'cancelled',
			allLogs: [],
		},
		{
			status: 'rejected',
			allLogs: [],
		}
	];

	let orderLogsOfOther = [
		{
			status: 'placed',
			allLogs: [
				{ title: 'Order Received', status: 'placed', class: 'done', date: '-', },
				{ title: 'Processing', status: 'processing', class: '', date: '-', }
			],
		}, 
		{
			status: 'processing',
			allLogs: [
				{ title: "Order Received", status: 'placed', class: 'done', date: '-', },
				{ title: "Processing", status: 'processing', class: 'done', date: '-', },
				{ title: "Ready to Pick", status: 'readytopick', class: '', date: '-',},
			],
		},
		{
			status: 'readytopick',
			allLogs: [
				{ title: "Order Received", status: 'placed', class: 'done', date: '-', },
				{ title: "Processing", status: 'processing', class: 'done', date: '-', },
				{ title: "Ready to Pick", status: 'readytopick', class: 'done', date: '-',},
				{ title: "Shipped", status: 'shipped', class: '', date: '-', }
			],
		},
		{
			status: 'shipped',
			allLogs: [
				{ title: 'Order Received', status: 'placed', class: 'done', date: '-', },
				{ title: 'Processing', status: 'processing', class: 'done', date: '-', },
				{ title: "Ready to Pick", status: 'readytopick', class: 'done', date: '-',},
				{ title: 'Shipped', status: 'shipped', class: 'done', date: '-', },
				{ title: 'Completed', status: 'completed', class: '', date: '-', }
			],
		},
		{
			status: 'completed',
			allLogs: [
				{ title: 'Order Received', status: 'placed', class: 'done', date: '-', },
				{ title: 'Processing', status: 'processing', class: 'done', date: '-', },
				{ title: 'Shipped', status: 'shipped', class: 'done', date: '-', },
				{ title: "Ready to Pick", status: 'readytopick', class: 'done', date: '-',},
				{ title: 'Completed', status: 'completed', class: 'done', date: '-', },
			],
		},
		{
			status: 'cancelled',
			allLogs: [],
		},
		{
			status: 'rejected',
			allLogs: [],
		}
	];

	let [trackerLog, setTrackerLog] = useState([]);

	useEffect(() => {
		if (orderTracker) {
			// let logs = orderTracker === '' ? [] : JSON.parse(orderTracker);
			let logs = orderTracker
			logs = logs.filter((item) => item.role === undefined);
			let logsWithRole = orderTracker //=== '' ? [] : JSON.parse(orderTracker);
			logsWithRole = logsWithRole.filter((item) => item.role !== undefined);
			logsWithRole = logsWithRole.filter((item) => (
				(item.role === "picker" && item.status === "completed" || item.status === "pickup_completed") ||
				(item.role === "qc" && item.status === "completed" || item.status === "qc_completed")
			)).map((item) => { return { ...item, status: item.role } });
			if (tracking === baladiInfo.BALADI_STORE_SLUG) {
				logs = [...logs, ...logsWithRole];	
			}

			if (status !== 'cancelled' && status !== 'rejected') {
				const storeLogs = tracking === baladiInfo.BALADI_STORE_SLUG
				? orderLogsOfBaladi
				: orderLogsOfOther;
				const logData = storeLogs.find((item) => item.status === status);
				
				if (logData) {
					let logsArray = logData.allLogs;
					trackerLog = logsArray.map((item) => {
						let log = logs.find((log) => log.status === item.status);
						if (log) {
							item.date = moment(log.updated_at).format('DD MMMM YYYY - hh:mm A');
						}
						return item;
					});
				}
			} else {
				trackerLog = logs.map((item) => { 
					return {
						class: 'done',
						date: moment(item.updated_at).format('DD MMMM YYYY - hh:mm A'),
						title: item.status
					}
				});
			}
			
			setTrackerLog(trackerLog);
		}
	}, [orderTracker]);

  return (
    <div className="order-tracking-box">
    	{trackerLog
		.filter(
			logger => logger.date !== '-'
		)
		.map((logger, index) =>
			<div key={`orderTracker-${index}`} className={`order-status position-relative w-150 ${logger.class}`}>
				{/* {tracking === baladiInfo.BALADI_STORE_SLUG?<>
					{index == 1 && <span>Pick</span>}
					{index == 2 && <span>IN-QC</span>}
				</>: null} */}
				<p className="p2">
					{/* {tracking === baladiInfo.BALADI_STORE_SLUG && index == 2 && (<b style={{ opacity: 0 }}>test</b>)} */}
					{logger.title}</p>
				<p className="p4">
					{/* {tracking === baladiInfo.BALADI_STORE_SLUG && index == 2 && (<b style={{ opacity: 0 }}>test</b>)} */}
					{logger.date}</p>
      		</div>
		)}
			
  	</div>)

};

export default TrackingComponent;