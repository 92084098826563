import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { Button, Typography, TextField, Grid, Divider } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Aside from "../../layout/aside/Aside";
import services from "../../../services";
import Header from "../../layout/header/Header";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import Select from "@mui/material/Select";
import { changeLoader } from "../../../redux/reducers/loader";
import MenuItem from "@mui/material/MenuItem";
import { validateFromFields } from "../../helper/custom";
import { Rating } from "@material-ui/lab";
import uploadImg from "../../../assets/images/structure/upload-img.png";
import { CancelRounded } from "@material-ui/icons";
import constant from "../../../constant";
import { blobToBase64, base64toFile } from "../../../pages/helper/custom";
import imageCompression from 'browser-image-compression';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  alwaysKeepResolution: true
}

export default function DriverOnboardingDetails() {

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [showDriverPassword, setShowDriverPassword] = useState(false);
  const [token, setToken] = useState("");
  const [team_id, setTeamId] = useState("");
  const [license, setLicense] = useState("");
  const [transport_number, setTransportNumber] = useState("");
  const [transport_type, setTransportType] = useState("");
  const [nationality, setNationality] = useState("");
  const [conformPassword, setConformPassword] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(1);
  const [driver_type, setDriverType] = useState("freelancer");
  const [userName, setUserName] = useState("");
  const [profileImgUp, setProfileImgUp] = useState(null);
  //state for error
  const [fullNameErrTxt, setFullNameErrTxt] = useState("");
  const [emailErrTxt, setEmailErrTxt] = useState("");
  const [phoneErrTxt, setPhoneErrTxt] = useState("");
  const [conformPasswordErrTxt, setConformPasswordErrTxt] = useState("");
  const [tokenErrTxt, setTokenErrTxt] = useState("");
  const [teamIdErrTxt, setTeamIdErrTxt] = useState("");
  const [licenseErrTxt, setLicenseErrTxt] = useState("");
  const [transportNumberErrTxt, setTransportNumberErrTxt] = useState("");
  const [transportTypeErrTxt, setTransportTypeErrTxt] = useState("");
  const [userNameErr, setUserNameErr] = useState(false);
  const [userNameErrTxt, setUserNameErrTxt] = useState("");
  const [nationalityErrTxt, setNationalityErrTxt] = useState("");
  const [isErr, setIsErr] = useState(false);
  const [forceErr, setForceErr] = useState(false);
  const [fullNameErr, setFullNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [teams, setTeams] = useState([]);
  const [avgRating, setAvgRating] = useState(0);
  const [currentTeam, setCurrentTeam] = useState("");
  const [base64img, setBase64img] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const ShowDriverPassword = () => setShowDriverPassword((show) => !show);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownDriverPassword = (event) => {
    event.preventDefault();
  };

  const handleTransportType = (event) => {
    setRole(event.target.value);
  };
  const handleDriverType = (event) => {
    setDriverType(event.target.value);
  };
  const handleTeamChange = (event) => {
    setCurrentTeam(event.target.value);
  };
  const getSpecificDriver = async (id) => {
    dispatch(changeLoader(true));
    const response = await services?.manageDriver?.GET_SPECIFIC_DRIVER(id);
    if (response?.data?.ack == 1) {
      const res = response?.data?.store;
      setAvgRating(response?.data?.rating);
      setFullName(res?.first_name + " " + res?.last_name);
      setEmail(res?.email);
      setPhone(res?.phone);
      // setTeamId(res?.team_id);
      setToken(res?.token);
      setNationality(res?.nationality);
      setTransportType(res?.transport_type);
      setRole(res?.transport_type);
      setLicense(res?.licence);
      setTransportNumber(res?.transport_desc);
      handleNameChange(null, res?.first_name + " " + res?.last_name);
      setDriverType(res?.driver_type);
      setUserName(res?.username);
      setProfileImgUp(res?.profile_url);
      setTimeout(() => {
        setCurrentTeam(res?.team_id);
      }, 2000);
    }
    dispatch(changeLoader(false));
  };
  useEffect(() => {
    if (id) getSpecificDriver(id);
    else {
      clearFields();
    }
  }, []);
  const handleNameChange = (e, isReg) => {
    if (e) {
      setFullName(e.target.value);
      let name = e.target.value.split(" ");
      if (name.length > 1) {
        setFirst_name(name[0]);
        name.shift();
        setLast_name(name.join(" "));
        setFullNameErr(false);
      }
    } else {
      let name = isReg.split(" ");
      if (name.length > 1) {
        setFirst_name(name[0]);
        name.shift();
        setLast_name(name.join(" "));
        setFullNameErr(false);
      }
    }
  };

  const imageBase64 = () => {
    const input = document.querySelector('input[type="file"]');
    const file = input.files[0];
    const reader = new FileReader();
    const img = new Image();
    reader.onload = () => {
      const dataURL = reader.result;
      const base64String = dataURL.split(",")[1];
      setBase64img(`data:image/png;base64,${base64String}`);
      img.src = `data:image/png;base64,${base64String}`;
    };
    const read = reader.readAsDataURL(file);
  };

  const validatePassword = (pass1, pass2) => {
    if (!pass1) {
      setIsErr(true);
      setConformPasswordErrTxt("Please Enter  Password");
      return false;
    }
    if (!pass2) {
      setIsErr(true);
      setConformPasswordErrTxt("Please Enter Conform Password");
      return false;
    }
    if (pass1 !== pass2) {
      setForceErr(true);
      setIsErr(true);
      setConformPasswordErrTxt("Password And Conform Password Should Be Same");
      return false;
    }
    if (pass1.length < 8) {
      setForceErr(true);
      setIsErr(true);
      setConformPasswordErrTxt("Password Should Be 8 Character Long");
      return false;
    }
    return true;
  };
  const validateFields = () => {
    if (!first_name || !last_name) {
      setFullNameErr(true);
      setFullNameErrTxt("Please Enter Your Full Name");
    }
    if (!userName) {
      setUserNameErr(true);
      setUserNameErrTxt("Please Enter Your User Name");
    }
    if (!email) {
      setEmailErr(true);
      setEmailErrTxt("Please Enter Your Email");
    }
    if (!phone) {
      setIsErr(true);
      setPhoneErrTxt("Please Enter Your Phone");
    }
    if (!nationality) {
      setIsErr(true);
      setNationalityErrTxt("Please Enter Nationality");
    }
    if (!transport_number) {
      setIsErr(true);
      setTransportNumberErrTxt("Please Enter Transport Number");
    }
    if (!license) {
      setIsErr(true);
      setLicenseErrTxt("Please Enter License");
    }
  };
  const addDriver = async () => {
    try {
      validateFields();
      if (email)
        if (!validateFromFields("email", email)) {
          setEmailErr(true);
          setEmailErrTxt("Please Enter Valid Email");
        }
      if (phone)
        if (!validateFromFields("phone", phone)) {
          setPhoneErr(true);
          setPhoneErrTxt("Please Enter Valid Phone");
        }

      if (!validatePassword(password, conformPassword)) return;
      else setForceErr(false);
      if (
        !first_name ||
        !last_name ||
        !email ||
        !phone ||
        !nationality ||
        !transport_number ||
        !license ||
        !password ||
        !conformPassword ||
        !userName ||
        !validateFromFields("phone", phone)
      )
        return;
      const data = {
        first_name,
        last_name,
        email,
        phone,
        password,
        nationality,
        transport_type: role,
        transport_desc: transport_number,
        licence: license,
        driver_token: token,
        driver_type,
        team_id: currentTeam,
        profile_image: base64img,
        username: userName,
      };
      dispatch(changeLoader(true));
      const response = await services.manageDriver.ADD_DRIVER(data);
      if (response?.data?.ack == 1) {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: response?.data?.msg,
            state: "success",
          })
        );
        navigate("/driver-onboarding-listing");
        dispatch(changeLoader(false));
      } else {
        let err = response?.data?.errMsg;
        if (err && Array.isArray(err)) {
          err.forEach((error) => {
            Object.entries(error).forEach(([key, value]) => {
              if (key == "email") {
                setEmailErr(true);
                setEmailErrTxt(value);
              }
            });
          });
        } else {
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: response?.data?.message,
              state: "error",
            })
          );
        }
        dispatch(changeLoader(false));
      }
    } catch (e) { }
  };

  const editDriver = async (id) => {
    try {
      validateFields();

      if (
        !first_name ||
        !last_name ||
        !email ||
        !phone ||
        !nationality ||
        !transport_number ||
        !license
      )
        return;
      dispatch(changeLoader(true));
      const data = {
        first_name,
        last_name,
        email,
        phone,
        nationality,
        team_id: 1461318,
        transport_type: role,
        transport_desc: transport_number,
        licence: license,
        driver_token: token,
        username: userName,
        profile_url: profileImgUp,
      };
      const response = await services.manageDriver.EDIT_DRIVER(id, data);
      if (response?.data?.ack == 1) {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: response?.data?.msg,
            state: "success",
          })
        );
        navigate("/driver-onboarding-listing");
        dispatch(changeLoader(false));
      } else {
        let err = response?.data?.errMsg;
        if (err && Array.isArray(err)) {
          err.forEach((error) => {
            Object.entries(error).forEach(([key, value]) => {
              if (key == "email") {
                setForceErr(true);
                setEmailErrTxt(value);
              }
            });
          });
        } else {
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: response?.data?.message,
              state: "error",
            })
          );
        }
        dispatch(changeLoader(false));
      }
    } catch (e) { }
  };
  const clearFields = () => {
    setEmail("");
  };

  const getTeamId = async () => {
    const response = await services.manageDriver.GET_TEAM_ID();
    if (response?.data?.ack == 1) {
      setTeams(response?.data?.data?.data);
      setCurrentTeam(response?.data?.data?.data[0]?.team_id);
    }
  };
  useEffect(() => {
    getTeamId();
  }, []);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Driver Onboarding Details
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={6}>
                    <div className="upload-img">
                      <div className="before-upload">
                        <img src={uploadImg} alt=".." className="upload-icon" />
                        <p className="upload-text">Upload Image</p>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={async (e) => {
                            const file = e.target.files[0];
                            const compressedFile = await imageCompression(file, options);
                            const imageUrl = await blobToBase64(compressedFile);
                            imageBase64(base64toFile(imageUrl));
                          }}
                        />
                        {/* {isEdit.state == "edit" && !base64img ? (
                          <img
                            alt="img"
                            src={`${constant.IMG_BASE_URL}${isEdit?.banner?.image}`}
                            className="upload-img"
                            style={{ height: "190px" }}
                          />
                        ) : (
                          ""
                        )} */}

                        {base64img ? (
                          <img
                            src={base64img}
                            className="upload-img"
                            style={{ height: "5rem !important" }}
                            alt=""
                            id="img-profile"
                          ></img>
                        ) : (
                          ""
                        )}
                        {profileImgUp && (
                          <img
                            src={`${constant.IMG_BASE_URL}${profileImgUp}`}
                            className="upload-img"
                            style={{ height: "5rem !important" }}
                            alt=""
                            id="img-profile"
                          ></img>
                        )}
                        {base64img || profileImgUp ? (
                          <CancelRounded
                            style={{
                              position: "absolute",
                              zIndex: 1,
                              right: "3px",
                              top: "4px",
                              color: "red",
                            }}
                            onClick={() => {
                              setBase64img(null);
                              setProfileImgUp(null);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Full Name"
                      defaultValue=""
                      value={fullName}
                      name="full_name"
                      onChange={(e) => {
                        handleNameChange(e);
                      }}
                    />

                    {fullNameErr && (
                      <span className="error">{fullNameErrTxt}</span>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="User Name"
                      defaultValue=""
                      value={userName}
                      name="User Name"
                      onChange={(e) => {
                        setUserName(e.target.value);
                        setUserNameErr(false);
                      }}
                    />

                    {userNameErr && (
                      <span className="error">{userNameErrTxt}</span>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Email ID"
                      defaultValue=""
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailErr(false);
                      }}
                    />
                    {emailErr && <span className="error">{emailErrTxt}</span>}
                  </Grid>
                  {!id && (
                    <Grid item xs={6}>
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="DriverPassword">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="DriverPassword"
                          onChange={(e) => setPassword(e.target.value)}
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownDriverPassword}
                                edge="end"
                              >
                                {!showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {!id && (
                    <Grid item xs={6}>
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="DriverPassword">
                          Confirm Password
                        </InputLabel>
                        <OutlinedInput
                          id="DriverPassword"
                          type={showDriverPassword ? "text" : "password"}
                          onChange={(e) => setConformPassword(e.target.value)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={ShowDriverPassword}
                                onMouseDown={handleMouseDownDriverPassword}
                                edge="end"
                              >
                                {!showDriverPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Confirm Password"
                        />
                        {((isErr && !conformPassword) || forceErr) && (
                          <span className="error">{conformPasswordErrTxt}</span>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Phone Number"
                      // type="number"
                      defaultValue=""
                      value={phone}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+974</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setPhoneErr(false);
                      }}
                    />
                    {phoneErr && <span className="error">{phoneErrTxt}</span>}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Nationality"
                      defaultValue=""
                      value={nationality}
                      onChange={(e) => setNationality(e.target.value)}
                    />
                    {isErr && !nationality && (
                      <span className="error">{nationalityErrTxt}</span>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Team Id *
                      </InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={currentTeam}
                        defaultValue={currentTeam}
                        label="Team Id"
                        onChange={handleTeamChange}
                      >
                        <MenuItem value="">dfgdgf</MenuItem>
                        {teams?.map((team, index) => {
                          return (
                            <MenuItem value={team.team_id} key={index}>
                              {team.team_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Transport Type *
                      </InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={role}
                        label="Transport Type"
                        onChange={handleTransportType}
                      >
                        <MenuItem value={1}>Car</MenuItem>
                        <MenuItem value={2}>Motor Cycle</MenuItem>
                        <MenuItem value={3}>Bicycle</MenuItem>
                        <MenuItem value={4}>Scooter</MenuItem>
                        <MenuItem value={5}>Motor Foot</MenuItem>
                        <MenuItem value={6}>Truck</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Driver Type *
                      </InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={driver_type}
                        label="Driver Type"
                        onChange={handleDriverType}
                      >
                        <MenuItem value={"freelancer"}>Freelancer</MenuItem>
                        <MenuItem value={"permanent"}>Permanent</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="transport number"
                      defaultValue=""
                      value={transport_number}
                      onChange={(e) => {
                        setTransportNumber(e.target.value);
                      }}
                    />
                    {isErr && !transport_number && (
                      <span className="error">{transportNumberErrTxt}</span>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="license number"
                      defaultValue=""
                      value={license}
                      onChange={(e) => setLicense(e.target.value)}
                    />
                    {isErr && !license && (
                      <span className="error">{licenseErrTxt}</span>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Token"
                      defaultValue=""
                      value={token}
                      onChange={(e) => {
                        setToken(e.target.value);
                      }}
                    />
                    {isErr && !token && (
                      <span className="error">{tokenErrTxt}</span>
                    )}
                  </Grid>
                </Grid>
                {id && (
                  <>
                    {/* <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={2}
                        fontWeight={500}
                      >
                        Review & Ratings
                      </Typography>
                      <Box mb={2}>
                        <Rating
                          name="simple-controlled"
                        />
                      </Box>
                    </Grid> */}
                    <Grid item xs={6} className="driver-rating">
                      <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                        Over All Rating
                      </Typography>
                      <Box mb={2}>
                        <Rating
                          name="simple-controlled"
                          value={avgRating}
                          precision={0.5}
                        />
                      </Box>
                    </Grid>
                  </>
                )}

                {/* <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Customer Reviews
                </Typography>
                {allRating.map((item,index) => {
                  return (<> 
                    <Rating
                      name="simple-controlled"
                      value={item?.rating}
                    />
                    <p>{item?.review}</p>
                    <Rating
                      name="simple-controlled"
                      value={item?.rating}
                    />
                    <p>{item?.review}</p>
                    <Rating
                      name="simple-controlled"
                      value={item?.rating}
                    />
                    <p>{item?.review}</p>
                  </>)
                })} */}
                <Divider />
              </Grid>



              <Grid item xs={10}>
                <div className="bottom-btn-row">
                  <Button
                    variant="standard"
                    size="large"
                    sx={{ marginRight: "10px" }}
                    onClick={() => {
                      navigate("/driver-onboarding-listing");
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {
                      if (!id) addDriver();
                      else editDriver(id);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
