import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button, Typography, Tabs, Tab, Switch, Box, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Pagination, Alert, Grid, Tooltip, DialogContent, Dialog
} from "@mui/material";
import { Boxed } from "../../../Styles-Elements/Box";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeFaq, changeRole, changeId } from "../../../redux/reducers/faq";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { currentPermission } from "../../../redux/reducers/permission";
import { API } from "../../ENUM";
import GlobalService from "../../../services/GlobalService";
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import services from "../../../services";
import modalClose from "../../../assets/images/structure/close-modal.png";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import AddCancellationReason from "../../../dialog/AddCancellationReason";
import DeleteConfirmBox from "../../../dialog/DeleteConfirmBox";


const CancellationReason = () => {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const permission = useSelector(currentPermission);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canAdd, setCanAdd] = useState(true);

	const limit = 10;
	let [page, setPage] = useState(1);
	let [sort, setSort] = useState("id");
	let [order, setOrder] = useState("desc");
	let [search, setSearch] = useState("");
	let [count, setCount] = useState(1);
	let [reasons, setReasons] = useState([]);
	let [departments, setDepartments] = useState([]);
	
	let [open, setOpen] = useState(false);
	let [reasonId, setReasonId] = useState(null);
	let [openDelete, setOpenDelete] = useState(false);
	let [openEdit, setOpenEdit] = useState(false);
	let [editData, setEditData] = useState({});

	const getAllCancellationReason = async () => {
		dispatch(changeLoader(true));
		let url = `${API.GET_ALL_CANCELLATION_REASON}?limit=${limit}&page=${page}&sort=${sort}&order=${order}`;
		url += search ? `&search=${search}` : "";
		const response = await GlobalService(url)
		count = Math.ceil(response.count / limit);
		setCount(count);
		reasons = response.results;
		setReasons(reasons);
		dispatch(changeLoader(false));
	};

	const getAllCancellationDepartment = async () => {
		dispatch(changeLoader(true));
		let url = `${API.GET_ALL_CANCELLATION_DEPARTMENT}?limit=${limit}&page=${page}&sort=${sort}&order=${order}`;
		url += search ? `&search=${search}` : "";
		const response = await GlobalService(url)
		count = Math.ceil(response.count / limit);
		// setCount(count);
		reasons = response.results;
		setDepartments(reasons);
		dispatch(changeLoader(false));
	};
	const handleDelete = async () => {
		dispatch(changeLoader(true));
		const response = await GlobalService(`${API.DELETE_CANCELLATION_REASON(reasonId)}`, {}, 'delete');
		if (response.ack === 1) {
			dispatch(changeSnackbar({ isOpen: true, message: response?.msg, state: "success" }));
			dispatch(changeLoader(false));
			getAllCancellationReason();
		} else {
			let message = typeof response?.msg == 'String' ? response?.msg: "Something went wrong";
			dispatch(changeSnackbar({ isOpen: true, message: message, state: "error" }));
			dispatch(changeLoader(false));
		}
	};

	const handleStatusChange = async (e, reasonId) => {
		dispatch(changeLoader(true));
		const response = await GlobalService(
			`${API.UPDATE_CANCELLATION_REASON_STATUS(reasonId)}`,
			{ status: e.target.checked ? 'active' : 'inactive' },
			'put');
		if (response.ack === 1) {
			dispatch(changeSnackbar({ isOpen: true, message: response?.msg, state: "success" }));
			dispatch(changeLoader(false));
			getAllCancellationReason();
		} else {
			dispatch(changeSnackbar({ isOpen: true, message: response?.msg, state: "error" }));
			dispatch(changeLoader(false));
		}
	};

	useEffect(() => {
		getAllCancellationDepartment();
		getAllCancellationReason();	
	}, []);

	useEffect(() => {
    if (permission.role !== "admin") {
      setCanEdit(permission?.cms.edit === 1)
      setCanAdd(permission?.cms.create === 1)
      setCanDelete(permission?.cms.delete === 1)
    } else {
      setCanEdit(true)
      setCanAdd(true)
      setCanDelete(true)
    }
  }, [permission]);


  return (
		<>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} mt={1} fontWeight={500}>Cancellation Reason</Typography>
              <div className="right-filter">
							{canAdd?<Button
								variant="contained" size="large" type="button"
								onClick={() => setOpen(true)}
							>Add Reason</Button>: null}
              </div>
            </div>

						{reasons.length > 0?<TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
				  <TableCell>Department</TableCell>
                    <TableCell>Cancel Reason</TableCell>
                    <TableCell>Status</TableCell>
                    {canEdit || canDelete?<TableCell>Action</TableCell>: null}
                  </TableRow>
                </TableHead>
                <TableBody>
								{reasons.map((reason, index) =>
									<TableRow key={`reason-${index}`}>
										<TableCell>{reason?.order_cancellation_department?.department_name  || <span style={{color: 'red'}}>N/A</span>}  </TableCell>
										<TableCell>{reason.reason}  </TableCell>
										
                    <TableCell>
											<Switch
												checked={reason.status === 'active'}
												onChange={(e) => handleStatusChange(e, reason.id)}
												disabled={!canEdit}
											/>
										</TableCell>
                    {canEdit || canDelete?<TableCell>
											{canEdit?<Tooltip title="Edit">
												<BorderColorIcon className="cursor-pointer" onClick={() => {
													setEditData(reason);
													setOpenEdit(true);
												}} />
											</Tooltip>: null}
											{canDelete?<Tooltip title="Delete">
												<DeleteForever className="cursor-pointer" onClick={() => {
													setReasonId(reason.id);
													setOpenDelete(true);
												}} />
											</Tooltip>: null}
										</TableCell>: null}
                  </TableRow>)}
                </TableBody>
              </Table>
							{count > 1 && (
								<Pagination
									count={count}
									color="primary"
									page={page}
									onChange={(e, newPage) => {
										page = newPage; setPage(page); getAllCancellationReason();
									}}
								/>
							)}
            </TableContainer>: <NoDataFound text={"No Data Found"} />}
          </Box>
        </Box>
      </Box>
		  {open ? <AddCancellationReason
			  	departments={departments}
				handleClose={() => setOpen(false)}
				handleConfirm={() => {
					setOpen(false);
					getAllCancellationReason();
				}}
				isEdit={false}
			/>:null}
			{openDelete? <DeleteConfirmBox
				title="Delete Reason"
				description="Are you sure you want to delete this reason?"
				handleClose={() => setOpenDelete(false)}
				handleConfirm={() => {
					setOpenDelete(false);
					handleDelete();
				}}
			/>:null}
		  {openEdit ? <AddCancellationReason
			  	departments={departments}
				handleClose={() => setOpenEdit(false)}
				handleConfirm={() => {
					setOpenEdit(false);
					getAllCancellationReason();
				}}
				editData={editData}
				isEdit={true}
			/>:null}
    </>
	);
};

export default CancellationReason;