import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Typography,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Box,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Radio,
  RadioGroup,
  Chip,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { getdataByKey, getFormatedDateDb } from "../../helper/custom";
import { amountMask } from "../../../global-modules/MaskedInputs";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../../pages/ENUM";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { currentPermission } from "../../../redux/reducers/permission";

export default function CouponDetail() {
  const permission = useSelector(currentPermission);
  let [canCreate, setCanCreate] = useState(true);

  const [RadioParentCheck, setRadioParentCheck] = useState(1);

  const [RadioParentCheckChildBusiness, setRadioParentCheckChildBusiness] =
    useState(1);
  const [RadioParentCheckChildStore, setRadioParentCheckChildStore] =
    useState(1);
  const [RadioParentCheckChildProduct, setRadioParentCheckChildProduct] =
    useState(1);
  const [RadioParentCheckChildBrand, setRadioParentCheckChildBrand] =
    useState(1);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const discountList = [
    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;

  let [brandList, setBrandList] = useState([]);
  let [storeList, setStoreList] = useState([]);
  let [product, setProduct] = useState([]);
  let [businessType, setBusinessType] = useState([]);
  let [selectedBusiness, setSelectedBusiness] = useState([]);
  let [selectedBrand, setSelectedBrand] = useState([]);
  let [excludeSale, setExcludeSale] = useState(false);
  const [vendorContributionAmount, setVendorContributionAmount] = useState(0);
  const [adminContributionAmount, setAdminContributionAmount] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [validUpto, setValidUpto] = useState(null);
  const [validFrom, setValidFrom] = useState(null);
  const [maxSpend, setMaxSpend] = useState("");
  const [minSpend, setMinSpend] = useState("");
  const [brand, setBrand] = useState([]);
  const [store, setStore] = useState([]);
  const [products, setProducts] = useState([]);
  const [discountType, setDiscountType] = useState("percentage");
  const [discountAmount, setDiscountAmount] = useState("");
  const [useCount, setUseCount] = useState("");
  
  const [isBrandDivVisibleAutocomplate, setIsBrandDivVisibleAutocomplate] = useState(false);
  const [isDivVisibleAutocomplate, setIsDivVisibleAutocomplate] = useState(false);
  const [isProductDivVisibleAutocomplate, setIsProductDivVisibleAutocomplate] = useState(false);

  const [status, setStatus] = useState("active");
  const [couponCodeError, setCouponCodeError] = useState("");
  const [discountAmountError, setDiscountAmountError] = useState("");
  const [storeError, setStoreError] = useState("");
  const [validUptoError, setValidUptoError] = useState("");
  const [validFromError, setValidFromError] = useState("");
  const [storeSearch, setStoreSearch] = useState("");
  const [brandSearch, setBrandSearch] = useState("");
  const [productSearch, setProductSearch] = useState("");
  const [useCountError, setUseCountError] = useState("");
  const [minSpendError, setMinSpendError] = useState("");
  const [maxSpendError, setMaxSpendError] = useState("");
  const [userCount, setUserCount] = useState("");
  const [userCountError, setUserCountError] = useState("");
  const [canApplyOn, setCanApplyOn] = useState("all");

  const getAllbrand = async () => {
    try {
      const response = await GlobalService(API.GET_ALL_BRANDS(brandSearch));
      if (response?.ack === 1) {
        brandList = response.data.map((item) => {
          return {
            id: item.id,
            name: getdataByKey(item?.stores_locales, "en", "business_name"),
          };
        });
        setBrandList(brandList);
        setIsBrandDivVisibleAutocomplate(true);
      } else {
        brandList = [];
        setBrandList(brandList);
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  }

  const getAllStores = async () => {
    try {
      const response = await GlobalService(API.GET_ALL_STORES(storeSearch));
      if (response?.ack === 1) {
        storeList = response.data.map((item) => {
          return {
            id: item.id,
            name: getdataByKey(item?.stores_locales, "en", "business_name"),
          };
        });
        setStoreList(storeList);
        setIsDivVisibleAutocomplate(true);
      } else {
        storeList = [];
        setStoreList(storeList);
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const getAllProducts = async () => {
    try {
      let url = API.GET_ALL_PRODUCTS(productSearch);
      // url += `&storeId=${store[0]?.id}`;
      const response = await GlobalService(url);
      if (response?.ack === 1) {
        product = response.data.map((item) => {
          let itemName = item?.title;
          if (!itemName) {
            itemName = item?.products_locales[0]?.title;
          }
          return {
            id: item.id,
            name: itemName,
          };
        });
        setProduct(product);
        setIsProductDivVisibleAutocomplate(true);
      } else {
        product = [];
        setProduct(product);
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const getAllBusiness = async () => {
    try {
      const response = await GlobalService(API.GET_ALL_BUSINESS);
      if (response?.ack === 1) {
        businessType = response.businesstype.rows;
        setBusinessType(businessType);
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const saveCoupon = async () => {
    dispatch(changeLoader(true));
    let isValid = true;
    if (couponCode === "") {
      setCouponCodeError("Please enter coupon code");
      isValid = false;
    }
    if (discountType === "flat" && discountAmount === "") {
      setDiscountAmountError("Please enter discount amount");
      isValid = false;
    } else if (discountType === "percentage" && discount === "") {
      setDiscountAmountError("Please enter discount amount");
      isValid = false;
    }
    if (validUpto === "" || validUpto === null) {
      setValidUptoError("Please select valid upto date");
      isValid = false;
    }
    if (validFrom === "" || validFrom === null) {
      setValidFromError("Please select valid from date");
      isValid = false;
    } else if (validFrom > validUpto) {
      setValidFromError("Valid from date should be less than valid upto date");
      isValid = false;
    }
    if (useCount === "") {
      setUseCountError("Please enter use count");
      isValid = false;
    }
    if (discountType !== "flat") {
      if (maxSpend === "" || maxSpend === undefined) {
        setMaxSpendError("Please enter max spend");
        isValid = false;
      }
    }
    if (minSpend === "" || minSpend === undefined) {
      setMinSpendError("Please enter min spend");
      isValid = false;
    }
    let type = "";
    let apply_on = "";
    let itemId = "";
    switch (RadioParentCheck) {
      case 1:
        type = "business_type";
        if (RadioParentCheckChildBusiness === 1) {
          apply_on = "all";
        } else {
          apply_on = "specific";
          itemId = selectedBusiness.join(",");
        }
        break;
      case 2:
        type = "store";
        if (RadioParentCheckChildStore === 1) {
          apply_on = "all";
        } else {
          apply_on = "specific";
          itemId = store.map((item) => item.id).join(",");
        }
        break;
      case 3:
        type = "product";
        if (RadioParentCheckChildProduct === 1) {
          apply_on = "all";
        } else {
          apply_on = "specific";
          itemId = products.map((item) => item.id).join(",");
        }
        break;
      case 4:
        type = "brand";
        if (RadioParentCheckChildBrand === 1) {
          apply_on = "all";
        } else {
          apply_on = "specific";
          itemId = brand.map((item) => item.id).join(",");
        }
        break;
      default:
        type = "business_type";
        if (RadioParentCheckChildBusiness === 1) {
          apply_on = "all";
        } else {
          apply_on = "specific";
        }
        break;
    }
    if (apply_on === "specific" && itemId === "") {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: "Please select at-least one item",
          state: "error",
        })
      );
      isValid = false;
    }
    if (isValid) {
      const data = {
        coupon_code: couponCode,
        discount_type: discountType,
        amount: discountType === "flat" ? discountAmount : discount,
        start_date: validFrom ? getFormatedDateDb(validFrom): "",
        end_date: validUpto ? getFormatedDateDb(validUpto) : "",
        minimum_spend: minSpend,
        maximum_discount_value: discountType === "flat" ? discountAmount: maxSpend,
        exclude_sale_items: excludeSale ? 1 : 0,
        uses_count: useCount,
        user_use_count: userCount,
        can_apply_on: canApplyOn,
        type: type,
        apply_on: apply_on,
        itemId: itemId,
        vendor_countribution_amount: vendorContributionAmount,
        admin_countribution_amount: adminContributionAmount,
        status: status,
      };
      const response = id
        ? await GlobalService(API.UPDATE_COUPONS(id), data, "put")
        : await GlobalService(API.ADD_COUPONS, data, "post");
      if (response.ack === 1) {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: response?.msg,
            state: "success",
          })
        );
        dispatch(changeLoader(false));
        navigate("/coupon-listing");
      } else {
        if (response?.msg !== "") {
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: response?.msg,
              state: "error",
            })
          );
        } else {
          let message = "";
          response?.errMsg.forEach((element) => {
            for (const [key, value] of Object.entries(element)) {
              message += value + "\n";
            }
          });
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: message,
              state: "error",
            })
          );
        }
        dispatch(changeLoader(false));
      }
    } else {
      dispatch(changeLoader(false));
    }
  };

  const getCouponDetail = async () => {
    dispatch(changeLoader(true));
    const response = await GlobalService(API.GET_COUPON_DETAIL(id));
    if (response.ack === 1) {
      setCouponCode(response?.data?.coupon_code);
      setMaxSpend(response?.data?.maximum_discount_value.toString());
      setMinSpend(response?.data?.minimum_spend.toString());
      setExcludeSale(response?.data?.exclude_sale_items === 1 ? true : false);
      setUseCount(response?.data?.uses_count.toString());
      setDiscountType(response?.data?.discount_type);
      if (response?.data?.discount_type === "flat") {
        setDiscountAmount(response?.data?.amount.toString());
      } else {
        setDiscount(response?.data?.amount);
      }
      setValidUpto(new Date(response?.data?.end_date));
      setValidFrom(new Date(response?.data?.start_date));
      setStatus(response?.data?.status);
      setVendorContributionAmount(response?.data?.vendor_countribution_amount);
      setAdminContributionAmount(response?.data?.admin_countribution_amount);
      setUserCount(response?.data?.user_use_count.toString());
      setCanApplyOn(response?.data?.can_apply_on);

      let type = response?.data?.type;
      let apply_on = response?.data?.apply_on;
      switch (type) {
        case "business_type":
          setRadioParentCheck(1);
          if (apply_on === "all") {
            setRadioParentCheckChildBusiness(1);
          } else {
            setRadioParentCheckChildBusiness(2);
            setSelectedBusiness(
              response?.data?.coupon_code_relations.map((item) => item.itemId)
            );
          }
          break;
        case "store":
          setRadioParentCheck(2);
          if (apply_on === "all") {
            setRadioParentCheckChildStore(1);
          } else {
            setRadioParentCheckChildStore(2);
            const storeLogs = response?.data?.coupon_code_relations.map(
              (item) => {
                return {
                  id: item.itemId,
                  name: getdataByKey(item.locals, "en", "business_name"),
                };
              }
            );
            setStore(storeLogs);
          }
          break;
        case "product":
          setRadioParentCheck(3);
          if (apply_on === "all") {
            setRadioParentCheckChildProduct(1);
          } else {
            setRadioParentCheckChildProduct(2);
            const ProductLogs = response?.data?.coupon_code_relations.map(
              (item) => {
                return {
                  id: item.itemId,
                  name: getdataByKey(item.locals, "en", "title"),
                };
              }
            );
            setProducts(ProductLogs);
          }
          break;
        case "brand":
          setRadioParentCheck(4);
          if (apply_on === "all") {
            setRadioParentCheckChildBrand(1);
          } else {
            setRadioParentCheckChildBrand(2);
            const brandLogs = response?.data?.coupon_code_relations.map(
              (item) => {
                return {
                  id: item.itemId,
                  name: getdataByKey(item.locals, "en", "business_name"),
                };
              }
            );
            setBrand(brandLogs);
          }
          break;
        default:
          break;
      }
      dispatch(changeLoader(false));
    } else {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: response?.msg,
          state: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (brandSearch !== "") {
      const getData = setTimeout(() => {
        getAllbrand();
      }, 500);
      return () => clearTimeout(getData);
    } else {
      setStoreList([]);
    }
  }, [brandSearch]);

  useEffect(() => {
    if (storeSearch !== "") {
      const getData = setTimeout(() => {
        getAllStores();
      }, 500);
      return () => clearTimeout(getData);
    } else {
      setStoreList([]);
    }
  }, [storeSearch]);

  useEffect(() => {
    if (productSearch !== "") {
      const getData = setTimeout(() => {
        getAllProducts();
      }, 500);
      return () => clearTimeout(getData);
    } else {
      setProduct([]);
    }
  }, [productSearch]);

  useEffect(() => {
    getAllBusiness();
  }, []);

  useEffect(() => {
    if (id) {
      getCouponDetail();
    }
  }, [id]);

  useEffect(() => {
    if (permission.role !== "admin") {
      if (id) {
        setCanCreate(permission?.coupon?.edit === 1);
      } else {
        setCanCreate(permission?.coupon?.create === 1);
      }
    } else {
      setCanCreate(true);
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Coupon Details
                </Typography>
              </Grid>
              <Grid item xs={4} align="right">
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  type="button"
                  onClick={() => navigate("/coupon-listing")}
                >
                  Cancel Coupon
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={3} mb={4} disabled="true">
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-required"
                      label="Coupon Code *"
                      value={couponCode}
                      disabled={!canCreate}
                      onChange={(e) => {
                        setCouponCode(e.target.value);
                        setCouponCodeError("");
                      }}
                    />
                    <div className="error">{couponCodeError}</div>
                    <Typography fontSize={"12px"} mb={1} fontWeight={500}>
                      A unique code of your choice with in upto you identify the
                      code
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={useCount}
                      onChange={(e) => {
                        setUseCount(e.target.value);
                      }}
                      disabled={!canCreate}
                      id="outlined-required"
                      label="Use Count"
                      InputProps={{
                        inputComponent: amountMask,
                      }}
                    />
                    <div className="error">{useCountError}</div>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={userCount}
                      onChange={(e) => {
                        setUserCount(e.target.value);
                        if (canApplyOn === "first") {
                          setUserCount("1");
                        }
                      }}
                      disabled={!canCreate}
                      id="outlined-required"
                      label="Per User Use Count"
                      InputProps={{
                        inputComponent: amountMask,
                      }}
                    />
                    <div className="error">{userCountError}</div>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">
                        Can Apply On
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Can Apply On*"
                        value={canApplyOn}
                        disabled={!canCreate}
                        onChange={(e) => {
                          setCanApplyOn(e.target.value)
                          if (e.target.value === "first") {
                            setUserCount('1')
                          }
                        }}
                      >
                        <MenuItem value={"all"}>All Orders</MenuItem>
                        <MenuItem value={"first"}>First Order only</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">
                        Discount Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Discount Type*"
                        value={discountType}
                        disabled={!canCreate}
                        onChange={(e) => setDiscountType(e.target.value)}
                      >
                        <MenuItem value={"percentage"}>Percent</MenuItem>
                        <MenuItem value={"flat"}>Flat</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth required>
                      {discountType === "flat" ? (
                        <>
                          <TextField
                            id="outlined-required"
                            label="Coupon Value *"
                            type="number"
                            disabled={!canCreate}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  QAR
                                </InputAdornment>
                              ),
                              inputComponent: amountMask,
                            }}
                            value={discountAmount}
                            onChange={(e) => {
                              setDiscountAmount(e.target.value);
                              setDiscountAmountError("");
                            }}
                          />
                          <div className="error">{discountAmountError}</div>
                        </>
                      ) : (
                        <>
                          <TextField
                            id="outlined-required"
                            label="Coupon Value *"
                            type="number"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                            value={discount}
                            disabled={!canCreate}
                            onChange={(e) => {
                              if (e.target.value <= 100) {
                                setDiscount(e.target.value);
                                setDiscountAmountError("");
                              }
                            }}
                          />
                          <div className="error">{discountAmountError}</div>
                        </>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Valid From"
                        value={validFrom}
                        minDate={new Date()}
                        disabled={!canCreate}
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue) => {
                          setValidFrom(newValue);
                          setValidFromError("");
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <div className="error">{validFromError}</div>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Valid Upto"
                        value={validUpto}
                        disabled={!canCreate}
                        onChange={(newValue) => {
                          setValidUpto(newValue);
                          setValidUptoError("");
                        }}
                        inputFormat="DD/MM/YYYY"
                        renderInput={(params) => <TextField {...params} />}
                        minDate={validFrom !== "" ? validFrom : new Date()}
                      />
                    </LocalizationProvider>
                    <div className="error">{validUptoError}</div>
                  </Grid>

                  {discountType !== "flat"?<Grid item xs={6}>
                    <TextField
                      id="outlined-required"
                      label="Maximum spend value"
                      value={maxSpend}
                      onChange={(e) => {
                        let val = e.target.value;
                        if (val === "") {
                          setMaxSpend("");
                          setMaxSpendError("");
                          return;
                        }
                        let numVal = parseInt(val);
                        if (numVal > 0) {
                          setMaxSpend(val);
                          setMaxSpendError("");
                        } else {
                          setMaxSpendError("Value should be greater than 0");
                        }
                      }}
                      disabled={!canCreate}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">QAR</InputAdornment>
                        ),
                        inputComponent: amountMask,
                      }}
                    />
                    <Typography
                      variant="body2"
                      component="body2"
                      fontSize={"12px"}
                      mb={3}
                      fontWeight={500}
                    >
                      Maximum amount value that can be applied for this coupon
                    </Typography>
                    <div className="error">{maxSpendError}</div>
                  </Grid>: null}

                  <Grid item xs={6}>
                    <TextField
                      value={minSpend}
                      onChange={(e) => {
                        let val = e.target.value;
                        if (val === "") {
                          setMinSpend("");
                          setMinSpendError("");
                          return;
                        }

                        let numVal = parseInt(val);

                        if (numVal > 0) {
                          setMinSpend(val);
                          setMinSpendError("");
                        } else {
                          setMinSpendError("Value should be greater than 0");
                        }
                      }}
                      id="outlined-required"
                      label="Minimum Spend"
                      disabled={!canCreate}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">QAR</InputAdornment>
                        ),
                        inputComponent: amountMask,
                      }}
                    />
                    <Typography
                      variant="body2"
                      component="body2"
                      fontSize={"12px"}
                      mb={3}
                      fontWeight={500}
                    >
                      Minimum order value on which the coupon can be applied
                    </Typography>
                    <div className="error">{minSpendError}</div>
                  </Grid>
                  <Grid item xs={10}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            excludeSale = e.target.checked;
                            setExcludeSale(excludeSale);
                          }}
                          disabled={!canCreate}
                          {...label}
                          checked={excludeSale ? true : false}
                        />
                      }
                      label="Exclude Sale Items of Clicked will not be applicable on discounted items"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      component="h6"
                      mb={2}
                      fontWeight={500}
                    >
                      Applicable on
                    </Typography>

                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={1}
                        name="storeligiblity"
                        value={RadioParentCheck}
                        disabled={!canCreate}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Business Type"
                          onClick={() => setRadioParentCheck(1)}
                          disabled={!canCreate}
                        />
                        <div className="pl-32">
                          {RadioParentCheck === 1 && (
                            <>
                              <FormControl>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue={1}
                                  name="storeligiblity"
                                  value={RadioParentCheckChildBusiness}
                                  disabled={!canCreate}
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio disabled={!canCreate} />}
                                    label="All Business"
                                    onClick={() =>
                                      setRadioParentCheckChildBusiness(1)
                                    }
                                  />
                                  <FormControlLabel
                                    value={2}
                                    control={<Radio disabled={!canCreate} />}
                                    label="Specific Business"
                                    onClick={() =>
                                      setRadioParentCheckChildBusiness(2)
                                    }
                                    disabled={!canCreate}
                                  />
                                </RadioGroup>
                              </FormControl>
                              {RadioParentCheckChildBusiness === 2 && (
                                <>
                                  <Grid item xs={6}>
                                    <FormControl fullWidth required>
                                      <InputLabel id="demo-simple-select-label">
                                        Business Type
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Business Type"
                                        multiple={true}
                                        value={selectedBusiness}
                                        disabled={!canCreate}
                                        onChange={(e) => {
                                          setSelectedBusiness(e.target.value);
                                        }}
                                      >
                                        {businessType.length > 0
                                          ? businessType.map(
                                              (business, index) => (
                                                <MenuItem
                                                  value={business.id}
                                                  key={`business-${index}`}
                                                >
                                                  {getdataByKey(
                                                    business?.business_type_locales,
                                                    "en",
                                                    "name"
                                                  )}
                                                </MenuItem>
                                              )
                                            )
                                          : null}
                                      </Select>
                                    </FormControl>
                                    {selectedBusiness.map((select, key) => {
                                      let business = businessType.find(
                                        (business) => business.id === select
                                      );
                                      return (
                                        <Chip
                                          label={getdataByKey(
                                            business?.business_type_locales,
                                            "en",
                                            "name"
                                          )}
                                          key={`business-${key}`}
                                          sx={{ mr: 1, mt: 2 }}
                                          onDelete={() => {
                                            const allBusiness = [
                                              ...selectedBusiness,
                                            ];
                                            allBusiness.splice(key, 1);
                                            setSelectedBusiness(allBusiness);
                                          }}
                                        />
                                      );
                                    })}
                                  </Grid>
                                </>
                              )}
                            </>
                          )}
                        </div>


                        <FormControlLabel
                          value={4}
                          control={<Radio />}
                          label="Brand"
                          onClick={() => setRadioParentCheck(4)}
                          disabled={!canCreate}
                        />
                        <div className="pl-32">
                          {RadioParentCheck === 4 && (
                            <>
                              <FormControl>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue={1}
                                  name="storeligiblity"
                                  value={RadioParentCheckChildBrand}
                                  disabled={!canCreate}
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio disabled={!canCreate} />}
                                    label="All Brands"
                                    onClick={() =>
                                      setRadioParentCheckChildBrand(1)
                                    }
                                  />
                                  <FormControlLabel
                                    value={2}
                                    control={<Radio disabled={!canCreate} />}
                                    label="Specific Brand"
                                    onClick={() =>
                                      setRadioParentCheckChildBrand(2)
                                    }
                                    disabled={!canCreate}
                                  />
                                </RadioGroup>
                              </FormControl>
                              {RadioParentCheckChildBrand === 2 && (
                                <>
                                  <Grid item xs={6}>
                                  <FormControl fullWidth required>
                                    <TextField
                                      label="Select Brand"
                                      placeholder="Enter your Brand Name which you want to search"
                                      onClick={() =>
                                        setIsBrandDivVisibleAutocomplate(
                                          !isBrandDivVisibleAutocomplate
                                        )
                                      }
                                      onChange={(e) =>
                                        setBrandSearch(e.target.value)
                                      }
                                      value={brandSearch}
                                      id="brandSearch"
                                      disabled={!canCreate}
                                    />
                                    {isBrandDivVisibleAutocomplate && (
                                      <ul className="show-hide-div">
                                        {brandList && brandList.length > 0
                                          ? brandList.map((item, index) => (
                                              <li
                                                key={`brand-${index}`}
                                                onClick={() => {
                                                  const allBrand = [...brand];
                                                  let index =
                                                  allBrand.findIndex(
                                                      (store) =>
                                                        store.id === item.id
                                                    );
                                                  if (index === -1) {
                                                    allBrand.push(item);
                                                  } else {
                                                    allBrand.splice(index, 1);
                                                  }
                                                  setBrand(allBrand);
                                                }}
                                                className={
                                                  brand.findIndex(
                                                    (store) =>
                                                      store.id === item.id
                                                  ) !== -1
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                {item?.name}
                                              </li>
                                            ))
                                          : null}
                                      </ul>
                                    )}
                                  </FormControl>
                                  {brand.map((sto) => (
                                    <Chip
                                      key={`brand-chip-${sto.id}`}
                                      label={sto.name}
                                      sx={{ mr: 1, mt: 2 }}
                                      onDelete={() => {
                                        const allBrand = [...brand];
                                        let index = allBrand.findIndex(
                                          (store) => store.id === sto.id
                                        );
                                        if (index !== -1) {
                                          allBrand.splice(index, 1);
                                        }
                                        setBrand(allBrand);
                                      }}
                                    />
                                  ))}
                                </Grid>
                                </>
                              )}
                            </>
                          )}
                        </div>

                        <FormControlLabel
                          value={2}
                          control={<Radio disabled={!canCreate} />}
                          label="Store"
                          onClick={() => setRadioParentCheck(2)}
                        />
                        <div className="pl-32">
                          {RadioParentCheck === 2 && (
                            <>
                              <FormControl>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue={1}
                                  name="storeligiblity"
                                  value={RadioParentCheckChildStore}
                                  disabled={!canCreate}
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio disabled={!canCreate} />}
                                    label="All Store"
                                    onClick={() =>
                                      setRadioParentCheckChildStore(1)
                                    }
                                  />
                                  <FormControlLabel
                                    value={2}
                                    control={<Radio disabled={!canCreate} />}
                                    label="Specific Store"
                                    onClick={() =>
                                      setRadioParentCheckChildStore(2)
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                              {RadioParentCheckChildStore === 2 && (
                                <Grid item xs={6}>
                                  <FormControl fullWidth required>
                                    <TextField
                                      label="Select Store"
                                      placeholder="Enter your Store Name which you want to search"
                                      onClick={() =>
                                        setIsDivVisibleAutocomplate(
                                          !isDivVisibleAutocomplate
                                        )
                                      }
                                      onChange={(e) =>
                                        setStoreSearch(e.target.value)
                                      }
                                      value={storeSearch}
                                      id="storeSearch"
                                      disabled={!canCreate}
                                    />
                                    {isDivVisibleAutocomplate && (
                                      <ul className="show-hide-div">
                                        {storeList && storeList.length > 0
                                          ? storeList.map((item, index) => (
                                              <li
                                                key={`store-${index}`}
                                                onClick={() => {
                                                  const allStore = [...store];
                                                  let index =
                                                    allStore.findIndex(
                                                      (store) =>
                                                        store.id === item.id
                                                    );
                                                  if (index === -1) {
                                                    allStore.push(item);
                                                  } else {
                                                    allStore.splice(index, 1);
                                                  }
                                                  setStore(allStore);
                                                }}
                                                className={
                                                  store.findIndex(
                                                    (store) =>
                                                      store.id === item.id
                                                  ) !== -1
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                {item?.name}
                                              </li>
                                            ))
                                          : null}
                                      </ul>
                                    )}
                                  </FormControl>
                                  {store.map((sto) => (
                                    <Chip
                                      key={`store-chip-${sto.id}`}
                                      label={sto.name}
                                      sx={{ mr: 1, mt: 2 }}
                                      onDelete={() => {
                                        const allStore = [...store];
                                        let index = allStore.findIndex(
                                          (store) => store.id === sto.id
                                        );
                                        if (index !== -1) {
                                          allStore.splice(index, 1);
                                        }
                                        setStore(allStore);
                                      }}
                                    />
                                  ))}
                                </Grid>
                              )}
                            </>
                          )}
                        </div>
                        <FormControlLabel
                          value={3}
                          control={<Radio disabled={!canCreate} />}
                          label="Product"
                          onClick={() => setRadioParentCheck(3)}
                        />
                        <div className="pl-32">
                          {RadioParentCheck === 3 && (
                            <>
                              <FormControl>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue="allProductType"
                                  name="storeligiblity"
                                  value={RadioParentCheckChildProduct}
                                  disabled={!canCreate}
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio disabled={!canCreate} />}
                                    label="All Product"
                                    onClick={() =>
                                      setRadioParentCheckChildProduct(1)
                                    }
                                  />
                                  <FormControlLabel
                                    value={2}
                                    control={<Radio disabled={!canCreate} />}
                                    label="Specific Product"
                                    onClick={() =>
                                      setRadioParentCheckChildProduct(2)
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                              {RadioParentCheckChildProduct === 2 && (
                                <>
                                  <Grid item xs={6}>
                                    <FormControl fullWidth required>
                                      <TextField
                                        label="Select Product"
                                        placeholder="Enter your product name which you want to search"
                                        onClick={() =>
                                          setIsProductDivVisibleAutocomplate(
                                            !isProductDivVisibleAutocomplate
                                          )
                                        }
                                        onChange={(e) =>
                                          setProductSearch(e.target.value)
                                        }
                                        value={productSearch}
                                        disabled={!canCreate}
                                        id="productSearch"
                                      />
                                      {isProductDivVisibleAutocomplate && (
                                        <ul className="show-hide-div">
                                          {product && product.length > 0
                                            ? product.map((item, index) => (
                                                <li
                                                  key={`product-${index}`}
                                                  onClick={() => {
                                                    const allProduct = [
                                                      ...products,
                                                    ];
                                                    let index =
                                                      allProduct.findIndex(
                                                        (prod) =>
                                                          prod.id === item.id
                                                      );
                                                    if (index === -1) {
                                                      allProduct.push(item);
                                                    } else {
                                                      allProduct.splice(
                                                        index,
                                                        1
                                                      );
                                                    }
                                                    setProducts(allProduct);
                                                  }}
                                                  // className={store.findIndex((store) => store.id === item.id) !== -1 ? "active" : ""}
                                                >
                                                  {item?.name}
                                                </li>
                                              ))
                                            : null}
                                        </ul>
                                      )}
                                    </FormControl>
                                    {products.map((selectedProd, index) => (
                                      <Chip
                                        key={`product-chip-${index}`}
                                        label={selectedProd.name}
                                        sx={{ mr: 1, mt: 2 }}
                                        onDelete={() => {
                                          const allProduct = [...products];
                                          allProduct.splice(index, 1);
                                          setProducts(allProduct);
                                        }}
                                      />
                                    ))}
                                  </Grid>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="standard"
                      size="large"
                      sx={{ marginRight: "10px" }}
                      onClick={() => navigate("/coupon-listing")}
                    >
                      Back
                    </Button>
                    <div className="bottom-btn-row">
                      {canCreate ? (
                        <Button
                          variant="contained"
                          size="large"
                          color="primary"
                          onClick={() => saveCoupon()}
                        >
                          Save
                        </Button>
                      ) : null}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
