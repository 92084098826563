import React from "react";
import Box from "@mui/material/Box";
import { Typography, Grid } from "@mui/material";

const PersonalDetails = ({ TabPanel, value, persnalDetail }) => {
    return (
    <TabPanel value={value} index={0}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          component="h6"
          mb={3}
          fontSize={"16px"}
          fontWeight={500}
        >
          Personal Detail
        </Typography>
      </Grid>
      <div className="card-white">
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box>
                <Typography
                  variant="h6"
                  color="#999999"
                  component="h6"
                  fontSize={"12px"}
                  fontWeight={500}
                >
                  Customer Name
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"14px"}
                  fontWeight={400}
                >
                  {persnalDetail?.full_name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography
                  variant="h6"
                  color="#999999"
                  component="h6"
                  fontSize={"12px"}
                  fontWeight={500}
                >
                  Phone number
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"14px"}
                  fontWeight={400}
                >
                  {`+${persnalDetail?.country_code}-${persnalDetail?.contact_number}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography
                  variant="h6"
                  color="#999999"
                  component="h6"
                  fontSize={"12px"}
                  fontWeight={500}
                >
                  Email Id
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"14px"}
                  fontWeight={400}
                >
                  {persnalDetail?.email}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </TabPanel>
  );
};

export default PersonalDetails;
