import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Boxed } from "../Styles-Elements/Box";
const DeleteConfirmBox = ({ handleClose, submit, title, description, handleConfirm }) => {

  return (
    <>
      <Dialog open={true} onClose={handleClose} className="main-modal theme-dialog">
        <DialogContent>
          <h2 className="h3" style={{ marginBottom: "8px" }}>{title}</h2>
          <p className="p3" style={{ marginBottom: "16px", fontSize: "14px" }}>{description}</p>
          <Boxed textAlign="center" display={"flex"} justifyContent={"flex-end"}>
            <Button type="button" size="medium" variant="text" color="primary" onClick={handleClose}>No</Button>
            <Button type="button" size="medium" variant="text" color="error" onClick={handleConfirm}>Yes</Button>
          </Boxed>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DeleteConfirmBox;