import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Boxed } from "../../../../Styles-Elements/Box";
import BackIcon from "../../../../assets/images/structure/arrow-left-black.svg";
import { Divider, Box, Typography, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Aside from "../../../layout/aside/Aside";
import Header from "../../../layout/header/Header";
import deliverIcon from "../../../../assets/images/structure/deliver-icon.svg";
import DownloadIcon from "../../../../assets/images/structure/download-icon.svg";
import foodImg from "../../../../assets/images/product/product-food2.png";
import services from "../../../../services";
import moment from "moment";
import { getdataByKey, getUomImage } from "../../../helper/custom";
import OrderItem from "./OrderItem";
import constant from "../../../../constant";
import TrackingComponent from  './TrackingComponent';

const RejectedTab = () => {
  const statusRedirect = {
    placed: "orders",
    processing: "processing",
    readytopick: "ready-to-pick",
    shipped: "shipped",
    completed: "completed",
    rejected: "rejected",
    cancelled: "cancelled",
    pending: "pending"
  }
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [productDetails, setProductDetails] = useState([]);
  const [orderTracker, setOrderTracker] = useState([]);
  const [orderDetails, setOrderDetails] = useState();
  const [picker, setPicker] = useState("");
  const [qc, setQc] = useState([]);
  const [lastValue, setLastValue] = useState(null);
  let [addressData, setAddressData] = useState(null);
  let [storeRefundAmount, setStoreRefundAmount] = useState(0);
  let [customerRefundAmount, setCustomerRefundAmount] = useState(0);
  const [vendortiming, setVendortiming] = useState({});
  const [wallet, setWallet] = useState(0);
  let [store, setStore] = useState(null);
  const [track, setTrack] = useState();

  useEffect(() => {
    getOrderDetails(orderId);
  }, []);

  const getOrderDetails = async (orderId) => {
    try {
      const responseOrder = (await services.Orders.GET_ORDER_BY_ID(orderId))
        .data;
      if (responseOrder.orders.status !== 'rejected') {
        navigate(`/order-manage-detail/${statusRedirect[responseOrder.orders.status]}/${orderId}`);
      }
      const map2 = responseOrder?.orderLogs.map(
        (item) => item.updated_at
      );
      const lastValue2 = map2[map2.length - 2];
      const map = responseOrder?.orderLogs.map(
        (item) => item.updated_at
      );
      const lastValue1 = map[map.length - 1];

      setLastValue(lastValue1 - lastValue2);
      setPicker(responseOrder?.pickers);
      setQc(responseOrder?.qcs);
      const response = responseOrder.orders;
      let total_MRP = 0;
      responseOrder?.order_details?.map((item) => {
        total_MRP = total_MRP + item.price * +item.quantity;
      });
      addressData =
        response?.addressJson && response?.addressJson !== ""
          ? JSON.parse(response?.addressJson)
          : null;
      setAddressData(addressData);
      store = response?.store;
      setStore(store);
      const placesData = responseOrder?.orderLogs?.filter((item) => item.status === "placed");
      setOrderDetails({ ...response, MRP: total_MRP, createdAt: placesData[0]?.updated_at });
      setVendortiming(JSON.parse(responseOrder?.ManageWorkingHour?.timejson || '[]'))
      setProductDetails(responseOrder.order_details);
      const orderTracker = responseOrder?.orderLogs;
      order(orderTracker);
      setTrack(orderTracker);
      const cancelTransaction = orderTracker?.find(
        (item) => item?.status === "cancelled"
      );
      storeRefundAmount = cancelTransaction?.store_refund
        ? cancelTransaction.store_refund
        : 0;
      setStoreRefundAmount(storeRefundAmount);
      customerRefundAmount = cancelTransaction?.user_refund
        ? cancelTransaction.user_refund
        : 0;
      setCustomerRefundAmount(customerRefundAmount);
      if (responseOrder.transaction.length > 0) {
        const walletAmount = responseOrder.transaction.find(
          (item) => item.method === "wallet"
        );
        setWallet(walletAmount?.amount);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const order = (_orderTracker) => {
    const orderArr = [];
    const orderTracker = _orderTracker.filter((item) => item.role === undefined).sort(
      (a, b) => a.updated_at - b.updated_at
    );
    const OrderReceived = orderTracker.filter(
      (stat) => stat?.status === "placed"
    ).pop();
    orderArr.push({
      status: "Order Received",
      class: "done",
      updated_at: OrderReceived? moment(new Date(OrderReceived?.updated_at)).format(
        "DD MMMM YYYY - hh:mm A"
      ): "-",
    });
    const rejected = orderTracker.filter((stat) => stat?.status === "rejected").pop();
    orderArr.push({
      status: "Rejected",
      class: "done",
      updated_at: rejected? moment(new Date(rejected?.updated_at)).format(
        "DD MMMM YYYY - hh:mm A"
      ): "-",
    });
    setOrderTracker(orderArr);
  };
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('tabtype');
  const goBack = () => myParam === "false"?
    navigate(`/order-listing?${orderDetails.storeId === 1? 'isbaladi=baladi&':''}status=rejected`):
    navigate(`/order-listing?${orderDetails.storeId === 1? 'isbaladi=baladi&':''}status=rejectedBaladi`);


  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <div className="order-listing-wrapper">
              <div className="">
                <div className="tabs-wrap">
                  <div className="table-type-parent">
                    <Box>
                      <div className="store-detail-head">
                        <img
                          src={BackIcon}
                          alt="back-icon"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={goBack}
                        />
                        {/* <Typography
                          variant="h6"
                          component="h6"
                          mb={0}
                          fontWeight={500}
                        >
                          Rejected Orders
                        </Typography>
                         */}
                      </div>
                    </Box>
                    <div className="table-type-parent">
                    <Box>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={2}
                        fontSize={"14px"}
                        fontWeight={500}
                      >
                         Rejected Orders
                      </Typography>
                      {vendortiming && vendortiming.length > 0 && (
                              <Typography
                                variant="h6"
                                component="h6"
                                mb={2}
                                fontSize={"14px"}
                                fontWeight={500}
                              >
                                <span> Vendor Timing: </span><br />
                                {vendortiming.map((timing, index) => (
                                  <div key={index}>
                                    <span>Opening: {timing.starttime} , </span> 
                                    <span>Closing: {timing.endtime} </span><br />
                                  </div>
                                ))}
                              </Typography>
                            )}
                    </Box>
                    <div className="table-type-box">
                      <div className="table-type-td flex">
                        <img
                          src={deliverIcon}
                          alt="deliver"
                          className="icon24"
                        />
                        <div className="relative">
                          <span className="type-th">Order Type</span>
                          <h3 className="type-td">
                            Deliver {orderDetails?.deliver}
                          </h3>
                        </div>
                      </div>
                      <div className="table-type-td">
                        <span className="type-th">Order ID</span>
                        <h3 className="type-td width120">
                          #{orderDetails?.order_id}
                        </h3>
                      </div>
                      <div className="table-type-td">
                        <span className="type-th">Customer Name</span>
                        <h3 className="type-td width120">
                          {orderDetails?.user?.full_name}
                        </h3>
                      </div>
                      <div className="table-type-td">
                        <span className="type-th">No. of Items</span>
                        <h3 className="type-td">
                          {orderDetails?.total_quantity} Items
                        </h3>
                      </div>
                      <div className="table-type-td">
                        <span className="type-th">Total Amount</span>
                        <h3 className="type-td">
                          QAR {orderDetails?.total_amount.toFixed(2)}
                        </h3>
                      </div>
                      <div className="table-type-td">
                        {/* <h3 className="type-td">
                          <img src={DownloadIcon} className="icon20" alt="" />{" "}
                          Download Invoice
                        </h3> */}
                      </div>
                    </div>
                    <div className="table-type-body">
                      <div className="table-type-body-inner-flex">
                        <div className="left-detail-box">
                          <h6 className="h6">Product Details</h6>
                          <Table className="product-table">
                            <TableBody>
                              {/* <TableRow>
                                <TableCell>
                                  <div className="flex">
                                    <img
                                      src={foodImg}
                                      alt=""
                                      className="img32"
                                    />
                                    <span className="product-title">
                                      Margherita Pizza
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  1 x QAR 20.00{" "}
                                  <span className="gray-through">
                                    QAR 20.00
                                  </span>
                                </TableCell>
                                <TableCell align="right">QAR 20.00</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <div className="flex">
                                    <img
                                      src={foodImg}
                                      alt=""
                                      className="img32"
                                    />
                                    <span className="product-title">
                                      Farmhouse Pizza
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  2 x QAR 20.00{" "}
                                  <span className="gray-through">
                                    QAR 20.00
                                  </span>
                                </TableCell>
                                <TableCell align="right">QAR 40.00</TableCell>
                              </TableRow> */}
                              {productDetails &&
                                productDetails.length > 0 &&
                                productDetails?.map((item, index) => (
                                  <OrderItem key={index} orderItem={item} storeId={store?.id}/>
                                ))}
                            </TableBody>
                          </Table>
                          <Divider style={{ marginTop: "15px" }} />
                          <h6 className="h6 gray" style={{ marginTop: "20px" }}>
                            Order Tracking
                          </h6>
                          <div className="order-tracking-box">
                            {/* <div className="order-status done">
                              <p className="p2">Order Received</p>
                              <p className="p4">17 January 2023 - 11:30 AM</p>
                            </div>
                            <div className="order-status done">
                              <p className="p2">Rejected</p>
                              <p className="p4">17 January 2023 - 11:30 AM</p>
                            </div> */}
                            <TrackingComponent
                            status= {orderDetails?.status}
                            tracking={'baladi'}
                            orderTracker={track}
                          />
                            {/* {orderTracker &&
                              orderTracker.length > 0 &&
                              orderTracker?.map((item, index) => (
                                <div
                                  key={index}
                                  className={`order-status ${
                                    item?.class ? item.class : ""
                                  }`}
                                >
                                  <p className="p2">{item?.status}</p>
                                  <p className="p4">{item?.updated_at}</p>
                                </div>
                              ))} */}
                          </div>
                        </div>
                        <div className="right-detail-box">
                          <h6 className="h6 mb-4">Payment Details</h6>
                          <div className="payment-value">
                            <span className="p2">MRP</span>
                            <span
                              className={`p2-bold ${
                                orderDetails?.MRP !== orderDetails?.sub_total
                                  ? "through-text"
                                  : ""
                              }`}
                            >
                              {orderDetails?.MRP !== orderDetails?.sub_total ? (
                                <strike>
                                  QAR {orderDetails?.MRP?.toFixed(2)}
                                </strike>
                              ) : (
                                <span>QAR {orderDetails?.MRP?.toFixed(2)}</span>
                              )}
                            </span>
                          </div>
                          {orderDetails?.MRP !== orderDetails?.sub_total ? (
                            <div className="payment-value">
                              <span className="p2">Discounted Price</span>
                              <span className="p2-bold">
                                QAR {orderDetails?.sub_total?.toFixed(2)}
                              </span>
                            </div>
                          ) : null}
                          {orderDetails?.delivery_charges > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Delivery Charges</span>
                              <span className="p2-bold">
                                QAR {orderDetails?.delivery_charges?.toFixed(2)}
                              </span>
                            </div>
                          ) : null}
                          {orderDetails?.tax > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Tax</span>
                              <span className="p2-bold">
                                QAR {orderDetails?.tax.toFixed(2)}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {orderDetails?.discount > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Coupon Discount</span>
                              <span className="p2-bold">
                                - QAR {orderDetails?.discount.toFixed(2)}
                              </span>
                            </div>
                          ) : null}
                          {wallet > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Wallet</span>
                              <span className="p2-bold">
                                - QAR {wallet.toFixed(2)}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          <Divider />
                          <div className="payment-value-total">
                            <span className="p2">
                              {wallet > 0 ? "Net Pay" : "Total Amount"}
                            </span>
                            <span className="p2">
                              QAR{" "}
                              {(orderDetails?.total_amount - (wallet || 0)).toFixed(2)}
                            </span>
                          </div>
                          {orderDetails?.offer_amount > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Offer/Discount</span>
                              <span className="p2-bold">
                                - QAR {orderDetails?.offer_amount.toFixed(2)}
                              </span>
                            </div>
                          ) : null}
                          <div className="pink-box">
                            {customerRefundAmount > 0 ? (
                              <div className="payment-value">
                                <span className="p2">Customer Refund</span>
                                <span className="p2-bold">
                                  QAR {Number(customerRefundAmount)?.toFixed(2)}
                                </span>
                              </div>
                            ) : null}
                            {storeRefundAmount > 0 ? (
                              <div className="payment-value">
                                <span className="p2">Store Payout</span>
                                <span className="p2-bold">
                                  QAR {Number(storeRefundAmount)?.toFixed(2)}
                                </span>
                              </div>
                            ) : null}
                          </div>
                          <Divider className="my-5" />
                          {orderDetails?.specialRequest && (
                            <Boxed
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"flex-end"}
                            >
                              <h6 className="h6 mb-4">Special Request: {orderDetails.specialRequest}</h6>
                            </Boxed>
                          )}
                          <Boxed
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"flex-end"}
                          >
                            <h6 className="h6 mb-4">Other Details</h6>
                          </Boxed>
                          <div>
                            <div className="relative mb-4">
                              <p className="p3">Order Date</p>
                              <p className="p2">
                                {moment(orderDetails?.createdAt).format(
                                  "DD MMMM YYYY hh:mm a"
                                )}
                              </p>
                            </div>
                            <div className="relative mb-4">
                              <p className="p3">Payment Method</p>
                              <p className="p2">
                                {orderDetails?.payment_method}
                              </p>
                            </div>

                            <div className="relative">
                              <p className="p3">Customer Details</p>
                              <p className="p2">
                                {addressData?.full_name} |{" "}
                                {`+${orderDetails?.user?.country_code} ${addressData?.contact_number}`}{" "}
                                | {orderDetails?.user?.email}
                              </p>
                              <Button
                                variant="contained"
                                size="small"
                                className="secondary"
                                onClick={() => {
                                  navigate(
                                    `/manage-customer-Details/${orderDetails?.user?.id}`
                                  );
                                }}
                              >
                                {" "}
                                View Customer{" "}
                              </Button>
                            </div>
                            <Divider />
                            <div className="relative mb-4">
                              <p className="p3">Vendor Details</p>
                              <p className="p2">
                                  {store?.stores_locales[0].business_name}
                                  {store?.country_code !== null && store?.contact_number !==null?<>
                                    {" "} | {" "}
                                  {`${store?.country_code !== null?'+'+store?.country_code:''} ${store?.contact_number !==null?store?.contact_number:''}`}{" "}
                                  </>: null}
                                  {" "} | {store?.business_address}
                                </p>
                            </div>
                            <Divider />
                              <div className="relative">
                                <p className="p3">Delivery Partner</p>
                                {store?.market_place === 1?
                                  <p className="p2">MarketPlace</p>:
                                  <p className="p2 capitalize">{store?.deliveryPartner}</p>
                                }
                              </div>
                            {orderDetails?.driverId > 0 ? (
                              <div className="relative">
                                <p className="p3">Driver Details</p>
                                <p className="p2">{`${orderDetails?.driver?.first_name} ${orderDetails?.driver?.last_name}`}</p>
                                <p className="p2">
                                  {orderDetails?.driver?.phone}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <Divider className="my-5" />
                          <div className="relative ">
                            <p className="p3 semi">Rejected Reason</p>
                            {orderDetails?.rejected_by !== 0 ? (
                              <p className="p2">
                                {orderDetails?.rejected_by_user.role === "admin"
                                  ? "Rejected by Admin"
                                  : "Rejected by Vendor"}
                              </p>
                            ) : null}
                            <p className="p2">
                              {orderDetails?.reason_of_reject}
                            </p>
                          </div>

                          {picker?.length > 0 && picker.map((item, index) => {
                            return (
                              <>
                                <Divider className="my-2" />
                                <div className="relative">
                                  <p className="p3">Picker Details</p>
                                  <p className="p2">
                                    Picker Name - {item?.first_name}{" "}
                                    {item?.last_name} <br />
                                    Picker Email - {item?.email}
                                  </p>
                                </div>
                                <Divider />
                              </>
                            );
                          })}
                          {qc?.length > 0 && qc.map((item, index) => {
                            return (
                              <>
                                <Divider className="my-2" />
                                <div className="relative">
                                  <p className="p3">QC Details</p>
                                  <p className="p2">
                                    QC Name - {item?.first_name}{" "}
                                    {item?.last_name} <br />
                                    QC Email - {item?.email}
                                  </p>
                                </div>
                                <Divider />
                              </>
                            );
                          })}
                          <Divider className="my-2" />
                          <p className="p3"> Time</p>
                          <p className="p2">Ideal Time</p>
                          <p className="p2">
                          Actual Time - {(lastValue / 1000 / 60) | 0} Min
                          </p>
                        </div>
                      </div>
                    </div>
                    </div>
                     </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default RejectedTab;
