import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { changeRedirect } from "../../../redux/reducers/manageCustomer";
import { currentPermission } from "../../../redux/reducers/permission";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from 'moment';
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import services from "../../../services";
import { 
  Grid, Button, Typography, TextField, Switch, Alert, Tooltip, Dialog, DialogContent,
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DeleteForever from "@mui/icons-material/DeleteForever";
import modalClose from "../../../assets/images/structure/close-modal.png";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";

export default function ManageCustomerListing() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permission = useSelector(currentPermission);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);
  let [canViewWallet, setCanViewWallet] = useState(true);
  let [canUpdateWallet, setCanUpdateWallet] = useState(true);

  const [checked, setChecked] = useState(false);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [sort_by, set_sort_by] = useState("");
  const [order_by, set_order_by] = useState("desc");
  const [customerList, setCustomerList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [editDialog, setEditDialog] = useState(false);
  const [updatedName, setUpdatedName] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updatedPhone, setUpdatedPhone] = useState("");
  const [phoneErrMsg, setPhoneErrMsg] = useState("");
  const [emailErrMsg, setEmailErrMsg] = useState("");
  const [nameErrMsg, setNameErrMsg] = useState("");
  const [fieldErr, setFieldErr] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [forceErr, setForceErr] = useState(false);
  let [page, setPage] = useState(1);
  let [count, setCount] = useState(0);
  let [valueDatepickerStart,setValueDatepickerStart] = useState(null)
  let [valueDatepickerEnd,setValueDatepickerEnd] = useState(null)

  const routerManageCustomerDetails = (id, redirect) => {
    if (redirect === "wallet") dispatch(changeRedirect("wallet"));
    else dispatch(changeRedirect(null));
    const path = `/manage-customer-Details/` + id;
    navigate(path);
  };

  const changeOrderBy = (order, value) => {
    if (order === "asc") set_order_by("asc");
    else if (order === "desc") set_order_by("desc");
    set_sort_by(value);
  };

  const handlePageChange = (event, value) => {
    page = value;
    setPage(page);
  };

  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  };

  const handleClose = () => setEditDialog(false);

  const getCustomerList = async () => {
    setCustomerList([]);
    count = 0;
    setCount(count);
    let startDate = null;
    let endDate = null;
    if(valueDatepickerStart !== null ){
       let d = new Date(valueDatepickerStart);
       startDate = `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? `0${d.getMonth()+1}` : d.getMonth()+1 }-${d.getDate() < 10 ? `0${d.getDate()}` : d.getDate() }`
    }

    if(valueDatepickerEnd !== null){
      let d = new Date(valueDatepickerEnd);
      endDate = `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? `0${d.getMonth()+1}` : d.getMonth()+1 }-${d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()}`
    }
  
    if (search)
    dispatch(changeLoader(true));
    const response = await services.manageCustomerListing.GET_CUSTOMER_LIST(
      page,
      limit,
      search,
      order_by,
      sort_by,
      startDate,
      endDate
    );

    if (response?.data?.ack === 1) {
      setCustomerList(response?.data?.data?.rows);
      count = Math.ceil(response?.data?.data?.count / limit);
      setCount(count);
      dispatch(changeLoader(false));
    }
    dispatch(changeLoader(false));
  };

  const updateStatus = async (id) => {
    const data = { customer_id: id };
    const response = await services.manageCustomerListing.UPDATE_STATUS(data);
    if (("response", response?.data?.ack === 1)) {
      setSeverity("success");
      setAlertMsg(response?.data?.msg);
      setShowAlert(true);
      disableAlert();
      getCustomerList();
    }
  };

  const setCustomerDetails = (row) => {
    setCustomerId(row?.id);
    setUpdatedName(row?.full_name);
    setUpdatedEmail(row?.email);
    setUpdatedPhone(row?.contact_number);
  };

  const updateCustomer = async () => {
    const data = {
      customer_id: customerId,
      name: updatedName,
      email: updatedEmail,
      phone: updatedPhone,
    };
    const response = await services?.manageCustomerListing?.UPDATE_CUSTOMER(data);
    if (response?.data?.ack == 1) {
      setEditDialog(false);
      getCustomerList();
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: response?.data?.msg,
          state: "success",
        })
      );
    } else {
      setFieldErr(true);
      setForceErr(true);
      setEmailErrMsg(response?.data?.msg);
    }
  };

  const validateCustomer = () => {
    if (!updatedName) {
      setFieldErr(true);
      setNameErrMsg("Name is required");
    }

    // if (!updatedEmail) {
    //   setFieldErr(true);
    //   setEmailErrMsg("Email is required");
    // }
    if (!updatedPhone) {
      setFieldErr(true);
      setPhoneErrMsg("Phone is required");
    }
    if (updatedPhone.length >= 8) {
      setFieldErr(true);
      setPhoneErrMsg("Phone must be 10 digit");
    }
    if (
      updatedName &&
      updatedPhone &&
      updatedPhone.length >= 8
    ) {
      updateCustomer();
    }
  };
  
  useEffect(() => {
    getCustomerList();
  }, [order_by, search, page]);

  useEffect(() => {
    const timer = setTimeout(() => {
      page = 1;
      setPage(page);
      setSearch(searchTerm);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    page = 1;
    setPage(page);
    getCustomerList();
  },[valueDatepickerStart,valueDatepickerEnd])

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanEdit(permission?.customerManagement.edit === 1)
      setCanDelete(permission?.customerManagement.delete === 1)
      setCanUpdateStatus(permission?.customerManagement.actions.status === 1)
      setCanViewWallet(permission?.walletManagement.read === 1)
      setCanUpdateWallet(permission?.walletManagement.actions.edit === 1)
    } else {
      setCanEdit(true)
      setCanDelete(true)
      setCanUpdateStatus(true)
      setCanViewWallet(true)
      setCanUpdateWallet(true)
    }
  }, [permission]);

  return (
    <>
      <Button variant="contained" size="large" color="primary">
        Save
      </Button>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
                Customer Listing
              </Typography>
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <SearchIcon sx={{ marginRight: "3px" }} />
                      ),
                      endAdornment: searchTerm ? (
                        <CloseIcon
                          onClick={() => {
                            setSearchTerm("");
                          }}
                        />
                      ) : null,
                    }}
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                </div>
                <div className="search-box">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="width160"
                      value={valueDatepickerStart}
                      onChange={(newValue) => {
                        valueDatepickerStart = newValue;
                        setValueDatepickerStart(valueDatepickerStart);
                      }}
                      label="Start Date"
                      renderInput={(params) => (
                        <TextField {...params} size="small" placeholder="start date" />
                      )}
                      inputFormat="DD-MM-YYYY"
                    />
                  </LocalizationProvider>
                </div>
                {
                  valueDatepickerStart != null ?
                    <div className="search-box">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="width160"
                          value={valueDatepickerEnd}
                          onChange={(newValue) => {
                            valueDatepickerEnd = newValue;
                            setValueDatepickerEnd(valueDatepickerEnd);
                          }}
                          label="End Date"
                          renderInput={(params) => (
                            <TextField {...params} size="small" placeholder="start date" />
                          )}
                          inputFormat="DD-MM-YYYY"
                        />
                      </LocalizationProvider>
                  </div> 
                : null
                }
               {
                valueDatepickerStart !== null || search ? 
                  <div className="search-box">
                    <Button onClick={()=>{
                      setValueDatepickerStart(null);
                      setValueDatepickerEnd(null);
                      setSearch("");
                      setSearchTerm("");
                      page = 1; setPage(page);
                    }}> Clear all </Button>
                  </div> : null 
               }
              </div>
              
            </div>
            {customerList.length > 0?<TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
                        Customer Name
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("asc", "full_name");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("desc", "full_name");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="short-div">
                        Customer’s Contact Number
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("asc", "contact_number");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("desc", "contact_number");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="short-div">
                        Email ID
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("asc", "email");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("desc", "email");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell> Date </TableCell>

                    { canEdit || canDelete || canViewWallet ?
                      <TableCell className="nowrap" align="center">Action</TableCell>: null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerList?.map((row) => {
                    return (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          onClick={() => {
                            routerManageCustomerDetails(row?.id);
                          }}
                        >
                          {row?.full_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={() => {
                            routerManageCustomerDetails(row?.id);
                          }}
                        >
                          {row?.contact_number}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={() => {
                            routerManageCustomerDetails(row?.id);
                          }}
                        >
                          {row?.email}
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            checked={row?.status == "active" ? true : false}
                            onClick={() => {
                              updateStatus(row?.id);
                            }}
                            disabled={!canUpdateStatus}
                          />
                        </TableCell>
                        <TableCell>
                          {moment(row?.createdAt).format('hh:mm A DD/MM/YYYY')}
                        </TableCell>

                        { canEdit || canDelete || canViewWallet ?
                          <TableCell align="center" className="nowrap">
                          {canViewWallet?<Tooltip
                            title="Wallet"
                            placement="top"
                            sx={{ marginRight: 1 }}
                            onClick={() => {
                              routerManageCustomerDetails(row?.id, "wallet");
                            }}
                          >
                            <AccountBalanceWalletIcon
                              color="action"
                              fontSize="small"
                            />
                          </Tooltip>: null}
                          {canEdit?<Tooltip
                            title="Edit"
                            placement="top"
                            sx={{ marginRight: 1 }}
                            onClick={() => {
                              // routerManageCustomerDetails(row?.id);
                              setCustomerDetails(row);
                              setEditDialog(true);
                            }}
                          >
                            <BorderColorIcon color="action" fontSize="small" />
                          </Tooltip>: null}
                          {/* <Tooltip title="Delete" placement="top">
                            <DeleteForever
                              color="error"
                              fontSize="small"
                              // onClick={() => {
                              //   handleOpen();
                              //   setBannerId(banner.id);
                              // }}
                            /> 
                          </Tooltip> */}
                        </TableCell>: null}

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>:<NoDataFound
              text={"No Customer Found"}
            />}
            {count > 1 ? (
              <Pagination
                count={count}
                color="primary"
                page={page}
                onChange={handlePageChange}
              />
             
            ) : null}
          </Box>
        </Box>
      </Box>
      <Dialog open={editDialog} onClose={() => handleClose()}>
        <img
          src={modalClose}
          alt="..."
          onClick={() => handleClose()}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Edit Customer
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="after-upload">
                  <TextField
                    required
                    id="category_en"
                    label={"Name"}
                    value={updatedName}
                    onChange={(e) => {
                      setUpdatedName(e.target.value);
                    }}
                  />
                  {fieldErr && !updatedName && (
                    <span style={{ color: "red" }}>{nameErrMsg}</span>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="category_en"
                  label={"Email"}
                  value={updatedEmail}
                  onChange={(e) => {
                    setUpdatedEmail(e.target.value);
                  }}
                />
             </Grid>
              <Grid item xs={12}>
                <TextField
                  id="category_en"
                  style={{ marginTop: "5px" }}
                  required
                  label={"Phone"}
                  value={updatedPhone}
                  onChange={(e) => {
                    setUpdatedPhone(e.target.value);
                  }}
                />
                {fieldErr && !updatedPhone && (
                  <span style={{ color: "red" }}>{phoneErrMsg}</span>
                )}
              </Grid>
              <Grid item xs={12} textAlign="center">
                {fieldErr && !updatedEmail && (
                  <span style={{ color: "red" }}>{emailErrMsg}</span>
                )}
                <Button
                  variant="contained" size="large" sx={{ width: 1, marginTop: "16px" }}
                  onClick={() => {validateCustomer();}}
                > Save </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
