import { useState, useEffect } from "react";
import {
	FormControlLabel, Checkbox, TableCell, TableRow
} from "@mui/material";


const ModuleActions = ({moduleActions}) => {

	const modulesActions = {
		"approveReject": "Approve/reject permission",
		"addEditAssociates": "Add/edit associates permission",
		"recommended": "Recommended",
		"status": "Status",
		"addEditAssociates": "Add/edit associates permission",
		"approveReject": "Approve/reject",
		"readyToPick": "Ready to Pick",
		"cancelOrder": "Cancel order",
		"acceptOrRejectOrder": "Accept or reject order",
		"refundOrder": "Refund order",
		"confirmPendingOrder": "Confirm pending order",
		"settlePayout": "Settle payout",
		"exportCSV": "Export CSV",
	}

	let [actions, setActions] = useState([]);

	useEffect(() => {
		actions = []
		for (const [key, value] of Object.entries(moduleActions)) {
			actions.push({key, value});
		}
		setActions(actions);
	}, [moduleActions]);

	return (<>
		{actions.map((action, index) =>  action.value === 1?
		<FormControlLabel
			key={`${action.key}-${index}`}
			control={<Checkbox checked={true} disabled />} label={modulesActions[action.key]} />: null)}
	</>)
};

export default ModuleActions;