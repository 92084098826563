import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useCallback, useLayoutEffect } from "react";
import "./App.scss";
import ScrollToTop from "./Styles-Elements/ScrollToTop/ScrollToTop";
// import pages 
import { Alert, Snackbar } from "@mui/material";
import { Login } from "./pages/auth/Login";
import { ResetPassword } from "./pages/auth/ResetPassword";
import Dashboard from "./pages/Main/Dashboard/Dashboard";
import BaladiLanding from "./pages/Main/ManagementSystem/BaladiLanding";
import Widgets from "./pages/Main/ManagementSystem/Widgets";
import HomePage from "./pages/Main/ManagementSystem/HomePage";
import BusinessType from "./pages/Main/ManagementSystem/BusinessType";
import StoreRequest from "./pages/Main/Store/StoreRequestList";
import StoreRequestDetail from "./pages/Main/Store/StoreRequestDetail";
import StoreListing from "./pages/Main/Store/StoreListing";
import StoreListingDetail from "./pages/Main/Store/StoreListingDetail";
import StoreRanking from "./pages/Main/Store/StoreRanking/StoreRanking";
import AddStore from "./pages/Main/Store/AddStore";
import StoreType from "./pages/Main/CategoryManagement/StoreType";
import BusinessCategory from "./pages/Main/CategoryManagement/BusinessCategory";
import AddCategoryManagement from "./pages/Main/CategoryManagement/AddCategoryManagement";
import StoreTypeUpdate from "./pages/Main/CategoryManagement/UpdateCategoryManagement";
import UnitOfMeasurement from "./pages/Main/UOM/UnitOfMeasurement";
import ProductRequest from "./pages/Main/Product/ProductRequest";
import ProductEditRequest from "./pages/Main/Product/ProductEditRequest";
import ProductRequestDetail from "./pages/Main/Product/ProductRequestDetail";
import ProductEditRequestDetail from "./pages/Main/Product/ProductEditRequestDetail";
import ProductListing from "./pages/Main/Product/ProductListing";
import ProductListDetail from "./pages/Main/Product/ProductListDetail";
import AddProduct from "./pages/Main/Product/AddProduct";
import ProductRanking from "./pages/Main/Product/ProductRanking";
// import OrderListing from "./pages/Main/ManageOrder/OrderListing";
// import OrderManageDetail from "./pages/Main/ManageOrder/OrderManageDetail";
// import CustomNotification from "./pages/Main/CustomNotification/CustomNotification";
import OrderListing from "./pages/Main/ManageOrder/OrderManageListing/OrderListing";
// Order Details page
import OrderManageDetail from "./pages/Main/ManageOrder/OrderManageDetail/OrderManageDetail";
import Orders from "./pages/Main/ManageOrder/OrderManageDetail/Orders";
import Pending from "./pages/Main/ManageOrder/OrderManageDetail/Pending";
import ProcessingTab from "./pages/Main/ManageOrder/OrderManageDetail/ProcessingTab";
import ReadToPickTab from "./pages/Main/ManageOrder/OrderManageDetail/ReadyToPickTab";
import ShippedTab from "./pages/Main/ManageOrder/OrderManageDetail/ShippedTab";
import CompletedTab from "./pages/Main/ManageOrder/OrderManageDetail/CompletedTab";
import RejectedTab from "./pages/Main/ManageOrder/OrderManageDetail/RejectedTab";
import CancelledTab from "./pages/Main/ManageOrder/OrderManageDetail/CancelledTab";
import FaqListing from "./pages/Main/CMS/Faq";
import TermsOfServices from "./pages/Main/CMS/TermsOfServices";
import PrivacyPolicy from "./pages/Main/CMS/PrivacyPolicy";
import Disclaimer from "./pages/Main/CMS/Disclaimer";
import ManageCustomerListing from "./pages/Main/ManageCustomer/ManageCustomerListing";
import ManageCustomerDetail from "./pages/Main/ManageCustomer/ManageCustomerDetail";
import PayoutManagementListing from "./pages/Main/PayoutManagement/PayoutManagementListing";
import PayoutManagementDetails from "./pages/Main/PayoutManagement/PayoutManagementDetail";
import DriverOnboardingListing from "./pages/Main/DriverOnboarding/DriverOnboardingListing";
import DriverOnboardingDetails from "./pages/Main/DriverOnboarding/DriverOnboardingDetails";
import SectionManagementListing from "./pages/Main/SectionManagement/SectionManagementListing";
import SectionManagementDetails from "./pages/Main/SectionManagement/SectionManagementDetail";
import ManageUserListing from "./pages/Main/ManageUser/ManageUserListing";
import ManageUserDetails from "./pages/Main/ManageUser/ManageUserDetail";
import FaqAdd from "./pages/Main/CMS/FaqAdd";
import SystemSettings from "./pages/Main/SystemSettings/SystemSettings";
import SystemSettingDetail from "./pages/Main/SystemSettings/SystemSettingDetail";
import BulkUpdateData from "./pages/Main/BulkUpdate/BulkUpdateData";
import Loaders from "./Loaders";
import { currentLoader } from "./redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import AutoLogout from "./pages/auth/AutoLogout";
import Snackbars from "./Snackbars";
import { currentSnackbar } from "./redux/reducers/snackbar";
import WarrentyPolicy from "./pages/Main/CMS/WarrentyPolicy";
import AboutBaladi from "./pages/Main/CMS/AboutBaladi";
import DeliveryInformation from "./pages/Main/CMS/DeliveryInformation";
import TrafficLightSystem from "./pages/Main/CMS/TrafficLightSystem";
import { changeSnackbar } from "./redux/reducers/snackbar";
import jwt_decode from "jwt-decode";
import services from "./services";
import ManageFinancialUserListing from "./pages/Main/ManageFinancialUser/ManageFinancialUserListing";
import ManageFinancialUserDetails from "./pages/Main/ManageFinancialUser/ManageFinancialUserDetails";
import ZoneList from "./pages/map/ZoneList";
import AddZone from "./pages/map/AddZone";
import AssociateZone from "./pages/Main/Store/AssociateZone";
import CouponListing from "./pages/Main/Coupon/CouponListing";
import CouponDetail from "./pages/Main/Coupon/CouponDetail";
import UserManagement from "./pages/Main/UserAndRoles/UserManagement";
import AddUser from "./pages/Main/UserAndRoles/AddUser";
import ManageRole from "./pages/Main/UserAndRoles/ManageRole";
import RoleAndPermission from "./pages/Main/UserAndRoles/RoleAndPermission";
import ProtectedRouter from "./ProtectedRouter";
import ManageStoreSelectCategories from "./pages/Main/Store/ManageStoreSelectCategories";
import AssociateCuisine from "./pages/Main/Store/AssociateCuisine";
import CuisineListing from "./pages/Main/Cuisine/CuisineListing";
import AddCuisine from "./pages/Main/Cuisine/AddCuisine";
import CuisineCategory from "./pages/Main/Cuisine/CuisineCategory";
import CuisineCategoryListing from "./pages/Main/Cuisine/CuisineCategoryListing";
import CancellationReason from "./pages/Main/CMS/CancellationReason";
import CancellationDeparment from "./pages/Main/CMS/CancellationDeparment";

import Forbidden from "./global-modules/Forbidden";
import PageNotFound from "./global-modules/PageNotFound";
import SettingList from "./pages/Main/AdminSettings/SettingList";
import PickerTrackingSystem from "./pages/Main/PickerTrackingSystem/PickerTrackingSystem";
import PickerListingPage from "./pages/Main/PickerTrackingSystem/PickerListingPage";
import PickupTrafficLightSystem from "./pages/Main/PickerTrackingSystem/PickupTrafficLightSystem";
import MyOffer from "./pages/Main/MyOffer/OfferListing";
import AddOffer from "./pages/Main/MyOffer/AddOffer";
import StockLedger from "./pages/Main/ManageOrder/StockLedger"
import { currentPermission } from "./redux/reducers/permission";
import socket from "./socket";
import {
  currentSocket,
  changeSocket,
  changeSocketObject,
  changePickerSocket,
  currentPickerSocket
} from "./redux/reducers/socket";

import GlobalService from "./services/GlobalService";
import { API } from "./pages/ENUM";
import { changeBaladiInfo } from "./redux/reducers/baladiInfo";

const App = () => {

  const loader = useSelector(currentLoader);
  const dispatch = useDispatch();
  const snackbarObj = useSelector(currentSnackbar);
  const permission = useSelector(currentPermission);
  const currentSocketObj = useSelector(currentSocket);
  const currentPickerSocketObj = useSelector(currentPickerSocket);

  const timeDifference = (date1, date2) => {
    return Math.floor((date1.getTime() - date2.getTime()) / 1000);
  };

  const refreshAuthToken = useCallback(async () => {
    const token = localStorage.getItem("access_token");
    if (token) {
      const decoded = jwt_decode(token);
      const timeLeft = timeDifference(new Date(decoded.exp * 1000), new Date());
      if (timeLeft < 20) {
        const response = await services.auth.REFRESH_TOKEN();
        if (response?.data?.ack === 1) {
          localStorage.removeItem("access_token");
          localStorage.setItem("access_token", response?.data?.data);
        }
      }
    }
  });

  const getBaladiInfo = async () => {
    const response = await GlobalService(API.GET_BALADI_INFO);
    if (response?.ack === 1) {
      dispatch(changeBaladiInfo({
        BALADI_BRAND_Id: response?.BALADI_BRAND_Id,
        BALADI_STORE_Id: response?.BALADI_STORE_Id,
        BALADI_STORE_SLUG: response?.BALADI_STORE_SLUG
      }));
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      refreshAuthToken();
      setInterval(() => { refreshAuthToken(); }, 5000);
    }
  }, []);

  const setResetSocket = useCallback((responseData, delay = 1000) => {
    if (!currentSocketObj) {
      dispatch(changeSocket(true));
      dispatch(changeSocketObject(responseData));
      setTimeout(() => {
        dispatch(changeSocket(false));
        dispatch(changeSocketObject({}));
      }, delay);
    }
  }, []);

  const setResetPickerSocket = useCallback((responseData, delay = 3000) => {
    if (!currentPickerSocketObj) {
      dispatch(changePickerSocket(true));
      dispatch(changeSocketObject(responseData));
      setTimeout(() => {
        dispatch(changePickerSocket(false));
        dispatch(changeSocketObject({}));
      }, delay);
    }
  });

  useEffect(() => {
    try {
      socket.on("newOrderPlaced", (response) => {
        // console.log("newOrderPlaced", response);
        if (response?.status === "placed") {
          setResetSocket(response);
        }
        if (response?.pickerId !== null) {
          setResetPickerSocket(response, 30000);
        }
        if (response?.qcId !== null) {
          setResetSocket(response);
          setResetPickerSocket(response, 30000);
        }
        
      });
      socket.on("orderStatusChanged", (response) => {
        // console.log("orderStatusChanged", response);
        setResetSocket(response);
      });
      socket.on("OrderRemoved", (response) => {
        // console.log("OrderRemoved");
        setResetPickerSocket(response, 30000);
        setResetSocket(response);
      });
      socket.on("OrderRemovedAdmin", (response) => {
        // console.log("OrderRemovedAdmin");
        setResetPickerSocket(response, 30000);
      });
    } catch (error) {
      console.error("error in socket at my orders", error);
    }
  }, [socket]);

  useLayoutEffect(() => {
    getBaladiInfo();
  }, []);

  return (
    <>
      <Router>
        <AutoLogout>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/" element={<ProtectedRouter/>}> 
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/dashboard" element={<Dashboard />} />
              
              <Route path="/widget-page/:type" element={<Widgets />} />
              <Route path="/baladi-landing" element={<BaladiLanding />} />
              <Route path="/home-page" element={<HomePage />} />

              <Route path="/business-type"  element={<BusinessType />} />
              {(permission?.storeRequest?.read === 1 || permission?.storeListing?.read === 1 || permission?.role === "admin") ? <>
                {(permission?.storeRequest?.read === 1 || permission?.role === "admin")? <>
                  <Route path="/store-request" element={<StoreRequest />} />
                  <Route path="/store-request-detail/:id" element={<StoreRequestDetail />}/>
                </>: null}
                {(permission?.storeRequest?.read === 1 || permission?.storeListing?.read === 1 || permission?.role === "admin")?
                  <Route path="/store-listing-detail/:id/:type/:page" element={<StoreListingDetail />}/> : null}
                {(permission?.storeListing?.read === 1 || permission?.role === "admin") ?<>
                  <Route path="/store-listing" element={<StoreListing />} />
                  <Route path="/associate-categories/:id" element={<ManageStoreSelectCategories />} />
                  <Route path="/associate-cuisine/:id" element={<AssociateCuisine />} />
                  <Route path="/store-ranking" element={<StoreRanking />} />
                </>: null}
                <Route path="/add-store" element={<AddStore />} />
              </>: null}
              {( permission?.storeType?.read === 1 ||
                permission?.businessCategory?.read === 1 ||
                permission?.uom?.read === 1 ||
                permission?.role === "admin"
              )?<>
                {(permission?.storeType?.read === 1 || permission?.role === "admin")?<>
                  <Route path="/business-type-listing" element={<StoreType />} />
                  <Route path="/update-business-type/:id" element={<StoreTypeUpdate />}/>
                  <Route path="/add-business-category" element={<AddCategoryManagement />} />
                </>: null}
                {permission?.businessCategory?.read === 1 || permission?.role === "admin"?
                  <Route path="/business-category" element={<BusinessCategory />} /> : null}
                { permission?.uom?.read === 1 || permission?.role === "admin"?
                  <Route path="/unit-of-measurement" element={<UnitOfMeasurement />}/>: null}
              </>: null}
              {(permission?.productRequest?.read === 1 || permission?.addProduct?.read === 1 || permission?.role === "admin")?<>
                {(permission?.productRequest?.read === 1 || permission?.role === "admin")?<>
                  <Route path="/product-request" element={<ProductRequest />} />
                  <Route path="/edit-product-request" element={<ProductEditRequest />} />
                  <Route path="/product-request-detail/:product_id" element={<ProductRequestDetail />}/>
                  <Route path="/product-edit-request-detail/:id/:request_id" element={<ProductEditRequestDetail />}/>
                </>: null}
                {(permission?.addProduct?.read === 1 || permission?.role === "admin")?<>
                  <Route path="/product-listing" element={<ProductListing />} />
                  <Route path="/product-list-detail/:product_id" element={<AddProduct />} order-listing />
                  <Route path="/add-product" element={<AddProduct />} />
                  <Route path="/product-ranking" element={<ProductRanking />} />
                </>: null}
              </>: null}
              {(permission?.orderListing?.read === 1 || permission?.role === "admin") ? <>
                <Route path="/order-listing" element={<OrderListing />} />
                <Route path="order-manage-detail" element={<OrderManageDetail />} />
                <Route path="/order-manage-detail/orders/:orderId" element={<Orders />} />
                <Route path="/order-manage-detail/pending/:orderId" element={<Pending />} />
                <Route path="/order-manage-detail/processing/:orderId" element={<ProcessingTab />} />
                <Route path="/order-manage-detail/ready-to-pick/:orderId" element={<ReadToPickTab />} />
                <Route path="/order-manage-detail/shipped/:orderId" element={<ShippedTab />} />
                <Route path="/order-manage-detail/completed/:orderId" element={<CompletedTab />} />
                <Route path="/order-manage-detail/rejected/:orderId" element={<RejectedTab />} />
                <Route path="/order-manage-detail/cancelled/:orderId" element={<CancelledTab />} />
                <Route path="/stock-ledger/:productId" element={<StockLedger />} />
              </>: null}
              {(permission?.cms?.read === 1 || permission?.role === "admin") ? <>
                <Route path="/faq" element={<FaqListing />} />
                <Route path="/faq-edit" element={<FaqAdd />} />
                <Route path="/faq-add" element={<FaqAdd />} />
                <Route path="/terms-services" element={<TermsOfServices />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="warrenty-policy" element={<WarrentyPolicy />} />
                <Route path="delivery-information" element={<DeliveryInformation />} />
                <Route path="about-baladi" element={<AboutBaladi />} />
                <Route path="cancellation-reason" element={<CancellationReason />} />
                <Route path="cancellation-department" element={<CancellationDeparment />} />
                <Route path="traffic-light-system" element={<TrafficLightSystem />} />
              </>: null}
              {(permission?.customerManagement?.read === 1 || permission?.role === "admin") ?<>
                <Route path="/manage-customer-listing" element={<ManageCustomerListing />} />
                <Route path="/manage-customer-Details" element={<ManageCustomerDetail />} />
                <Route path="/manage-customer-Details/:id" element={<ManageCustomerDetail />} />
              </>: null}
              {(permission?.payoutManagement?.read === 1 || permission?.role === "admin") ?<>
                <Route path="/payout-management-listing" element={<PayoutManagementListing />} />
                <Route path="/payout-management-Details/:id" element={<PayoutManagementDetails />} />
              </>: null}
              {(permission?.driverOnboarding?.read === 1 || permission?.role === "admin") ?<>
                <Route path="/driver-onboarding-listing" element={<DriverOnboardingListing />} />
                <Route path="/driver-onboarding-Details/:id?" element={<DriverOnboardingDetails />} />
              </>: null}
              {(permission?.sectionManagement?.read === 1 || permission?.role === "admin") ?<>
                <Route path="/section-management-listing" element={<SectionManagementListing />} />
                <Route path="/section-management-Details/:id" element={<SectionManagementDetails />} />
              </>: null}
              {(permission?.manageUsers?.read === 1 || permission?.role === "admin") ?<>
                <Route path="/manage-user-listing" element={<ManageUserListing />} />
                <Route path="/manage-user-Details" element={<ManageUserDetails />} />
                <Route path="/manage-financial-user" element={<ManageFinancialUserListing />} />
                <Route path="/manage-financial-user-details" element={<ManageFinancialUserDetails />}/>
              </>: null}
              <Route path="/bulk-upload" element={<BulkUpdateData />} />
              {(permission?.banner?.read === 1 || permission?.role === "admin") ?<>
                <Route path="/banner-detail" element={<SystemSettingDetail />} />
                <Route path="/banner-management" element={<SystemSettings />} />
              </>: null}
              {(permission?.zone?.read === 1 || permission?.role === "admin") ?<>
                <Route path="/zone-list" element={<ZoneList />} />
                <Route path="/add-zone/:id?" element={<AddZone />} />
                <Route path="/associate-zone/:storeId" element={<AssociateZone />} />
              </>: null}
              {(permission?.cuisine?.read === 1 || permission?.role === "admin") ?<>
                <Route path="/cuisine-category-listing" element={<CuisineCategoryListing />} />
                <Route path="/add-cuisine-category/:data?" element={<CuisineCategory />} />
                <Route path="/cuisine-listing/:category_id" element={<CuisineListing />} />
                <Route path="/add-cuisine" element={<AddCuisine />} />
                <Route path="/add-cuisine/:data?" element={<AddCuisine />} />
              </>: null}
              {(permission?.coupon?.read === 1 || permission?.role === "admin") ?<>
                <Route path="/coupon-listing" element={<CouponListing />} />
                <Route path="/add-coupon" element={<CouponDetail />} />
                <Route path="/edit-coupon/:id" element={<CouponDetail />} />
              </>: null}
              {(permission?.rolesPermissions?.read === 1 || permission?.manageUsers?.read === 1 || permission?.role === "admin") ?<>
                {(permission?.manageUsers?.read === 1 || permission?.role === "admin")?<>
                  <Route path="/user-management" element={<UserManagement />} />
                  <Route path="/add-user" element={<AddUser />} />
                  <Route path="/edit-user/:id" element={<AddUser />} />
                </>: null}
                {(permission?.rolesPermissions?.read === 1 || permission?.role === "admin")?<>
                  <Route path="/manage-role" element={<ManageRole />} />
                  <Route path="/roles-permissions" element={<RoleAndPermission />} />
                  <Route path="/roles-permissions/:id" element={<RoleAndPermission />} />
                </>: null}
              </>: null}
              {(permission?.adminSetting?.read === 1 || permission?.role === "admin")?
                <Route path="/admin-setting" element={<SettingList />} />: null}

                
              <Route path="/picker-tracking-system" element={<PickerTrackingSystem />} />
              <Route path="/picker-listing-page" element={<PickerListingPage />} />
              <Route path="/pickup-traffic-light-system" element={<PickupTrafficLightSystem />} />

              <Route path="/offer-listing" element={<MyOffer />} />
              <Route path="/add-offer" element={<AddOffer />} />
              <Route path="/edit-offer/:id" element={<AddOffer />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/403-forbidden" element={<Forbidden />} />
          </Routes>
        </AutoLogout>
      </Router>
      {loader ? <Loaders /> : null}

      <Snackbar
        open={snackbarObj?.isOpen}
        onClose={() => { dispatch(changeSnackbar({ isOpen: false, message: "", state: "error", })); }}
        autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={'Fade'}
      > <Alert
        onClose={() => { dispatch(changeSnackbar({ isOpen: false, message: "", state: "error", })); }}
        severity={snackbarObj?.state} sx={{ width: "100%" }}
      > {snackbarObj?.message} </Alert> </Snackbar>

      
    </>
  );
};

export default App;
