import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import modalClose from "../assets/images/structure/close-black.svg";
// import defaultImg from "../assets/images/product/store-logo1.svg";
import { Boxed } from "../Styles-Elements/Box";
import { Button, Dialog, DialogContent, Switch } from "@mui/material";
import { changeSnackbar } from "../redux/reducers/snackbar";

import GlobalService from "../services/GlobalService";
import { API } from "../pages/ENUM";


const ActiveInactiveStoreUom = ({
  handleClose,
  storeForActiveInactive,
  selectedUom,
  getProduct,
  storeId
}) => {

  const dispatch = useDispatch();
  let [inactiveStores, setInactiveStores] = useState([]);
  let [selectedStores, setSelectedStores] = useState([]);

  const changeStatus = async () => {
    if (selectedStores.length === 0) {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: "Please select atleast one store"
      }));
      return;
    }
    const data = {
      status: "active",
      uomId: selectedUom.id.toString(),
      storeId: selectedStores.join(","),
      brand_id: storeId.toString()
    };
    const response = await GlobalService(API.UPDATE_UOM_STORE_STATUS, data, "post");
    getProduct();
    if (response.ack === 1) {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "success",
        message: response.msg
      }));
      handleClose();
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: response.msg !== '' ? response.msg : 'Something went wrong'
      }));
    }
  };

  const getAllStores = async () => {
    const response = await GlobalService(API.GET_ALL_STORES_BU_BRAND(storeId))
    const inactiveStore = storeForActiveInactive.map((store) => store.storeId);
    inactiveStores = response.stores.filter((store) => inactiveStore.includes(store.id));
    setInactiveStores(inactiveStores);
  };
  
  useEffect(() => {
    selectedStores = [];
    setSelectedStores(selectedStores);
    getAllStores();
  }, [storeForActiveInactive]);

  return (
    <Dialog open={true} onClose={handleClose} className="main-modal maxwidth-430" >
      <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h4 className="h4" style={{ marginBottom: "10px",marginTop: '0px', textTransform: 'capitalize' }}>
        Inactive Store ({storeForActiveInactive.length})
        </h4>
        {/* <p className="p3" style={{ marginBottom: "16px" }}>
            All the 10 stores are listed here, you can make store Active from here directly.
        </p> */}
        <div className="store-switch-box">

          {inactiveStores.length > 0 && inactiveStores.map((store) => (
          <div className="store-switch-flex" >
            <Boxed style={{ display: "flex", alignItems: 'start'}}>
              {/* <img src={defaultImg} alt="store-logo" className="store-logo" /> */}
              <div className="relative">
                <h6 className="h6 mt-0">{store.stores_locales[0].business_name}</h6>
                <p className="p3 m-0">{store.business_address}</p>
              </div>
            </Boxed>
            <Switch
              checked={selectedStores.includes(store.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  selectedStores = [...selectedStores, store.id];
                  setSelectedStores(selectedStores);
                } else {
                  selectedStores = selectedStores.filter((id) => id !== store.id);
                  setSelectedStores(selectedStores);
                }
              }}
            />
          </div>))}
        </div>
        <Boxed>
          <Button
            type="button" variant="contained" sx={{ width: "100%", marginTop: "20px" }}
            onClick={changeStatus}
          >Save</Button>
        </Boxed>
      </DialogContent>
    </Dialog>
  );
};

export default ActiveInactiveStoreUom;
