import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button, Typography, TextField, Grid, Divider, Alert, Dialog, DialogContent, OutlinedInput,
  Box, IconButton, Select, InputLabel, MenuItem, FormControl, InputAdornment
} from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import modalClose from "../../../assets/images/structure/close-modal.png";
import services from "../../../services";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { currentUserId } from "../../../redux/reducers/manageUser";
import { currentPermission } from "../../../redux/reducers/permission";

export default function ManageFinancialUserDetails() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permission = useSelector(currentPermission);
  let [canEdit, setCanEdit] = useState(true);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("cashier");
  const [conformpasswords, setConformPasswords] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [conformpasswordsError, setConformPasswordsError] = useState(false);
  const [passwordErrText, setPasswordErrText] = useState("");
  const [conformpasswordsErrText, setConformPasswordsErrText] = useState("");
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [firstnameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [emailErrText, setEmailErrText] = useState("");
  const [BusinessCategory, setBusinessCategory] = useState("");
  const [passwordPopUp, setPasswordPopUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  let userId = useSelector(currentUserId);

  const routerStoreListing = () => navigate(`/manage-financial-user`);
  
  const handleBusinessCategory = (event) => {
    setBusinessCategory(event.target.value);
    setRole(event.target.value);
  };

  const handleClose = () => {
    setPasswordPopUp(false);
    setConformPasswordsError(false);
    setPasswordError(false);
    setPasswordErrText("");
    setConformPasswordsErrText("");
  };
  
  const openPopUp = () => setPasswordPopUp(true);

  const OpenAlertDelete = () => setOpenAlertDelete(true)

  const handleClose2 = () => setOpenAlertDelete(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  
  const handleMouseDownPassword = (event) => { event.preventDefault(); };

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => { event.preventDefault(); };

  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
      navigate(`/manage-financial-user`);
    }, 500);
  };

  const SetAlert = (type, msg) => {
    setSeverity(type);
    setAlertMsg(msg);
    setShowAlert(true);
    disableAlert();
  };

  const getUserData = async () => {
    dispatch(changeLoader(true));
    const response = await services?.manageUser?.GET_USER_DISCRIPTIONS(userId);
    if (response?.data?.ack == 1) {
      setFirstName(response?.data?.picker?.first_name);
      setLastName(response?.data?.picker?.last_name);
      setEmail(response?.data?.picker?.email);
      setRole(response?.data?.picker?.role);
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (userId) {
      getUserData();
    }
  }, []);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const editUser = async (password = null) => {
    if (!first_name) setFirstNameErr(true);
    if (!last_name) setLastNameErr(true);
    if (!email) {
      setEmailErr(true);
      setEmailErrText("please enter email");
    }
    if (!validateEmail(email) && email) {
      setEmailErr(true);
      setEmailErrText("Please enter valid email");
      return;
    }

    if (first_name && last_name && email) {
      dispatch(changeLoader(true));
      const data = { first_name, last_name, email, role };
      if (password) data.password = password;
      const response = await services?.manageUser?.EDIT_USER(userId, data);
      if (response?.data?.ack == 1) {
        SetAlert("success", response?.data?.msg);
      } else {
        let err = response?.data?.errMsg;
        if (err && Array.isArray(err)) {
          err.forEach((error) => {
            Object.entries(error).forEach(([key, value]) => {
              if (key == "email") {
                setEmailErr(true);
                setEmailErrText(value);
              }
            });
          });
        }
      }
      dispatch(changeLoader(false));
    }
  };

  const validatePassword = (password, conformPassword) => {
    if (!password) {
      setPasswordError(true);
      setPasswordErrText("password is required");
    }
    if (!conformPassword) {
      setConformPasswordsError(true);
      setConformPasswordsErrText("Confirm password is required");
    }
    if (password !== conformPassword && password && conformpasswords) {
      setPasswordError(true);
      setConformPasswordsError(true);
      setPasswordErrText("Password and Confirm Password must be same");
      setConformPasswordsErrText("Password and Confirm Password must be same");
    }

    if (password.length < 8 && password && conformpasswords) {
      setPasswordError(true);
      setConformPasswordsError(true);
      setPasswordErrText("Password must be 8 characters long");
      setConformPasswordsErrText("Password must be 8 characters long");
    }

    if (password === conformPassword && password.length >= 8) {
      if (userId) editUser(password);
      else return true;
    }
  };

  const addUser = async () => {
    if (!first_name) {
      setFirstNameErr(true);
    }
    if (!last_name) {
      setLastNameErr(true);
    }
    if (!email) {
      setEmailErr(true);
      setEmailErrText("Please enter email");
    }
    if (!validateEmail(email) && email) {
      setEmailErr(true);
      setEmailErrText("Please enter valid email");
      return;
    }
    if (validatePassword(password, conformpasswords)) {
      if (first_name && last_name) {
        dispatch(changeLoader(true));
        const data = { first_name, last_name, email, password, role };
        const response = await services?.manageUser?.ADD_USER(data);
        if (response?.data?.ack == 1) {
          SetAlert("success", response?.data?.msg);
        } else {
          let err = response?.data?.errMsg;
          if (err && Array.isArray(err)) {
            err.forEach((error) => {
              Object.entries(error).forEach(([key, value]) => {
                if (key == "email") {
                  setEmailErr(true);
                  setEmailErrText(value);
                }
              });
            });
          }
        }
        dispatch(changeLoader(false));
      }
    }
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      if (userId) {
        setCanEdit(permission?.manageUsers.edit === 1)
      } else {
        setCanEdit(permission?.manageUsers.create === 1)
      }
    } else {
      setCanEdit(true)
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  {userId ? "Edit" : "Add New"} User Details
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="First Name"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setFirstNameErr(false);
                      }}
                      value={first_name}
                      disabled={!canEdit}
                    />
                    {firstnameErr && (
                      <span style={{ color: "#ed4731", marginLeft: "5px" }}>
                        Please enter first name
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Last Name"
                      defaultValue=""
                      disabled={!canEdit}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setLastNameErr(false);
                      }}
                      value={last_name}
                    />
                    {lastNameErr && (
                      <span style={{ color: "#ed4731", marginLeft: "5px" }}>
                        Please enter last name
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Email"
                      defaultValue=""
                      disabled={!canEdit}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailErr(false);
                      }}
                      value={email}
                    />
                    {emailErr && (
                      <span style={{ color: "#ed4731", marginLeft: "5px" }}>
                        {emailErrText}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Role*
                      </InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={role}
                        label="Role"
                        onChange={handleBusinessCategory}
                        disabled={!canEdit}
                      >
                        <MenuItem value={"cashier"}>Cashier</MenuItem>
                        <MenuItem value={"cashier_manager"}>Cashier Manager</MenuItem>
                        <MenuItem value={"finance_manager"}>Finance Manager</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {!userId && (
                    <>
                      <Grid item xs={6}>
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="outlined-adornment-password"
                            required
                          >Password</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "Password"}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setPasswordError(false);
                            }}
                            disabled={!canEdit}
                            value={password}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >{!showPassword ? <VisibilityOff />:<Visibility />}</IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                        </FormControl>
                        {passwordError && (
                          <span style={{ color: "#ed4731", marginLeft: "5px" }}>
                            {passwordErrText}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="outlined-adornment-password"
                            required
                          >Confirm Password</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={showConfirmPassword ? "text" : "Password"}
                            onChange={(e) => {
                              setConformPasswords(e.target.value);
                              setConformPasswordsError(false);
                            }}
                            disabled={!canEdit}
                            value={conformpasswords}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownConfirmPassword}
                                  edge="end"
                                >{!showConfirmPassword ? <VisibilityOff />: <Visibility />}</IconButton>
                              </InputAdornment>
                            }
                            label="Confirm Password"
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Divider />
                <div className="bottom-btn-row">
                  <Button
                    variant="standard" size="large" sx={{ marginRight: "10px" }}
                    onClick={routerStoreListing}
                  >Back </Button>
                  {userId && canEdit?<Button
                    variant="contained" size="large" color="primary" onClick={openPopUp}
                    className="change-password-btn" sx={{ marginLeft: "200px" }}
                  > Change Password </Button>: null}
                  {canEdit?<Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => { if (userId) editUser(password); else addUser(); }}
                  > Save </Button>: null}
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={openAlertDelete}
        onClose={handleClose2}
        className="alert-delete"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose2}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Alert
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  Are you sure you want to delete this
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={passwordPopUp}
        onClose={handleClose}
        className="maxwidth-430"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose}
          className="modal-close"
        />
        <DialogContent>
          <Grid item xs={2}>
            <Typography variant="h6" component="h6" mb={3}>
              Change Password
            </Typography>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "Password"}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError(false);
                }}
                value={password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
              {passwordError && (
                <span style={{ color: "#ed4731", marginLeft: "5px" }}>
                  {passwordErrText}
                </span>
              )}
            </FormControl>
            <form style={{ marginTop: "1.5rem" }}>
              <FormControl variant="outlined" mt={5}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showConfirmPassword ? "text" : "Password"}
                  onChange={(e) => {
                    setConformPasswords(e.target.value);
                    setConformPasswordsError(false);
                  }}
                  value={conformpasswords}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {!showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                />
              </FormControl>
              {conformpasswordsError && (
                <span style={{ color: "#ed4731", marginLeft: "5px" }}>
                  {conformpasswordsErrText}
                </span>
              )}
            </form>
            <Button
              style={{ width: "100%", marginTop: "1.5rem" }}
              variant="contained"
              size="large"
              color="primary"
              onClick={() => {
                validatePassword(password, conformpasswords);
              }}
            >Save</Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
