import Box from "@mui/material/Box";
import {Typography} from "@mui/material";

const DetailBox = ({title, value, changeValue, type}) => {

    return (
        <Box>
          <Typography
            variant="h6"
            color="#999999"
            component="h6"
            fontSize={"12px"}
            fontWeight={500}
          >{title}</Typography>
        {type === 'text'?<>
          <Typography
            color={changeValue && changeValue !== ''? "#999999": "#000000"}
            style={changeValue && changeValue !== ''? {textDecoration: 'line-through'}: null}
            variant="h6"
            component="h6"
            fontSize={"14px"}
            fontWeight={400}
            className="word-nowrap"
          >{value}</Typography>
          {changeValue && changeValue !== ''?<Typography
            variant="h6"
            component="h6"
            fontSize={"14px"}
            fontWeight={400}
            className="word-nowrap"
          >{changeValue}</Typography>: null}</>:null}
          {type === 'html'? <>
            <div dangerouslySetInnerHTML={{__html: value}}></div>
            {changeValue && changeValue !== ''?
            <div dangerouslySetInnerHTML={{__html: changeValue}}></div>: null}
          </> :null}
        </Box>
    );
};

export default DetailBox;