import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import Pagination from "@mui/material/Pagination";
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Button, Typography, Tabs, Tab, Switch } from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { Boxed } from "../../../Styles-Elements/Box";
import services from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeFaq, changeRole, changeId } from "../../../redux/reducers/faq";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { Alert } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import modalClose from "../../../assets/images/structure/close-modal.png";
import { Grid } from "@mui/material";
import { currentPermission } from "../../../redux/reducers/permission";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Boxed>{children}</Boxed>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function createData(SNo, Questions, Answer, Status, Action) {
  return { SNo, Questions, Answer, Status, Action };
}

export default function FaqListing() {

  const permission = useSelector(currentPermission);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canAdd, setCanAdd] = useState(true);

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [role, setRole] = useState("vendor");
  const [faqData, setFaqData] = useState([]);
  const [limit, setLimit] = useState(5);
  let [page, setPage] = useState(1);
  let [count, setCount] = useState(0);
  const [openid, setopen] = useState("");
  const [is_admin, setis_admin] = useState(1);
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [openAlertDelete, setOpenAlertDelete] = React.useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const openDelete = () => {
    setOpenAlertDelete(true);
  };
  const handleClick = (event) => {
    setopen(event);
  };
  const handleClose = () => {
    setopen("");
    setOpenAlertDelete(false);
  };
  const [value, setValue] = React.useState(0);
  const handleChange1 = (event, newValue) => {
    page = newValue;
    setValue(page);
  };
  const navigate = useNavigate();

  const routerAddFaq = () => {
    const path = `/faq-add`;
    navigate(path);
  };

  const handlePageChange = (event, value) => {
    page = value;
    setPage(page);
  };

  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  };

  const routerEditFaq = (method, id) => {
    if (method == "edit") {
      dispatch(changeFaq("edit"));
      dispatch(changeId(id));
      dispatch(changeRole(role));
      const path = `/faq-edit`;
      navigate(path);
    } else if (method == "add") {
      dispatch(changeFaq("add"));
      dispatch(changeId(null));
      dispatch(changeRole(role));
      const path = `/faq-add`;
      navigate(path);
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const getFaq = async () => {
    dispatch(changeLoader(true));
    const data = { role, is_admin: 1 };
    const response = await services?.faq?.GET_FAQ(role, is_admin, limit, page);
    if (response?.data.ack == 1) {
      setFaqData(response?.data?.cms?.rows);
      count = Math.ceil(response?.data?.cms?.count / limit);
      setCount(count);
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    getFaq();
  }, [role, page]);

  const updateStatus = async (row) => {
    try {
      let status;
      if (row?.status == "active") status = "inactive";
      else status = "active";
      let data = { status };
      dispatch(changeLoader(true));
      const response = await services.faq.UPDATE_FAQ_STATUS(row?.id, data);
      if (response?.data.ack == 1) {
        getFaq();
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: response?.data?.msg,
            state: "success",
          })
        );
      }
      dispatch(changeLoader(false));
    } catch (e) {
      dispatch(changeLoader(false));
    }
  };

  const getEngLang = (locales) => {
    let answer = "";
    locales?.forEach((item) => {
      if (item?.locale == "en") answer = item?.answer;
    });
    if (answer.length > 40) return answer.slice(0, 40) + "...";
    return answer.slice(0, 40);
  };

  const deleteFaq = async (id) => {
    const response = await services?.faq?.DELETE_FAQ(id);
    if (response?.data.ack == 1) {
      getFaq();
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: response?.data?.msg,
          state: "success",
        })
      );
      handleClose();
    }
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanEdit(permission?.cms.edit === 1)
      setCanAdd(permission?.cms.create === 1)
      setCanDelete(permission?.cms.delete === 1)
    } else {
      setCanEdit(true)
      setCanAdd(true)
      setCanDelete(true)
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} mt={1} fontWeight={500}>FAQ</Typography>
              <div className="right-filter">
                {canAdd?<Button
                  onClick={() => { routerEditFaq("add"); }}
                  variant="contained"
                  size="large"
                >
                  Add Faq For {role == "vendor" ? "Vendor" : "Customer"}
                </Button>: null}
              </div>
            </div>
            <Tabs
              value={value}
              onChange={handleChange1}
              aria-label="basic tabs example"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tab
                label="Vendor"
                {...a11yProps(0)}
                onClick={() => {
                  setRole("vendor");
                  dispatch(changeRole("vendor"));
                }}
              />
              <Tab
                label="Customer"
                {...a11yProps(1)}
                onClick={() => {
                  setRole("user");
                  dispatch(changeRole("user"));
                }}
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <TableContainer className="height-fix">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Order No.</TableCell>
                      <TableCell>Questions</TableCell>
                      <TableCell>Answer</TableCell>
                      {canEdit?<TableCell>Status</TableCell>: null}
                      {canEdit || canDelete ?<TableCell>Action</TableCell>: null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {faqData?.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {row?.sequence}
                          </TableCell>
                          <TableCell>
                            {row?.cms_locales[0]?.question?.length > 50
                              ? row?.cms_locales[0]?.question.slice(0, 50) +
                                "..."
                              : row?.cms_locales[0]?.question}
                          </TableCell>
                          <TableCell>{getEngLang(row?.cms_locales)}</TableCell>
                          
                            {canEdit?<TableCell>
                              <Switch
                                onClick={() => {
                                  updateStatus(row);
                                }}
                                checked={row?.status == "active" ? true : false}
                              />
                            </TableCell>: null}

                            {canEdit || canDelete ?
                            <TableCell align="center" className="nowrap">
                              {canEdit?<Tooltip
                                title="Edit"
                                placement="top"
                                sx={{ marginRight: 1 }}
                                onClick={() => {
                                  routerEditFaq("edit", row?.id);
                                }}
                              >
                                <BorderColorIcon
                                  color="action"
                                  fontSize="small"
                                />
                              </Tooltip>: null}
                              {canDelete?<Tooltip
                                title="Delete"
                                placement="top"
                                onClick={() => {
                                  setDeleteId(row?.id);
                                  openDelete(row?.id);
                                }}
                              >
                                <DeleteForever color="error" fontSize="small" />
                              </Tooltip>: null}
                            </TableCell>: null}

                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TableContainer className="height-fix">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Order No.</TableCell>
                      <TableCell>Questions</TableCell>
                      <TableCell>Answer</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {faqData?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row?.sequence}
                        </TableCell>
                        <TableCell>
                          {" "}
                          {row?.cms_locales[0]?.question?.length > 50
                            ? row?.cms_locales[0]?.question.slice(0, 50) + "..."
                            : row?.cms_locales[0]?.question}
                        </TableCell>
                        <TableCell>{getEngLang(row?.cms_locales)}</TableCell>
                        <TableCell>
                          <Switch
                            onClick={() => {
                              updateStatus(row);
                            }}
                            checked={row?.status == "active" ? true : false}
                          />
                        </TableCell>
                        <TableCell align="center" className="nowrap">
                          <Tooltip
                            title="Edit"
                            placement="top"
                            sx={{ marginRight: 1 }}
                            onClick={() => {
                              routerEditFaq("edit", row?.id);
                            }}
                          >
                            <BorderColorIcon color="action" fontSize="small" />
                          </Tooltip>
                          <Tooltip
                            title="Delete"
                            placement="top"
                            onClick={() => {
                              setDeleteId(row?.id);
                              openDelete(row?.id);
                            }}
                          >
                            <DeleteForever color="error" fontSize="small" />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            {count > 1 && (
              <Pagination
                count={count}
                color="primary"
                onChange={handlePageChange}
                page={page}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Dialog
        open={openAlertDelete}
        onClose={handleClose}
        className="alert-delete"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Alert
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  Are you sure you want to delete this?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                  onClick={handleClose}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                  onClick={() => {
                    deleteFaq(deleteId);
                  }}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
