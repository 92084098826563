import { createSlice } from "@reduxjs/toolkit";

export const manageUser = createSlice({
  name: "manageUser",
  initialState: {
    userId: null,
  },
  reducers: {
    changeUserId: (state, action) => {
      state.userId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeUserId } = manageUser.actions;
export const currentUserId = (state) => state.manageUser.userId;
export default manageUser.reducer;
