import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, Typography, TextField, Grid, Divider } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import services from "../../../services";
import uploadImg from "../../../assets/images/structure/upload-img.png";
import modalClose from "../../../assets/images/structure/close-modal.png";
import categoryImg1 from "../../../assets/images/product/category-img1.png";
import categoryImg2 from "../../../assets/images/product/category-img2.png";
import categoryImg3 from "../../../assets/images/product/category-img3.png";
import moment from "moment";
import { currentPermission } from "../../../redux/reducers/permission";
import { useSelector } from "react-redux";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../../pages/ENUM";

export default function StoreRequestDetail() {

  const permission = useSelector(currentPermission);
  let [canEdit, setCanEdit] = useState(true);
  let [canApprove, setCanApprove] = useState(true);
  let [canAssign, setCanAssign] = useState(true);
  const [date, setDate] = useState("");
  const [BusinessCategory, setBusinessCategory] = useState({
    id: 0,
    err: "required",
    isValid: false,
  });
  const [openRejectReason, setOpenRejectReason] = useState(false);
  const [businesstypeData, setBusinesstypeData] = useState([]);
  const [storeData, setStoreData] = useState({});
  const { id } = useParams();

  // validation states
  const [businessName, setBusinessName] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [businessEmail, setBusinessEmail] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [businessAddress, setBusinessAddress] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [businessCountry, setBusinessCountry] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [ownerName, setOwnerName] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [ownerContact, setOwnerContact] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [ownerEmail, setOwnerEmail] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [commission, setCommission] = useState({
    val: "",
    err: "",
    isValid: false,
  });

  const [rejectionTime, setRejectionTime] = useState({
    val: "",
    err: "",
    isValid: false,
  });

  const [isApproved, setIsApproved] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    getStoreRequestById(id);
    getBusinessCategory();
  }, []);

  const routerStoreListing = () => {
    const path = `/store-listing`;
    navigate(path);
  };

  const onApproveHandler = async () => {
    try {
      const updatedData = {
        ...storeData,
        is_approved: true,
      };
      const response = await services.Stores.UPDATE_STORE(id, updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const onRejectHandler = async () => {
    setOpenRejectReason(true);
    try {
      const updatedData = {
        ...storeData,
        is_approved: false,
      };
      const response = await services.Stores.UPDATE_STORE(id, updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const getStoreRequestById = async (id) => {
    try {
      const getData = (await services.Stores.GET_STORE_BY_ID(id)).data.store;
      setBusinessCategory({
        err: "",
        isValid: true,
        id:
          getData.businessTypeId === businesstypeData.id
            ? getData.businessTypeId
            : 0,
      });
      setIsApproved(getData.is_approved);

      setBusinessName({ val: getData.business_name, isValid: true, err: "" });
      setBusinessEmail({ val: getData.user.email, isValid: true, err: "" });
      setBusinessAddress({
        val: getData.business_address,
        isValid: true,
        err: "",
      });
      setBusinessCountry({ val: getData.country, isValid: true, err: "" });
      setOwnerName({ val: getData.user.full_name, isValid: true, err: "" });
      setOwnerContact({
        val: getData.user.contact_number,
        isValid: true,
        err: "",
      });
      setOwnerEmail({
        val: getData.user.email,
        isValid: true,
        err: "",
      });

      setCommission({
        val: getData.user.commission,
        isValid: true,
        err: "",
      });
      setDate(
        moment(getData.createdAt, "DD-MM-YYYY HH:mm:ss").format("MM/DD/YYYY")
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getBusinessCategory = async () => {
    try {
      const data = (
        await services.BusinessType.GET_ALL_BUSINESS_TYPE_BY_QUERY()
      ).data.businesstype.rows;
      if (data) {
        setBusinesstypeData(data);
      }
    } catch (error) {
      console.log("Error_In_store_type", error);
    }
  };

  const onSaveHandler = async () => {
    if (
      businessName.isValid &&
      businessEmail.isValid &&
      businessAddress.isValid &&
      // businessCountry.isValid &&
      ownerName.isValid &&
      ownerContact.isValid &&
      ownerEmail.isValid &&
      commission.isValid
    ) {
      const data = {
        businessTypeId: BusinessCategory,
        business_name: businessName.val,
        business_address: businessAddress.val, //no such field for adding business address
        // country:businessCountry,
        full_name: ownerName.val,
        contact_number: ownerContact.val,
        email: ownerEmail.val,
        commission: commission.val,
      };
      try {
        const response = await services.Stores.ADD_STORE(id, data);
      } catch (error) {}
    } else {
    }
  };

  const updateStateWithValidation = (e, state, type = null) => {
    switch (type) {
      case "contact": {
        if (!isNaN(e.target.value)) {
          if (e.target.value.length > 10) {
            return {
              val: e.target.value,
              isValid: false,
              err: "Enter valid Mobile Number",
            };
          } else {
            return {
              val: e.target.value,
              isValid: true,
              err: "",
            };
          }
        } else if (isNaN(e.target.value)) {
          return {
            val: e.target.value,
            isValid: false,
            err: "Only Numbers allowed",
          };
        } else {
          return {
            val: e.target.value,
            isValid: false,
            err: "required",
          };
        }
      }
      case "email": {
        let validRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (e.target.value) {
          if (!validRegex.test(e.target.value)) {
            return {
              val: e.target.value,
              isValid: false,
              err: "Enter a valid email address",
            };
          } else {
            return {
              val: e.target.value,
              isValid: true,
              err: "",
            };
          }
        } else {
          return {
            val: e.target.value,
            isValid: false,
            err: "required",
          };
        }
      }
      case "commission": {
        if (isNaN(e.target.value)) {
          return {
            val: e.target.value,
            isValid: false,
            err: "Numbers allowed",
          };
        }

        const floatValue = parseFloat(e.target.value);

        if (floatValue <= 100) {
          return {
            val: e.target.value,
            isValid: true,
            err: "",
          };
        } else {
          return {
            val: e.target.value,
            isValid: false,
            err: "percentage can't be greater than 100",
          };
        }
      }
      case "paragraph": {
        if (e.target.value) {
          if (e.target.value.length > 250) {
            return {
              val: e.target.value,
              isValid: false,
              err: "max 250 char allowed",
            };
          } else {
            return {
              val: e.target.value,
              isValid: true,
              err: "",
            };
          }
        } else {
          return {
            val: e.target.value,
            isValid: false,
            err: "required",
          };
        }
      }
      default: {
        if (e.target.value) {
          if (e.target.value.length > 50) {
            return {
              val: e.target.value,
              isValid: false,
              err: "max 50 char allowed",
            };
          } else {
            return {
              val: e.target.value,
              isValid: true,
              err: "",
            };
          }
        } else {
          return {
            val: e.target.value,
            isValid: false,
            err: "required",
          };
        }
      }
    }
    // if (e.target.value) {
    //   if (e.target.value.length > 50) {
    //     return {
    //       val: e.target.value,
    //       isValid: false,
    //       err: "max 50 char allowed",
    //     };
    //   } else {
    //     return {
    //       val: e.target.value,
    //       isValid: true,
    //       err: "",
    //     };
    //   }
    // } else {
    //   return {
    //     val: e.target.value,
    //     isValid: false,
    //     err: "required",
    //   };
    // }
  };

  const businessNameChange = (e) => {
    const updatedState = updateStateWithValidation(e, businessName);
    setBusinessName(updatedState);
  };

  const businessEmailChange = (e, type) => {
    const updatedState = updateStateWithValidation(e, businessEmail, type);
    setBusinessEmail(updatedState);
  };

  const businessCountryChange = (e) => {
    const updatedState = updateStateWithValidation(e, businessCountry);
    setBusinessCountry(updatedState);
  };
  const ownerNameChange = (e) => {
    const updatedState = updateStateWithValidation(e, ownerName);
    setOwnerName(updatedState);
  };
  const ownerContactChange = (e, type) => {
    const updatedState = updateStateWithValidation(e, ownerContact, type);
    setOwnerContact(updatedState);
  };
  const ownerEmailChange = (e, type) => {
    const updatedState = updateStateWithValidation(e, ownerEmail, type);
    setOwnerEmail(updatedState);
  };
  const commissionChange = (e, type) => {
    const updatedState = updateStateWithValidation(e, commission, type);
    setCommission(updatedState);
  };

  const businessAddressChange = (e) => {
    const updatedState = updateStateWithValidation(e, businessAddress);
    setBusinessAddress(updatedState);
  };

  const rejectionTimeChange = (e) => {
    const updatedState = updateStateWithValidation(e, rejectionTime);
    setRejectionTime(updatedState);
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanEdit(permission?.storeRequest.edit === 0);
      setCanApprove(permission?.storeRequest.actions.approveReject === 0);
      setCanAssign(permission?.storeRequest.actions.addEditAssociates === 0);
    } else {
      setCanEdit(true);
      setCanApprove(true);
      setCanAssign(true);
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Store Request Detail
                </Typography>
              </Grid>
              <Grid item xs={4} align="right">
                {isApproved === null ? (
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    disabled={commission.val === null ? true : false}
                    onClick={() => onApproveHandler()}
                    sx={{ marginRight: "10px" }}
                  >
                    Approve
                  </Button>
                ) : null}
                {isApproved === null || isApproved === false ? (
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    disabled={
                      isApproved === false
                        ? true
                        : false || commission.val === null
                        ? true
                        : false
                    }
                    onClick={() => onRejectHandler()}
                  >
                    {isApproved === false ? "Rejected" : "Reject"}
                  </Button>
                ) : null}
              </Grid>
              <Grid item xs={4}>
                <div className="left-side">
                  <div className="upload-img">
                    {/* <div className='before-upload'>
                                        <img src={uploadImg} alt=".." className="upload-icon" />
                                        <p className='upload-text'>Upload Image</p>
                                        <input type="file" />
                                    </div> */}
                    <div className="after-upload">
                      <CancelIcon className="delete-icon" />
                      <img
                        src={categoryImg2}
                        alt=".."
                        className="upload-icon"
                      />
                      <input type="file" />
                    </div>
                  </div>
                  <div
                    className="owner-detail"
                    align="center"
                    style={{ margin: "20px 0px" }}
                  >
                    <p className="name-text mb-2">{}</p>
                    <p className="number-text">{businessAddress.val}</p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  mb={3}
                  fontWeight={500}
                >
                  Basic Detail
                </Typography>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Business Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Business Category"
                        value={BusinessCategory.id}
                        onChange={(e) => {
                          setBusinessCategory({
                            id: e.target.value,
                            err: "",
                            isValid: true,
                          });
                        }}
                      >
                        <MenuItem value={0}>
                          {"Select Business Category"}
                        </MenuItem>
                        {businesstypeData &&
                          businesstypeData.length > 0 &&
                          businesstypeData.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {!BusinessCategory.isValid && (
                        <div className="error">{BusinessCategory.err}</div>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Business Name"
                      value={businessName.val}
                      error={!businessName.isValid}
                      onChange={(e) => {
                        businessNameChange(e, businessName);
                      }}
                    />
                    {!businessName.isValid && (
                      <div className="error">{businessName.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Business Email"
                      value={businessEmail.val}
                      error={!businessEmail.isValid}
                      onChange={(e) => businessEmailChange(e, "email")}
                    />
                    {!businessEmail.isValid && (
                      <div className="error">{businessEmail.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled="true"
                        label="Register Date"
                        value={date}
                        onChange={(newValue) => {
                          setDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Business Address"
                      value={businessAddress.val}
                      error={!businessAddress.isValid}
                      onChange={(e) =>
                        businessAddressChange(e, businessAddress)
                      }
                    />
                    {!businessAddress.isValid && (
                      <div className="error">{businessAddress.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Country"
                      disabled="true"
                      value={businessCountry.val}
                      // error={!businessCountry.isValid}
                      // helperText={businessCountry.err}
                      onChange={(e) => businessCountryChange(e)}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="uploads-row">
                      <div className="upload-multiple">
                        <img src={uploadImg} alt=".." className="upload-icon" />
                        <p className="upload-text">Company registration ID</p>
                        <span>JPEG or PNG (3 files)</span>
                        <input type="file" />
                      </div>
                      <div className="upload-multiple">
                        <CancelIcon className="delete-icon" />
                        <img src={categoryImg1} alt=".." className="id-img" />
                      </div>
                      <div className="upload-multiple">
                        <CancelIcon className="delete-icon" />
                        <img src={categoryImg2} alt=".." className="id-img" />
                      </div>
                      <div className="upload-multiple">
                        <CancelIcon className="delete-icon" />
                        <img src={categoryImg3} alt=".." className="id-img" />
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Divider />
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  mb={3}
                  mt={3}
                  fontWeight={500}
                >
                  Personal Detail
                </Typography>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Owner Full Name"
                      value={ownerName.val}
                      error={!ownerName.isValid}
                      // helperText={ownerName.err}
                      onChange={(e) => ownerNameChange(e, ownerName)}
                    />
                    {!ownerName.isValid && (
                      <div className="error">{ownerName.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Owner Contact Number"
                      value={ownerContact.val}
                      error={!ownerContact.isValid}
                      // helperText={ownerContact.err}
                      onChange={(e) => ownerContactChange(e, "contact")}
                    />
                    {!ownerContact.isValid && (
                      <div className="error">{ownerContact.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Owner Email Address"
                      value={ownerEmail.val}
                      error={!ownerEmail.isValid}
                      // helperText={ownerEmail.err}
                      onChange={(e) => ownerEmailChange(e, "email")}
                    />
                    {!ownerEmail.isValid && (
                      <div className="error">{ownerEmail.err}</div>
                    )}
                  </Grid>
                </Grid>
                <Divider />
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  mb={3}
                  mt={3}
                  fontWeight={500}
                >
                  Other Detail
                </Typography>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={4}>
                    <TextField
                      label="Commission"
                      id="outlined-start-adornment"
                      value={commission.val !== null ? commission.val : 0}
                      error={!commission.isValid}
                      // helperText={commission.err}
                      onChange={(e) => commissionChange(e, "commission")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Rejection time for order"
                      id="outlined-start-adornment"
                      disabled="true"
                      value={rejectionTime.val}
                      // error={!rejectionTime.isValid}
                      // helperText={rejectionTime.err}
                      // onChange={(e) => rejectionTimeChange(e, rejectionTime)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Minute</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <div className="bottom-btn-row">
                  <Button
                    variant="standard"
                    size="large"
                    sx={{ marginRight: "10px" }}
                    onClick={routerStoreListing}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => onSaveHandler()}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={openRejectReason}
        onClose={() => setOpenRejectReason(false)}
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setOpenRejectReason(false)}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Reject Reason
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField required label="Reason" multiline rows={2} />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
