import { useNavigate } from "react-router-dom";
import { Typography, Box, TextField, Autocomplete, Button, Pagination, Tabs, Tab } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { changeLoader } from "../../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import {API} from "../../../pages/ENUM";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { currentPermission } from "../../../redux/reducers/permission";
import PropTypes from "prop-types";
import Header from "../../layout/header/Header";
import Aside from "../../layout/aside/Aside";
import OfferListTable from "./OfferListTable";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import GlobalService from "../../../services/GlobalService";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >{value === index && <>{children}</>}</div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabs = [
  // { label: "Awaiting Approval", value: "awaiting-approval" },
  { label: "Active / Upcoming", value: "active"  },
  { label: "Past", value: "past" }
];

const OfferListing = () => {
  const permission = useSelector(currentPermission);
  let [canCreate, setCanCreate] = useState(true);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const limit = 10;
  const [offers, setOffers] = useState([]);
  let [value, setValue] = useState(0);
  let [page, setPage] = useState(1);
  let [total, setTotal] = useState(0);
  let [sortBy, setSortBy] = useState("id");
  let [sortOrder, setSortOrder] = useState("desc");

  const getOffers = async () => {
    dispatch(changeLoader(true));
    let url = API.DISCOUNT_AND_OFFERS.GET_ALL;
    url += `?limit=${limit}&page=${page}&type=${tabs[value].value}`;
    url += `&sort_by=${sortBy}&order_by=${sortOrder}`
    const response = await GlobalService(url);
    setOffers([]);
    setTotal(0);
    if (response.ack === 1) {
      setOffers(response.data.rows);
      const totalPage = Math.ceil(response.data.count / limit);
      setTotal(Math.ceil(response.data.count / limit));
      if (totalPage < page) {
        page = totalPage;
        setPage(page);
        getOffers();
      }
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => { getOffers(); }, []);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanCreate(permission?.offerDiscount?.create === 1)
      setCanEdit(permission?.offerDiscount?.edit === 1)
      setCanDelete(permission?.offerDiscount?.delete === 1)
      setCanUpdateStatus(permission?.offerDiscount?.actions?.status === 1)
    } else {
      setCanCreate(true)
      setCanEdit(true)
      setCanDelete(true)
      setCanUpdateStatus(true)
    }
  }, [permission]);

  return (
    <Box className="dashboard-parent">
      <Header />
      <Aside />
      <Box className="main-wrapper">
        <Box className="white-box-20">
          <div className="top-heading-search">
            <Typography variant="h6" component="h6" mb={3} fontWeight={500}>My Offers</Typography>
            <div className="right-filter">
              {canCreate?<Button
                variant={"contained"} color="error" style={{ marginRight: "10px" }}
                size="medium" className="btn-sm" onClick={() => navigate("/add-offer")}
              >Add New</Button>: null}
            </div>
          </div>
          <div className="order-listing-wrapper">
            <div className="">
              <div className="tabs-wrap">
                <div style={{ borderBottom: "1px solid #E3E3E3", marginBottom: "12px", }}>
                  <Tabs
                    value={value}
                    onChange={(event, newValue) => {
                      value = newValue;
                      setValue(value);
                      getOffers();
                    }}
                    aria-label="basic tabs example"
                  >
                  {tabs.map((tab, index) => 
                    <Tab label={tab.label} {...a11yProps(index)} key={`tab-${index}`} />
                  )}
                  </Tabs>
                </div>
                {offers.length > 0?
                  <OfferListTable
                    offers={offers} type={tabs[value].value} getOffers={getOffers}
                    sortOrder={(sort_by, order_by) => {
                      sortBy = sort_by; setSortBy(sortBy);
                      sortOrder = order_by; setSortOrder(sortOrder);
                      getOffers();
                    }}
                    canEdit={canEdit}
                    canDelete = {canDelete}
                    canUpdateStatus = {canUpdateStatus}
                  />: <NoDataFound text={"No discount and offer found"} />
                }
                {total > 1?
                <Pagination
                  count={total} page={page} color="primary"
                  onChange={(e, newpage) => { page = newpage; setPage(page); getOffers(); }}
                />:null}

                {/* <CustomTabPanel value={value} index={0}>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <OfferListTable />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <OfferListTable />
                </CustomTabPanel> */}
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};
export default OfferListing;
