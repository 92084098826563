import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
  GET_CUSTOMERS: (page, limit, sort_by, order_by, created_by, search, role) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL +
            api.manageUser.GET_CUSTOMERS(
              page,
              limit,
              sort_by,
              order_by,
              created_by,
              search,
              role
            ),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_USER_DISCRIPTIONS: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.manageUser.GET_USER_DISCRIPTIONS(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  EDIT_USER: (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.manageUser.EDIT_USER(id),
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  DELETE_USER: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.delete(
          constant.BASE_URL + api.manageUser.DELETE_USER(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  ADD_USER: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.manageUser.ADD_USER,
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  UPDATE_BANNER: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.manageUser.UPDATE_BANNER,
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
};
// UPDATE_CMS
