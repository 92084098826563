import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography, TextField, Button, Grid, Alert } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { getdataByKey } from "../../helper/custom";
import Tooltip from "@mui/material/Tooltip";
import BorderColorIcon from "@mui/icons-material/BorderColor";
// image
import uploadImg from "../../../assets/images/structure/upload-img.png";
import categoryImg1 from "../../../assets/images/product/category-img1.png";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import services from "../../../services";
import { useNavigate, useParams } from "react-router-dom";
import constant from "../../../constant";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { currentPermission } from "../../../redux/reducers/permission";
import { ImageCropper, imagesToBase64, base64toFile } from "../../../dialog/Cropper";
import { blobToBase64 } from "../../../pages/helper/custom";
import imageCompression from 'browser-image-compression';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  alwaysKeepResolution: true
}

export default function AddBusinessType() {
  
  const permission = useSelector(currentPermission);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [canCreateEdit, setCanCreateEdit] = useState(true);
  let [cropperData, setCropperData] = useState({
    croppedImage: "",
    isOpen: false,
  });
  let [cropperData1, setCropperData1] = useState({
    croppedImage: "",
    isOpen: false,
  });
  const [redirectTextAr, setRedirectTextAr] = useState({
    val: "",
    err: "Please enter the redirect text in arabic",
    isValid: false,
  });
  const [redirectTextEn, setRedirectTextEn] = useState({
    val: "",
    err: "Please enter the redirect text in english",
    isValid: false,
  });
  const [businessTypeLogo, setBusinessTypeLogo] = useState({
    file: "",
    isValid: false,
    err: "Please upload business logo",
  });
  const [businessTypeLogo1, setBusinessTypeLogo1] = useState({
    file: "",
    isValid: false,
    err: "Please upload banner logo",
  });
  const [categoryName, setCategoryName] = useState({
    val: "",
    err: "Please enter the business Name ",
    isValid: false,
  });
  const [categoryDesc, setCategoryDesc] = useState({
    val: "",
    err: "Please enter a short description",
    isValid: false,
  });

  const [categoryNameAr, setCategoryNameAr] = useState({
    val: "",
    err: "Please enter the business Name ",
    isValid: false,
  });
  const [categoryDescAr, setCategoryDescAr] = useState({
    val: "",
    err: "Please enter a short description",
    isValid: false,
  });

  const [categoryOption, setCategoryOption] = useState({
    val: "1",
    err: "",
    isValid: true,
  });

  const [deliveryCharge, setDeliveryCharge] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [MinimumOrder, setMinimumOrder] = useState({
    val: "",
    err: "",
    isValid: false,
  });

  const [toaster, setToaster] = useState({
    visibilty: false,
    status: "",
    text: "",
  });
  const [isValidate, setIsValidate] = useState(false);
  const [uploadedImage, setuploadedImage] = useState("");
  const [uploadedImage1, setuploadedImage1] = useState("");
  // const handleCategoryLevel = (event) => {
  //   setCategoryLevel(event.target.value);
  // };
  const { id } = useParams();

  useEffect(() => {
    getBusinessTypeById(id);
  }, []);

  function handleNameChange(e, type = "en") {
    if (type == "ar") {
      if (e.target.value) {
        if (e.target.value.length > 100) {
          setCategoryNameAr({
            val: e.target.value,
            err: "max 100 character allowed",
            isValid: false,
          });
        } else {
          setCategoryNameAr({
            val: e.target.value,
            err: "",
            isValid: true,
          });
        }
      } else {
        setCategoryNameAr({
          val: e.target.value,
          err: "Please enter category",
          isValid: false,
        });
      }

      return true;
    }

    // setIsValidate(true);
    if (e.target.value) {
      if (
        e.target.value.toLocaleLowerCase() !== `grocery` &&
        businessTypeLogo.file.type === "image/gif" && businessTypeLogo1.file.type === "image/gif"   
      ) {
        setBusinessTypeLogo({
          ...businessTypeLogo,
          err: "",
          isValid: false,
        });
        setBusinessTypeLogo1({
          ...businessTypeLogo1,
          err: "",
          isValid: false,
        });
        setToaster({
          status: "error",
          text: `GIF not allowed`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 2500);
      }
      if (e.target.value.length > 100) {
        setCategoryName({
          val: e.target.value,
          err: "max 100 character allowed",
          isValid: false,
        });
      } else {
        setCategoryName({ val: e.target.value, err: "", isValid: true });
      }
    } else {
      setCategoryName({
        val: "",
        err: "Please enter the business Name",
        isValid: false,
      });
    }
  }

  function handleDescriptionChange(e, type = "en") {
    if (type == "ar") {
      if (e.target.value.length == 0) {
        setCategoryDescAr({
          val: "",
          err: "Please enter a short description",
          isValid: false,
        });
      } else {
        if (e.target.value) {
          if (e.target.value.length > 100) {
            setCategoryDescAr({
              val: e.target.value,
              err: "max 100 character allowed",
              isValid: false,
            });
          } else {
            setCategoryDescAr({ val: e.target.value, err: "", isValid: true });
          }
        } else {
          setCategoryDescAr({
            val: "",
            err: "Please enter the business Name",
            isValid: false,
          });
        }
      }

      return true;
    }

    // setIsValidate(true);
    if (e.target.value) {
      if (e.target.value.length > 100) {
        setCategoryDesc({
          val: e.target.value,
          err: "max 100 character allowed",
          isValid: false,
        });
      } else {
        setCategoryDesc({ val: e.target.value, err: "", isValid: true });
      }
    } else {
      setCategoryDesc({
        val: "",
        err: "Please enter the business Name",
        isValid: false,
      });
    }
  }

  const onBackHandler = () => {
    navigate("/business-type-listing");
  };

  const getBusinessTypeById = async (id) => {
    try {
      dispatch(changeLoader(true));
      const getData = (await services.BusinessType.GET_BUSINESS_TYPE_BY_ID(id))
        .data.businesstype;
      dispatch(changeLoader(false));
      setCategoryName({
        val: getdataByKey(getData.business_type_locales, "en", "name"),
        err: "",
        isValid: true,
      });
      setCategoryDesc({
        val: getdataByKey(getData.business_type_locales, "en", "description"),
        err: "",
        isValid: true,
      });

      setCategoryNameAr({
        val: getdataByKey(getData.business_type_locales, "ar", "name"),
        err: "",
        isValid: true,
      });
      setCategoryDescAr({
        val: getdataByKey(getData.business_type_locales, "ar", "description"),
        err: "",
        isValid: true,
      });
      setRedirectTextAr({
        val: getdataByKey(
          getData.business_type_locales,
          "ar",
          "redirect_description"
        ),
        err: "",
        isValid: true,
      });
      setRedirectTextEn({
        val: getdataByKey(
          getData.business_type_locales,
          "en",
          "redirect_description"
        ),
        err: "",
        isValid: true,
      });

      setuploadedImage(getData?.image);
      setuploadedImage1(getData?.banner);
      setCategoryOption({
        val: getData.category_level,
        err: "",
        isValid: true,
      });

      setDeliveryCharge({
        val: getData.delivery_charges,
        err: "",
        isValid: true,
      });
      setMinimumOrder({
        val: getData.minimum_order_value,
        err: "",
        isValid: true,
      });

    } catch (error) {
      console.log(error);
    }
  };

  function handleRedirectTextChange(e, type = "en") {
    if (type == "ar") {
      if (e.target.value.length == 0) {
        setRedirectTextAr({
          val: e.target.value,
          err: "Please enter a short description",
          isValid: false,
        });
      } else {
        if (e.target.value.length > 120) {
          setRedirectTextEn({
            val: e.target.value,
            err: "max 120 character allowed",
            isValid: false,
          });
        } else {
          setRedirectTextAr({ val: e.target.value, err: "", isValid: true });
        }
      }

      return true;
    }
    if (e.target.value) {
      if (e.target.value.length > 100) {
        setRedirectTextEn({
          val: e.target.value,
          err: "max 100 character allowed",
          isValid: false,
        });
      } else {
        setRedirectTextEn({ val: e.target.value, err: "", isValid: true });
      }
    } else {
      setRedirectTextEn({
        val: "",
        err: "Please enter a short description",
        isValid: false,
      });
    }
  }
  const onUpdateHandler = async () => {
    let isValid = true;
    if (deliveryCharge.val === '' || deliveryCharge.val < 0) {
      setDeliveryCharge({
        val: deliveryCharge.val,
        err: "Please enter delivery charge",
        isValid: false,
      });
      isValid = false;
    }
    if (MinimumOrder.val === '' || MinimumOrder.val < 0) {
      setMinimumOrder({
        val: MinimumOrder.val,
        err: "Please enter minimum order amount",
        isValid: false,
      });
      isValid = false;
    }
    let imgval = uploadedImage ? 0 : businessTypeLogo.isValid ? 1 : 0;
    let imgval1 = uploadedImage1 ? 0 : businessTypeLogo1.isValid ? 1 : 0;
    if (
      categoryDesc.isValid &&
      categoryName.isValid &&
      imgval == 0 && isValid || imgval1 == 0
    ) {
      try {
        const updatedData = {
          name_en: categoryName.val,
          description_en: categoryDesc.val,
          name_ar: categoryNameAr.val,
          description_ar: categoryDescAr.val,
          redirect_text_en: redirectTextEn.val,
          redirect_text_ar: redirectTextAr.val,
          delivery_charges: deliveryCharge.val,
          minimum_order_value: MinimumOrder.val,
        };
        const formData = new FormData();
        for (const property in updatedData) {
          if (updatedData.hasOwnProperty(property)) {
            formData.append(property, updatedData[property]);
          }
        }
        if (businessTypeLogo && businessTypeLogo.file) {
          formData.append("image", businessTypeLogo.file);
        }
        if (businessTypeLogo1 && businessTypeLogo1.file) {
          formData.append("banner", businessTypeLogo1.file);
        }
        dispatch(changeLoader(true));
        const response = await services.BusinessType.UPDATE_BUSINESS_TYPE(
          id,
          formData
        );
        dispatch(changeLoader(false));
        if (response) {
          setIsValidate(false);
          if (response) {
            setToaster({
              status: "success",
              text: `Business Category Updated Successfuly`,
              visibilty: true,
            });
            setTimeout(() => {
              setToaster({ ...toaster, visibilty: false });
            }, 6000);
            navigate(`/business-type-listing`);
          }
        }
      } catch (error) {
        console.log("Error_In_Login", error);
      }
    } else if (!isValidate) {
      setIsValidate(true);
    }
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanCreateEdit(permission?.businessCategory?.edit === 1)
    } else {
      setCanCreateEdit(true)
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty && (<Alert severity={toaster.status}>{toaster.text}</Alert>)}
          <Box className="white-box-20-30">
            <Typography variant="h6" component="h6" mb={4} fontWeight={500}>
              Update Store Type
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="upload-img" style={{ margin: "0px 0px 30px 0px" }} >
                  {!(businessTypeLogo && businessTypeLogo.isValid) &&
                  uploadedImage == "" ? (
                    <div className="before-upload">
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload Image</p>
                      <input type="file" id="file"
                        accept={`image/png, image/jpeg, ${categoryName.val.toLocaleLowerCase() === `grocery` ? `image/gif` : ``}`}
                        onChange={async (e) => {
                          const imageType = e.target.files[0].type;
                          if (
                            (categoryName.val.toLocaleLowerCase() === "grocery" ? imageType === "image/gif" : "") ||
                            imageType === "image/png" ||
                            imageType === "image/jpeg" ||
                            imageType === "image/jpg" ||
                            imageType === "application/msword" ||
                            imageType ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                            imageType === "application/pdf"
                          ) {
                            const file = e.target.files[0];
                            const compressedFile = await imageCompression(file, options);
                            const imageUrl = await blobToBase64(compressedFile);
                              cropperData = {
                                croppedImage: imageUrl,
                                isOpen: true,
                              };
                              setCropperData(cropperData);
                              document.getElementById("file").value = "";
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div className="after-upload">
                      <div className="edit-new-icon">
                        <EditIcon
                          className="delete-icon"
                          onClick={() => {
                            setBusinessTypeLogo({
                              file: "",
                              isValid: false,
                              err: "Please upload business logo",
                            });
                          }}
                        />
                      </div>
                      <img
                        src={
                          businessTypeLogo.file == ""
                            ? `${constant.IMG_BASE_URL}${uploadedImage}`
                            : URL.createObjectURL(businessTypeLogo.file)
                        }
                        alt=".."
                        className="upload-icon"
                      />
                      <input
                        type="file"
                        id="catfile3"
                        accept={`image/png, image/jpeg, ${
                          categoryName.val.toLocaleLowerCase() === `grocery`
                            ? `image/gif`
                            : ``
                        }`}
                        onChange={async (e) => {
                          const imageType = e.target.files[0].type;
                          if (
                            (categoryName.val.toLocaleLowerCase() === "grocery" ? imageType === "image/gif" : "") ||
                            imageType === "image/png" ||
                            imageType === "image/jpeg" ||
                            imageType === "image/jpg" ||
                            imageType === "application/msword" ||
                            imageType ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                            imageType === "application/pdf"
                          ) {
                            const file = e.target.files[0];
                            const compressedFile = await imageCompression(file, options);
                            const imageUrl = await blobToBase64(compressedFile);
                              cropperData = {
                                croppedImage: imageUrl,
                                isOpen: true,
                              };
                              setCropperData(cropperData);
                              document.getElementById("catfile3").value = "";
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
                {!businessTypeLogo.isValid &&
                  isValidate &&
                  uploadedImage == "" && (
                    <div className="error">{businessTypeLogo.err}</div>
                  )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="upload-img" style={{ margin: "0px 0px 30px 0px" }} >
                  {!(businessTypeLogo1 && businessTypeLogo1.isValid) &&
                  uploadedImage1 == "" ? (
                    <div className="before-upload">
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload Image</p>
                      <input
                        type="file"
                        id="file"
                        accept={`image/png, image/jpeg, ${categoryName.val.toLocaleLowerCase() === `grocery` ? `image/gif` : ``}`}
                        onChange={async (e) => {
                          const imageType = e.target.files[0].type;
                          if (
                            (categoryName.val.toLocaleLowerCase() === "grocery" ? imageType === "image/gif" : "") ||
                            imageType === "image/png" ||
                            imageType === "image/jpeg" ||
                            imageType === "image/jpg" ||
                            imageType === "application/msword" ||
                            imageType ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                            imageType === "application/pdf"
                          ) {
                            const file = e.target.files[0];
                            const compressedFile = await imageCompression(file, options);
                            const imageUrl = await blobToBase64(compressedFile);
                              cropperData1 = {
                                croppedImage: imageUrl,
                                isOpen: true,
                              };
                              setCropperData1(cropperData1);
                              document.getElementById("file").value = "";
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div className="after-upload">
                      <div className="edit-new-icon">
                        <EditIcon
                          className="delete-icon"
                          onClick={() => {
                            setBusinessTypeLogo1({
                              file: "",
                              isValid: false,
                              err: "Please upload business logo",
                            });
                          }}
                        />
                      </div>
                      <img
                        src={businessTypeLogo1.file == "" ? `${constant.IMG_BASE_URL}${uploadedImage1}` : URL.createObjectURL(businessTypeLogo1.file)}
                        alt=".."
                        className="upload-icon"
                      />
                      <input
                        type="file"
                        id="catfile3"
                        accept={`image/png, image/jpeg, ${
                          categoryName.val.toLocaleLowerCase() === `grocery`
                            ? `image/gif`
                            : ``
                        }`}
                        onChange={async (e) => {
                          const imageType = e.target.files[0].type;
                          if (
                            (categoryName.val.toLocaleLowerCase() === "grocery" ? imageType === "image/gif" : "") ||
                            imageType === "image/png" ||
                            imageType === "image/jpeg" ||
                            imageType === "image/jpg" ||
                            imageType === "application/msword" ||
                            imageType ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                            imageType === "application/pdf"
                          ) {
                            const file = e.target.files[0];
                            const compressedFile = await imageCompression(file, options);
                            const imageUrl = await blobToBase64(compressedFile);
                              cropperData1 = {
                                croppedImage: imageUrl,
                                isOpen: true,
                              };
                              setCropperData1(cropperData1);
                              document.getElementById("catfile3").value = "";
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
                {!businessTypeLogo1.isValid &&
                  isValidate &&
                  uploadedImage1 == "" && (
                    <div className="error">{businessTypeLogo1.err}</div>
                  )}
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={4}>
                <TextField
                  required
                  id="outlined-required"
                  label="Business Type Name"
                  onChange={(e) => handleNameChange(e, "en")}
                  inputProps={{ maxLength: 50 }}
                  value={categoryName.val}
                  disabled={!canCreateEdit}
                />
                {!categoryName.isValid && isValidate && (<div className="error">{categoryName.err}</div>)}
                <TextField
                  style={{ marginTop: "10px" }}
                  required
                  id="outlined-required-ar"
                  label="Business Type Name"
                  inputProps={{ maxLength: 50 }}
                  value={categoryNameAr.val}
                  onChange={(e) => handleNameChange(e, "ar")}
                  disabled={!canCreateEdit}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Category Level
                  </InputLabel>
                  <Select
                    disabled={categoryOption.isValid}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={categoryOption.val === 0 ? 1 : categoryOption.val}
                    label="Payment Status"
                  >
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Short Description"
                  multiline
                  value={categoryDesc.val}
                  onChange={(e) => handleDescriptionChange(e, "en")}
                  maxRows={4}
                  inputProps={{ maxLength: 100 }}
                  disabled={!canCreateEdit}
                />
                {!categoryDesc.isValid && isValidate && (<div className="error">{categoryDesc.err}</div>)}
                <TextField
                  id="outlined-multiline-flexible-ar"
                  label="Short Description (Arabic)"
                  multiline
                  value={categoryDescAr.val}
                  style={{ marginTop: "10px" }}
                  inputProps={{ maxLength: 100 }}
                  onChange={(e) => handleDescriptionChange(e, "ar")}
                  maxRows={4}
                  disabled={!canCreateEdit}
                />
                <TextField
                  style={{ marginTop: "10px" }}
                  id="outlined-multiline-flexible-ar"
                  label="Redirect Text (English)"
                  multiline
                  value={redirectTextEn.val}
                  onChange={(e) => handleRedirectTextChange(e, "en")}
                  maxRows={4}
                  disabled={!canCreateEdit}
                />
                {!redirectTextEn.isValid && isValidate && (<div className="error">{redirectTextEn.err}</div>)}
                <TextField
                  style={{ marginTop: "10px" }}
                  id="outlined-multiline-flexible-ar"
                  label="Redirect Text (Arabic)"
                  multiline
                  value={redirectTextAr.val}
                  onChange={(e) => handleRedirectTextChange(e, "ar")}
                  maxRows={4}
                  disabled={!canCreateEdit}
                />
                {!redirectTextAr.isValid && isValidate && (<div className="error">{redirectTextAr.err}</div>)}
                <TextField
                  style={{ marginTop: "10px" }}
                  id="outlined-multiline-flexible-ar"
                  label="Minimum Order Amount"
                  value={ MinimumOrder.val }
                  onChange={(e) => setMinimumOrder({
                    val: e.target.value,
                    err: "",
                    isValid: true,
                  })}
                  disabled={!canCreateEdit}
                />
                {!MinimumOrder.isValid && isValidate && (<div className="error">{MinimumOrder.err}</div>)}
                <TextField
                  style={{ marginTop: "10px" }}
                  type="number"
                  id="outlined-multiline-flexible-ar"
                  label="Delivery Charge"
                  value={ deliveryCharge.val }
                  onChange={(e) => setDeliveryCharge({
                    val: e.target.value,
                    err: "",
                    isValid: true,
                  })}
                  disabled={!canCreateEdit}
                />
                {!deliveryCharge.isValid && isValidate && (<div className="error">{deliveryCharge.err}</div>)}
              </Grid>
              <Grid item xs={8}>
                <div className="bottom-btn-row">
                  <Button
                    variant="standard"
                    size="large"
                    sx={{ marginRight: "10px" }}
                    onClick={() => onBackHandler()}
                  > Back </Button>
                  {canCreateEdit?<Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => onUpdateHandler()}
                    disabled={
                      !categoryDesc.isValid ||
                      !categoryName.isValid ||
                      !categoryDescAr.isValid ||
                      (!businessTypeLogo.isValid && uploadedImage == "") ||
                      (!businessTypeLogo1.isValid && uploadedImage1 == "")
                    }
                  > Save </Button>: null}
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {cropperData.isOpen ? (
        <ImageCropper
          cropperImage={cropperData.croppedImage}
          closepopup={() => {
            setCropperData({
              croppedImage: "",
              isOpen: false,
            });
          }}
          setCropImage={async (cropped) => {
            const response = await base64toFile(cropped.croppedImage, 'categoryImage.png')
            setBusinessTypeLogo({
              file: response,
              isValid: true,
              err: "",
            });
            setCropperData({
              croppedImage: "",
              isOpen: false,
            });
          }}
          otherParams={{aspectRatio: 1/1,}}
        />
      ) : null}
      {cropperData1.isOpen ? (
        <ImageCropper
          cropperImage={cropperData1.croppedImage}
          closepopup={() => {
            setCropperData1({
              croppedImage: "",
              isOpen: false,
            });
          }}
          setCropImage={async (cropped) => {
            const response = await base64toFile(cropped.croppedImage, 'categoryImage.png')
            setBusinessTypeLogo1({
              file: response,
              isValid: true,
              err: "",
            });
            setCropperData1({
              croppedImage: "",
              isOpen: false,
            });
          }}
          otherParams={{aspectRatio: 16/9,}}
        />
      ) : null}

    </>
  );
}
