import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Box, FormControl, Select, MenuItem, Menu, Tabs, Tab, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { API } from "../../../pages/ENUM";
import { currentPickerSocket } from "../../../redux/reducers/socket";
import { 
  Description as DescriptionIcon, PlayCircle as PlayCircleIcon, CheckCircle as CheckCircleIcon,
  Info as InfoIcon, DeliveryDining as DeliveryDiningIcon, Search as SearchIcon, Close as CloseIcon,
 } from "@mui/icons-material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import GlobalService from "../../../services/GlobalService";
import InPicked from "./TrackingTabs/InPicked";
import InQC from "./TrackingTabs/InQC";
import Delayed from "./TrackingTabs/Delayed";
import NotPicked from "./TrackingTabs/NotPicked";
import PickerIdle from "./TrackingTabs/PickerIdle";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PickerTrackingSystem = () => {

  const navigate = useNavigate();
  const currentPickerSocketObj = useSelector(currentPickerSocket);
  const [value, setValue] = useState(0);
  const [count, setCount] = useState("");
  const [search, setSearch] = useState("");
  const [SortBy, setSortBy] = useState("");
  const [picker_state,setpicker_state]= useState("")
  const [Delay, setDelay] = useState("");

  const GET_Time = async () => {
    try {
      let url = API.TRAFFIC_LIGHT.GET_TIME;
      const response = await GlobalService(url);
      if (response.ack == 1) {
        const PickQc = response.results.count;
        const color = response.pickstartime.color;
        const picktime = response.pickstartime.time;
        getAllOrder(color, PickQc, picktime);
      }
    } catch (error) {
      console.log("Error_In_Picker_traffic_light", error);
    }
  };

  const getAllOrder = async (color, PickQc, picktime) => {
    try {
      let url = API.PICKER_TRACKING.GET_PICKER_ORDER;
      const response = await GlobalService(url);
      if (response?.ack === 1) {
        setCount(response?.data);
        if (
          (PickQc === 0 && picktime === 0 && color === "white") ||
          (PickQc === 0 && picktime === 0 && color === "")
        )
          setDelay(0);
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  useEffect(() => {
    GET_Time();
  }, []);

  useEffect(() => {
    if (currentPickerSocketObj) {
      getAllOrder();
    }
  }, [currentPickerSocketObj]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={2} fontWeight={500}> Picker Tracking </Typography>
            </div>
            <div className="five-box-column">
              <div className="five-box-column-item" onClick={(event) => setValue(0) } >
                <div className="flex-head">
                  <Typography variant="h6" component="h6" fontWeight={500}>New</Typography>
                  <DescriptionIcon />
                </div>
                <Typography variant="h3" component="h3" fontWeight={700}>{count?.newOrder}</Typography>
                <Typography variant="body" component="body" fontWeight={500}>New orders</Typography>
              </div>
              <div className="five-box-column-item" onClick={(event) => setValue(1) } >
                <div className="flex-head">
                  <Typography variant="h6" component="h6" fontWeight={500}>In Pickup</Typography>
                  <PlayCircleIcon />
                </div>
                <Typography variant="h3" component="h3" fontWeight={700}>{count?.inPickup}</Typography>
                <Typography variant="body" component="body" fontWeight={500}>Pickup Inprogress</Typography>
              </div>
              <div className="five-box-column-item" onClick={(event) => setValue(2) } >
                <div className="flex-head">
                  <Typography variant="h6" component="h6" fontWeight={500}>In QC</Typography>
                  <CheckCircleIcon />
                </div>
                <Typography variant="h3" component="h3" fontWeight={700}>{count?.inQc}</Typography>
                <Typography variant="body" component="body" fontWeight={500}>Pickup Completed</Typography>
              </div>
              <div className="five-box-column-item" onClick={(event) =>  setValue(3) } >
                <div className="flex-head">
                  <Typography variant="h6" component="h6" fontWeight={500}>Delayed</Typography>
                  <InfoIcon />
                </div>
                <Typography variant="h3" component="h3" fontWeight={700}>{Delay === 0 ? Delay : count?.delayed}</Typography>
                <Typography variant="body" component="body" fontWeight={500}>Delayed Orders</Typography>
              </div>
              <div className="five-box-column-item" onClick={() => navigate("/picker-listing-page")} >
                <div className="flex-head">
                  <Typography variant="h6" component="h6" fontWeight={500}>Picker Performance</Typography>
                  <DeliveryDiningIcon />
                </div>
                <Typography variant="h3" component="h3" fontWeight={700}>{count?.pickerount}</Typography>
                <Typography variant="body" component="body" fontWeight={500}>Picker</Typography>
              </div>
            </div>
            <Box pt={3}>
              {/* all tabs options */}
              <div className="top-heading-search">
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Task Tracking
                </Typography>
              </div>
              <div className="order-listing-wrapper">
                <div className="">
                  <div className="tabs-wrap">
                    <div style={{borderBottom: "1px solid #E3E3E3", marginBottom: "20px", display: "flex", justifyContent: "space-between"}}>
                      <Tabs value={value} onChange={(event, newValue) => setValue(newValue) } aria-label="basic tabs example" >
                        <Tab label="Not Picked" {...a11yProps(0)} />
                        <Tab label="In-Picked" {...a11yProps(1)} />
                        <Tab label="In QC" {...a11yProps(2)} />
                        <Tab label="Delayed" className="redLabel" {...a11yProps(3)} />
                        <Tab label="Picker List" {...a11yProps(4)} />
                      </Tabs>
                      <div className="sort-filter">
                        <TextField
                          id="outlined-size-small" size="small" value={search} sx={{ mr: 2, width: "150px" }}
                          placeholder={ value == 4 ? "Search Picker " : "Search Order id " } 
                          onChange={(e) => setSearch(e.target.value.trimStart()) }
                          InputProps={{
                            startAdornment: <SearchIcon sx={{ marginRight: "10px" }} />,
                            endAdornment: search ?  <CloseIcon onClick={() => setSearch("") } />: null,
                          }}
                        />
                        {value == 4?
                        <FormControl sx={{ ml: 1, width: "150px" }}>
                          <Select
                            labelId="demo-select-small" id="demo-select-small" size="small" className="min-width150"
                            value={picker_state} onChange={(event) => setpicker_state(event.target.value)}
                          >
                            <MenuItem value="" disabled>Select one</MenuItem>
                            <MenuItem value={"idle"}>Idle</MenuItem>
                            <MenuItem value={"picker_exceed_threshold_time"}>Picker Exceed Threshold Time</MenuItem>
                            <MenuItem value={"busy"}>Busy</MenuItem>
                            <MenuItem value={"offline"}>Offline</MenuItem>
                          </Select>
                        </FormControl> : <>
                          <Typography variant="body" component="body" whiteSpace={"nowrap"} fontWeight={500}>Sort By</Typography>
                          <FormControl sx={{ ml: 1, width: "150px" }}>
                            <Select
                              labelId="demo-select-small" id="demo-select-small" size="small"
                              value={SortBy} onChange={(event) => setSortBy(event.target.value)} className="min-width150"
                            >
                              <MenuItem value="" disabled>Select one</MenuItem>
                              <MenuItem value={"createdAt"}>Order Date</MenuItem>
                              <MenuItem value={"total_quantity"}>Order Qty</MenuItem>
                            </Select>
                          </FormControl>
                        </>}
                        <span className="clear-all" onClick={() => { setSortBy(""); setSearch(""); }} >Clear</span>
                      </div>
                    </div>
                    <div className="">
                      <CustomTabPanel value={value} index={0}>
                        <NotPicked sortBy={SortBy} search={search} />
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={1}>
                        <InPicked sortBy={SortBy} search={search} />
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={2}>
                        <InQC sortBy={SortBy} search={search} />
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={3}>
                        <Delayed sortBy={SortBy} search={search} />
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={4}>
                        <PickerIdle picker_state={picker_state} search={search} />
                      </CustomTabPanel>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default PickerTrackingSystem;
