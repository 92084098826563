
import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Grid, Button, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';


export const ResetPassword = () => { 

    const [showPasswordNew, setShowPasswordNew] = React.useState(false);
    const ShowPasswordNew = () => setShowPasswordNew((show) => !show);
    const handleMouseDownPasswordNew = (event) => {
        event.preventDefault();
    };

    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
    const ShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);
    const handleMouseDownPasswordConfirm = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <div className='auth-bg'>
                <Alert severity="success">Successful Password Reset</Alert>
                <Box className='form-bg'>
                    <form>
                        <Typography variant="h5" component="h5" mb={4} fontWeight={'700'} textAlign="center">Reset Password</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" >
                                    <InputLabel htmlFor="worktokPasswordNew">New Password</InputLabel>
                                    <OutlinedInput
                                        id="worktokPasswordNew"
                                        type={showPasswordNew ? 'text' : 'password'}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={ShowPasswordNew}
                                            onMouseDown={handleMouseDownPasswordNew}
                                            edge="end"
                                            >
                                            {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="New Password"
                                    />
                                </FormControl>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <FormControl variant="outlined" >
                                    <InputLabel htmlFor="worktokPasswordConfirm">Confirm Password</InputLabel>
                                    <OutlinedInput
                                        id="worktokPasswordConfirm"
                                        type={showPasswordConfirm ? 'text' : 'password'}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={ShowPasswordConfirm}
                                            onMouseDown={handleMouseDownPasswordConfirm}
                                            edge="end"
                                            >
                                            {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Confirm Password"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} textAlign="center" >
                                <Button to={'/'} variant="contained" size="large" sx={{ width: 1, marginTop: '10px' }}>Confirm</Button>  
                            </Grid>
                        </Grid>                        
                    </form>
                </Box>
            </div>
        </>
    )
}
