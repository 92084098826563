export default {
  GET_ALL_ADDONS: (query) =>
    `/addons/admin/get-all-addons?${query ? query : ""}`,
  // GET_PRODUCT: (id) => `/products/get-product-by-id/${id}`,
  // GET_EDIT_PRODUCT: (id) => `/products/get-edit-product-request-by-id/${id}`,
  // ADD_PRODUCT: () => `/products/add-product-by-admin`,
  // UPDATE_PRODUCT: (id) => `/products/update-product-by-id/${id}`,
  // UPDATE_STATUS_APPROVE: (id) => `/products/approve-new-product/${id}`,
  // UPDATE_EDIT_STATUS_APPROVE: (id) => `/products/approve-edit-product/${id}`,
  // DELETE_PRODUCT: (id) => `/products/delete-products-by-admin/${id}`,
  // GET_PRODUCT_LIST_BY_ID: (id) => `/products/get-specific/${id}`,
  // UPLOAD_PRODUCT_IMAGE: `/products/admin/upload-product-image`,
  // UPLOAD_PRODUCT_IMAGE_UOM: `/products/admin/upload-uom-image`,
  // ADD_UOM: `/products/admin/add-product-uom`,
  // GET_EDIT_REQUEST_PRODUCTS: (ID) => `/products/get-edit-product-request-by-id/${ID}`,
  // UPDATE_STATUS_BY_ID: (id) => `/products/update-status-by-id/${id}`,
  // GET_UOM: (id) => `/products/admin/get-uom-detail/${id}`,
  // DELETE_UOM: (id) => `/products/admin/delete-uom/${id}`,
  // UPDATE_UOM: (id) => `/products/admin/edit-product-uom/${id}`,
};
