import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
  ADD_STORE: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Stores.ADD_STORE(),
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_ALL_STORE: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Stores.GET_ALL_STORE(query),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_STORE_LIST: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Stores.GET_STORE_LIST(),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_STORES_COUNT: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Stores.GET_STORES_COUNT(query),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_STORE_BY_ID: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Stores.GET_STORE_BY_ID(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  UPDATE_STORE: (id, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Stores.UPDATE_STORE(id),
          updatedData,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  DELETE_STORE: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.delete(
          constant.BASE_URL + api.Stores.DELETE_STORE(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
};
