import modalClose from "../assets/images/structure/close-black.svg";
import thankyouImg from "../assets/images/structure/offer-thankyou.png"
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Boxed } from "../Styles-Elements/Box";


const ThankyouOffer = ({ isOpen = false, handleClose}) => {
  
    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} className="main-modal maxwidth-430">
          <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
          <DialogContent>
            <Boxed className="text-center" style={{ marginBottom: "20px", marginTop: "10px" }}>
              <img src={thankyouImg} alt="" />
            </Boxed>
            <h2 className="h3 text-center" style={{ marginBottom: "8px" }}>
              Thank you
            </h2>
            <p className="p3 text-center" style={{ marginBottom: "12px", fontSize: "14px" }}>
            Your offer request has been submitted to the admin for approval. Once approved, you will find the offer in the Active/Upcoming tab.
            </p>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  
  export default ThankyouOffer;