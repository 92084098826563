import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography, Grid } from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { Boxed } from "../../../Styles-Elements/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation } from "react-router-dom";
import services from "../../../services";
import PersonalDetails from "./PersonalDetails";
import CustomerAddress from "./CustomerAddress";
import WalletDetails from "./WalletDetails";
import OrderHistory from "./OrderHistory";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { currentRedirect } from "../../../redux/reducers/manageCustomer";
import { currentPermission } from "../../../redux/reducers/permission";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeSnackbar } from "../../../redux/reducers/snackbar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Boxed>{children}</Boxed>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function ManageCustomerDetail() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const reDirect = useSelector(currentRedirect);
  const permission = useSelector(currentPermission);
  let [canUpdateWallet, setCanUpdateWallet] = useState(true);
  const navigate = useNavigate();
  const [persnalDetail, setPersonalDetails] = useState({});
  const [address, setAddress] = useState([]);
  const [walletDetails, setWalletDetails] = useState([]);
  const [value, setValue] = React.useState(reDirect == "wallet" ? 2 : 0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  const getCoustomerDetails = async () => {
    if (id) {
      try {
        const response =
          await services?.manageCustomerListing?.GET_CUSTOMER_DETAILS(
            id
          );
  
        if (response?.data.ack === 1) {
          setPersonalDetails(response?.data?.data?.personal_details);
          setAddress(response?.data?.data?.addresses);
          setWalletDetails(response?.data?.data?.wallet_details);
        }
      } catch (error) {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "error",
          message: "Something went wrong!",
        }));
        dispatch(changeLoader(false));
      }
    }
  };

  useEffect(() => {
    getCoustomerDetails();
  }, []);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanUpdateWallet(permission?.walletManagement.create === 1)
    } else {
      setCanUpdateWallet(true)
    }
  }, [permission]);


  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Manage Customer Details
                </Typography>
              </Grid>

              <Grid item xs={12} md={12} lg={2}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange1}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  <Tab label={"Personal Detail"} {...a11yProps(0)} />
                  <Tab label={"Address"} {...a11yProps(1)} />
                  <Tab label={"Wallet Details"} {...a11yProps(2)} />
                  <Tab label={"Order history"} {...a11yProps(3)} />
                </Tabs>
              </Grid>
              <Grid item xs={12} md={12} lg={10}>
                <div className="">
                  <PersonalDetails
                    TabPanel={TabPanel}
                    value={value}
                    persnalDetail={persnalDetail}
                  />
                  <CustomerAddress
                    TabPanel={TabPanel}
                    value={value}
                    address={address}
                  />
                  <WalletDetails
                    TabPanel={TabPanel}
                    value={value}
                    walletDetails={walletDetails}
                    canUpdateWallet={canUpdateWallet}
                    id={id}
                  />
                  <OrderHistory
                    TabPanel={TabPanel}
                    value={value}
                    persnalDetail={persnalDetail}
                  />
                </div>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              size="large"
              color="primary"
              sx={{ marginTop: "20px", marginLeft: "20px" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
