import React, { useState, useEffect  } from "react";
import {
  Typography, Box, Pagination, Divider, Collapse, IconButton, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";
import { API } from "../../../ENUM";
import { currentPickerSocket } from "../../../../redux/reducers/socket";
import { useSelector } from "react-redux";
import plusIcon from "../../../../assets/images/structure/plus-icon.svg";
import minusIcon from "../../../../assets/images/structure/minus-icon.svg";
import GlobalService from "../../../../services/GlobalService";
import moment from "moment-timezone";

const InPicked = ({ sortBy, search }) => {

  const limit = 10;
  const currentPickerSocketObj = useSelector(currentPickerSocket);
  const [data, setData] = useState([]);
  const [traffic, setTraffic] = useState([]);
  const [count, setCount] = useState(0);
  let [page, setPage] = useState(1);
  let [openIds, setOpenIds] = useState([]);

  useEffect(() => {
    const getData = setTimeout(() => {
      GET_Time();
      page = 1;
      setPage(page);
      getAllOrder();
    }, 500);
    return () => clearTimeout(getData);
  }, [sortBy, search]);
  
  const GET_Time = async () => {
    try {
      let url = API.TRAFFIC_LIGHT.GET_TIME;
      const response = await GlobalService(url);
      setTraffic(response?.results?.rows);
    } catch (error) {
      console.log("Error_In_Picker traffic_light", error);
    }
  };
  
  const getAllOrder = async () => {
    const object = {
      pickerStatus: "in_pick",
      page: page,
      limit: limit,
      sortBy: sortBy,
      search: search,
    };
    const query = new URLSearchParams(object);
    try {
      let url = API.PICKER_TRACKING.GET_NOTPICKER_ORDER(query);
      const response = await GlobalService(url);

      setCount(Math.ceil(response.total_count / limit));

      if (response?.ack === 1) {
        setData(response?.orders);
        createData();
      } else {
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const handlePagination = (e, value) => {
    page = value;
    setPage(page);
    getAllOrder();
  };

  const createData = () => {
    let array =
      data &&
      data.length > 0 &&
      data.map((item) => {
        const productArray = item?.orders_details
          ? item?.orders_details.map((detail, index) => ({
              SNo: (index + 1).toString(),
              ProductName: detail?.product?.products_locales[0]?.title,
              ItemCode: detail?.product?.itemCode,
              PickupStatus: detail?.status,
              // traffic:traffic.find(obj => Object.values(obj).some(value => typeof value === 'number' && value <= item?.total_quantity)),
            }))
          : [];
          let statusLog = item?.order_status_log !== '' ? JSON.parse(item?.order_status_log) : [];
          let log = statusLog.find((log) => log?.status === "in_pick");
        const orderDate = new Date(log?.updated_at);
        const currentDate = new Date(
          moment.tz(new Date(), "Asia/Qatar").format("DD MMMM YYYY hh:mm a")
        );
        const diff = Math.abs(currentDate - orderDate);
        const duration = Math.floor(diff / 1000 / 60);
        const hours = Math.floor(duration / 60);
        const minutes = Math.floor(duration % 60);
        let matchingTraffic = traffic.find(
          (element) => item.total_quantity <= element.no_of_item
        )||"";
       

        return {
          name: `${item?.picker_user?.first_name || ""} ${
            item?.picker_user?.last_name || ""
          }`,
          OrderID: item?.order_id,
          NoOfItems: item?.total_quantity,
          color: matchingTraffic,
          Priority:
            duration > matchingTraffic?.in_pick_time - 2 ? "High" : "Medium",
          OrderDateTime:
            duration < matchingTraffic?.in_pick_time
              ? Math.abs(matchingTraffic?.in_pick_time - duration) + " min"
              : matchingTraffic
                ? "Already OverDue"
                : "--",
          over_due:
            duration < matchingTraffic?.in_pick_time
              ? "--"
              : matchingTraffic
                ? Math.abs(duration - matchingTraffic?.in_pick_time) + " min"
                : "--",
          PlacementDateTime: moment(item?.createdAt).format(
            "DD MMMM YYYY hh:mm a"
          ),
          product: productArray,
          TrafficColor:
            matchingTraffic?.in_pick_time > duration
              ? "white"
              : matchingTraffic?.in_pick_color,
        };
      });
    return array;
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow
          sx={{ "& > *": { borderBottom: "unset" } }}
          style={{
            borderWidth: "2px",
            // borderColor: row.color ? row?.color?.in_pick_color : "white",
            borderColor: row?.TrafficColor ? row?.TrafficColor : "white",
          }}
        >
          <TableCell component="th" scope="row">
            <div className="table-img-text">
              <img
                src="https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"
                alt=".."
                className="round36"
              />
              <Typography variant="body2" component="div">
                {row?.name}
              </Typography>
            </div>
          </TableCell>
          <TableCell>{row?.OrderID}</TableCell>
          <TableCell>{row?.NoOfItems}</TableCell>
          <TableCell>
            <span className="chips chips-inactive">{row?.Priority}</span>
          </TableCell>
          <TableCell>
            {moment
              .tz(row?.PlacementDateTime, "Asia/Qatar")
              .subtract(30, "minutes")
              .format("DD MMMM YYYY hh:mm a")}
            {/* {moment(row?.PlacementDateTime).format("DD MMMM YYYY hh:mm a")} */}
          </TableCell>
          <TableCell>
            <span className={row?.OrderDateTime}>{row?.OrderDateTime}</span>
          </TableCell>
          <TableCell>
            <span className={row?.over_due ? "chips chips-inactive" : ""}>
              {row?.over_due}
            </span>
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                if (openIds.includes(row?.OrderID)) {
                  openIds = openIds.filter((id) => id !== row?.OrderID);
                } else {
                  openIds = [...openIds, row?.OrderID];
                }
                setOpenIds(openIds)
              }}
            >
              <img src={openIds.includes(row?.OrderID) ? minusIcon: plusIcon} alt="plus-icon" />
            </IconButton>
          </TableCell>
        </TableRow>
        <Divider className="my-1" />
        <TableRow>
          <TableCell className="p-0 px-2" colSpan={8}>
            <Collapse in={openIds.includes(row?.OrderID)} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1, backgroundColor: "#f9f9f9" }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>S no.</TableCell>
                      <TableCell>Product name</TableCell>
                      <TableCell>Item code</TableCell>
                      <TableCell>Picked Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.product.map((productListing) => (
                      <TableRow key={productListing.SNo}>
                        <TableCell component="th" scope="row">
                          {productListing.SNo}
                        </TableCell>
                        <TableCell>{productListing.ProductName}</TableCell>
                        <TableCell>{productListing.ItemCode}</TableCell>
                        <TableCell>
                          <span className="chips chips-pending">
                            {/* {productListing.PickupStatus} */} Picked
                          </span>
                          {/* you can change status color for use this classes 'chips-active', 'chips-pending','chips-inactive' */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  useEffect(() => {
    if (currentPickerSocketObj) {
      getAllOrder();
    }
  }, [currentPickerSocketObj]);

  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Picker Name</TableCell>
              <TableCell>Order ID</TableCell>
              <TableCell>No. of Items</TableCell>
              <TableCell>Priority on the basis threshold</TableCell>
              <TableCell>Placement Date and Time</TableCell>
              <TableCell>Remaining Time</TableCell>
              <TableCell>Over Due By</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
          {createData() && createData().map((row) => (
            <Row row={row} key={`inpick-${row.id}`} />))}
          </TableBody>
        </Table>
      </TableContainer>
      {count > 1 ? 
        <Pagination count={count} page={page} onChange={handlePagination} color="primary" />: null}
    </>
  );
};
export default InPicked;
