import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { currentPermission } from "../../../redux/reducers/permission";
import {
  Grid, Typography, TextField, Button, Alert, Chip, Dialog, DialogContent, Box, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, Tooltip, MenuItem, Select
} from "@mui/material";
import { getdataByKey } from "../../helper/custom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import modalClose from "../../../assets/images/structure/close-modal.png";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import services from "../../../services";
import constant from "../../../constant";

export default function SectionManagementListing() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permission = useSelector(currentPermission);
  let [canCreate, setCanCreate] = useState(true);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);

  const [Category, setCategory] = useState("All");
  const [search, setSearch] = useState("");
  let [count, setCount] = useState(0);
  let [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [allManagers, setAllManagers] = useState([]);
  const [categories, setScategories] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [order_by, setOrder_by] = useState("");
  const [order_type, setOrder_type] = useState("");
  const [openRejectReason, setOpenRejectReason] = useState(false);

  const handleCategory = (event) => setCategory(event.target.value);
  
  const OpenRejectReason = () => setOpenRejectReason(true);

  const handleClose = () => setOpenRejectReason(false);
  
  const routerSectionManagementDetails = (sid = 0) => navigate("/section-management-Details/" + sid);
  
  const handlePageChange = (event, value) => {
    page = value;
    setPage(page);
    getAllSectionManager();
  };

  const changeOrderBy = (value, order) => {
    if (order === "asc") setOrder_type("asc");
    else if (order === "desc") setOrder_type("desc");
    setOrder_by(value);
  };

  const getAllSectionManager = async () => {
    const query = new URLSearchParams({
      page: page,
      limit: limit,
      search: search ? search : "",
      order_by,
      order_type,
    }).toString();
    dispatch(changeLoader(true));
    const response = await services?.sectionManager?.GET_SECTIONS(query);
    if (response?.data?.ack == 1) {
      setAllManagers([]);
      count = 0;
      setCount(0);
      if (response?.data?.data?.count > 0) {
        setAllManagers(response?.data?.data?.rows);
        count = Math.ceil(response?.data?.data?.count / limit);
        setCount(count);
      }
    }
    dispatch(changeLoader(false));
  };

  const getCategories = async () => {
    const query = new URLSearchParams({
      businessTypeId: constant.BALADI_STORE_ID,
      //parent_id: 0,
    }).toString();
    let catList = (await services.Category.GET_CATEGORIES(query)).data
      .categories;
    if (catList) {
      setScategories(catList);
    }
  };

  const getAllUsers = async () => {
    dispatch(changeLoader(true));
    const response = await services?.manageUser?.GET_CUSTOMERS(1, 500, "", "", "", "", "");
    if (response?.data?.ack == 1) {
      let rowdata = response?.data?.picker?.rows;
      if (typeof rowdata !== "undefined") { setAllUsers(rowdata); }
    }
    dispatch(changeLoader(false));
  };

  const renderCategories = (items) => {
    let categoriesname = [];
    if (items) {
      items = items.split(",");
      items.map((itemss) => {
        let cats = categories.filter((item) => {
          if (item?.id == itemss) {
            return item;
          }
        });
        if (cats.length) {
          categoriesname.push(
            getdataByKey(cats[0]?.category_locales, "en", "name")
          );
        }
      });
      return categoriesname.length ? categoriesname.join(", ") : "";
    }
    return "";
  };

  const delete_section = async (id) => {
    dispatch(changeLoader(true));
    const response = await services?.sectionManager?.DELETE_SECTION(id);
    if (response?.data?.ack == 1) {
      getAllSectionManager();
    }
    dispatch(changeLoader(false));
  };

  const renderUsers = (items, type = "picker") => {
    let name = [];
    if (items) {
      items=items.split(',');
      items.map(function (item) {
        if (item) {
          let users = allUsers.filter((itemss) => {
            if (itemss?.id == item && itemss?.role == type) {
              return item;
            }
          });
          if (users.length > 0) {
            users[0].first_name
              ? name.push(
                  users[0].first_name.charAt(0).toUpperCase() +
                    users[0].first_name.slice(1)
                )
              : "";
          }
        }
      });
    }
    return name.length ? name.join(", ") : "";
  };
  
  useEffect(() => {
    page = 1;
    setPage(page);
    const getData = setTimeout(() => {
      getAllSectionManager();
    }, 500);
    return () => clearTimeout(getData);
  }, [search, order_by, order_type]);

  useEffect(() => { getAllUsers(); }, []);

  useEffect(() => {
    if (categories.length == 0) { getCategories(); }
  }, []);

  useEffect(() => {getAllSectionManager();}, [categories]);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanCreate(permission?.sectionManagement.create === 1)
      setCanEdit(permission?.sectionManagement.edit === 1)
      setCanDelete(permission?.sectionManagement.delete === 1)
    } else {
      setCanCreate(true)
      setCanEdit(true)
      setCanDelete(true)
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {/* <Alert severity="error">Store Request Reject </Alert> */}

          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>Section Management List</Typography>
              <div className="right-filter">
                <TextField
                  id="outlined-size-small" size="small" placeholder="Search.." value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon sx={{ marginRight: "3px" }} />,
                    endAdornment: search ? <CloseIcon onClick={() => {setSearch("");}} />: null,
                  }}
                />
                { canCreate?
                  <Button
                  variant="contained" size="medius" style={{ width: "270px" }}
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={(e) => {routerSectionManagementDetails(0);}}
                > Add Section </Button>: null}
              </div>
            </div>
            {allManagers.length > 0?<TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
                        Section Name
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("name", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("name", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Select Pickers
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("user_name", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("user_name", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">Select Categories</div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Select Section Manager
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("user_name", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("user_name", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Qc
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("user_name", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("user_name", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>

                    { canEdit || canDelete?
                      <TableCell className="nowrap" align="center">Action</TableCell>: null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allManagers.map((item, index) => {
                    return (
                      <TableRow style={{ verticalAlign: "top" }}>
                        <TableCell
                          onClick={(e) => {
                            (e) => {
                              routerSectionManagementDetails(item?.id);
                            };
                          }}
                          component="th"
                          scope="row"
                        >
                          {item?.name}
                        </TableCell>
                        <TableCell
                          onClick={(e) => {
                            routerSectionManagementDetails(item?.id);
                          }}
                        >
                          {renderUsers(
                            item?.all_user_ids,
                            "picker"
                          )}
                        </TableCell>
                        <TableCell
                          onClick={(e) => {
                            routerSectionManagementDetails(item?.id);
                          }}
                        >
                          {renderCategories(item?.allcategories_id)}
                        </TableCell>
                        <TableCell
                          onClick={(e) => {
                            routerSectionManagementDetails(item?.id);
                          }}
                        >
                          {renderUsers(
                            item?.all_user_ids,
                            "section_manager"
                          )}
                        </TableCell>
                        <TableCell
                          onClick={(e) => {
                            routerSectionManagementDetails(item?.id);
                          }}
                        >
                          {renderUsers(
                            item?.all_user_ids,
                            "qc"
                          )}
                        </TableCell>

                        { canEdit || canDelete?
                          <TableCell align="center" className="nowrap">
                          {canEdit?
                          <Tooltip title="Edit" placement="top" sx={{ marginRight: 1 }} >
                            <BorderColorIcon
                              color="action" fontSize="small"
                              onClick={(e) => routerSectionManagementDetails(item?.id) }
                            />
                          </Tooltip>: null}
                          {/* <Tooltip title="Delete" placement="top">
                            <DeleteForever
                              color="error"
                              onClick={(e) => {
                                delete_section(item?.id);
                              }}
                              fontSize="small"
                            />
                          </Tooltip> */}
                        </TableCell>: null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>: <NoDataFound
              text={"No Section Found"}
            />}
            {count > 1 && (
              <Pagination
                count={count}
                color="primary"
                page={page}
                onChange={handlePageChange}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Dialog open={openRejectReason} onClose={handleClose}>
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Reject Reason
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField required label="Reason" multiline rows={2} />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
