import * as React from 'react';
import {
  Button, Box, InputLabel, MenuItem, FormControl, Select, Dialog, DialogContent, Autocomplete, TextField, Chip
} from "@mui/material";
import { Boxed } from "../Styles-Elements/Box";
import modalClose from "../assets/images/structure/close-black.svg";
import GlobalService from "../services/GlobalService";
import { API } from "../pages/ENUM";
import { getdataByKey } from "../pages/helper/custom";
import { useDispatch } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import { changeSnackbar } from "../redux/reducers/snackbar";
import { Link } from 'react-router-dom';


const ChooseCategories = ({ handleClose, categoryLevel, categories, businessTypeId, storeId, getList, availableCategories }) => {

  const dispatch = useDispatch();
  const [category, setCategory] = React.useState('');
  const [subcategory, setSubCategory] = React.useState('');
  const [subsubcategory, setSubSubCategory] = React.useState('');
  const [subCategoryList, setSubCategoryList] = React.useState([]);
  const [subSubCategoryList, setSubSubCategoryList] = React.useState([]);
  let [categoryError, setCategoryError] = React.useState('');
  let [subcategoryError, setSubCategoryError] = React.useState('');
  let [subsubcategoryError, setSubSubCategoryError] = React.useState('');
  const [showCategoryDropdown, setShowCategoryDropdown] = React.useState(false);
  const [searchCategory, setSearchCategory] = React.useState('');

  const handleChange1 = async (categoryId) => {
    if (categoryId) {
      setCategory(categoryId);
      const CategoryResponse = await GlobalService(API.GET_ALL_PARENT_CATEGORIES(businessTypeId, categoryId));
      setSubCategoryList(CategoryResponse.categories.map((item) => {
        return {
          id: item.id,
          name: getdataByKey(item.category_locales, 'en', 'name'),
        }
      }));
      setCategoryError('');
    } else {
      setCategory('');
      setSubCategoryList([]);
    }
    setSubCategory('');
    setSubSubCategory('');
    setSubSubCategoryList([]);
  };

  const handleChange2 = async (event) => {
    if (event.target.value) {
      setSubCategory(event.target.value);
      const SubCategoryResponse = await GlobalService(API.GET_ALL_SUB_SUB_CATEGORIES(event.target.value));
      setSubSubCategoryList(SubCategoryResponse.categories.map((item) => {
        return {
          id: item.id,
          name: getdataByKey(item.category_locales, 'en', 'name'),
        }
      }))
      setSubCategoryError('');
    } else {
      setSubCategory('')
    }
    setSubSubCategory('');
  };

  const handleChange3 = (event) => {
    setSubSubCategory(event.target.value);
    setSubSubCategoryError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(changeLoader(true));
    let isValid = true;
    let id = '';
    switch (categoryLevel) {
      case 1:

        if (category === '') {
          isValid = false;
          categoryError = 'Please select category';
          setCategoryError(categoryError);
        }
        id = category;
        break;
      case 2:
        if (category === '') {
          isValid = false;
          categoryError = 'Please select category';
          setCategoryError(categoryError);
        }
        if (subcategory === '') {
          isValid = false;
          subcategoryError = 'Please select sub category';
          setSubCategoryError(subcategoryError);
        }
        id = subcategory;
        break;
      case 3:
        if (category === '') {
          isValid = false;
          categoryError = 'Please select category';
          setCategoryError(categoryError);
        }
        if (subcategory === '') {
          isValid = false;
          subcategoryError = 'Please select sub category';
          setSubCategoryError(subcategoryError);
        }
        if (subsubcategory === '') {
          isValid = false;
          subsubcategoryError = 'Please select sub sub category';
          setSubSubCategoryError(subsubcategoryError);
        }
        id = subsubcategory;
        break;
      default:
        break;
    }
    if (isValid) {
      const data = {
        storeId: storeId,
        categoryId: id,
      }
      const response = await GlobalService(API.GET_ADD_CATEGORY_TO_STORE, data, 'post');
      if (response.ack === 1) {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: response?.msg,
            state: "success",
          })
        );
        dispatch(changeLoader(false));
        getList();
      } else {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: response?.msg,
            state: "error",
          })
        );
        dispatch(changeLoader(false));
      }
    } else {
      dispatch(changeLoader(false));
    }
  };

  return (
    <>
      <Dialog open={true} onClose={handleClose} className="main-modal theme-dialog maxwidth-430">
        <DialogContent>
          <Boxed display={'flex'} alignItems={'center'} justifyContent={'space-between'} style={{ marginBottom: "16px" }}>
            <h2 className="h4" style={{ marginBottom: "8px" }}>Select Categories</h2>
            <img src={modalClose} alt="..." style={{width: '24px', height: '24px'}} onClick={() => handleClose() } className="modal-close"/>
          </Boxed>
          <Boxed>
            <Box mt={'12px'}>
              <FormControl fullWidth>
                <TextField
                  label="Select Category" placeholder="Search Category" autoComplete='off' value={searchCategory}
                  onClick={() => setShowCategoryDropdown(!showCategoryDropdown) }
                  onChange={(e) =>{ setSearchCategory(e.target.value); setShowCategoryDropdown(true); }}
                />
                {showCategoryDropdown && 
                 <ul className="show-hide-div">
                  {categories.map((item, index) => {
                    if (item.title.toLowerCase().includes(searchCategory.toLowerCase())) {
                      return <li key={`category-${index}`}
                      onClick={() => { handleChange1(item.id); setShowCategoryDropdown(false); }}
                      >{item.title}</li>
                    }
                  })}
                 </ul>}
                  {categories.find((item) => item.id === category) ?
                  <Chip
                    style={{ marginTop: '10px', alignSelf: 'flex-start'}}
                    label={categories.find((item) => item.id === category).title}
                    onDelete={() => {
                      setCategory('');
                      setSubCategoryList([]);
                      setSubCategory('');
                      setSubSubCategoryList([]);
                      setSubSubCategory('');
                    }}
                  />: null}
                {/* <Autocomplete
                  id="combo-box-demo"
                  options={categories}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, value) => handleChange1(value?.id) }
                  renderInput={(params) => <TextField {...params} label="Category" />}
                /> */}
                <span className='redspan'>{categoryError}</span>
              </FormControl>
            </Box>
            {categoryLevel > 1?<Box mt={'20px'}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sub Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subcategory}
                  label="Sub Category"
                  onChange={handleChange2}
                >
                  {subCategoryList? subCategoryList.map((item, index) => 
                  <MenuItem key={`sub-${index}`} value={item.id}>{item.name}</MenuItem>): null}
                </Select>
                <span className='redspan'>{subcategoryError}</span>
              </FormControl>
            </Box>: null}
            {categoryLevel === 3?<Box mt={'20px'}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sub Sub Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subsubcategory}
                  label="Sub Sub Category"
                  onChange={handleChange3}
                >
                  {subSubCategoryList? subSubCategoryList.map((item, index) => 
                  <MenuItem key={`sub-${index}`} value={item.id}>{item.name}</MenuItem>): null}
                </Select>
                <span className='redspan'>{subsubcategoryError}</span>
              </FormControl>
            </Box>: null}
          </Boxed>
          <Boxed textAlign="center" display={"flex"} justifyContent={"space-between"} mt={'20px'}>
            <Link size="medium" variant="text" color="primary" to={`/business-category?business_type=${businessTypeId}`}>Add New Category</Link>
            <Button variant="contained" size="medius" color="error" type='button' onClick={handleSubmit}>Save</Button>
          </Boxed>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ChooseCategories;