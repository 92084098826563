import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import modalClose from "../../../assets/images/structure/close-modal.png";
import "./AddProduct.scss";
import { getdataByKey } from "../../helper/custom";

import {
  Button,
  Typography,
  TextField,
  Grid,
  Divider,
  Alert,
  Chip,
  Box,
  Dialog,
  DialogContent,
} from "@mui/material";
import Aside from "../../layout/aside/Aside";
import { useState, useEffect } from "react";
import Header from "../../layout/header/Header";
import services from "../../../services";
import DetailBox from "./DetailBox";
import UomDetailBox from "./UomDetailBox";
import { currentPermission } from "../../../redux/reducers/permission";
import { useSelector } from "react-redux";

export default function ProductRequestDetail() {

  const navigate = useNavigate();
  const { product_id } = useParams();
  const permission = useSelector(currentPermission);
  const [searchParams] = useSearchParams();
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);
  let [categories, setCategories] = useState([]);
  let [storeList, setStoreList] = useState([]);
  const [toaster, setToaster] = useState({
    visibilty: false,
    status: "",
    text: "",
  });
  let [tag, setTag] = useState([]);
  const [productTitle, setProductTitle] = useState({
    val: "",
    err: "please enter product title",
    isValid: false,
  });
  const [productTitleArabic, setProductTitleArabic] = useState({
    val: "",
    err: "please enter arabic product title",
    isValid: false,
  });
  const [price, setPrice] = useState({
    val: "",
    err: "please enter product price",
    isValid: false,
  });
  let [store, setStore] = useState({
    val: "",
    err: "please select store",
    isValid: false,
  });
  const [paragraph, setParagraph] = useState({
    val: "",
    err: "please enter product description",
    isValid: false,
  });
  const [paragraphArabic, setParagraphArabic] = useState({
    val: "",
    err: "please enter arabic product description",
    isValid: false,
  });
  const [itemCode, setItemCode] = useState({
    val: "",
    err: "please enter product item code",
    isValid: false,
  });
  let [uoms, setUoms] = useState([]);
  let [uom, setUom] = useState(null);
  let [editProductData, setEditProductData] = useState(null);
  const [stock, setStock] = useState(true);
  const [unitData, setUnitData] = useState([]);
  let [categoryData, setCategoryData] = useState([]);
  let [subCategoryList, setSubCategoryList] = useState([]);
  let [subSubCategoryList, setSubSubCategoryList] = useState([]);
  const [category, setCategory] = useState({
    val: "",
    err: "please select category",
    isValid: false,
  });
  const [subCategory, setSubCategory] = useState({
    val: "",
    err: "please select sub category",
    isValid: false,
  });
  const [subSubCategory, setSubSubCategory] = useState({
    val: "",
    err: "please select sub sub category",
    isValid: false,
  });
  let [rejectPopup, setRejectPopup] = useState(false);
  let [rejectReason, setRejectReason] = useState("");

  const routerProductListing = () => {
    const path = `/product-request?${searchParams.toString()}`;
    navigate(path);
  };

  const handleApprove = async (id) => {
    try {
      const response = await services.Products.UPDATE_STATUS_APPROVE(id, {
        status: "approve",
      });
      if (response?.data?.ack) {
        setToaster({
          status: "success",
          text: `Product Request approved`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({
            status: "",
            text: ``,
            visibilty: false,
          });
        }, 2500);
        routerProductListing();
      }
    } catch (error) {
      setToaster({
        status: "error",
        text: `${error}`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({
          status: "",
          text: ``,
          visibilty: false,
        });
      }, 2500);
    }
  };

  const handleRejection = async () => {
    try {
      const response = await services.Products.UPDATE_STATUS_APPROVE(
        editProductData?.id,
        {
          status: "reject",
          reason_of_reject: rejectReason,
        }
      );
      if (response?.data?.ack) {
        setToaster({
          status: "success",
          text: `Product Request Rejected`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 6000);
        setRejectPopup(false);
        routerProductListing();
      }
    } catch (error) {
      setToaster({
        status: "error",
        text: `${error}`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({ ...toaster, visibilty: false });
      }, 2500);
      setRejectPopup(false);
    }
  };

  const handleCategory = (id) => {
    setCategory({
      val: id,
      err: "",
      isValid: true,
    });
    if (store?.data?.business_type?.category_level > 1) {
      setSubCategory({
        val: "",
        err: "please select sub category",
        isValid: false,
      });
      setSubSubCategory({
        val: "",
        err: "please select sub sub category",
        isValid: false,
      });
      getSubCategory(id);
      setSubSubCategoryList([]);
    }
  };

  const getUnit = async () => {
    try {
      const data = (await services.Unit.GET_ALL_UNITS()).data.units.rows;
      if (data) {
        setUnitData(data);
      }
    } catch (error) {
      console.log("Error_In_product_type", error);
    }
  };

  const getAllStores = async () => {
    try {
      const data = (await services.Stores.GET_ALL_STORE()).data.stores;
      if (data?.rows) {
        storeList = data.rows;
        setStoreList(storeList);
        if (product_id) {
          getProductById(product_id);
        }
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const getCategory = async (businessTypeId) => {
    if (businessTypeId && businessTypeId !== "") {
      try {
        const query = new URLSearchParams({
          parent_id: 0,
          businessTypeId,
        }).toString();
        const data = (await services.Category.GET_CATEGORIES(query)).data
          .categories;
        categoryData = data;
        setCategoryData(categoryData);
      } catch (er) {
        console.log(er);
      }
    }
  };

  const getSubCategory = async (id) => {
    try {
      const query = new URLSearchParams({
        parent_id: id,
      }).toString();

      const data = (await services.Category.GET_CATEGORIES(query)).data
        .categories;
      setSubCategoryList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategoriesForEdit = async () => {
    const business_type_id = storeList.find(
      (item) => item.id === editProductData?.storeId
    )?.business_type?.id;
    getCategory(business_type_id);
    switch (store?.data?.business_type?.category_level) {
      case 1: {
        handleCategory(editProductData?.categoryId);
        break;
      }
      case 2: {
        break;
      }
      case 3: {
        break;
      }
      default:
        break;
    }
  };

  const getProductById = async (id) => {
    try {
      const response = (await services.Products.GET_PRODUCT(id)).data;
      const categoryLevel = response.product.store.business_type.category_level
      categories = []
      response.categories.forEach((element) => {
        const parent = element?.find((item) => item?.parent_id === 0);
        const subCategory = categoryLevel > 1?element?.find((item) => item?.parent_id === parent?.id): null;
        const subSubCategory = categoryLevel > 2?element?.find((item) => item?.parent_id === subCategory?.id): null;
        categories.push({parent, subCategory, subSubCategory})
      });
      setCategories(categories)
      const getData = response.product
      editProductData = getData;
      setEditProductData(getData);
      store = {
        val: getData?.storeId,
        data: storeList.find((item) => item.id === getData?.storeId),
        err: "",
        isValid: true,
      };
      setStore(store);
      setProductTitle({
        val: getdataByKey(getData?.products_locales, "en", "title"),
        err: "please enter product title",
        isValid: true,
      });
      setProductTitleArabic({
        val: getdataByKey(getData?.products_locales, "ar", "title"),
        err: "please enter arabic product title",
        isValid: true,
      });
      setParagraph({
        val: getdataByKey(getData?.products_locales, "en", "description"),
        err: "please enter product description",
        isValid: true,
      });
      setParagraphArabic({
        val: getdataByKey(getData?.products_locales, "ar", "description"),
        err: "please enter arabic product description",
        isValid: true,
      });
      setItemCode({
        val: getData?.itemCode,
        err: "please enter product item code",
        isValid: true,
      });
      setUoms(getData?.uoms);
      tag = getData?.product_tag_relations.map((item, index) => {
        return { name: item?.tag?.tag, index: index };
      });
      setTag(tag);
      getCategoriesForEdit();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanUpdateStatus(
        permission?.productRequest?.actions?.approveReject === 1
      );
    } else {
      setCanUpdateStatus(true);
    }
  }, [permission]);

  useEffect(() => {
    getUnit();
    getAllStores();
  }, []);

  return (
    <Box className="dashboard-parent">
      <Header />
      <Aside />
      <Box className="main-wrapper">
        {toaster.visibilty && (
          <Alert severity={toaster.status}>{toaster.text}</Alert>
        )}
        <Box className="white-box-20-30">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                Product Request
              </Typography>
            </Grid>
            {editProductData ? (
              <Grid item xs={10}>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={3}>
                    <DetailBox
                      type="text"
                      title="Brand Name"
                      value={getdataByKey(
                        editProductData?.store?.stores_locales,
                        "en",
                        "business_name"
                      )}
                      changeValue={""}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailBox
                      type="text"
                      title="Product Title"
                      value={getdataByKey(
                        editProductData?.products_locales,
                        "en",
                        "title"
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailBox
                      type="text"
                      title="Arabic Product Title"
                      value={getdataByKey(
                        editProductData?.products_locales,
                        "ar",
                        "title"
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailBox
                      type="text"
                      title="Item Code"
                      value={"#" + editProductData?.itemCode}
                      changeValue={""}
                    />
                  </Grid>

                  {categories.length > 0 && categories.map((item, index) => 
                    <Grid item xs={12} key={`Category-${index}`}>
                    <DetailBox
                      type="text"
                      title="Category"
                      value={
                        `
                          ${getdataByKey(item?.parent?.category_locales, "en", "name") || item?.parent?.category_locales[0].name}
                          ${item?.subCategory ? ' >> ' + getdataByKey(item?.subCategory?.category_locales, "en", "name") || item?.subCategory?.category_locales[0].name: ''}
                          ${item?.subSubCategory ? ' >> ' + getdataByKey(item?.subSubCategory?.category_locales, "en", "name") || item?.subSubCategory?.category_locales[0].name: ''}
                        `
                      }
                      changeValue={""}
                    />
                  </Grid>)}
                  {/* {editProductData?.store?.business_type?.category_level ===
                    1 && (
                    <Grid item xs={4}>
                      <DetailBox
                        type="text"
                        title="Category"
                        value={
                          getdataByKey(
                            editProductData?.category?.category_locales,
                            "en",
                            "name"
                          ) ||
                          editProductData?.category?.category_locales[0].name
                        }
                        changeValue={""}
                      />
                    </Grid>
                  )}
                  {editProductData?.store?.business_type?.category_level ===
                    2 && (
                    <>
                      <Grid item xs={4}>
                        <DetailBox
                          type="text"
                          title="Category"
                          value={
                            getdataByKey(
                              editProductData?.category?.parent
                                ?.category_locales,
                              "en",
                              "name"
                            ) ||
                            editProductData?.category?.parent
                              ?.category_locales[0].name
                          }
                          changeValue={""}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <DetailBox
                          type="text"
                          title="Sub Category"
                          value={
                            getdataByKey(
                              editProductData?.category?.category_locales,
                              "en",
                              "name"
                            ) ||
                            editProductData?.category?.category_locales[0].name
                          }
                          changeValue={""}
                        />
                      </Grid>
                    </>
                  )}
                  {editProductData?.store?.business_type?.category_level ===
                    3 && (
                    <>
                      <Grid item xs={4}>
                        <DetailBox
                          type="text"
                          title="Category"
                          value={
                            getdataByKey(
                              editProductData?.category?.parent?.parent
                                ?.category_locales,
                              "en",
                              "name"
                            ) ||
                            editProductData?.category?.parent?.parent
                              ?.category_locales[0].name
                          }
                          changeValue={""}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <DetailBox
                          type="text"
                          title="Sub Category"
                          value={
                            getdataByKey(
                              editProductData?.category?.parent
                                ?.category_locales,
                              "en",
                              "name"
                            ) ||
                            editProductData?.category?.parent
                              ?.category_locales[0].name
                          }
                          changeValue={""}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <DetailBox
                          type="text"
                          title="Sub Sub Category"
                          value={
                            getdataByKey(
                              editProductData?.category?.category_locales,
                              "en",
                              "name"
                            ) ||
                            editProductData?.category?.category_locales[0].name
                          }
                          changeValue={""}
                        />
                      </Grid>
                    </>
                  )}
                   */}
                  <Grid item xs={12}>
                    <DetailBox
                      type="html"
                      title="Description"
                      value={getdataByKey(
                        editProductData?.products_locales,
                        "en",
                        "description"
                      )}
                      changeValue={""}
                    />
                  </Grid>
                  {/*arabic paragrapg */}
                  <Grid item xs={12}>
                    <DetailBox
                      type="html"
                      title="Description Arabic"
                      value={getdataByKey(
                        editProductData?.products_locales,
                        "ar",
                        "description"
                      )}
                      changeValue={""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="#999999"
                      component="h6"
                      fontSize={"12px"}
                      fontWeight={500}
                    >
                      Tags
                    </Typography>
                    {editProductData?.product_tag_relations.length
                      ? editProductData?.product_tag_relations.map(
                          (obj, ind) => (
                            <Chip
                              key={`tag-${ind}`}
                              className={`select-chips`}
                              label={obj.tag.tag}
                              style={{ marginRight: "5px" }}
                            />
                          )
                        )
                      : null}
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      mb={2}
                    >
                      <Typography variant="h6" component="h6" fontWeight={500}>
                        Unit of Measurement
                      </Typography>
                    </Box>
                    <div className="table-block mb-30">
                      <div className="table-wrapper">
                        {editProductData?.uoms.map((uom, index) => {
                          if (uom.status !== "pending") {
                            const changed = false;
                            const changedUom = {};
                            return (
                              <span key={`uom-${index}`}>
                                <UomDetailBox
                                  uom={uom}
                                  changedUom={changedUom}
                                  changed={changed}
                                />
                                <Divider />
                              </span>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Divider />
                <div className="bottom-btn-row">
                  <Button
                    type="button"
                    variant="standard"
                    size="large"
                    sx={{ marginRight: "10px" }}
                    onClick={routerProductListing}
                  >
                    Back
                  </Button>
                  {editProductData?.is_approved == 0 && canUpdateStatus ? (
                    <span>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        sx={{ marginRight: "10px" }}
                        onClick={() => handleApprove(editProductData?.id)}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        onClick={() => setRejectPopup(true)}
                      >
                        Reject
                      </Button>
                    </span>
                  ) : null}
                </div>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Box>
      <Dialog open={rejectPopup} onClose={() => setRejectPopup(false)}>
        <img
          src={modalClose}
          alt="..."
          onClick={() => setRejectPopup(false)}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Reject Reason
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Reason"
                  multiline
                  rows={2}
                  onChange={(e) => setRejectReason(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                  onClick={() => handleRejection()}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
