import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import modalClose from "../../../assets/images/structure/close-modal.png";
import constant from "../../../constant";
import {
  Button,
  Typography,
  Grid,
  Divider,
  TextField,
  Chip,
  Box,
  Dialog,
  DialogContent,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { PAYOUT } from "../../../services/Payout";
import { MONTH } from "../../ENUM";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { utcTimeConvert } from "../../helper/custom";
import { getdataByKey } from "../../helper/custom";
import { currentPermission } from "../../../redux/reducers/permission";
let timer = null;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PayoutManagementDetails = () => {
  let index = 0;
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permission = useSelector(currentPermission);
  const id = params.id;
  const [tabValue, setTabValue] = useState(0);
  const [data, setData] = useState({});
  const [cdate, setCdate] = useState({ time: "" });
  const [enableSave, setEnableSave] = useState(false);
  const [ordData, setOrdData] = useState({});
  const [storeData, setStoreData] = useState(null);
  const [bankDetails, setbankDetails] = useState(null);
  const [orderCount, setOrderCount] = useState(0);
  const [bankDetail, setBankDetail] = useState("");
  const [dataToSend, setDataToSend] = useState({
    payout_id: id,
    payout_adjustment_reason: "",
    amount: [],
    actual_payout_amount: 0,
    status: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");
  const [actualAmount, setActualAmount] = useState(0);
  let [canSettlePayout, setCanSettlePayout] = useState(true);
  let [sortBy, setSortBy] = useState("id");
  let [orderBy, setOrderBy] = useState("desc");

  const dates = (d) => {
    let date = new Date(d);
    let month = MONTH[date.getMonth()];
    return `${date.getDate()} ${month} ${date.getFullYear()}`;
  };

  const handleChange = (event, newValue) => setTabValue(newValue);

  const handleClose = () => setIsOpen(false);

  const getTimeZone = () => {
    PAYOUT.GET_SERVER_TIMEZONE(id)
      .then((res) => {
        let response = res.data;
        if (response.ack == 1) {
          setCdate(response);
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };

  const getList = () => {
    PAYOUT.GET_PAYOUT_DETAILS_ID(id, sortBy, orderBy)
      .then((res) => {
        let response = res?.data;
        if (response?.ack == 1) {
          setBankDetail(res?.data?.data?.stores?.payout_detail)
          setData(response?.data);
          setActualAmount(response?.data?.actual_payout_amount);
          setReason(response?.data?.payout_adjustment_reason);
          setOrdData(response?.data);
          setStoreData(response?.data?.stores);
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };

  const sortData = async (key, value) => {
    sortBy = key;
    setSortBy(sortBy);
    orderBy = value;
    setOrderBy(orderBy);
    getList();
  };

  const GetTableCompleteCancelled = ({ sortData, status = "completed" }) => {
    const GetData = ({ obj }) => {
      let find = dataToSend?.amount.find((a) => a.id == obj.id);
      let mainamcount =
        obj?.amount -
        (
          obj?.amount -
          (Number(obj?.expected_payout_amount) + Number(obj?.platform_fee))
        ).toFixed(2);
      return (
        <TableRow>
          <TableCell>#{obj?.order_id}</TableCell>
          <TableCell>{obj?.amount ? mainamcount : "00"}</TableCell>
          {status === "completed" ? (
            <>
              <TableCell>{(obj?.amount - mainamcount).toFixed(2)}</TableCell>
              <TableCell>
                {obj?.platform_fee ? (obj?.platform_fee).toFixed(2) : "00"} (
                {((obj?.platform_fee / mainamcount) * 100).toFixed(2)}%)
              </TableCell>
            </>
          ) : null}
          {status === "completed" ? (
            <TableCell>
              {obj?.expected_payout_amount
                ? (obj?.expected_payout_amount).toFixed(2)
                : "00"}
            </TableCell>
          ) : (
            <TableCell>
              {obj?.actual_payout_amount
                ? (obj?.actual_payout_amount).toFixed(2)
                : "00"}
            </TableCell>
          )}
        </TableRow>
      );
    };

    return (
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="short-div">
                Order ID
                <div className="short-box">
                  <ArrowDropUpIcon
                    onClick={() => sortData("order_id", "desc")}
                  />
                  <ArrowDropDownIcon
                    onClick={() => sortData("order_id", "asc")}
                  />
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div className="short-div">
                Order Total
                <div className="short-box">
                  <ArrowDropUpIcon onClick={() => sortData("amount", "desc")} />
                  <ArrowDropDownIcon
                    onClick={() => sortData("amount", "asc")}
                  />
                </div>
              </div>
            </TableCell>
            {status === "completed" ? (
              <>
                <TableCell>
                  <div className="short-div">
                    Delivery Fee
                    <div className="short-box">
                      <ArrowDropUpIcon
                        onClick={() => sortData("amount", "desc")}
                      />
                      <ArrowDropDownIcon
                        onClick={() => sortData("amount", "asc")}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="short-div">
                    Platform Fee
                    <div className="short-box">
                      <ArrowDropUpIcon
                        onClick={() => sortData("platform_fee", "desc")}
                      />
                      <ArrowDropDownIcon
                        onClick={() => sortData("platform_fee", "asc")}
                      />
                    </div>
                  </div>
                </TableCell>
              </>
            ) : null}
            <TableCell>
              <div className="short-div">
                Payout Amount
                <div className="short-box">
                  <ArrowDropUpIcon
                    onClick={() =>
                      sortData(
                        status === "completed"
                          ? "expected_payout_amount"
                          : "actual_payout_amount",
                        "desc"
                      )
                    }
                  />
                  <ArrowDropDownIcon
                    onClick={() =>
                      sortData(
                        status === "completed"
                          ? "expected_payout_amount"
                          : "actual_payout_amount",
                        "asc"
                      )
                    }
                  />
                </div>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.payouts_items
            ?.filter((a) => a.type == status)
            ?.map((obj) => (
              <GetData obj={obj} />
            ))}
        </TableBody>
      </Table>
    );
  };

  const save = () => {
    //let dateget=utcTimeConvert(data?.expected_payout_date);
    // alert(data);
    //return;
    dispatch(changeLoader(true));

    let data = {
      ...dataToSend,
      amount: JSON.stringify(dataToSend.amount),
      status: "settled",
    };

    if (ordData?.status == "settled" || enableSave == false) {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: "You can not update this payout",
          state: "error",
        })
      );
      return false;
    }

    if (data?.actual_payout_amount == "" || data?.actual_payout_amount == 0) {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: "Please enter a valid amount",
          state: "error",
        })
      );
      return false;
    }

    if (data?.payout_adjustment_reason == "") {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: "Please enter a valid note",
          state: "error",
        })
      );
      return false;
    }

    PAYOUT.UPDATE_PAYOUT_STATUS(data)
      .then((res) => {
        dispatch(changeLoader(false));
        let data = res.data;
        if (data.ack == 1) {
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: data.msg,
              state: "success",
            })
          );
          navigate("/payout-management-listing");
        } else {
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: "Please enter a valid details",
              state: "error",
            })
          );
        }
      })
      .catch((er) => {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: "something went wrong " + er,
            state: "error",
          })
        );
      });
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (data.payouts_items?.length) {
      let a = [];
      let a1 = [];
      let cancelledcount = [];
      let completed = [];
      data.payouts_items?.map((obj, index) => {
        a.push({ id: obj?.id, amount: obj?.actual_payout_amount });
        if (obj?.type == "cancelled") {
          cancelledcount.push(1);
        }
        if (obj?.type == "completed") {
          completed.push(1);
        }
      });
      setOrderCount(tabValue == 1 ? cancelledcount.length : completed.length);
      setDataToSend({
        ...dataToSend,
        amount: a,
        status: data?.status,
        actual_payout_amount: data?.actual_payout_amount,
        payout_adjustment_reason: data?.payout_adjustment_reason,
      });
    }
  }, [data, tabValue]);

  useEffect(() => {
    getTimeZone();
  }, []);

  useEffect(() => {
    if (
      cdate?.time &&
      typeof data?.expected_payout_date !== "undefined" &&
      data?.expected_payout_date
    ) {
      let get_convert_date = utcTimeConvert(cdate?.time);
      let get_convert_date1 = utcTimeConvert(data?.expected_payout_date);
      if (get_convert_date?.date >= get_convert_date1?.date) {
        setEnableSave(true);
      }
    }
  }, [data, cdate]);

  useEffect(() => {
    setStatus(dataToSend?.status);
    setReason(dataToSend?.payout_adjustment_reason);
    setActualAmount(dataToSend?.actual_payout_amount);
  }, [dataToSend]);

  useEffect(() => {
    if (storeData != null) {
      if (data?.status != "settled") {
        setbankDetails(storeData?.payout_detail);
      } else {
        if (data?.bank_details != null) {
          setbankDetails(JSON.parse(data?.bank_details));
        }
      }
    }
  }, [storeData]);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanSettlePayout(
        permission?.payoutManagement?.actions.settlePayout === 1
      );
    } else {
      setCanSettlePayout(true);
    }
  }, [permission]);

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Payout Management Details
                </Typography>
                <div
                  className="white-box-new-shadow"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={
                        constant?.IMG_BASE_URL +
                        "" +
                        data?.stores?.business_logo
                      }
                    ></img>
                    <div className="relative">
                      <h6 className="h6">
                        {getdataByKey(
                          data?.stores?.stores_locales,
                          "en",
                          "business_name"
                        )}
                      </h6>
                      <p className="p2">{data?.stores?.business_address}</p>
                    </div>
                  </div>
                  <Typography
                    variant="body"
                    component="body"
                    fontWeight={400}
                    onClick={handleClickOpen2}
                    style={{ cursor: "pointer" }}
                  >
                    Bank Account Details
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="">
                  <TableContainer
                    component={Paper}
                    className="overflow-auto-custome"
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sales Period</TableCell>
                          <TableCell>Expected Payout Date</TableCell>
                          <TableCell>Actual Payout Date</TableCell>
                          <TableCell>Sales Amount</TableCell>
                          <TableCell>
                            Expected Payout <br></br> Amount
                          </TableCell>
                          <TableCell>
                            Actual Payout <br></br>Amount
                          </TableCell>
                          <TableCell>Payout Status</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={index}>
                          <TableCell>
                            {data.period_start_date
                              ? dates(data.period_start_date)
                              : null}{" "}
                            -{" "}
                            {data.period_end_date
                              ? dates(data.period_end_date)
                              : null}
                          </TableCell>
                          <TableCell>
                            {data.expected_payout_date
                              ? dates(data.expected_payout_date)
                              : null}
                          </TableCell>
                          <TableCell>
                            {data.actual_payout_date
                              ? dates(data.actual_payout_date)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            QAR{" "}
                            {data?.sales_amount
                              ? (data?.sales_amount).toFixed(2)
                              : "00"}
                          </TableCell>
                          <TableCell align="center">
                            QAR{" "}
                            {data?.expected_payout_amount
                              ? (data?.expected_payout_amount).toFixed(2)
                              : "00"}{" "}
                          </TableCell>
                          <TableCell align="center">
                            QAR{" "}
                            {data?.actual_payout_amount
                              ? (data?.actual_payout_amount).toFixed(2)
                              : "00"}{" "}
                            {data?.status == "settled" ? (
                              <div>
                                <span
                                  className="pay_info"
                                  onClick={(e) => {
                                    setIsOpen(true);
                                  }}
                                >
                                  Payment Info
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <TableCell>
                              <Chip label={data?.status} />
                            </TableCell>
                          </TableCell>
                          <TableCell>
                            {data?.status != "settled" && canSettlePayout ? (
                              <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                style={{
                                  maxWidth: "1120px",
                                  minWidth: "150px",
                                  textTransform: "capitalize",
                                }}
                                onClick={(e) => {
                                  if (storeData?.payout_detail == null) {
                                    dispatch(changeLoader(false));
                                    dispatch(
                                      changeSnackbar({
                                        isOpen: true,
                                        message: "Bank account not added",
                                        state: "error",
                                      })
                                    );
                                  } else {
                                    setIsOpen(true);
                                  }
                                }}
                              >
                                Settle Payout
                              </Button>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="tabs-wrap" style={{ marginTop: "20px" }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      className="border-bottom"
                      aria-label="basic tabs example"
                    >
                      <Tab label="Completed Order" {...a11yProps(0)} />
                      <Tab label="Cancelled Order" {...a11yProps(1)} />
                      <span className="total-order-text">
                        Total Orders - {orderCount}
                      </span>
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                      <TableContainer component={Paper}>
                        <GetTableCompleteCancelled
                          status="completed"
                          sortData={sortData}
                        />
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      <TableContainer component={Paper}>
                        <GetTableCompleteCancelled
                          status="cancelled"
                          sortData={sortData}
                        />
                      </TableContainer>
                    </TabPanel>
                  </div>
                </div>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <div className="bottom-btn-row">
                  <Button
                    variant="standard"
                    size="large"
                    sx={{ marginRight: "10px" }}
                    onClick={() => {
                      navigate("/payout-management-listing");
                    }}
                  >
                    Back
                  </Button>
                  {/* {enableSave?(
                    <Button
                    onClick={save}
                    variant="contained"
                    size="large"
                    color="primary" 
                   >
                    Save
                  </Button>
                  ):
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled 
                   >
                    Save
                  </Button>
                  }  */}
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Dialog open={open2} onClose={handleClose2}>
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose2}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Payout Detail
          </Typography>
          <div className="label-row" style={{ gap: "10px" }}>
            <div className="form-group">
              <TextField
                required
                label="Beneficiory Name"
                variant="outlined"
                value={bankDetail?.beneficiaryName}
              />
            </div>
            <div className="form-group">
              <TextField
                required
                label="Bank Account Number"
                variant="outlined"
                value={bankDetail?.bankAccountNumber}
              />
            </div>
          </div>
          <div className="label-row" style={{ gap: "10px" }}>
            <div className="form-group">
              <TextField
                required
                label="IBAN"
                variant="outlined"
                value={bankDetail?.iban}
              />
            </div>
            <div className="form-group">
              <TextField
                required
                label="Swift Code"
                variant="outlined"
                value={bankDetail?.swiftCode}
              />
            </div>
          </div>
          {/* <div className="">
            <Button
              variant="contained"
              size="large"
              sx={{ width: 1, marginTop: "4px" }}
            >Save</Button>
          </div> */}
        </DialogContent>
      </Dialog>

      {bankDetails != null && storeData != null && data?.status != "settled" ? (
        <Dialog open={isOpen} onClose={handleClose}>
          <img
            src={modalClose}
            alt="..."
            onClick={handleClose}
            className="modal-close"
          />
          <DialogContent>
            <Typography variant="h6" component="h6" mb={3}>
              Settle Payout
            </Typography>

            {/* <form> */}
            <p className="new-modal-hed">
              {storeData?.stores_locales[0].business_name}
            </p>
            <p className="p2">{storeData?.business_address}</p>
            <p className="new-modal-hed">Bank detail :</p>
            <div className="label-row">
              <p className="label-left">Beneficiory Name </p>
              <p className="label-right">: {bankDetails?.beneficiaryName}</p>
            </div>
            <div className="label-row">
              <p className="label-left">Account Number</p>
              <p className="label-right">: {bankDetails?.bankAccountNumber}</p>
            </div>
            <div className="label-row">
              <p className="label-left">IBAN </p>
              <p className="label-right">: {bankDetails?.iban}</p>
            </div>
            <div className="label-row">
              <p className="label-left">Swift Code </p>
              <p className="label-right">: {bankDetails?.swiftCode}</p>
            </div>
            <div className="mt-4">
              <div className="form-group">
                <TextField
                  required
                  label="Payout Amount "
                  onChange={(e) => {
                    let thidval = e.target.value.replace(/[^0-9\.]/g, "");
                    if (thidval) {
                      if (thidval.split(".").length > 2) {
                        thidval = "";
                      }
                    }
                    setDataToSend({
                      ...dataToSend,
                      actual_payout_amount: thidval,
                    });
                    setActualAmount(thidval);
                  }}
                  value={actualAmount}
                  variant="outlined"
                />
              </div>
              <p className="new-modal-hed mb-3">
                Add any notes and transaction details for the store.
              </p>
              <div className="form-group">
                <TextField
                  className="modal-arabic-unit"
                  required
                  label="Enter Detail here"
                  multiline
                  rows="2"
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      setDataToSend({
                        ...dataToSend,
                        payout_adjustment_reason: e.target.value,
                      });
                    }, 100);
                  }}
                  variant="outlined"
                />
              </div>
              <p className="note-text">
                <b>Note : </b>Please provide all the transaction details to the
                store once you have made the transfer for the payout amount and
                confirm this settlement so that store can track the. transaction
                for the payout{" "}
              </p>

              <Button
                variant="contained"
                size="large"
                sx={{ width: 1, marginTop: "16px" }}
                type="submit"
                onClick={save}
              >
                Comfirm
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      ) : null}

      {bankDetails != null && storeData != null && data?.status == "settled" ? (
        <Dialog open={isOpen} onClose={handleClose}>
          <img
            src={modalClose}
            alt="..."
            onClick={handleClose}
            className="modal-close"
          />
          <DialogContent>
            <Typography variant="h6" component="h6" mb={3}>
              Transaction Detail
            </Typography>

            {/* <form> */}
            <p className="new-modal-hed">
              {storeData?.stores_locales[0].business_name}
            </p>
            <p className="p2">{storeData?.business_address}</p>
            <p className="new-modal-hed">Bank detail :</p>
            <div className="label-row">
              <p className="label-left">Beneficiory Name </p>
              <p className="label-right">: {bankDetails?.beneficiaryName}</p>
            </div>
            <div className="label-row">
              <p className="label-left">Account Number</p>
              <p className="label-right">: {bankDetails?.bankAccountNumber}</p>
            </div>
            <div className="label-row">
              <p className="label-left">IBAN </p>
              <p className="label-right">: {bankDetails?.iban}</p>
            </div>
            <div className="label-row">
              <p className="label-left">Swift Code </p>
              <p className="label-right">: {bankDetails?.swiftCode}</p>
            </div>
            <div className="mt-4">
              <p className="new-modal-hed">Payment Amount</p>
              <div className="form-group">
                <p>{actualAmount ? actualAmount.toFixed() : "00"}</p>
              </div>
              <p className="new-modal-hed">
                Add any notes and transaction details for the store.
              </p>
              <div className="form-group">
                <p>{reason}</p>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
};
export default PayoutManagementDetails;
