import { createSlice } from "@reduxjs/toolkit";

export const driver = createSlice({
  name: "driver",
  initialState: {
    fleet_id: null,
  },
  reducers: {
    changeDriverId: (state, action) => {
      state.fleet_id = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeDriverId } = driver.actions;
export const currentDriverId = (state) => state.driver.fleet_id;
export default driver.reducer;
