import { useNavigate } from "react-router-dom";
import {
  Typography, TextField, Button, Switch, Grid, Tooltip, Box, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Pagination, Dialog, DialogContent,
} from "@mui/material";
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@material-ui/icons/Close";
import modalClose from "../../../assets/images/structure/close-modal.png";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import GlobalService from "../../../services/GlobalService";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import { useEffect, useState } from "react";
import { changeLoader } from "../../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../ENUM";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { encode } from "base-64";
import { currentPermission } from "../../../redux/reducers/permission";
import {getdataByKey} from "../../helper/custom";

export default function CuisineCategoryListing() {

  const permission = useSelector(currentPermission);
  let [canAdd, setCanAdd] = useState(true);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canView, setCanView] = useState(true);
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: "" });
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [isDelete, setIsDelete] = useState(false);
  const [id, setId] = useState(0);
  let [search, setSearch] = useState("");
  let [page, setPage] = useState(1);

  const callApi = () => {
    dispatch(changeLoader(true));
    // , pages: page
    GlobalService(
      API.CUISINE.GET_ALL_CUISINE,
      {
        search: search,
        limit: 1000,
      },
      "get"
    ).then((da) => {
      if (da.ack == 1) {
        setData(da.data);
        setCount(da.count);
      }
    }).catch((err) => {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: err.response.body.msg,
          state: "error",
        })
      );
    }).finally(() => {
      dispatch(changeLoader(false));
    });
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      page = 1;
      setPage(page);
      callApi();
    }, 500);
    return () => clearTimeout(getData);
  }, [search]);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanAdd(permission?.cuisine?.create === 1)
      setCanEdit(permission?.cuisine?.edit === 1)
      setCanDelete(permission?.cuisine?.delete === 1)
      setCanView(permission?.cuisine?.read === 1)
      setCanUpdateStatus(permission?.cuisine?.actions?.status === 1)
    } else {
      setCanEdit(true)
      setCanAdd(true)
      setCanDelete(true)
      setCanView(true)
      setCanUpdateStatus(true)
    }
  }, [permission]);

  const DeleteModel = ({ handleClose, submit }) => {
    return (
      <>
        <Dialog open={true} onClose={handleClose} className="alert-delete">
          <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
          <DialogContent>
            <Typography variant="h6" component="h6" textAlign={"left"} mb={1}>
              Delete
            </Typography>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h6" fontSize={"16px"} fontWeight={500} mb={0} textAlign={"left"}>
                    Are you sure you want to delete this Cuisine?
                  </Typography>
                </Grid>
                <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"end"}>
                  <Button variant="contained" size="medium" sx={{ marginTop: "16px" }} onClick={handleClose}>
                    No
                  </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{ marginTop: "16px", marginLeft: "16px" }}
                    onClick={submit}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const routerAddCuisine = () => {
    window.location.href = "/add-cuisine";
    // navigate(`/add-cuisine`);
  };

  const deleteApi = (id) => {
    dispatch(changeLoader(true));
    GlobalService(API.CUISINE.DELETE_CATEGORY(id), {}, "delete")
      .then((data) => {
        if (data.ack == 1) {
          callApi();
        }
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: data?.msg,
            state: data?.ack == 1 ? "success" : "error",
          })
        );
        setId(0);
      })
      .catch((err) => {
        let msg = err.response.body.msg;
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: msg,
            state: "error",
          })
        );
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  };

  const updateStatus = (id, status) => {
    GlobalService(
      API.CUISINE.UPDATE_CATEGORY_STATUS,
      {
        cuisineCategoryId: id,
        status: status,
      },
      "put"
    )
      .then((data) => {})
      .catch((err) => {});
  };

  const ShowData = ({ data, canEdit, canDelete, canView, canUpdateStatus }) => {
    
    const [status, setStatus] = useState(data?.status == "active" ? true : false);
    let eng = getdataByKey(data?.cuisine_category_locales, 'en', 'name');
    let ar = getdataByKey(data?.cuisine_category_locales, 'ar', 'name');

    return (
      <>
        <TableRow>
          <TableCell>{data?.name}</TableCell>
          <TableCell component="th" scope="row">{eng}</TableCell>
          <TableCell>{ar}</TableCell>
          <TableCell align="center" className="nowrap">
            <Switch
              checked={status}
              onChange={(e) => {
                let check = e.target.checked;
                setStatus(check);
                updateStatus(data?.id, check ? "active" : "inactive");
              }}
              disabled={!canUpdateStatus}
            />
          </TableCell>
          { canView && <TableCell
            style={{cursor: "pointer"}}
            onClick={() => {
              sessionStorage.setItem("category-name", data?.name);
              navigate(`/cuisine-listing/${data?.id}`);
            }}
          >View Cuisines</TableCell>}
          { canEdit || canDelete ?
          <TableCell align="center" className="nowrap">
            {canEdit ? <Tooltip title="Edit" placement="top" sx={{ marginRight: 1 }}>
              <BorderColorIcon
                color="action"
                fontSize="small"
                onClick={() => {
                  let datas = encodeURIComponent(encode(JSON.stringify({id: data?.id,})));
                  sessionStorage.setItem("cuisine-category", JSON.stringify({
                    name_en: eng, name_ar: ar, id: data?.id,
                  }));
                  navigate(`/add-cuisine-category/${datas}`);
                }}
              />
            </Tooltip>: null}
            {canDelete ? <Tooltip title="Delete" placement="top">
              <DeleteForever
                onClick={() => { setId(data.id); setIsDelete(true); }}
                color="error" fontSize="small"
              />
            </Tooltip>: null}
          </TableCell>: null}
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
                Cuisine Category Listing
              </Typography>
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    placeholder="Search Cuisine"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value); }}
                    InputProps={{
                      startAdornment: <SearchIcon sx={{ marginRight: "3px" }} />,
                      endAdornment: search ?  <CloseIcon onClick={() => { setSearch(""); }} />: null
                    }}
                  />
                </div>
                { canAdd?
                  <>
                  <Button
                    variant="contained"
                    size="medius"
                    onClick={routerAddCuisine}
                    startIcon={<AddCircleOutlineIcon />}
                  > Add Cuisine </Button>
                  <Button
                    variant="contained"
                    size="medius"
                    onClick={() => {navigate(`/add-cuisine-category`);}}
                    startIcon={<AddCircleOutlineIcon />}
                  > Add Cuisine Category </Button>
                </>: null}
              </div>
            </div>
            {data.length ? <>
              <TableContainer className="height-fix">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell> Category Name </TableCell>
                      <TableCell>English Name</TableCell>
                      <TableCell>Arabic Name</TableCell>
                      <TableCell align="center" className="nowrap">Status</TableCell>
                      { canView?
                        <TableCell>View Cuisine</TableCell>: null}
                      { canEdit || canDelete?
                        <TableCell className="nowrap" align="center">Action</TableCell>: null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((obj, ind) => <ShowData
                      key={ind} data={obj} canAdd={canAdd} canEdit={canEdit}
                      canDelete={canDelete} canView={canView}
                      canUpdateStatus={canUpdateStatus}
                    />)}
                  </TableBody>
                </Table>
              </TableContainer>
              {count > 1? <Pagination
                count={count} page={page} color="primary"
                onChange={(event, value) => {
                  page = value;
                  setPage(page);
                  callApi()
                }}
              />: null}
            </>: <NoDataFound text={"No Cuisine Found"} />}
          </Box>
        </Box>
      </Box>
      {isDelete ?
        <DeleteModel
          handleClose={() => setIsDelete(false)}
          submit={() => {
            setIsDelete(false);
            deleteApi(id);
          }}
        /> : null}
    </>
  );
}
