import { X_API_KEY } from "./pages/ENUM";
// const baseUrl = "https://localhost:3100";
const baseUrl = "https://devapi.baladiexpress.com";
// const baseUrl = "https://stagapi.baladiexpress.com";
// const baseUrl = "https://api.baladiexpress.com";

export default {
  BASE_URL: baseUrl + "/v7",
  X_API_KEY: X_API_KEY,
  IMG_BASE_URL: baseUrl,
  SOCKET_URL: baseUrl,
  BALADI_STORE_ID: 2,
  ADMIN_ID: 1,
};
