import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Typography,
  Button,
  Switch,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Tooltip,
  Alert,
  DialogContent,
  Dialog,
} from "@mui/material";
import modalClose from "../../../assets/images/structure/close-modal.png";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import services from "../../../services";
import constant from "../../../constant";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { currentPermission } from "../../../redux/reducers/permission";

export default function SystemSettings() {
  const permission = useSelector(currentPermission);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canAdd, setCanAdd] = useState(true);
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState(0);
  const [banners, setBanners] = useState([]);
  const [bannerId, setBannerId] = useState(null);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [alertMsg, setAlertMsg] = useState("");
  const [order_by, setOrder_by] = useState("");
  const [order_type, setOrder_type] = useState("");
  const routerSystemSettingDetail = (state, banner) => {
    const path = `/banner-detail`;
    const data = { state, banner };
    navigate(path, { state: data });
  };
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const handleClose2 = () => {
    setOpenAlertDelete(false);
  };
  const handleOpen = () => {
    setOpenAlertDelete(true);
  };
  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const changeOrderBy = (value, order) => {
    if (order === "asc") setOrder_type("asc");
    else if (order === "desc") setOrder_type("desc");
    setOrder_by(value);
  };

  const getAllBanners = async () => {
    dispatch(changeLoader(true));
    const response = await services.systemSetting.GET_ALL_BANNER(
      query,
      page,
      limit,
      order_by,
      order_type
    );
    if (response?.data?.ack === 1) {
      if (response?.data) {
        if (
          response?.data?.banner &&
          response?.data?.banner?.rows?.length > 0
        ) {
          setBanners(response?.data?.banner?.rows);
          let pageCount = Math.ceil(response?.data?.banner?.count / limit);
          setCount(pageCount);
        } else {
          setBanners([]);
          let pageCount = 1;
          setCount(pageCount);
        }
      }
    } else {
      setBanners([]);
      let pageCount = 1;
      setCount(pageCount);
    }
    dispatch(changeLoader(false));
  };
  const deleteBanner = async (id) => {
    const response = await services.systemSetting.DELETE_BANNER(bannerId);
    if (response?.data?.ack === 1) {
      handleClose2();

      setSeverity("success");
      setAlertMsg(response?.data?.msg);
      setShowAlert(true);
      disableAlert();
      setTimeout(() => {
        getAllBanners();
      }, 500);
    }
  };
  useEffect(() => {
    getAllBanners();
  }, [page, order_by, order_type]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const updateBanner = async (banner_id, status) => {
    const data = { banner_id, status };
    dispatch(changeLoader(true));
    const response = await services.systemSetting.UPDATE_BANNER_STATUS(data);
    if (response?.data?.ack === 1) {
      setSeverity("success");
      setAlertMsg(response?.data?.msg);
      setShowAlert(true);
      disableAlert();
      setTimeout(() => {
        getAllBanners();
      }, 100);
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanAdd(permission?.banner.create === 1);
      setCanEdit(permission?.banner.edit === 1);
      setCanDelete(permission?.banner.delete === 1);
      setCanUpdateStatus(permission?.banner?.actions?.status === 1);
    } else {
      setCanEdit(true);
      setCanAdd(true);
      setCanDelete(true);
      setCanUpdateStatus(true);
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
                Banner Management
              </Typography>
              <div className="right-filter">
                {canAdd ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      routerSystemSettingDetail("add");
                    }}
                    SystemSettingDetail
                    size="medius"
                  >
                    Add Banner
                  </Button>
                ) : null}
              </div>
            </div>
            <TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">Image</div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Keyword
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("keyword", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("keyword", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Platform
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("type", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("type", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">Status</div>
                    </TableCell>
                    {canEdit || canDelete ? (
                      <TableCell className="nowrap" align="center">
                        Action
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {banners?.map((banner, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <img
                            alt="img"
                            src={`${constant.IMG_BASE_URL}${banner?.image}`}
                            style={{ height: "22px" }}
                          />
                        </TableCell>
                        <TableCell>
                          {banner?.header_banner_locales?.map((url, index) => {
                            if (url?.locale == "en") {
                              return <span key={index}>{url?.url}</span>;
                            }
                          })}
                        </TableCell>
                        <TableCell style={{ textTransform: "capitalize" }}>
                          {banner?.type}
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={banner?.status == "active" ? true : false}
                            onClick={() => {
                              updateBanner(
                                banner?.id,
                                banner?.status == "active"
                                  ? "inactive"
                                  : "active"
                              );
                            }}
                            disabled={!canUpdateStatus}
                          />
                        </TableCell>

                        {canEdit || canDelete ? (
                          <TableCell align="center" className="nowrap">
                            {canEdit ? (
                              <Tooltip
                                title="Edit"
                                placement="top"
                                sx={{ marginRight: 1 }}
                                onClick={() => {
                                  routerSystemSettingDetail("edit", banner);
                                }}
                              >
                                <BorderColorIcon
                                  color="action"
                                  fontSize="small"
                                />
                              </Tooltip>
                            ) : null}
                            {canDelete ? (
                              <Tooltip title="Delete" placement="top">
                                <DeleteForever
                                  color="error"
                                  fontSize="small"
                                  onClick={() => {
                                    handleOpen();
                                    setBannerId(banner.id);
                                  }}
                                />
                              </Tooltip>
                            ) : null}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {count > 1 && (
              <Pagination
                count={count}
                color="primary"
                onChange={handlePageChange}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Dialog
        open={openAlertDelete}
        onClose={handleClose2}
        className="alert-delete"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose2}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Alert
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  Are you sure you want to delete this Banner?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                  onClick={handleClose2}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                  onClick={() => {
                    deleteBanner();
                  }}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
