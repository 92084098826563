import React, { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import moment from "moment-timezone";
import plusIcon from "../../../../assets/images/structure/plus-icon.svg";
import minusIcon from "../../../../assets/images/structure/minus-icon.svg";
import TrackingComponentIdle from "./TrackingComponentIdle";


const PickerIdle = ({ row }) => {
    const [open, setOpen] = useState(false);
	const states = {
		idle: "Idle",
		offline: "Offline",
		busy: "Busy",
		picker_exceed_threshold_time: "Picker Exceed Threshold Time"
	};

	const checkEvent = (online, order) => {
		if (online === 0) {
			return "Not in shift";
		} else {
			if (order.length === 0) { return "No order"; }
			else{  
				let t = false;
				if(order[0].order_status_log!==''){
					const order_status_log_data = JSON.parse(order[0].order_status_log)
					for(let item of order_status_log_data){
						if((item.status==='completed' || item.status==='pickup_completed') && item.role=='picker'){
							t = true;
							return "Pickup Completed"
						}
					}
				}
				if(!t)return "Picked";
			}			
		}
	};
	let lastTime = 0;
    let order_status_log = row?.orders.length > 0 ? JSON.parse(row?.orders[0].order_status_log) : [];
    if(order_status_log.length>0){
        for(let item of order_status_log){
            if(item.status === 'in_pick')lastTime = item.updated_at;			
        }
    }
	const in_pick=[]
	const placed=[]
	const completed=[]
	
	if(row.orders.length>0){
		const order_status_log_data=JSON.parse(row?.orders[0]?.order_status_log)
		for(let item of order_status_log_data){

			if(item.status==='placed'){
				placed.push(item)
			}
			if(item.status==='in_pick' && item.role=='picker'){
				in_pick.push(item)
			}
			if((item.status==='completed' || item.status==='pickup_completed') && item.role=='picker'){
				completed.push(item)
			}
		}
	}
	

  return (
  	// <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
  	//   <TableCell component="th" scope="row">
  	//     <div className="table-img-text">
  	//       <img
  	//         src="https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"
  	//         alt=".."
  	//         className="round36"
  	//       />
  	//       <Typography variant="body2" component="div">{row?.first_name} {row?.last_name}</Typography>
  	//     </div>
  	//   </TableCell>
  	//   <TableCell>{row?.id}</TableCell>
  	//   <TableCell>{row?.email}</TableCell>
  	//   <TableCell>{ checkEvent(row?.online, row?.orders) }</TableCell>
  	//   <TableCell>{
	// 		row?.orders.length > 0?
	// 			moment(row?.orders[0]?.createdAt).format("DD MMMM YYYY hh:mm a"):
	// 			"No order picked yet"
	// 		}</TableCell>
  	//   <TableCell>{ row?.online === 0 ? "Offline" : states[row?.picker_state]}</TableCell>
  	// </TableRow>
	  <React.Fragment>
	  <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
		  <TableCell component="th" scope="row">
			  <div className="table-img-text">
			  <img
				  src="https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"
				  alt=".."
				  className="round36"
			  />
			  <Typography variant="body2" component="div">{row?.first_name} {row?.last_name}</Typography>
			  </div>
		  </TableCell>
		  <TableCell>{row?.id}</TableCell>
		  <TableCell>{row?.email}</TableCell>
		  <TableCell>{ checkEvent(row?.online, row?.orders) }</TableCell>
		  <TableCell>{
			lastTime > 0 ? moment
			.tz(lastTime, "Asia/Qatar")
			.format("DD MMMM YYYY hh:mm a") : "No order picked yet"
				  }</TableCell>
		  <TableCell>{ row?.online === 0 ? "Offline" : states[row?.picker_state]}</TableCell>
		  <TableCell>
			{ row?.orders.length > 0 ? 				
				<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => {
						setOpen(!open);
						}}
					>
		{row.orders[0]? open ? (
			<img src={minusIcon} alt="plus-icon" />
		  ) : (
			<img src={plusIcon} alt="plus-icon" />
		  ):<></>}
	  </IconButton>
	  : null }
	  
	</TableCell>
	  </TableRow>
	  <TableRow>
		<TableCell className="p-0" style={{ padding: 0, padding: 0 }} colSpan={7}>
		  <Collapse in={open} timeout="auto" unmountOnExit>
			<TrackingComponentIdle in_pick={in_pick} placed={placed} completed={completed}  />
		  </Collapse>
		</TableCell>
	  </TableRow>
	</React.Fragment>
  );
};

export default PickerIdle;