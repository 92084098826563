import { useState, useEffect } from "react";
import { Button, Select,MenuItem, Dialog, DialogContent, TextField } from "@mui/material";
import { Boxed } from "../Styles-Elements/Box";
import { API } from "../pages/ENUM";
import { useDispatch } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import { changeSnackbar } from "../redux/reducers/snackbar";
import GlobalService from "../services/GlobalService";

const AddCancellationReason = ({ 	departments ,  handleClose, submit, handleConfirm, editData, isEdit }) => {

	const dispatch = useDispatch();
	const [reason, setReason] = useState("");
	const [departmentsdata, setDepartmentsdata] = useState(departments);

	const [selectedDepartment, setSelectedDepartment] = useState('');
	let [reasonError, setReasonError] = useState('');
	let [reasonErrorDepartment, setReasonErrorDepartment] = useState('');

	const handleSubmit = async () => {
		dispatch(changeLoader(true));
		
		if(selectedDepartment === undefined || selectedDepartment === ''){
			setReasonError("Select department");
			dispatch(changeLoader(false));
	     } else  if (reason.trim() === '') {
			setReasonError("Please enter reason");
			dispatch(changeLoader(false));
		} else {
			const response = await GlobalService(API.ADD_CANCELLATION_REASON, {
				department_id: selectedDepartment,
				reason: reason
			}, 'post');
			if (response.ack === 1) {
				dispatch(changeLoader(false));
				dispatch(
					changeSnackbar({
						isOpen: true,
						message: response?.msg,
						state: "success"
					})
				);
				handleConfirm();
			} else {
				dispatch(changeLoader(false));
				setReasonError(response.msg);
			}
		}
	};

	const handleUpdate = async () => {
		dispatch(changeLoader(true));
		if(selectedDepartment === undefined || selectedDepartment === ''){
			setReasonErrorDepartment("Select department");
			dispatch(changeLoader(false));
	     }else if (reason === '') {
			setReasonError("Please enter reason");
			dispatch(changeLoader(false));
		} else {
			const response = await GlobalService(API.UPDATE_CANCELLATION_REASON(editData.id), {
				department_id : selectedDepartment,
				reason: reason,
				status: editData.status
			}, 'put');
			if (response.ack === 1) {
				dispatch(changeLoader(false));
				dispatch(
					changeSnackbar({
						isOpen: true,
						message: response?.msg,
						state: "success"
					})
				);
				handleConfirm();
			} else {
				dispatch(changeLoader(false));
				setReasonError(response.msg);
			}
		}
	};

	useEffect(() => {
		if (isEdit) {
			setReason(editData.reason);
			setSelectedDepartment(editData?.order_cancellation_department?.id || '');
		}
	}, [editData]);
	const handleDepartmentChange = (event) => {
		setSelectedDepartment(event.target.value);
		setReasonErrorDepartment('');
	  };
	
  return (
    <>
      <Dialog open={true} onClose={handleClose} className="main-modal theme-dialog maxwidth-600">
        <DialogContent>
          <h4 className="h4" style={{ marginBottom: "8px" }}>{isEdit?'Update':'Add'} Cancellation Reason</h4>
				  <Boxed style={{ position: "relative", }} >
				  <span className="redspan">{reasonErrorDepartment}</span>
				  <Select
						value={selectedDepartment}
						onChange={handleDepartmentChange}
						fullWidth
						displayEmpty
						inputProps={{ 'aria-label': 'Select Department' }}
						label="Select Department"
					>
						<MenuItem value="" disabled>
						Select Department
						</MenuItem>
						{departments.map((department) => (
						<MenuItem key={department.id} value={department.id}>
							{department.department_name}
						</MenuItem>
						))}
					  </Select>
					
						<TextField
							id="outlined-basic" label="Reason*" multiline={true} value={reason}
							onChange={(e) => {
								setReason(e.target.value);
								setReasonError('');
							}} rows={2} variant="outlined"
							fullWidth={true} margin="normal"
						/>
						<span className="redspan">{reasonError}</span>
					</Boxed>
          <Boxed textAlign="center" display={"flex"} justifyContent={"flex-end"}>
            <Button type="button" size="medium" variant="text" color="primary" onClick={handleClose}>Cancel</Button>

            {isEdit ?
							<Button type="button" size="medium" variant="text" color="error" onClick={handleUpdate}>Update</Button>:
							<Button type="button" size="medium" variant="text" color="error" onClick={handleSubmit}>Submit</Button>}
          </Boxed>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AddCancellationReason;