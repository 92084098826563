import {  useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from "@mui/icons-material/Clear";
import GlobalService from "../../../../services/GlobalService";
import { API } from "../../../ENUM";

export default function TrendingExclusive({allBrands , name , type , columnNames , selectedBrands , GetAllBrandsRanking , GetAllBrands}) {
  const [activeIndexes, setActiveIndexes] = useState([]);
  const [brandId, setBrandId] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [toaster, setToaster] = useState({ visibilty: false, status: "", text: "" });

  const handleClick = (index , id , type) => {
    setBrandId(id)
    setSelectedType(type)
    if (activeIndexes.includes(index)) {
      setActiveIndexes(activeIndexes.filter((i) => i !== index));
    } else {
      setActiveIndexes([...activeIndexes, index]);
    }
  };

  const handleDeleteBrands = async (id) => {  
    try {
      const response = await GlobalService(
        API.STORE_RANKING.DELETE_BRANDS_RANKING , { delete_ids : id } , "delete"
      );
      if (response?.ack === 1) {
        GetAllBrandsRanking(selectedType)
        GetAllBrands()
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleCreateBrands = async () => {
    // console.log("selectedBrands")
    if(brandId === null || selectedType === null){
      return;
    }
    try {
      const response = await GlobalService(
        API.STORE_RANKING.CREATE_BRANDS_RANKING , { type : selectedType , brand_id : brandId  } , "post"
      );
      if (response?.ack === 1) {
         handleDeleteBrands(brandId)
        // GetAllBrandsRanking(selectedType)
      }else{
        setToaster({
          status: "error",
          text: data.msg,
          visibilty: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
   console.log("selectedBrands", selectedBrands)
  },[selectedBrands])

  // useEffect(()=>{
  //   console.log("selectedBrands", selectedBrands)
  //   if(allBrands.length>0){
  //     // console.log("allBrands", allBrands)
  //     //  console.log("selectedBrands", selectedBrands)
  //   }
  // },[selectedBrands])

  return (
    <>
      <Grid item xs={12}>
      {toaster?.visibilty && (
            <Alert severity={toaster?.status}>{toaster?.text}</Alert>
          )}        
        <Typography
          variant="p"
          component="p"
          mt={2}
          mb={0}
          fontWeight={600}
          textTransform={"uppercase"}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <div className="ranking-box">
          <div className="ranking-head">
            <Typography
              variant="h6"
              component="h6"
              fontSize={"16px"}
              fontWeight={500}
            >
              Available Brands
            </Typography>
          </div>
          <div className="search-box">
            <TextField
              id="outlined-basic"
              size="small"
              value={""}
              placeholder="Search.."
              variant="outlined"
              InputProps={{
                endAdornment: <ClearIcon sx={{ marginLeft: "8px" }} />,
              }}
            />
          </div>
          <div className="ranking-content">
            {
              allBrands && allBrands.length > 0 && allBrands.map((item, index) => {
                // console.log("item", item)
                return (
                  <div
                    className={`ranking-row ${
                      activeIndexes.includes(index) ? "active" : ""
                    }`}
                    onClick={() => handleClick(index , item?.id , type)}
                  >
                    <p className="ranking-name">{item?.stores_locales[0]?.business_name}</p>
                  </div>
                );
              })
            }
            {/* <div
              className={`ranking-row ${
                activeIndexes.includes(0) ? "active" : ""
              }`}
              onClick={() => handleClick(0)}
            >
              <p className="ranking-name">Papa John's</p>
            </div>
            <div
              className={`ranking-row ${
                activeIndexes.includes(1) ? "active" : ""
              }`}
              onClick={() => handleClick(1)}
            >
              <p className="ranking-name">Pizza hut</p>
            </div>
            <div
              className={`ranking-row ${
                activeIndexes.includes(2) ? "active" : ""
              }`}
              onClick={() => handleClick(2)}
            >
              <p className="ranking-name">Dominos</p>
            </div>
            <div
              className={`ranking-row ${
                activeIndexes.includes(3) ? "active" : ""
              }`}
              onClick={() => handleClick(3)}
            >
              <p className="ranking-name">Papa John's</p>
            </div>
            <div
              className={`ranking-row ${
                activeIndexes.includes(4) ? "active" : ""
              }`}
              onClick={() => handleClick(4)}
            >
              <p className="ranking-name">Pizza hut</p>
            </div>
            <div
              className={`ranking-row ${
                activeIndexes.includes(5) ? "active" : ""
              }`}
              onClick={() => handleClick(5)}
            >
              <p className="ranking-name">Dominos</p>
            </div>
            <div
              className={`ranking-row ${
                activeIndexes.includes(6) ? "active" : ""
              }`}
              onClick={() => handleClick(6)}
            >
              <p className="ranking-name">Papa John's</p>
            </div>
            <div
              className={`ranking-row ${
                activeIndexes.includes(7) ? "active" : ""
              }`}
              onClick={() => handleClick(7)}
            >
              <p className="ranking-name">Pizza hut</p>
            </div>
            <div
              className={`ranking-row ${
                activeIndexes.includes(8) ? "active" : ""
              }`}
              onClick={() => handleClick(8)}
            >
              <p className="ranking-name">Dominos</p>
            </div> */}
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={1}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <ArrowForwardIcon onClick={handleCreateBrands} />
        <ArrowBackIcon />
      </Grid>
      <Grid item xs={4}>
        <div className="ranking-box">
          <div className="ranking-head">
            <Typography
              variant="h6"
              component="h6"
              fontSize={"16px"}
              fontWeight={500}
            >
              {columnNames}
            </Typography>
          </div>
          <div className="search-box">
            <TextField
              id="outlined-basic"
              size="small"
              value={""}
              placeholder="Search.."
              variant="outlined"
              InputProps={{
                endAdornment: <ClearIcon sx={{ marginLeft: "8px" }} />,
              }}
            />
          </div>
          {/* <div className="ranking-content">
          <div
              className={`ranking-row ${
                activeIndexes.includes(9) ? "active" : ""
              }`}
              onClick={() => handleClick(9)}
            >
              <p className="ranking-name">Dominos</p>
            </div>
            <div
              className={`ranking-row ${
                activeIndexes.includes(10) ? "active" : ""
              }`}
              onClick={() => handleClick(10)}
            >
              <p className="ranking-name">De Brazia</p>
            </div>
          </div> */}
          {
            selectedBrands && selectedBrands?.length > 0 && selectedBrands.map((item, index) => {
              // console.log("item", item)
              return (
                <div
                  className={`ranking-row ${
                    activeIndexes.includes(index) ? "active" : ""
                  }`}
                  onClick={() => handleClick(index)}
                >
                  <p className="ranking-name">{item?.stores?.stores_locales[0]?.business_name}</p>
                </div>
              );
            })
          }
        </div>
      </Grid>
    </>
  );
}
