import { useState, useEffect, useRef } from "react";
import { Typography, Box, Switch } from "@mui/material";
import deleteBlackImg from "../../../../assets/images/structure/delete-black.svg";
import uploadBlackIcon from "../../../../assets/images/structure/upload-dark-icon.svg";
import { TreeItem } from "@material-ui/lab";
import { Boxed } from "../../../../Styles-Elements/Box";
import GlobalService from "../../../../services/GlobalService";
import { API } from "../../../../pages/ENUM";
import ChildCategoryComponent from "./ChildCategory";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import constant from "../../../../constant";

const SubCategory = ({
  subCategory,
  handleStatusUpdate,
  handleSetDeleteId,
  setOpenDeletePopup,
  selectedId,
  handleSelectedId,
  id,
  openUploadImage,
}) => {
  const dragItem = useRef();
  const dragOverItem = useRef();

  const [subCategoryList, setSubCategoryList] = useState([]);

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = async (e, index) => {
    const copyListItems = [...subCategoryList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setSubCategoryList(copyListItems);
    const newSequence = copyListItems.map((item, index) => {
      return {
        catStoreId: item.catStoreId,
        sequence: index + 1,
      };
    });
    await GlobalService(API.UPDATE_CATEGORY_SEQUENCE, newSequence, "post");
  };

  useEffect(() => {
    setSubCategoryList(subCategory);
  }, [subCategory]);

  return (
    <>
      {subCategoryList.map((subCategoryItem, index) =>
        subCategoryItem?.chiledCategory?.length > 0 ? (
          <TreeItem
            nodeId={`subCategory${subCategoryItem.id}`}
            label={
              <Boxed
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                onClick={() => {
                  handleSelectedId(subCategoryItem.id, subCategoryItem.name);
                }}
              >
                <DragIndicatorIcon
                  sx={{ marginRight: "3px", color: "#a7a7a7" }}
                />
                <Typography
                  variant="body2"
                  component="body2"
                  width={"100%"}
                  fontSize={"16px"}
                  mb={0}
                  fontWeight={500}
                >
                  {subCategoryItem.name}
                </Typography>
                <Box display={"flex"} alignItems={"center"}>
                  <Switch
                    checked={subCategoryItem.status === "active" ? true : false}
                    onChange={(e) => {
                      handleStatusUpdate(
                        e.target.checked ? "active" : "inactive",
                        subCategoryItem.id
                      );
                    }}
                  />
                  <img
                    src={deleteBlackImg}
                    alt=""
                    onClick={() => {
                      handleSetDeleteId(subCategoryItem.id);
                      setOpenDeletePopup(true);
                    }}
                  />
                  <img
                    src={subCategoryItem.image?
                      constant?.IMG_BASE_URL + subCategoryItem.image:
                      uploadBlackIcon
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = uploadBlackIcon
                    }}
                    width={"20px"}
                    onClick={() => {
                      openUploadImage(
                        subCategoryItem.id,
                        subCategoryItem.name,
                        subCategoryItem.image
                      );
                    }}
                    alt=""
                  />
                </Box>
              </Boxed>
            }
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={(e) => drop(e, index)}
            draggable
          >
            {subCategoryItem?.chiledCategory?.length > 0 ? (
              <ChildCategoryComponent
                childCategory={subCategoryItem?.chiledCategory}
                handleStatusUpdate={handleStatusUpdate}
                handleSetDeleteId={handleSetDeleteId}
                setOpenDeletePopup={setOpenDeletePopup}
                id={id}
                selectedId={selectedId}
                handleSelectedId={handleSelectedId}
                openUploadImage={openUploadImage}
              />
            ) : null}
          </TreeItem>
        ) : (
          <Box
            p={"8px 44px 8px 36px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleSelectedId(subCategoryItem.id, subCategoryItem.name);
            }}
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={(e) => drop(e, index)}
            draggable
          >
            <DragIndicatorIcon sx={{ marginRight: "3px", color: "#a7a7a7" }} />
            <Typography
              variant="body2"
              component="body2"
              width={"100%"}
              fontSize={"16px"}
              mb={0}
              fontWeight={500}
            >
              {subCategoryItem.name}
            </Typography>
            <Box display={"flex"} alignItems={"center"}>
              <Switch
                checked={subCategoryItem.status === "active" ? true : false}
                onChange={(e) => {
                  handleStatusUpdate(
                    e.target.checked ? "active" : "inactive",
                    subCategoryItem.id
                  );
                }}
              />
              <img
                src={deleteBlackImg}
                alt=""
                onClick={() => {
                  handleSetDeleteId(subCategoryItem.id);
                  setOpenDeletePopup(true);
                }}
              />
              <img
                src={subCategoryItem.image?
                  constant?.IMG_BASE_URL + subCategoryItem.image:
                  uploadBlackIcon}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = uploadBlackIcon
                  }}
                width={"20px"}
                onClick={() => {
                  openUploadImage(
                    subCategoryItem.id,
                    subCategoryItem.name,
                    subCategoryItem.image
                  );
                }}
                alt=""
              />
            </Box>
          </Box>
        )
      )}
    </>
  );
};

export default SubCategory;
