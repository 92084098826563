import Box from "@mui/material/Box";
import { Typography, Grid } from "@mui/material";
import { getdataByKey, getAllBarcode } from "../../helper/custom"
import { useEffect, useState } from "react";
import constant from "../../../constant";
import defaultImage from "../../../assets/images/structure/default.jpeg";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const NewUomDetailBox = ({uom,unitData}) => {

	let [unitName, setUnitName] = useState('');
	let [images, setImage] = useState([
    {name: "Front View (Featured)", type: "front", product_image: defaultImage },
    {name: "Open View", type: "open",product_image: defaultImage },
    {name: "Close-Up View", type: "closeup",product_image: defaultImage },
    {name: "Top View", type: "top",product_image: defaultImage },
    {name: "Side View", type: "side",product_image: defaultImage },
  ]);
	
	const setImages = () => {
		const unit = unitData?.find((unit) => unit.id === uom?.unitId);
		const englishUnit = unit?.unit_locales?.find((u) => u.locale === 'en');
		unitName = englishUnit?.name; setUnitName(unitName);
		const uomImageGet = uom?.uom_images;
		const front = uomImageGet?.find((image) => image.type === 'front');
		const open = uomImageGet?.find((image) => image.type === 'open');
		const closeup = uomImageGet?.find((image) => image.type === 'closeup');
		const top = uomImageGet?.find((image) => image.type === 'top');
		const side = uomImageGet?.find((image) => image.type === 'side');
		images = [{
			name: "Front View (Featured)", 
			type: "front",
			product_image: front? constant.IMG_BASE_URL + (front?.name? front?.name: front?.image): defaultImage,
		}, {
			name: "Open View", 
			type: "open",
			product_image: open? constant.IMG_BASE_URL + (open?.name? open?.name: open?.image): defaultImage,
		}, {
			name: "Close-Up View",
			type: "closeup",
			product_image: closeup? constant.IMG_BASE_URL + (closeup?.name? closeup?.name: closeup?.image): defaultImage,
		}, {
			name: "Top View",
			type: "top",
			product_image: top? constant.IMG_BASE_URL + (top?.name ? top?.name: top?.image): defaultImage, 
		}, {
			name: "Side View",
			type: "side",
			product_image: side? constant.IMG_BASE_URL + (side?.name ? side?.name : side?.image): defaultImage,
		}];
		setImage(images);
	}	

	useEffect(() => { setImages() }, [uom])

  return (
		<>
			<Grid container spacing={3} mb={2} mt={2}>
        <Grid item xs={12}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>
								<span
									style={{
										maxWidth: "150px", display: "inline-block", overflow: "hidden",
										textOverflow: "ellipsis", whiteSpace: "nowrap"
									}}
								>Barcode</span>
								{uom?.isDefault === 1?<sup style={{
              marginLeft: "5px", color: "#000000", fontSize: "12px",
              padding: "3px", backgroundColor: "rgb(187 229 223)", borderRadius: "5px"
            }}>Default</sup>:null}</Typography>
						<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	><span>#{(uom?.uom_barcode_relations)? getAllBarcode(uom?.uom_barcode_relations): '-'}</span></Typography>
          </Box>
        </Grid>
				<Grid item xs={12} sm={6} md={2}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>UOM Code And Name</Typography>
						<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>{uom?.uomName}</Typography>
          </Box>
        </Grid>
				<Grid item xs={12} sm={6} md={2}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>UOM Unit</Typography>
						<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>{unitName}</Typography>
          </Box>
        </Grid>
				<Grid item xs={12} sm={6} md={2}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>Relation With Base</Typography>
						<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>{uom?.relationWithBase}</Typography>
          </Box>
        </Grid>
				<Grid item xs={12} sm={6} md={2}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>Price</Typography>
						<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>QAR {uom?.price}</Typography>
          </Box>
        </Grid>
				<Grid item xs={12} sm={6} md={2}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>Sell Price</Typography>
						<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>{uom?.salePrice !== 0 ? <span>QAR {uom?.salePrice}</span>: '-'}</Typography>
          </Box>
        </Grid>
				<Grid item xs={12} sm={6} md={2}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>Stock</Typography>
						<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>{uom?.stock === 1 ? "Active" : "Inactive"}</Typography>
          </Box>
        </Grid>
      </Grid>
			<Grid container spacing={3} mb={2} mt={2}>
			{images.map((obj, index) => 
        <div className="align-center" key={index} style={{padding: '10px',}}>
          <div className={`upload-multiple`}>
						<img src={obj.product_image} alt=".." className={`id-img `} style={{maxWidth:'100px',}}/>
          </div>
          <p className="p2">{obj.name}</p>
        </div>)}
			</Grid>
			<Grid item xs={12} sm={12} md={12}>
				{uom?.uom_addon_relations?.length ? (
				<Box sx={{display: 'flex', flexDirection: 'column'}}>
					<Typography
						variant="h6" color="#999999" component="h6"
						fontSize={"12px"} fontWeight={500}>Modifiers</Typography>
					<Stack direction="row" spacing={1} mb={'16px'}>
						{uom?.uom_addon_relations.map((itemsaddon)=>{
							return(<>
							<Chip label={getdataByKey(itemsaddon?.addon?.addons_locales,'en','name')} /></>)
						})}
					</Stack>
				</Box>
				):''}			
      </Grid>
		</>
  );
};

export default NewUomDetailBox;