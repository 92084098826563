import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import SearchIcon from "@mui/icons-material/Search";
import CrossIcon from "@mui/icons-material/Close";
import {
  Typography, TextField, Button, Box, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Pagination, Tooltip, InputAdornment
} from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { changeLoader } from "../../../redux/reducers/loader";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../../pages/ENUM";
import DeleteConfirmBox from "../../../dialog/DeleteConfirmBox";
import { currentPermission } from "../../../redux/reducers/permission";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound"

// image
export default function UserManagement() {

  const limit = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permission = useSelector(currentPermission);

  let [canEdit, setCanEdit] = useState(true);
  let [canCreate, setCanCreate] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);

  const [userList, setUserList] = useState([]);
  let [page, setPage] = useState(1);
  let [search, setSearch] = useState('');
  let [totalCount, setTotalCount] = useState(0);
  let [deleteId, setDeleteId] = useState(null);
  let [openDeleteModal, setOpenDeleteModal] = useState(false);

  const getUserList = async () => {
    dispatch(changeLoader(true));
    let url = `${API.GET_ALL_SUB_USERS}?limit=${limit}&page=${page}`;
    url += search !== '' ? `&search=${search}` : '';
    const response = await GlobalService(url);
    setUserList(response.data);
    dispatch(changeLoader(false));
  };

  const handleDelete = async () => {
    setOpenDeleteModal(false);
    dispatch(changeLoader(true));
    let url = API.DELETE_SUB_USERS(deleteId);
    const response = await GlobalService(url, {}, 'delete');
    if (response.ack === 1) {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response.msg,
        status: 'success',
      }));
      getUserList();
      dispatch(changeLoader(false));
    } else {
      dispatch(changeSnackbar({ 
        isOpen: true,
        message: response.msg,
        status: 'error',
      }));
      dispatch(changeLoader(false));
    }
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      page = 1;
      setPage(page);
      getUserList();
    }, 500);
    return () => clearTimeout(getData);
  }, [search]);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanEdit(permission?.manageUsers.edit === 1);
      setCanCreate(permission?.manageUsers.create === 1);
      setCanDelete(permission?.manageUsers.delete === 1);
      // setCanUpdateStatus(permission?.manageUsers?.action.status === 1);
    } else {
      setCanEdit(true);
      setCanCreate(true);
      setCanDelete(true);
      setCanUpdateStatus(true);
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
                User Management Listing
              </Typography>
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    
                    placeholder="Search here.."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      startAdornment: <SearchIcon sx={{ marginRight: "3px" }} />,
                      endAdornment: search!==''?<InputAdornment position="end"> <CrossIcon
                      className="cursor-pointer"
                      onClick={() => {
                        search = ''; setSearch(search);
                      }}
                    /> </InputAdornment>: null,
                    }}
                  />
                </div>
                { canCreate?
                  <Button
                  variant="contained"
                  size="medius"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => navigate("/add-user")}
                >
                  Add User
                </Button>: null}
              </div>
            </div>
            {userList?.length > 0?<TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Roles</TableCell>
                    { canEdit || canDelete?
                      <TableCell className="nowrap" align="center">Action</TableCell>: null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.map((sub_user, index) =>
                  <TableRow key={`user-${index}`}>
                    <TableCell component="th" scope="row">{sub_user?.full_name}</TableCell>
                    <TableCell>{sub_user?.email}</TableCell>
                    <TableCell>{sub_user?.subadmin_role?.name}</TableCell>

                    { canEdit || canDelete?
                      <TableCell align="center" className="nowrap">
                      {canEdit?<Tooltip title="Edit" placement="top" sx={{ marginRight: 1 }}>
                        <BorderColorIcon
                          color="action" fontSize="small"
                          onClick={() => navigate( `/edit-user/${sub_user?.id}`)}
                        />
                      </Tooltip>: null}
                      {canDelete?<Tooltip title="Delete" placement="top" sx={{ marginRight: 1 }}>
                        <DeleteForever
                          color="error" fontSize="small"
                          onClick={() => {
                            setDeleteId(sub_user?.id);
                            setOpenDeleteModal(true);
                          }}
                        />
                      </Tooltip>: null}
                      {/* <Tooltip title="Resend password" placement="top" sx={{ marginRight: 1 }}>
                        <SyncLockIcon color="primary" fontSize="small" />
                      </Tooltip> */}
                    </TableCell>: null}
                  </TableRow>)}
                </TableBody>
              </Table>
            </TableContainer>: <NoDataFound
              text="No Users found"
            />}

            {totalCount > 0?
            <Pagination
              count={totalCount}
              page={page}
              onChange={(e, val) => {
                page = val; setPage(val); getUserList();
              }}
              color="primary" />: null}
          </Box>
        </Box>
      </Box>
      { openDeleteModal?
        <DeleteConfirmBox
          title={"Delete User"}
          description={"Are you sure you want to delete this user?"}
          handleClose={() => setOpenDeleteModal(false)}
          handleConfirm={() => handleDelete()}
        />: null}
    </>
  );
}
