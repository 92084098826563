import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, TextField, Checkbox, FormControlLabel, Button } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { changeLoader } from "../../../redux/reducers/loader";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../../pages/ENUM";

export default function RoleAndPermission() {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();
	let [roleName, setRoleName] = useState("");
	let [businessType, setBusinessType] = useState([]);
	
	let [storeRequest, setStoreRequest] =
		useState({
			moduleName: "StoreRequest", all: 0, read: 0, create: 0, edit: 0, actions: { approveReject: 0, addEditAssociates: 0 }, delete: 0,
			expiry_date: null
		});
	let [storeListing, setStoreListing] =
		useState({
			moduleName: "StoreListing", all: 0, read: 0, create: 0, edit: 0, delete: 0,
			actions: { recommended: 0, status: 0, addEditAssociates: 0 },
			expiry_date: null
		});
	let [storeType, setStoreType] =
		useState({
			moduleName: "StoreType", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
			expiry_date: null
		});
	let [businessCategory, setBusinessCategory] =
		useState({
			moduleName: "BusinessCategory", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
			expiry_date: null
		});
	let [uom, setUom] =
		useState({
			moduleName: "UOM", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
			expiry_date: null
		});
	let [productRequest, setProductRequest] =
		useState({
			moduleName: "ProductRequest", all: 0, read: 0, create: 0, edit: 0, actions: { approveReject: 0 }, delete: 0,
			expiry_date: null
		});
	let [addProduct, setAddProduct] =
		useState({
			moduleName: "AddProduct", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
			expiry_date: null
		});
	let [orderListing, setOrderListing] =
		useState({
			moduleName: "OrderListing", all: 0, read: 0, create: 0, edit: 0,
			actions: { readyToPick: 0, cancelOrder: 0, acceptOrRejectOrder: 0, refundOrder: 0, confirmPendingOrder: 0,},
			availableTypes: [], delete: 0, expiry_date: null
		});
	let [cms, setCms] =
		useState({
			moduleName: "CMS", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
			expiry_date: null
		});
	let [customerManagement, setCustomerManagement] =
		useState({
			moduleName: "CustomerManagement", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
			expiry_date: null
		});
	let [walletManagement, setWalletManagement] =
		useState({
			moduleName: "WalletManagement", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
			expiry_date: null
		});
	let [manageUsers, setManageUsers] =
		useState({
			moduleName: "ManageUsers", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
			expiry_date: null
		});
	let [rolesPermissions, setRolesPermissions] =
		useState({
			moduleName: "RolesPermissions", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
			expiry_date: null
		});
	let [payoutManagement, setPayoutManagement] =
		useState({
			moduleName: "PayoutManagement", all: 0, read: 0, create: 0, edit: 0, actions: { settlePayout: 0, exportCSV: 0 }, delete: 0,
			expiry_date: null
		});
	let [driverOnboarding, setDriverOnboarding] =
		useState({
			moduleName: "DriverOnboarding", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
			expiry_date: null
		});
	// let [sectionManagement, setSectionManagement] =
	// 	useState({
	// 		moduleName: "SectionManagement", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
	// 		expiry_date: null
	// 	});
	let [zone, setZone] =
		useState({
			moduleName: "Zone", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
			expiry_date: null
		});
	let [banner, setBanner] =
		useState({
			moduleName: "Banner", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
			expiry_date: null
		});
	let [coupon, setCoupon] = 
		useState({
			moduleName: "Coupon", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
			expiry_date: null
		});
	let [cuisine, setCuisine] = 
		useState({
			moduleName: "Cuisine", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
			expiry_date: null
		});
	let [adminSetting, setAdminSetting] = 
		useState({
			moduleName: "AdminSetting", all: 0, read: 0, create: 0, edit: 0, actions: { }, delete: 0,
			expiry_date: null
		});
	let [offerDiscount, setOfferDiscount] = useState({
		moduleName: "OfferDiscount", 
		all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0, expiry_date: null
	});
	let [pickerTracking, setPickerTracking] = useState({
		moduleName: "PickerTracking", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
		expiry_date: null
	});

	const handleSave = async () => {
		dispatch(changeLoader(true));
		let isValid = true;
		if (roleName.trim() === "") {
			isValid = false;
			dispatch(changeSnackbar({
				isOpen: true,
				state: "error",
				message: "Please enter role name"
			}));
		}
		const permissionData = [
			storeRequest, storeListing, storeType, businessCategory, uom, productRequest,
			addProduct, orderListing, cms, customerManagement, walletManagement, manageUsers,
			rolesPermissions, payoutManagement, driverOnboarding, sectionManagement, zone, banner,
			coupon, cuisine, adminSetting, offerDiscount, pickerTracking
		];
		let hasAccess = false;
		permissionData.forEach((item) => {
			if (item.read === 1) {
				hasAccess = true;
			}
		});
		if (!hasAccess) {
			dispatch(changeSnackbar({
				isOpen: true,
				state: "error",
				message: "Please select at-least one permission"
			}));
			isValid = false;
		}

		if (isValid) {
			const data = {
				name: roleName,
				permissions: permissionData
			};
			let url = API.ADD_ROLE;
			let method = "post";

			if (id) {
				url = API.UPDATE_ROLE(id);
				method = "put";
			}

			const response = await GlobalService(url, data, method);
			if (response.ack === 1) {
				dispatch(changeSnackbar({
					isOpen: true,
					state: "success",
					message: response.msg
				}));
				navigate("/manage-role");
			} else {
				if (response.msg === "") {
					let message = "";
					response?.errMsg.forEach(element => {
            for (const [key, value] of Object.entries(element)) {
              message += value + "\n";
            }
          });
					dispatch(changeSnackbar({
						isOpen: true,
						state: "error",
						message: message
					}));
				} else {
					dispatch(changeSnackbar({
						isOpen: true,
						state: "error",
						message: response.msg
					}));
				}
			}
			dispatch(changeLoader(false));
		} else {
			dispatch(changeLoader(false));
		}
	}

	const getAllBusinessType = async () => {
		const response = await GlobalService(API.GET_ALL_BUSINESS);
		businessType = response.businesstype.rows.map((item) => {
		return { id: item.id, name: item.business_type_locales[0].name, };
		});
		setBusinessType(businessType);
	};

	const getRoleDetails = async () => {
		dispatch(changeLoader(true));
		const response = await GlobalService(API.GET_ROLE_BY_ID(id));
		if (response.ack === 1) {
			setRoleName(response?.data?.name);
			const permissionData = response?.data?.permissions;
			setStoreRequest(permissionData?.find((item) => item.moduleName === "StoreRequest") || storeRequest)
			setStoreListing(permissionData?.find((item) => item.moduleName === "StoreListing") || storeListing)
			setStoreType(permissionData?.find((item) => item.moduleName === "StoreType") || storeType)
			setBusinessCategory(permissionData?.find((item) => item.moduleName === "BusinessCategory") || businessCategory)
			setUom(permissionData?.find((item) => item.moduleName === "UOM") || uom)
			setProductRequest(permissionData?.find((item) => item.moduleName === "ProductRequest") || productRequest)
			setAddProduct(permissionData?.find((item) => item.moduleName === "AddProduct") || addProduct)
			setOrderListing(permissionData?.find((item) => item.moduleName === "OrderListing") || orderListing)
			setCms(permissionData?.find((item) => item.moduleName === "CMS") || cms)
			setCustomerManagement(permissionData?.find((item) => item.moduleName === "CustomerManagement") || customerManagement)
			setWalletManagement(permissionData?.find((item) => item.moduleName === "WalletManagement") || walletManagement)
			setManageUsers(permissionData?.find((item) => item.moduleName === "ManageUsers") || manageUsers)
			setRolesPermissions(permissionData?.find((item) => item.moduleName === "RolesPermissions") || rolesPermissions)
			setPayoutManagement(permissionData?.find((item) => item.moduleName === "PayoutManagement") || payoutManagement)
			setDriverOnboarding(permissionData?.find((item) => item.moduleName === "DriverOnboarding") || driverOnboarding)
			// setSectionManagement(permissionData?.find((item) => item.moduleName === "SectionManagement") || sectionManagement)
			setZone(permissionData?.find((item) => item.moduleName === "Zone") || zone)
			setBanner(permissionData?.find((item) => item.moduleName === "Banner") || banner)
			setCoupon(permissionData?.find((item) => item.moduleName === "Coupon") || coupon)
			setCuisine(permissionData?.find((item) => item.moduleName === "Cuisine") || cuisine)
			setAdminSetting(permissionData?.find((item) => item.moduleName === "AdminSetting") || adminSetting)
			setOfferDiscount(permissionData?.find((item) => item.moduleName === "OfferDiscount") || offerDiscount)
			setPickerTracking(permissionData?.find((item) => item.moduleName === "PickerTracking") || pickerTracking)
		} else {
			dispatch(changeSnackbar({
				isOpen: true,
				state: "error",
				message: response.msg
			}));
		}
		dispatch(changeLoader(false));
	};

	const SelectAll = (e, type) => {
		let checked = e.target.checked ? 1 : 0;
		switch (type) {
			case "storeRequest":
				setStoreRequest({
					...storeRequest, all: checked, read: checked, edit: checked, actions: { approveReject: checked, addEditAssociates: checked }
				});
				break;
			case "storeListing":
				setStoreListing({
					...storeListing, all: checked, read: checked, edit: checked, actions: { status: checked, addEditAssociates: checked, recommended: checked }, delete: checked
				});
				break;
			case "storeType":
				setStoreType({
					...storeType, all: checked, read: checked, create: checked, edit: checked, delete: checked
				});
				break;
			case "businessCategory":
				setBusinessCategory({
					...businessCategory, all: checked, read: checked, create: checked, edit: checked, delete: checked, actions: { status: checked }
				});
				break;
			case "uom":
				setUom({
					...uom, all: checked, read: checked, create: checked, edit: checked, delete: checked
				});
				break;
			case "productRequest":
				setProductRequest({
					...productRequest, all: checked, read: checked, actions: { approveReject: checked }
				});
				break;
			case "addProduct":
				setAddProduct({
					...addProduct, all: checked, read: checked, create: checked, edit: checked, delete: checked, actions: { status: checked }
				});
				break;
			case "orderListing":
				let availableTypes = businessType.map((item) => item.id)
				setOrderListing({
					...orderListing, all: checked, read: checked, availableTypes: checked? availableTypes: [],
						actions: { readyToPick: checked, cancelOrder: checked, acceptOrRejectOrder: checked, refundOrder: checked, confirmPendingOrder: checked }
				});
				break;
			case "cms":
				setCms({
					...cms, all: checked, read: checked, create: checked, edit: checked, delete: checked
				});
				break;
			case "customerManagement":
				setCustomerManagement({
					...customerManagement, all: checked, read: checked, delete: checked, actions: { status: checked }
				});
				break;
			case "walletManagement":
				setWalletManagement({
					...walletManagement, all: checked, read: checked, create: checked
				});
				break;
			case "manageUsers":
				setManageUsers({
					...manageUsers, all: checked, read: checked, create: checked, edit: checked, delete: checked, actions: { status: checked }
				});
				break;
			case "rolesPermissions":
				setRolesPermissions({
					...rolesPermissions, all: checked, read: checked, create: checked, edit: checked, delete: checked, actions: { status: checked }
				});
				break;
			case "payoutManagement":
				setPayoutManagement({
					...payoutManagement, all: checked, read: checked, actions: { settlePayout: checked, exportCSV: checked, }
				});
				break;
			case "driverOnboarding":
				setDriverOnboarding({
					...driverOnboarding, all: checked, read: checked, create: checked, edit: checked, delete: checked,
				});
				break;
			// case "sectionManagement":
			// 	setSectionManagement({
			// 		...sectionManagement, all: checked, read: checked, create: checked, edit: checked, delete: checked,
			// 	});
			// 	break;
			case "zone":
				setZone({
					...zone, all: checked, read: checked, create: checked, edit: checked, delete: checked,
				});
				break;
			case "banner":
				setBanner({
					...banner, all: checked, read: checked, create: checked, edit: checked, delete: checked, actions: { status: checked }
				});
				break;
			case "coupon":
				setCoupon({
					...coupon, all: checked, read: checked, create: checked, edit: checked, delete: checked, actions: { status: checked }
				});
				break;
			case "cuisine":
				setCuisine({
					...cuisine, all: checked, read: checked, create: checked, edit: checked, delete: checked, actions: { status: checked }
				});
				break;
			case "adminSetting":
				setAdminSetting({
					...adminSetting, all: checked, read: checked, create: checked, edit: checked, delete: checked, actions: {  }
				});
				break;
			case "offerDiscount":
				setOfferDiscount({
					...offerDiscount, all: checked, read: checked, create: checked, edit: checked, delete: checked, actions: { status: checked }
				});
				break;
			case "pickerTracking":
				setPickerTracking({
					...pickerTracking, all: checked, read: checked, create: checked, edit: checked, delete: checked, actions: {  }
				});
		}
	}

	const unSelectAll = (type) => {
		switch (type) {
			case "storeRequest":
				setStoreRequest({
					...storeRequest, all: 0, read: 0, create: 0, edit: 0, actions: { approveReject: 0, addEditAssociates: 0 }, delete: 0, expiry_date: null
				});
				break;
			case "storeListing":
				setStoreListing({
					...storeListing, all: 0, read: 0, edit: 0, actions: { status: 0, addEditAssociates: 0, recommended: 0 }, delete: 0, expiry_date: null
				});
				break;
			case "storeType":
				setStoreType({ ...storeType, all: 0, read: 0, create: 0, edit: 0, delete: 0, expiry_date: null });
				break;
			case "businessCategory":
				setBusinessCategory({ ...businessCategory, all: 0, read: 0, create: 0, edit: 0, delete: 0, actions: { status: 0 }, expiry_date: null });
				break;
			case "uom":
				setUom({ ...uom, all: 0, read: 0, create: 0, edit: 0, delete: 0, expiry_date: null });
				break;
			case "productRequest":
				setProductRequest({ ...productRequest, all: 0, read: 0, actions: { approveReject: 0 }, expiry_date: null });
				break;
			case "addProduct":
				setAddProduct({ ...addProduct, all: 0, read: 0, create: 0, edit: 0, delete: 0, actions: { status: 0 }, expiry_date: null });
				break;
			case "orderListing":
				setOrderListing({
					...orderListing, all: 0, read: 0, availableTypes: [],
					actions: { readyToPick: 0, cancelOrder: 0, acceptOrRejectOrder: 0, refundOrder: 0, confirmPendingOrder: 0 }, expiry_date: null
				});
				break;
			case "cms":
				setCms({ ...cms, all: 0, read: 0, create: 0, edit: 0, delete: 0, expiry_date: null });
				break;
			case "customerManagement":
				setCustomerManagement({ ...customerManagement, all: 0, read: 0, delete: 0, actions: { status: 0 }, expiry_date: null });
				break;
			case "walletManagement":
				setWalletManagement({ ...walletManagement, all: 0, read: 0, create: 0, expiry_date: null });
				break;
			case "manageUsers":
				setManageUsers({ ...manageUsers, all: 0, read: 0, create: 0, edit: 0, delete: 0, actions: { status: 0 }, expiry_date: null });
				break;
			case "rolesPermissions":
				setRolesPermissions({ ...rolesPermissions, all: 0, read: 0, create: 0, edit: 0, delete: 0, actions: { status: 0 }, expiry_date: null });
				break;
			case "payoutManagement":
				setPayoutManagement({ ...payoutManagement, all: 0, read: 0, actions: { settlePayout: 0, exportCSV: 0, }, expiry_date: null });
				break;
			case "driverOnboarding":
				setDriverOnboarding({...driverOnboarding, all: 0, read: 0, create: 0, edit: 0, delete: 0, expiry_date: null});
				break;
			// case "sectionManagement":
			// 	setSectionManagement({...sectionManagement, all: 0, read: 0, create: 0, edit: 0, delete: 0, expiry_date: null});
			// 	break;
			case "zone":
				setZone({...zone, all: 0, read: 0, create: 0, edit: 0, delete: 0, expiry_date: null});
				break;
			case "banner":
				setBanner({...banner, all: 0, read: 0, create: 0, edit: 0, delete: 0, actions: { status: 0 }, expiry_date: null});
				break;
			case "coupon":
				setCoupon({...coupon, all: 0, read: 0, create: 0, edit: 0, delete: 0, actions: { status: 0 }, expiry_date: null});
				break;
			case "cuisine":
				setCuisine({...cuisine, all: 0, read: 0, create: 0, edit: 0, delete: 0, actions: { status: 0 }, expiry_date: null});
				break;
			case "adminSetting":
				setAdminSetting({...adminSetting, all: 0, read: 0, create: 0, edit: 0, delete: 0, actions: {  }, expiry_date: null});
				break;
			case "offerDiscount":
				setOfferDiscount({...offerDiscount, all: 0, read: 0, create: 0, edit: 0, delete: 0, actions: { status: 0 }, expiry_date: null});
				break;
			case "pickerTracking":
				setPickerTracking({...pickerTracking, all: 0, read: 0, create: 0, edit: 0, delete: 0, actions: {  }, expiry_date: null});
				break;
		}
	};

	useEffect(() => {
		if (id) { getRoleDetails();}
	}, [id]);

	useEffect(() => {
		getAllBusinessType();
	}, []);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
              {id ? <Typography variant="h6" component="h6" mb={3} fontWeight={500}>Edit Role & Permission</Typography>:
							<Typography variant="h6" component="h6" mb={3} fontWeight={500}>Add New Role & Permission</Typography>}
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small" size="small" label="Role Name" placeholder="Enter role name"
										value={roleName} onChange={(e) => setRoleName(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="add-roles-row">
              <div className="add-roles-column">
                <Typography variant="body" component="p" mb={0} fontWeight={500}>
									Store request
								</Typography>
								<FormControlLabel control={<Checkbox
									checked={storeRequest?.all === 1}
									onClick={(e) => SelectAll(e, "storeRequest")}
								/>} label="All" />
              </div>
              <div className="add-roles-column">
                <FormControlLabel control={<Checkbox
									checked={storeRequest?.read === 1}
									onChange={(e) => {
										setStoreRequest({ ...storeRequest, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : storeRequest.all })
										!e.target.checked? unSelectAll("storeRequest"): null;
									}}
								/>} label="Read" />
              </div>
              <div className="add-roles-column"></div>
              <div className="add-roles-column">
                <FormControlLabel control={<Checkbox
									checked={storeRequest?.edit === 1}
									onChange={(e) => setStoreRequest({ ...storeRequest, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : storeRequest.all, read: e.target.checked ? 1 : storeRequest.read })}
								/>} label="Edit" />
              </div>
              <div className="add-roles-column">
                <FormControlLabel control={<Checkbox
									checked={storeRequest?.actions.approveReject === 1}
									onChange={(e) => setStoreRequest({ ...storeRequest, actions: { ...storeRequest.actions, approveReject: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : storeRequest.all, read: e.target.checked ? 1 : storeRequest.read  })}
								/>} label="Approve/reject permission" />
                <FormControlLabel control={<Checkbox
									checked={storeRequest?.actions.addEditAssociates === 1}
									onChange={(e) => setStoreRequest({ ...storeRequest, actions: { ...storeRequest.actions, addEditAssociates: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : storeRequest.all, read: e.target.checked ? 1 : storeRequest.read  })}
								/>} label="Add/edit associates permission" />
              </div>
              <div className="add-roles-column"></div>
							<div className="add-roles-column">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Expire Date"
                        inputFormat="DD/MM/YYYY"
												minDate={new Date()}
												value={storeRequest?.expiry_date}
                        renderInput={(params) => <TextField {...params} size="small" />}
												onChange={(date) => { setStoreRequest({ ...storeRequest, expiry_date: date }) }}
                      />
                    </LocalizationProvider>
                </div>
            </div>

            <div className="add-roles-row">
              <div className="add-roles-column">
                <Typography variant="body" component="p" mb={0} fontWeight={500}>Store Listing</Typography>
								<FormControlLabel control={<Checkbox
									checked={storeListing?.all === 1}
									onClick={(e) => SelectAll(e, "storeListing")}
								/>} label="All" />
              </div>
              <div className="add-roles-column">
                <FormControlLabel control={<Checkbox
									checked={storeListing?.read === 1}
									onChange={(e) => {
										setStoreListing({ ...storeListing, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : storeListing.all })
										!e.target.checked? unSelectAll("storeListing"): null;
									}}
								/>} label="Read" />
              </div>
              <div className="add-roles-column"></div>
              <div className="add-roles-column">
								<FormControlLabel control={<Checkbox
									checked={storeListing?.edit === 1}
									onChange={(e) => setStoreListing({ ...storeListing, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : storeListing.all, read: e.target.checked ? 1 : storeListing.read  })}
								/>} label="Edit" />
              </div>
              <div className="add-roles-column">
                <FormControlLabel control={<Checkbox
									checked={storeListing?.actions.recommended === 1}
									onChange={(e) => setStoreListing({ ...storeListing, actions: { ...storeListing?.actions, recommended: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : storeListing.all, read: e.target.checked ? 1 : storeListing.read   })}
								/>} label="Recommended" />
                <FormControlLabel control={<Checkbox
									checked={storeListing?.actions.status === 1}
									onChange={(e) => setStoreListing({ ...storeListing, actions: { ...storeListing?.actions, status: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : storeListing.all, read: e.target.checked ? 1 : storeListing.read   })}
								/>} label="Status" />
                <FormControlLabel control={<Checkbox
									checked={storeListing?.actions.addEditAssociates === 1}
									onChange={(e) => setStoreListing({ ...storeListing, actions: { ...storeListing?.actions, addEditAssociates: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : storeListing.all, read: e.target.checked ? 1 : storeListing.read   })}
								/>} label="Add/edit associates permission" />
              </div>
              <div className="add-roles-column">
                <FormControlLabel control={<Checkbox
									checked={storeListing?.delete === 1}
									onChange={(e) => setStoreListing({ ...storeListing, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : storeListing.all, read: e.target.checked ? 1 : storeListing.read   })}
								/>} label="Delete" />
              </div>
							<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={storeListing?.expiry_date}
										onChange={(date) => {setStoreListing({ ...storeListing, expiry_date: date })}}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
              <div className="add-roles-column">
                <Typography variant="body" component="p" mb={0} fontWeight={500}>Store Type</Typography>
								<FormControlLabel control={<Checkbox
									checked={storeType?.all === 1}
									onClick={(e) => SelectAll(e, "storeType")}
								/>} label="All" />
              </div>
              <div className="add-roles-column">
                <FormControlLabel control={<Checkbox
									checked={storeType?.read === 1}
									onChange={(e) => {
										setStoreType({ ...storeType, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : storeType.all })
										!e.target.checked? unSelectAll("storeType"): null;
									}}
								/>} label="Read" />
              </div>
              <div className="add-roles-column">
                <FormControlLabel control={<Checkbox
									checked={storeType?.create === 1}
									onChange={(e) => setStoreType({ ...storeType, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : storeType.all, read: e.target.checked ? 1 : storeType.read })}
								/>} label="Create" />
              </div>
              <div className="add-roles-column">
								<FormControlLabel control={<Checkbox
										checked={storeType?.edit === 1}
										onChange={(e) => setStoreType({ ...storeType, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : storeType.all, read: e.target.checked ? 1 : storeType.read })}
								/>} label="Edit" />
              </div>
              <div className="add-roles-column"></div>
              <div className="add-roles-column">
                <FormControlLabel control={<Checkbox
									checked={storeType?.delete === 1}
									onChange={(e) => setStoreType({ ...storeType, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : storeType.all, read: e.target.checked ? 1 : storeType.read })}
								/>} label="Delete" />
              </div>
							<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={storeType?.expiry_date}
										onChange={(date) => { setStoreType({ ...storeType, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Business category</Typography>
										<FormControlLabel control={<Checkbox
									checked={businessCategory?.all === 1}
									onClick={(e) => SelectAll(e, "businessCategory")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={businessCategory?.read === 1}
											onChange={(e) => {
												setBusinessCategory({ ...businessCategory, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : businessCategory.all })
												!e.target.checked? unSelectAll("businessCategory"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={businessCategory?.create === 1}
											onChange={(e) => setBusinessCategory({ ...businessCategory, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : businessCategory.all, read: e.target.checked ? 1 : businessCategory.read })}
										/>} label="Create" />
                </div>
                <div className="add-roles-column">
                 <FormControlLabel control={<Checkbox
								 		checked={businessCategory?.edit === 1}
										onChange={(e) => setBusinessCategory({ ...businessCategory, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : businessCategory.all, read: e.target.checked ? 1 : businessCategory.read  })}
								 />} label="Edit" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={businessCategory?.actions.status === 1}
											onChange={(e) => setBusinessCategory({ ...businessCategory, actions: { ...businessCategory?.actions, status: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : businessCategory.all, read: e.target.checked ? 1 : businessCategory.read  })}
										/>} label="Active/inactive" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={businessCategory?.delete === 1}
											onChange={(e) => setBusinessCategory({ ...businessCategory, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : businessCategory.all, read: e.target.checked ? 1 : businessCategory.read  })}
										/>} label="Delete" />
                </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={businessCategory?.expiry_date}
										onChange={(date) => { setBusinessCategory({ ...businessCategory, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>UOM</Typography>
										<FormControlLabel control={<Checkbox
									checked={uom?.all === 1}
									onClick={(e) => SelectAll(e, "uom")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={uom?.read === 1}
											onChange={(e) => {
												setUom({ ...uom, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : uom.all })
												!e.target.checked? unSelectAll("uom"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={uom?.create === 1}
											onChange={(e) => setUom({ ...uom, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : uom.all, read: e.target.checked ? 1 : uom.read  })}
										/>} label="Create" />
                </div>
                <div className="add-roles-column">
                 <FormControlLabel control={<Checkbox
								 		checked={uom?.edit === 1}
										onChange={(e) => setUom({ ...uom, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : uom.all, read: e.target.checked ? 1 : uom.read })}
								 />} label="Edit" />
                </div>
                <div className="add-roles-column"></div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={uom?.delete === 1}
											onChange={(e) => setUom({ ...uom, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : uom.all, read: e.target.checked ? 1 : uom.read })}
										/>} label="Delete" />
                </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={uom?.expiry_date}
										onChange={(date) => { setUom({ ...uom, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Product request (Add or edit)</Typography>
										<FormControlLabel control={<Checkbox
									checked={productRequest?.all === 1}
									onClick={(e) => SelectAll(e, "productRequest")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={productRequest?.read === 1}
											onChange={(e) => {
												setProductRequest({ ...productRequest, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : productRequest.all })
												!e.target.checked? unSelectAll("productRequest"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column"></div>
                <div className="add-roles-column"></div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={productRequest?.actions.approveReject === 1}
											onChange={(e) => setProductRequest({ ...productRequest, actions: { ...productRequest?.actions, approveReject: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : productRequest.all, read: e.target.checked ? 1 : productRequest.read })}
										/>} label="Approve/reject" />
                </div>
                <div className="add-roles-column"></div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={productRequest?.expiry_date}
										onChange={(date) => { setProductRequest({ ...productRequest, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Add Product</Typography>
										<FormControlLabel control={<Checkbox
									checked={addProduct?.all === 1}
									onClick={(e) => SelectAll(e, "addProduct")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={addProduct?.read === 1}
											onChange={(e) => {
												setAddProduct({ ...addProduct, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : addProduct.all })
												!e.target.checked? unSelectAll("addProduct"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={addProduct?.create === 1}
											onChange={(e) => setAddProduct({ ...addProduct, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : addProduct.all, read: e.target.checked ? 1 : addProduct.read })}
										/>} label="Create" />
                </div>
                <div className="add-roles-column">
                 <FormControlLabel control={<Checkbox
								 		checked={addProduct?.edit === 1}
										onChange={(e) => setAddProduct({ ...addProduct, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : addProduct.all, read: e.target.checked ? 1 : addProduct.read })}
								 />} label="Edit" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={addProduct?.actions.status === 1}
											onChange={(e) => setAddProduct({ ...addProduct, actions: { ...addProduct?.actions, status: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : addProduct.all, read: e.target.checked ? 1 : addProduct.read })}
										/>} label="Status" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={addProduct?.delete === 1}
											onChange={(e) => setAddProduct({ ...addProduct, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : addProduct.all, read: e.target.checked ? 1 : addProduct.read })}
										/>} label="Delete" />
                </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={addProduct?.expiry_date}
										onChange={(date) => { setAddProduct({ ...addProduct, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Order Listing</Typography>
										<FormControlLabel control={<Checkbox
									checked={orderListing?.all === 1}
									onClick={(e) => SelectAll(e, "orderListing")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                  <FormControlLabel control={<Checkbox
										checked={orderListing?.read === 1}
										onChange={(e) => {
											setOrderListing({ ...orderListing, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : orderListing.all })
											!e.target.checked? unSelectAll("orderListing"): null;
										}}
									/>} label="Read" />
                </div>
                <div className="add-roles-column"></div>
                <div className="add-roles-column"style={{width: "300px",}}>
                	<FormControlLabel control={<Checkbox
										checked={orderListing?.actions.readyToPick === 1}
										onChange={(e) => setOrderListing({ ...orderListing, actions: { ...orderListing?.actions, readyToPick: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : orderListing.all, read: e.target.checked ? 1 : orderListing.read })}
									/>} label="Ready to Pick" />
                	<FormControlLabel control={<Checkbox
										checked={orderListing?.actions.cancelOrder === 1}
										onChange={(e) => setOrderListing({ ...orderListing, actions: { ...orderListing?.actions, cancelOrder: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : orderListing.all, read: e.target.checked ? 1 : orderListing.read })}
									/>} label="Cancel order" />
                	<FormControlLabel control={<Checkbox
										checked={orderListing?.actions.acceptOrRejectOrder === 1}
										onChange={(e) => setOrderListing({ ...orderListing, actions: { ...orderListing?.actions, acceptOrRejectOrder: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : orderListing.all, read: e.target.checked ? 1 : orderListing.read })}
									/>} label="Accept or reject order" />
                	<FormControlLabel control={<Checkbox
										checked={orderListing?.actions.confirmPendingOrder === 1}
										onChange={(e) => setOrderListing({ ...orderListing, actions: { ...orderListing?.actions, confirmPendingOrder: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : orderListing.all, read: e.target.checked ? 1 : orderListing.read })}
									/>} label="Confirm pending order" />
                </div>
                <div className="add-roles-column" >
									<FormControl fullWidth>
										<InputLabel id="demo-simple-select-label">Available Types</InputLabel>
										<Select
											labelId="demo-simple-select-label" id="demo-simple-select" label="Available Types"
											value={orderListing.availableTypes || []} multiple={true}
											onChange={(e) => {
												setOrderListing({ ...orderListing, all: 0, read: 1, availableTypes: e.target.value })
											}}
										>
											{businessType.length > 0 && businessType.map((item, index) =>
												<MenuItem key={`businessType-${index}`} value={item.id}>{item.name}</MenuItem>)}
										</Select>
									</FormControl>
								</div>
                <div className="add-roles-column"> </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={orderListing?.expiry_date}
										onChange={(date) => { setOrderListing({ ...orderListing, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
              <div className="add-roles-column">
                <Typography variant="body" component="p" mb={0} fontWeight={500}>CMS</Typography>
								<FormControlLabel control={<Checkbox
									checked={cms?.all === 1}
									onClick={(e) => SelectAll(e, "cms")}
								/>} label="All" />
              </div>
              <div className="add-roles-column">
                <FormControlLabel control={<Checkbox
									checked={cms?.read === 1}
									onChange={(e) => {
										setCms({ ...cms, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : cms.all })
										!e.target.checked? unSelectAll("cms"): null;
									}}
								/>} label="Read" />
              </div>
              <div className="add-roles-column">
                <FormControlLabel control={<Checkbox
									checked={cms?.create === 1}
									onChange={(e) => setCms({ ...cms, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : cms.all, read: e.target.checked ? 1 : cms.read })}
								/>} label="Create" />
              </div>
              <div className="add-roles-column">
               	<FormControlLabel control={<Checkbox
							 		checked={cms?.edit === 1}
									onChange={(e) => setCms({ ...cms, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : cms.all, read: e.target.checked ? 1 : cms.read })}
							 	/>} label="Edit" />
              </div>
              <div className="add-roles-column"></div>
              <div className="add-roles-column">
                <FormControlLabel control={<Checkbox
									checked={cms?.delete === 1}
									onChange={(e) => setCms({ ...cms, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : cms.all, read: e.target.checked ? 1 : cms.read })}
								/>} label="Delete" />
              </div>
							<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={cms?.expiry_date}
										onChange={(date) => { setCms({ ...cms, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Customer Management</Typography>
										<FormControlLabel control={<Checkbox
									checked={customerManagement?.all === 1}
									onClick={(e) => SelectAll(e, "customerManagement")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={customerManagement?.read === 1}
											onChange={(e) => {
												setCustomerManagement({ ...customerManagement, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : customerManagement.all })
												!e.target.checked? unSelectAll("customerManagement"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column"> </div>
                <div className="add-roles-column">
									
								<FormControlLabel control={<Checkbox
											checked={customerManagement?.edit === 1}
											onChange={(e) => setCustomerManagement({ ...customerManagement, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : customerManagement.all, read: e.target.checked ? 1 : customerManagement.read })}
										/>} label="Edit" />
								</div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={customerManagement?.actions.status === 1}
											onChange={(e) => setCustomerManagement({ ...customerManagement, actions: { ...customerManagement?.actions, status: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : customerManagement.all, read: e.target.checked ? 1 : customerManagement.read })}
										/>} label="Status" />
                </div>
                <div className="add-roles-column">
                    {/* <FormControlLabel control={<Checkbox
											checked={customerManagement?.delete === 1}
											onChange={(e) => setCustomerManagement({ ...customerManagement, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : customerManagement.all, read: e.target.checked ? 1 : customerManagement.read })}
										/>} label="Delete" /> */}
                </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={customerManagement?.expiry_date}
										onChange={(date) => { setCustomerManagement({ ...customerManagement, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Wallet Management</Typography>
										<FormControlLabel control={<Checkbox
									checked={walletManagement?.all === 1}
									onClick={(e) => SelectAll(e, "walletManagement")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={walletManagement?.read === 1}
											onChange={(e) => {
												setWalletManagement({ ...walletManagement, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : walletManagement.all })
												!e.target.checked? unSelectAll("walletManagement"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={walletManagement?.create === 1}
											onChange={(e) => setWalletManagement({ ...walletManagement, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : walletManagement.all, read: e.target.checked ? 1 : walletManagement.read })}
										/>} label="Create" />
                </div>
                <div className="add-roles-column"></div>                
                <div className="add-roles-column"></div>
                <div className="add-roles-column"></div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={walletManagement?.expiry_date}
										onChange={(date) => { setWalletManagement({ ...walletManagement, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Manage users</Typography>
										<FormControlLabel control={<Checkbox
									checked={manageUsers?.all === 1}
									onClick={(e) => SelectAll(e, "manageUsers")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={manageUsers?.read === 1}
											onChange={(e) => {
												setManageUsers({ ...manageUsers, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : manageUsers.all })
												!e.target.checked? unSelectAll("manageUsers"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={manageUsers?.create === 1}
											onChange={(e) => setManageUsers({ ...manageUsers, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : manageUsers.all, read: e.target.checked ? 1 : manageUsers.read })}
										/>} label="Create" />
                </div>
                <div className="add-roles-column">
                 	<FormControlLabel control={<Checkbox
								 		checked={manageUsers?.edit === 1}
										onChange={(e) => setManageUsers({ ...manageUsers, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : manageUsers.all, read: e.target.checked ? 1 : manageUsers.read })}
								 	/>} label="Edit" />
                </div>                
                <div className="add-roles-column">
                  <FormControlLabel control={<Checkbox
											checked={manageUsers?.actions.status === 1}
											onChange={(e) => setManageUsers({ ...manageUsers, actions: { ...manageUsers?.actions, status: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : manageUsers.all, read: e.target.checked ? 1 : manageUsers.read })}
									/>} label="Status" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={manageUsers?.delete === 1}
											onChange={(e) => setManageUsers({ ...manageUsers, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : manageUsers.all, read: e.target.checked ? 1 : manageUsers.read })}
										/>} label="Delete" />
                </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={manageUsers?.expiry_date}
										onChange={(date) => { setManageUsers({ ...manageUsers, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Roles & Permissions</Typography>
										<FormControlLabel control={<Checkbox
									checked={rolesPermissions?.all === 1}
									onClick={(e) => SelectAll(e, "rolesPermissions")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={rolesPermissions?.read === 1}
											onChange={(e) => {
												setRolesPermissions({ ...rolesPermissions, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : rolesPermissions.all })
												!e.target.checked? unSelectAll("rolesPermissions"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={rolesPermissions?.create === 1}
											onChange={(e) => setRolesPermissions({ ...rolesPermissions, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : rolesPermissions.all, read: e.target.checked ? 1 : rolesPermissions.read })}
										/>} label="Create" />
                </div>
                <div className="add-roles-column">
                 <FormControlLabel control={<Checkbox
								 		checked={rolesPermissions?.edit === 1}
										onChange={(e) => setRolesPermissions({ ...rolesPermissions, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : rolesPermissions.all, read: e.target.checked ? 1 : rolesPermissions.read })}
								 />} label="Edit" />
                </div>
                <div className="add-roles-column">
                    {/* <FormControlLabel control={<Checkbox
											checked={rolesPermissions?.actions.status === 1}
											onChange={(e) => setRolesPermissions({ ...rolesPermissions, actions: { ...rolesPermissions?.actions, status: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : rolesPermissions.all, read: e.target.checked ? 1 : rolesPermissions.read })}
										/>} label="Status" /> */}
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={rolesPermissions?.delete === 1}
											onChange={(e) => setRolesPermissions({ ...rolesPermissions, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : rolesPermissions.all, read: e.target.checked ? 1 : rolesPermissions.read })}
										/>} label="Delete" />
                </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={rolesPermissions?.expiry_date}
										onChange={(date) => { setRolesPermissions({ ...rolesPermissions, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Payout Management</Typography>
										<FormControlLabel control={<Checkbox
									checked={payoutManagement?.all === 1}
									onClick={(e) => SelectAll(e, "payoutManagement")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={payoutManagement?.read === 1}
											onChange={(e) => {
												setPayoutManagement({ ...payoutManagement, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : payoutManagement.all })
												!e.target.checked? unSelectAll("payoutManagement"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column"></div>
                <div className="add-roles-column"></div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={payoutManagement?.actions.settlePayout === 1}
											onChange={(e) => setPayoutManagement({ ...payoutManagement, actions: { ...payoutManagement?.actions, settlePayout: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : payoutManagement.all, read: e.target.checked ? 1 : payoutManagement.read })}
										/>} label="Settle payout" />
                    <FormControlLabel control={<Checkbox
											checked={payoutManagement?.actions.exportCSV === 1}
											onChange={(e) => setPayoutManagement({ ...payoutManagement, actions: { ...payoutManagement?.actions, exportCSV: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : payoutManagement.all, read: e.target.checked ? 1 : payoutManagement.read })}
										/>} label="Export CSV" />
                </div>
                <div className="add-roles-column"></div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={payoutManagement?.expiry_date}
										onChange={(date) => { setPayoutManagement({ ...payoutManagement, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Driver Onboarding</Typography>
										<FormControlLabel control={<Checkbox
									checked={driverOnboarding?.all === 1}
									onClick={(e) => SelectAll(e, "driverOnboarding")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={driverOnboarding?.read === 1}
											onChange={(e) => {
												setDriverOnboarding({ ...driverOnboarding, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : driverOnboarding.all })
												!e.target.checked? unSelectAll("driverOnboarding"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={driverOnboarding?.create === 1}
											onChange={(e) => setDriverOnboarding({ ...driverOnboarding, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : driverOnboarding.all, read: e.target.checked ? 1 : driverOnboarding.read })}
										/>} label="Create" />
                </div>
                <div className="add-roles-column">
                 <FormControlLabel control={<Checkbox
								 		checked={driverOnboarding?.edit === 1}
										onChange={(e) => setDriverOnboarding({ ...driverOnboarding, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : driverOnboarding.all, read: e.target.checked ? 1 : driverOnboarding.read })}
								 />} label="Edit" />
                </div>
                <div className="add-roles-column"></div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={driverOnboarding?.delete === 1}
											onChange={(e) => setDriverOnboarding({ ...driverOnboarding, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : driverOnboarding.all, read: e.target.checked ? 1 : driverOnboarding.read })}
										/>} label="Delete" />
                </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={driverOnboarding?.expiry_date}
										onChange={(date) => { setDriverOnboarding({ ...driverOnboarding, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
			
            {/* <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Section Management</Typography>
										<FormControlLabel control={<Checkbox
									checked={sectionManagement?.all === 1}
									onClick={(e) => SelectAll(e, "sectionManagement")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={sectionManagement?.read === 1}
											onChange={(e) => {
												setSectionManagement({ ...sectionManagement, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : sectionManagement.all })
												!e.target.checked? unSelectAll("sectionManagement"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={sectionManagement?.create === 1}
											onChange={(e) => setSectionManagement({ ...sectionManagement, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : sectionManagement.all, read: e.target.checked ? 1 : sectionManagement.read })}
										/>} label="Create" />
                </div>
                <div className="add-roles-column">
                 <FormControlLabel control={<Checkbox
								 		checked={sectionManagement?.edit === 1}
										onChange={(e) => setSectionManagement({ ...sectionManagement, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : sectionManagement.all, read: e.target.checked ? 1 : sectionManagement.read })}
								 />} label="Edit" />
                </div>                
                <div className="add-roles-column"></div>
                <div className="add-roles-column">
                </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={sectionManagement?.expiry_date}
										onChange={(date) => { setSectionManagement({ ...sectionManagement, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div> */}
            <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Zone</Typography>
										<FormControlLabel control={<Checkbox
									checked={zone?.all === 1}
									onClick={(e) => SelectAll(e, "zone")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={zone?.read === 1}
											onChange={(e) => {
												setZone({ ...zone, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : zone.all })
												!e.target.checked? unSelectAll("zone"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={zone?.create === 1}
											onChange={(e) => setZone({ ...zone, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : zone.all, read: e.target.checked ? 1 : zone.read })}
										/>} label="Create" />
                </div>
                <div className="add-roles-column">
                 <FormControlLabel control={<Checkbox
								 		checked={zone?.edit === 1}
										onChange={(e) => setZone({ ...zone, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : zone.all, read: e.target.checked ? 1 : zone.read })}
								 />} label="Edit" />
                </div>
                <div className="add-roles-column"></div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={zone?.delete === 1}
											onChange={(e) => setZone({ ...zone, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : zone.all, read: e.target.checked ? 1 : zone.read })}
										/>} label="Delete" />
                </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={zone?.expiry_date}
										onChange={(date) => { setZone({ ...zone, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Banner</Typography>
										<FormControlLabel control={<Checkbox
									checked={banner?.all === 1}
									onClick={(e) => SelectAll(e, "banner")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={banner?.read === 1}
											onChange={(e) => {
												setBanner({ ...banner, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : banner.all })
												!e.target.checked? unSelectAll("banner"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={banner?.create === 1}
											onChange={(e) => setBanner({ ...banner, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : banner.all, read: e.target.checked ? 1 : banner.read })}
										/>} label="Create" />
                </div>
                <div className="add-roles-column">
                 <FormControlLabel control={<Checkbox
								 		checked={banner?.edit === 1}
										onChange={(e) => setBanner({ ...banner, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : banner.all, read: e.target.checked ? 1 : banner.read })}
								 />} label="Edit" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={banner?.actions.status === 1}
											onChange={(e) => setBanner({ ...banner, actions: { ...banner?.actions, status: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : banner.all, read: e.target.checked ? 1 : banner.read })}
										/>} label="Status" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={banner?.delete === 1}
											onChange={(e) => setBanner({ ...banner, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : banner.all, read: e.target.checked ? 1 : banner.read })}
										/>} label="Delete" />
                </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={banner?.expiry_date}
										onChange={(date) => { setBanner({ ...banner, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
						<div className="add-roles-row">
                <div className="add-roles-column">
                    <Typography variant="body" component="p" mb={0} fontWeight={500}>Coupon</Typography>
										<FormControlLabel control={<Checkbox
									checked={coupon?.all === 1}
									onClick={(e) => SelectAll(e, "coupon")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={coupon?.read === 1}
											onChange={(e) => {
												setCoupon({ ...coupon, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : coupon.all })
												!e.target.checked? unSelectAll("coupon"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={coupon?.create === 1}
											onChange={(e) => setCoupon({ ...coupon, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : coupon.all, read: e.target.checked ? 1 : coupon.read })}
										/>} label="Create" />
                </div>
                <div className="add-roles-column">
                 <FormControlLabel control={<Checkbox
								 		checked={coupon?.edit === 1}
										onChange={(e) => setCoupon({ ...coupon, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : coupon.all, read: e.target.checked ? 1 : coupon.read })}
								 />} label="Edit" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={coupon?.actions.status === 1}
											onChange={(e) => setCoupon({ ...coupon, actions: { ...coupon?.actions, status: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : coupon.all, read: e.target.checked ? 1 : coupon.read })}
										/>} label="Status" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={coupon?.delete === 1}
											onChange={(e) => setCoupon({ ...coupon, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : coupon.all, read: e.target.checked ? 1 : coupon.read })}
										/>} label="Delete" />
                </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={coupon?.expiry_date}
										onChange={(date) => { setCoupon({ ...coupon, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>

						<div className="add-roles-row">
                <div className="add-roles-column">
                  <Typography variant="body" component="p" mb={0} fontWeight={500}>Cuisine Management</Typography>
									<FormControlLabel control={<Checkbox
									checked={cuisine?.all === 1}
									onClick={(e) => SelectAll(e, "cuisine")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={cuisine?.read === 1}
											onChange={(e) => {
												setCuisine({ ...cuisine, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : cuisine.all })
												!e.target.checked? unSelectAll("cuisine"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={cuisine?.create === 1}
											onChange={(e) => setCuisine({ ...cuisine, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : cuisine.all, read: e.target.checked ? 1 : cuisine.read })}
										/>} label="Create" />
                </div>
                <div className="add-roles-column">
                 <FormControlLabel control={<Checkbox
								 		checked={cuisine?.edit === 1}
										onChange={(e) => setCuisine({ ...cuisine, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : cuisine.all, read: e.target.checked ? 1 : cuisine.read })}
								 />} label="Edit" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={cuisine?.actions.status === 1}
											onChange={(e) => setCuisine({ ...cuisine, actions: { ...cuisine?.actions, status: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : cuisine.all, read: e.target.checked ? 1 : cuisine.read })}
										/>} label="Status" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={cuisine?.delete === 1}
											onChange={(e) => setCuisine({ ...cuisine, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : cuisine.all, read: e.target.checked ? 1 : cuisine.read })}
										/>} label="Delete" />
                </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={cuisine?.expiry_date}
										onChange={(date) => { setCuisine({ ...cuisine, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>


						<div className="add-roles-row">
                <div className="add-roles-column">
                  <Typography variant="body" component="p" mb={0} fontWeight={500}>Admin Setting</Typography>
									<FormControlLabel control={<Checkbox
									checked={adminSetting?.all === 1}
									onClick={(e) => SelectAll(e, "adminSetting")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={adminSetting?.read === 1}
											onChange={(e) => {
												setAdminSetting({ ...adminSetting, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : adminSetting.all })
												!e.target.checked? unSelectAll("adminSetting"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column"></div>
                <div className="add-roles-column">
                 <FormControlLabel control={<Checkbox
								 		checked={adminSetting?.edit === 1}
										onChange={(e) => setAdminSetting({ ...adminSetting, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : adminSetting.all, read: e.target.checked ? 1 : adminSetting.read })}
								 />} label="Edit" />
                </div>
                <div className="add-roles-column"></div>
                <div className="add-roles-column"></div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={adminSetting?.expiry_date}
										onChange={(date) => { setAdminSetting({ ...adminSetting, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>

						<div className="add-roles-row">
                <div className="add-roles-column">
                  <Typography variant="body" component="p" mb={0} fontWeight={500}>Offer/Discount Management</Typography>
									<FormControlLabel control={<Checkbox
									checked={offerDiscount?.all === 1}
									onClick={(e) => SelectAll(e, "offerDiscount")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={offerDiscount?.read === 1}
											onChange={(e) => {
												setOfferDiscount({ ...offerDiscount, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : offerDiscount.all })
												!e.target.checked? unSelectAll("offerDiscount"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={offerDiscount?.create === 1}
											onChange={(e) => setOfferDiscount({ ...offerDiscount, create: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : offerDiscount.all, read: e.target.checked ? 1 : offerDiscount.read })}
										/>} label="Create" />
                </div>
                <div className="add-roles-column">
                 <FormControlLabel control={<Checkbox
								 		checked={offerDiscount?.edit === 1}
										onChange={(e) => setOfferDiscount({ ...offerDiscount, edit: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : offerDiscount.all, read: e.target.checked ? 1 : offerDiscount.read })}
								 />} label="Edit" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={offerDiscount?.actions.status === 1}
											onChange={(e) => setOfferDiscount({ ...offerDiscount, actions: { ...offerDiscount?.actions, status: e.target.checked ? 1 : 0 }, all: !e.target.checked ? 0 : offerDiscount.all, read: e.target.checked ? 1 : offerDiscount.read })}
										/>} label="Status" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={offerDiscount?.delete === 1}
											onChange={(e) => setOfferDiscount({ ...offerDiscount, delete: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : offerDiscount.all, read: e.target.checked ? 1 : offerDiscount.read })}
										/>} label="Delete" />
                </div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={offerDiscount?.expiry_date}
										onChange={(date) => { setOfferDiscount({ ...offerDiscount, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>

						<div className="add-roles-row">
                <div className="add-roles-column">
                  <Typography variant="body" component="p" mb={0} fontWeight={500}>Picker Tracking</Typography>
									<FormControlLabel control={<Checkbox
									checked={pickerTracking?.all === 1}
									onClick={(e) => SelectAll(e, "pickerTracking")}
								/>} label="All" />
                </div>
                <div className="add-roles-column">
                    <FormControlLabel control={<Checkbox
											checked={pickerTracking?.read === 1}
											onChange={(e) => {
												setPickerTracking({ ...pickerTracking, read: e.target.checked ? 1 : 0, all: !e.target.checked ? 0 : pickerTracking.all })
												!e.target.checked? unSelectAll("pickerTracking"): null;
											}}
										/>} label="Read" />
                </div>
                <div className="add-roles-column"></div>
                <div className="add-roles-column"></div>
                <div className="add-roles-column"></div>
                <div className="add-roles-column"></div>
								<div className="add-roles-column">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire Date"
                    inputFormat="DD/MM/YYYY"
										minDate={new Date()}
										value={pickerTracking?.expiry_date}
										onChange={(date) => { setPickerTracking({ ...pickerTracking, expiry_date: date }) }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>

          </Box>

					<Box className="white-box-20">
						<Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
					</Box>
        </Box>
      </Box>
    </>
  );
}
