import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Boxed } from "../../../../Styles-Elements/Box";
import modalClose from "../../../../assets/images/structure/close-modal.png";
import BackIcon from "../../../../assets/images/structure/arrow-left-black.svg";
import {
  Button,
  Divider,
  Box,
  Typography,
  Alert,
  Dialog,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Aside from "../../../layout/aside/Aside";
import Header from "../../../layout/header/Header";
import deliverIcon from "../../../../assets/images/structure/deliver-icon.svg";
import DownloadIcon from "../../../../assets/images/structure/download-icon.svg";
import foodImg from "../../../../assets/images/product/product-food2.png";
import services from "../../../../services";
import moment from "moment";
import OrderItem from "./OrderItem";
import constant from "../../../../constant";
import TrackingComponent from  './TrackingComponent';

const CancelledTab = () => {
  const statusRedirect = {
    placed: "orders",
    processing: "processing",
    readytopick: "ready-to-pick",
    shipped: "shipped",
    completed: "completed",
    rejected: "rejected",
    cancelled: "cancelled",
    pending: "pending"
  }
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [productDetails, setProductDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState();
  const [orderTracker, setOrderTracker] = useState([]);
  const [allOrderDetails, setAllOrderDetails] = useState([]);
  const [refundArray, setRefundArray] = useState(null);
  const [picker, setPicker] = useState("");
  const [qc, setQc] = useState([]);
  const [lastValue, setLastValue] = useState(null);
  let [refundPopUp, setRefundPopUp] = useState(false);
  let [refundReason, setRefundReason] = useState("");
  let [refundCustomerAmount, setRefundCustomerAmount] = useState(null);
  let [refundStoreAmount, setRefundStoreAmount] = useState(null);
  let [refundReasonError, setRefundReasonError] = useState("");
  let [refundCustomerAmountError, setRefundCustomerAmountError] = useState("");
  let [refundStoreAmountError, setRefundStoreAmountError] = useState("");
  let [addressData, setAddressData] = useState(null);
  let [storeRefundAmount, setStoreRefundAmount] = useState(0);
  let [customerRefundAmount, setCustomerRefundAmount] = useState(0);
  const [wallet, setWallet] = useState(0);
  let [store, setStore] = useState(null);
  let [cancelledBy, setCancelledBy] = useState("Admin");
  const [track, setTrack] = useState();

  let allstatus = [];
  const [toaster, setToaster] = useState({
    visibilty: false,
    status: "",
    text: "",
  });
  useEffect(() => {
    getOrderDetails(orderId);
  }, []);
  const [rating, setRating] = useState([]);

  const getRating = (utype = "store", key = "rating") => {
    let result = { rating: 0, review: "" };
    if (rating) {
      let res = rating.filter((item) => {
        if (item.driverId == 0 && utype == "store") {
          return item;
        }
        if (item.storeId == 0 && utype == "driver") {
          return item;
        }
      });

      if (res?.length) {
        result = { rating: Number(res[0]?.rating), review: res[0]?.review };
      }
    }
    return result[key];
  };

  const getOrderDetails = async (orderId) => {
    try {
      const responseOrder = (await services.Orders.GET_ORDER_BY_ID(orderId))
        .data;
      if (responseOrder?.orders.status !== 'cancelled') {
        navigate(`/order-manage-detail/${statusRedirect[responseOrder?.orders.status]}/${orderId}`);
      }
      
      const map2 = responseOrder.orderLogs.map(
        (item) => item.updated_at
      );
      const lastValue2 = map2[map2.length - 2];
      const map = responseOrder.orderLogs.map(
        (item) => item.updated_at
      );
      setTrack(responseOrder.orderLogs)
      const lastValue1 = map[map.length - 1];

      setLastValue(lastValue1 - lastValue2);

      setPicker(responseOrder?.pickers);
      setQc(responseOrder?.qcs);
      if (responseOrder.transaction.length > 0) {
        const walletAmount = responseOrder.transaction.find(
          (item) => item.method === "wallet"
        );
        setWallet(walletAmount?.amount);
      }
      const response = responseOrder;
      let total_MRP = 0;
      responseOrder?.order_details?.map((item) => {
        total_MRP = total_MRP + item.price * +item.quantity;
      });
      addressData =
        response?.orders?.addressJson && response?.orders?.addressJson !== ""
          ? JSON.parse(response?.orders?.addressJson)
          : null;
      setAddressData(addressData);
      store = response?.orders?.store;
      setStore(store);

      setAllOrderDetails(response);
      const placesData = responseOrder?.orderLogs?.filter((item) => item.status === "placed");
      setOrderDetails({ ...response?.orders, MRP: total_MRP, createdAt: placesData[0]?.updated_at });
      setProductDetails(responseOrder.order_details);
      const orderTracker = responseOrder.orderLogs.filter((item) => item.role === undefined);
      setRating(response?.orders?.review_ratings);
      order(orderTracker);
      const cancelTransaction = orderTracker?.find(
        (item) => item?.status === "cancelled"
      );
      storeRefundAmount = cancelTransaction.store_refund
        ? cancelTransaction.store_refund
        : 0;
      setStoreRefundAmount(storeRefundAmount);
      customerRefundAmount = cancelTransaction.user_refund
        ? cancelTransaction.user_refund
        : 0;
      setCustomerRefundAmount(customerRefundAmount);
    } catch (error) {}
  };

  const refundOrder = async (e) => {
    e.preventDefault();
    let isValid = true;
    if (refundReason === "") {
      setRefundReasonError("Please enter refund reason");
      isValid = false;
    }
    if (refundCustomerAmount === null || refundCustomerAmount === "") {
      setRefundCustomerAmountError("Please enter refund amount");
      isValid = false;
    } else if (refundCustomerAmount < 0) {
      setRefundCustomerAmountError("Please enter valid refund amount");
      isValid = false;
    }
    if (refundStoreAmount === null || refundStoreAmount === "") {
      setRefundStoreAmountError("Please enter refund amount");
      isValid = false;
    } else if (refundStoreAmount < 0) {
      setRefundStoreAmountError("Please enter valid refund amount");
      isValid = false;
    }
    if (isValid) {
      const updatedData = {
        reason_of_reject: refundReason,
        refund_amount: refundCustomerAmount,
        store_payout: refundStoreAmount,
        status: "cancelled",
        request_type: "refund",
      };
      const response = await services.Orders.UPDATE_ORDER(orderId, updatedData);
      if (response) {
        setToaster({
          status: "success",
          text: `Order has been refunded successfully`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 6000);
        getOrderDetails(orderId);
        setRefundPopUp(false);
      }
    }
  };

  const order = (_orderTracker) => {
    const orderArr = [];
    const orderTracker = _orderTracker.sort(
      (a, b) => a.updated_at - b.updated_at
    );
    const OrderReceived = orderTracker.filter(
      (stat) => stat?.status === "placed"
    ).pop();
    orderArr.push({
      status: "Order Received",
      class: "done",
      updated_at: OrderReceived?moment(new Date(OrderReceived.updated_at)).format(
        "DD MMMM YYYY - hh:mm A"
      ):"-"
    });
    const Processing = orderTracker.filter(
      (stat) => stat?.status === "processing"
    ).pop();
    if (Processing) {
      orderArr.push({
        status: "Processing",
        class: "done",
        updated_at: Processing?moment(new Date(Processing.updated_at)).format(
          "DD MMMM YYYY - hh:mm A"
        ): "-",
      });
    }
    const readyToPick = orderTracker.filter(
      (stat) => stat?.status === "readytopick"
    ).pop();
    if (readyToPick) {
      orderArr.push({
        status: "Ready To Pick",
        class: "done",
        updated_at: readyToPick?moment(new Date(readyToPick.updated_at)).format(
          "DD MMMM YYYY - hh:mm A"
        ): "-",
      });
    }
    const shipped = orderTracker.filter((stat) => stat?.status === "shipped").pop();
    if (shipped) {
      orderArr.push({
        status: "Shipped",
        class: "active",
        updated_at: shipped?moment(new Date(shipped?.updated_at)).format(
          "DD MMMM YYYY - hh:mm A"
        ): "-",
      });
    }
    const cancelled = orderTracker.filter((stat) => stat?.status === "cancelled").pop();
    orderArr.push({
      status: "Cancelled",
      class: "active",
      updated_at: cancelled?moment(new Date(cancelled?.updated_at)).format(
        "DD MMMM YYYY - hh:mm A"
      ): "-",
    });
    setOrderTracker(orderArr);
  };

  const downloadInvoice = async () => {
    try {
      const response = (await services.Orders.GET_ORDER_INVOICE(orderId)).data;
      if (response.ack == 1) {
        window.open(constant.IMG_BASE_URL + response.invoice, "_blank");
      }
    } catch (error) {}
  };
const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('tabtype');
const goBack = () => myParam === "false"?
  navigate(`/order-listing?${orderDetails.storeId === 1? 'isbaladi=baladi&':''}status=cancelled`):
  navigate(`/order-listing?${orderDetails.storeId === 1? 'isbaladi=baladi&':''}status=cancelledBaladi`);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty && (
            <Alert severity={toaster.status}>{toaster.text}</Alert>
          )}
          <Box className="white-box-20-30">
            <div className="order-listing-wrapper">
              <div className="">
                <div className="tabs-wrap">
                  <div className="table-type-parent">
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      mb={"20px"}
                    >
                      <div className="store-detail-head">
                        <img
                          src={BackIcon}
                          alt="back-icon"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={goBack}
                        />
                        <Typography
                          variant="h6"
                          component="h6"
                          mb={0}
                          fontWeight={500}
                        >
                          Cancelled Orders
                        </Typography>
                      </div>
                    </Box>
                    <div className="table-type-box">
                      <div className="table-type-td flex">
                        <img
                          src={deliverIcon}
                          alt="deliver"
                          className="icon24"
                        />
                        <div className="relative">
                          <span className="type-th">Order Type</span>
                          <h3 className="type-td">
                            Deliver {orderDetails?.deliver}
                          </h3>
                        </div>
                      </div>
                      <div className="table-type-td">
                        <span className="type-th">Order ID</span>
                        <h3 className="type-td width120">
                          #{orderDetails?.order_id}
                        </h3>
                      </div>
                      <div className="table-type-td">
                        <span className="type-th">Customer Name</span>
                        <h3 className="type-td width120">
                          {orderDetails?.user?.full_name}
                        </h3>
                      </div>
                      <div className="table-type-td">
                        <span className="type-th">No. of Items</span>
                        <h3 className="type-td">
                          {orderDetails?.total_quantity} Items
                        </h3>
                      </div>
                      <div className="table-type-td">
                        <span className="type-th">Total Amount</span>
                        <h3 className="type-td">
                          QAR {+orderDetails?.total_amount?.toFixed(2)}
                        </h3>
                      </div>
                      <div className="table-type-td"></div>
                    </div>
                    <div className="table-type-body">
                      <div className="table-type-body-inner-flex">
                        <div className="left-detail-box">
                          <h6 className="h6">Product Details</h6>
                          <Table className="product-table">
                            <TableBody>
                              {productDetails &&
                                productDetails?.length > 0 &&
                                productDetails?.map((item, index) => (
                                  <OrderItem
                                    key={index}
                                    orderItem={item}
                                    storeId={store?.id}
                                  />
                                ))}
                            </TableBody>
                          </Table>

                          <Divider style={{ marginTop: "15px" }} />
                          <h6 className="h6 gray" style={{ marginTop: "20px" }}>
                            Order Tracking
                          </h6>
                          <div className="order-tracking-box">
                          <TrackingComponent
                            status= {orderDetails?.status}
                            tracking={'baladi'}
                            orderTracker={track}
                          />
                            {/* {orderTracker &&
                              orderTracker?.length > 0 &&
                              orderTracker?.map((item, index) => {
                                if (allstatus.indexOf(item?.status) == -1) {
                                  allstatus.push(item?.status);
                                  return (
                                    <div
                                      key={index}
                                      className={`order-status ${
                                        item?.class ? item.class : ""
                                      }`}
                                    >
                                      <p className="p2">{item?.status}</p>
                                      <p className="p4">{item?.updated_at}</p>
                                    </div>
                                  );
                                }
                              })} */}
                          </div>
                          <Divider style={{ marginTop: "15px" }} />
                          {rating?.length ? (
                            <Box display={"flex"} alignItems={"center"}>
                              <Box pr={2} className="raing-50">
                                <h6
                                  className="h6 gray"
                                  style={{ marginTop: "20px" }}
                                >
                                  Rating & Review
                                </h6>
                                {rating?.length ? (
                                  <Rating
                                    name="rating-read"
                                    defaultValue={Number(
                                      getRating("store", "rating")
                                    )}
                                    readOnly
                                    emptyIcon={
                                      <StarIcon
                                        style={{ opacity: 0.55 }}
                                        fontSize="inherit"
                                      />
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                                <p className="p2">
                                  {getRating("store", "review")}
                                </p>
                              </Box>
                              <Box pl={2}>
                                <h6
                                  className="h6 gray"
                                  style={{ marginTop: "20px" }}
                                >
                                  Driver Rating & Review
                                </h6>
                                {rating?.length ? (
                                  <Rating
                                    name="rating-read"
                                    defaultValue={Number(
                                      getRating("driver", "rating")
                                    )}
                                    readOnly
                                    emptyIcon={
                                      <StarIcon
                                        style={{ opacity: 0.55 }}
                                        fontSize="inherit"
                                      />
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                                <p className="p2">
                                  {getRating("driver", "review")}
                                </p>
                              </Box>
                            </Box>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="right-detail-box">
                          <h6 className="h6 mb-4">Payment Details</h6>
                          <div className="payment-value">
                            <span className="p2">MRP</span>
                            <span
                              className={`p2-bold ${
                                orderDetails?.MRP !== orderDetails?.sub_total
                                  ? "through-text"
                                  : ""
                              }`}
                            >
                              {orderDetails?.MRP !== orderDetails?.sub_total ? (
                                <strike>
                                  QAR {orderDetails?.MRP?.toFixed(2)}
                                </strike>
                              ) : (
                                <span>QAR {orderDetails?.MRP?.toFixed(2)}</span>
                              )}
                            </span>
                          </div>
                          {orderDetails?.MRP !== orderDetails?.sub_total ? (
                            <div className="payment-value">
                              <span className="p2">Discounted Price</span>
                              <span className="p2-bold">
                                QAR {orderDetails?.sub_total?.toFixed(2)}
                              </span>
                            </div>
                          ) : null}
                          {orderDetails?.delivery_charges > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Delivery Charges</span>
                              <span className="p2-bold">
                                QAR {orderDetails?.delivery_charges?.toFixed(2)}
                              </span>
                            </div>
                          ) : null}
                          {orderDetails?.tax > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Tax</span>
                              <span className="p2-bold">
                                QAR {orderDetails?.tax?.toFixed(2)}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {orderDetails?.discount > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Coupon Discount</span>
                              <span className="p2-bold">
                                - QAR {orderDetails?.discount.toFixed(2)}
                              </span>
                            </div>
                          ) : null}

                          {orderDetails?.offer_amount > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Offer/Discount</span>
                              <span className="p2-bold">
                                - QAR {orderDetails?.offer_amount.toFixed(2)}
                              </span>
                            </div>
                          ) : null}
                          
                          {wallet > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Wallet</span>
                              <span className="p2-bold">
                                - QAR {wallet.toFixed(2)}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          <Divider />
                          <div className="payment-value-total">
                            <span className="p2">
                              {wallet > 0 ? "Net Pay" : "Total Amount"}
                            </span>
                            <span className="p2">
                              QAR{" "}
                              {(orderDetails?.total_amount - (wallet || 0))?.toFixed(
                                2
                              )}
                            </span>
                          </div>
                          <div className="pink-box">
                            {customerRefundAmount > 0 ? (
                              <div className="payment-value">
                                <span className="p2">Customer Refund</span>
                                <span className="p2-bold">
                                  QAR {Number(customerRefundAmount)?.toFixed(2)}
                                </span>
                              </div>
                            ) : null}
                            {storeRefundAmount > 0 ? (
                              <div className="payment-value">
                                <span className="p2">Store Payout</span>
                                <span className="p2-bold">
                                  QAR {Number(storeRefundAmount)?.toFixed(2)}
                                </span>
                              </div>
                            ) : null}
                          </div>
                          <Divider className="my-5" />
                          <Boxed
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"flex-end"}
                          >
                            <h6 className="h6 mb-4">Other Details</h6>
                          </Boxed>
                          <div>
                            <div className="relative mb-4">
                              <p className="p3">Order Date</p>
                              <p className="p2">
                                {moment(orderDetails?.createdAt).format(
                                  "DD MMMM YYYY hh:mm a"
                                )}
                              </p>
                            </div>
                            <div className="relative mb-4">
                              <p className="p3">Payment Method</p>
                              <p className="p2">
                                {orderDetails?.payment_method}
                              </p>
                            </div>
                            <div className="relative">
                              <p className="p3">Customer Details</p>
                              <p className="p2">
                                {addressData?.full_name} |{" "}
                                {`+${orderDetails?.user?.country_code} ${addressData?.contact_number}`}{" "}
                                | {orderDetails?.user?.email}
                              </p>
                              <Button
                                  variant="contained"
                                  size="small"
                                  className="secondary"
                                  onClick={() => {
                                    navigate(`/manage-customer-Details/${orderDetails?.user?.id}`);
                                  }}
                                > View Customer </Button>
                            </div>
                            <Divider />
                            <div className="relative mb-4">
                              <p className="p3">Vendor Details</p>
                              <p className="p2">
                                  {store?.stores_locales[0].business_name}
                                  {store?.country_code !== null && store?.contact_number !==null?<>
                                    {" "} | {" "}
                                  {`${store?.country_code !== null?'+'+store?.country_code:''} ${store?.contact_number !==null?store?.contact_number:''}`}{" "}
                                  </>: null}
                                  {" "} | {store?.business_address}
                                </p>
                            </div>
                            <Divider />
                              <div className="relative">
                                <p className="p3">Delivery Partner</p>
                                {store?.market_place === 1?
                                  <p className="p2">MarketPlace</p>:
                                  <p className="p2 capitalize">{store?.deliveryPartner}</p>
                                }
                              </div>
                            {orderDetails?.driverId > 0 ? (
                              <div className="relative">
                                <p className="p3">Driver Details</p>
                                <p className="p2">{`${orderDetails?.driver?.first_name} ${orderDetails?.driver?.last_name}`}</p>
                                <p className="p2">
                                  {orderDetails?.driver?.phone}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <Divider className="my-5" />

                          <div className="relative">
                            <p className="p3 semi">Cancellation Reason</p>

                            {orderDetails?.rejected_by !== 0 ? (
                              <p className="p2">
                                {`Cancelled by ${
                                  orderDetails?.rejected_by_user.role
                                    .charAt(0)
                                    .toUpperCase() +
                                  orderDetails?.rejected_by_user.role.slice(1)
                                }`}
                                <p className="p2">
                                  {orderDetails?.rejected_by_user?.full_name}
                                </p>
                              </p>
                            ) : null}
                            <p className="p2">
                              {orderDetails?.reason_of_reject}
                            </p>
                          </div>
                          {picker.length > 0 && picker.map((item, index) => {
                            return (
                              <>
                                <Divider className="my-2" />
                                <div className="relative">
                                  <p className="p3">Picker Details</p>
                                  <p className="p2">
                                    Picker Name - {item?.first_name}{" "}
                                    {item?.last_name} <br />
                                    Picker Email - {item?.email}
                                  </p>
                                </div>
                                <Divider />
                              </>
                            );
                          })}

                          {qc?.length > 0 && qc.map((item, index) => {
                            return (
                              <>
                                <Divider className="my-2" />
                                <div className="relative">
                                  <p className="p3">QC Details</p>
                                  <p className="p2">
                                    QC Name - {item?.first_name}{" "}
                                    {item?.last_name} <br />
                                    QC Email - {item?.email}
                                  </p>
                                </div>
                                <Divider />
                              </>
                            );
                          })}

                          <Divider className="my-2" />
                          <p className="p3"> Time</p>
                          <p className="p2">Ideal Time</p>
                          <p className="p2">
                          Actual Time - {(lastValue / 1000 / 60) | 0} Min
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={refundPopUp}
        onClose={() => {
          setRefundPopUp(false);
        }}
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => {
            setRefundPopUp(false);
          }}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Refund Order
          </Typography>
          <form onSubmit={refundOrder}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Refund Reason*"
                  multiline
                  rows={2}
                  value={refundReason}
                  inputProps={{
                    maxLength: 250,
                  }}
                  onChange={(e) => {
                    setRefundReason(e.target.value);
                    setRefundReasonError("");
                  }}
                />
                {refundReasonError !== "" ? (
                  <div className="error">{refundReasonError}</div>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={refundCustomerAmount}
                  label="Customer Refund Amount*"
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  onChange={(e) => {
                    setRefundCustomerAmount(e.target.value);
                    setRefundCustomerAmountError("");
                  }}
                />
                {refundCustomerAmountError !== "" ? (
                  <div className="error">{refundCustomerAmountError}</div>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Store Payout Amount*"
                  type="number"
                  value={refundStoreAmount}
                  inputProps={{
                    min: 0,
                  }}
                  onChange={(e) => {
                    setRefundStoreAmount(e.target.value);
                    setRefundStoreAmountError("");
                  }}
                />
                {refundStoreAmountError !== "" ? (
                  <div className="error">{refundStoreAmountError}</div>
                ) : null}
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CancelledTab;
