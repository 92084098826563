import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import modalClose from "../../../../assets/images/structure/close-modal.png";
import BackIcon from "../../../../assets/images/structure/arrow-left-black.svg";
import { Boxed } from "../../../../Styles-Elements/Box";
import {
  Button,
  Divider,
  Box,
  Typography,
  Alert,
  Dialog,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Aside from "../../../layout/aside/Aside";
import Header from "../../../layout/header/Header";
import deliverIcon from "../../../../assets/images/structure/deliver-icon.svg";
import DownloadIcon from "../../../../assets/images/structure/download-icon.svg";
import foodImg from "../../../../assets/images/product/product-food2.png";
import services from "../../../../services";
import moment from "moment";
import constant from "../../../../constant";
import { getdataByKey, getUomImage } from "../../../helper/custom";
import OrderItem from "./OrderItem";
import GlobalService from "../../../../services/GlobalService";
import { API } from "../../../ENUM";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../../redux/reducers/loader";
import { changeSnackbar } from "../../../../redux/reducers/snackbar";
import TrackingComponent from  './TrackingComponent';
import { currentBaladiInfo } from "../../../../redux/reducers/baladiInfo";

const CompletedTab = () => {
  const statusRedirect = {
    placed: "orders",
    processing: "processing",
    readytopick: "ready-to-pick",
    shipped: "shipped",
    completed: "completed",
    rejected: "rejected",
    cancelled: "cancelled",
    pending: "pending"
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentBaladiInfoObj = useSelector(currentBaladiInfo);
  const { orderId } = useParams();
  const [idealTIme, setIdealTIme] = useState("");
  const [productDetails, setProductDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState();
  const [orderTracker, setOrderTracker] = useState([]);
  const [rating, setRating] = useState([]);
  const [picker, setPicker] = useState("");
  const [qc, setQc] = useState([]);
  let [refundPopUp, setRefundPopUp] = useState(false);
  let [refundReason, setRefundReason] = useState("");
  let [refundCustomerAmount, setRefundCustomerAmount] = useState(null);
  let [refundStoreAmount, setRefundStoreAmount] = useState(null);
  let [refundReasonError, setRefundReasonError] = useState("");
  let [refundCustomerAmountError, setRefundCustomerAmountError] = useState("");
  let [refundStoreAmountError, setRefundStoreAmountError] = useState("");
  let [addressData, setAddressData] = useState(null);
  const [wallet, setWallet] = useState(0);
  const [lastValue, setLastValue] = useState(null);
  let [store, setStore] = useState(null);
  const [stats, setStats] = useState(null);
  const [tracking, setTracking] = useState("");
  const [track, setTrack] = useState();
  const [vendortiming, setVendortiming] = useState({});

  let allstatus = [];
  useEffect(() => {
    getOrderDetails(orderId);
  }, []);

  const getall = async () => {
    const res = await GlobalService(API.TRAFFIC_LIGHT_SYSTEM.GET_ALL);
    setIdealTIme(res?.results[5]?.time);
  };
  useEffect(() => {
    getall();
  });

  const getOrderDetails = async (orderId) => {
    try {
      const responseOrder = (await services.Orders.GET_ORDER_BY_ID(orderId))
        .data;
      if (responseOrder?.orders.status !== 'completed') {
        navigate(`/order-manage-detail/${statusRedirect[responseOrder?.orders.status]}/${orderId}`);
      }
      setTracking(responseOrder?.orders?.store?.slug);
      const map2 = responseOrder.orderLogs.map(
        (item) => item.updated_at
      );
      const lastValue2 = map2[map2.length - 2];
      const map = responseOrder.orderLogs.map(
        (item) => item.updated_at
      );
      const lastValue1 = map[map.length - 1];
      const completeOrder = responseOrder?.orderLogs?.find((item) => item.status === "completed");
      setStats(completeOrder);
      setLastValue(lastValue1 - lastValue2);
      setPicker(responseOrder?.pickers);
      setQc(responseOrder?.qcs);
      if (responseOrder.transaction.length > 0) {
        const walletAmount = responseOrder.transaction.find((item) => item.method === "wallet" && item.type !== "refund");
        setWallet(walletAmount?.amount);
      }
      const response = responseOrder?.orders;
      let total_MRP = 0;
      responseOrder.order_details?.map((item) => {
        total_MRP = total_MRP + item.price * +item.quantity;
      });
      addressData =
        response?.addressJson && response?.addressJson !== ""
          ? JSON.parse(response?.addressJson)
          : null;
      store = response?.store;
      setStore(store);
      setAddressData(addressData);
      const placesData = responseOrder?.orderLogs?.filter((item) => item.status === "placed");
      setOrderDetails({ ...response, MRP: total_MRP, createdAt: placesData[0]?.updated_at });
      setVendortiming(JSON.parse(responseOrder?.ManageWorkingHour?.timejson || '[]'))
      setProductDetails(responseOrder.order_details);
      setRating(response?.review_ratings);
      const orderTracker = responseOrder.orderLogs;
      setTrack(orderTracker);
    } catch (error) {}
  };

  let drating = 0;
  const getRating = (utype = "store", key = "rating") => {
    let result = { rating: 0, review: "" };
    if (rating) {
      let res = rating.filter((item) => {
        if (item.driverId == 0 && utype == "store") {
          return item;
        }
        if (item.storeId == 0 && utype == "driver") {
          return item;
        }
      });
      if (res.length) {
        result = { rating: Number(res[0]?.rating), review: res[0]?.review };
      }
    }
    return result[key];
  };

  const refundOrder = async (e) => {
    dispatch(changeLoader(true));
    setRefundPopUp(false);
    e.preventDefault();
    let isValid = true;
    if (refundReason === "") {
      setRefundReasonError("Please enter refund reason");
      isValid = false;
    }
    if (refundCustomerAmount === null || refundCustomerAmount === "") {
      setRefundCustomerAmountError("Please enter refund amount");
      isValid = false;
    } else if (refundCustomerAmount < 0) {
      setRefundCustomerAmountError("Please enter valid refund amount");
      isValid = false;
    }
    if (refundStoreAmount === null || refundStoreAmount === "") {
      setRefundStoreAmountError("Please enter refund amount");
      isValid = false;
    } else if (refundStoreAmount < 0) {
      setRefundStoreAmountError("Please enter valid refund amount");
      isValid = false;
    }
    if (isValid) {
      const updatedData = {
        reason_of_reject: refundReason,
        refund_amount: refundCustomerAmount,
        store_payout: refundStoreAmount,
        status: "completed",
        request_type: "refund",
      };
      const response = await services.Orders.UPDATE_ORDER(orderId, updatedData);
      if (response) {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "success",
            message: `Order has been refunded successfully`,
          })
        );
        // setToaster({
        //   status: "success",
        //   text: `Order has been refunded successfully`,
        //   visibilty: true,
        // });
        // setTimeout(() => {
        //   setToaster({ ...toaster, visibilty: false });
        // }, 6000);
        getOrderDetails(orderId);
        setRefundPopUp(false);
      }
    } else {
      dispatch(changeLoader(true));
    }
  };

  const downloadInvoice = async () => {
    try {
      const response = (await services.Orders.GET_ORDER_INVOICE(orderId)).data;
      if (response.ack == 1) {
        window.open(constant.IMG_BASE_URL + response.invoice, "_blank");
      }
    } catch (error) {}
  };
const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('tabtype');
const goBack = () => myParam === "false"?
  navigate(`/order-listing?${orderDetails.storeId === 1? 'isbaladi=baladi&':''}status=completed`):
  navigate(`/order-listing?${orderDetails.storeId === 1? 'isbaladi=baladi&':''}status=completedBaladi`);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <div className="order-listing-wrapper">
              <div className="">
                <div className="tabs-wrap">
                  <div className="table-type-parent">
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      mb={"20px"}
                    >
                      <div className="store-detail-head">
                        <img
                          src={BackIcon}
                          alt="back-icon"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={goBack}
                        />
                        {/* <Typography
                          variant="h6"
                          component="h6"
                          mb={0}
                          fontWeight={500}
                        >
                          Completed Orders
                        </Typography>
                        */}
                      </div>
                      <div className="search-box">
                        <Button
                          fullWidth
                          variant="contained"
                          size="large"
                          className="secondary-gray"
                          onClick={() => {
                            setRefundReason('')
                            setRefundCustomerAmount('')
                            setRefundStoreAmount('')
                            setRefundReasonError('')
                            setRefundCustomerAmountError('')
                            setRefundStoreAmountError('')
                            setRefundPopUp(true)
                          }}
                        >
                          Refund Order
                        </Button>
                      </div>
                    </Box>

                    <div className="table-type-parent">
                    <Box>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={2}
                        fontSize={"14px"}
                        fontWeight={500}
                      >
                        Completed Orders
                      </Typography>
                      {vendortiming && vendortiming.length > 0 && (
                              <Typography
                                variant="h6"
                                component="h6"
                                mb={2}
                                fontSize={"14px"}
                                fontWeight={500}
                              >
                                <span> Vendor Timing: </span><br />
                                {vendortiming.map((timing, index) => (
                                  <div key={index}>
                                    <span>Opening: {timing.starttime} , </span> 
                                    <span>Closing: {timing.endtime} </span><br />
                                  </div>
                                ))}
                              </Typography>
                            )}
                    </Box>
                    <div className="table-type-box">

                   
                      <div className="table-type-td flex">
                        <img
                          src={deliverIcon}
                          alt="deliver"
                          className="icon24"
                        />
                        <div className="relative">
                          <span className="type-th">Order Type</span>
                          <h3 className="type-td">
                            Deliver {orderDetails?.deliver}
                          </h3>
                        </div>
                      </div>
                      <div className="table-type-td">
                        <span className="type-th">Order ID</span>
                        <h3 className="type-td width120">
                          #{orderDetails?.order_id}
                        </h3>
                      </div>
                      <div className="table-type-td">
                        <span className="type-th">Customer Name</span>
                        <h3 className="type-td width120">
                          {orderDetails?.user?.full_name}
                        </h3>
                      </div>
                      <div className="table-type-td">
                        <span className="type-th">No. of Items</span>
                        <h3 className="type-td">
                          {orderDetails?.total_quantity} Items
                        </h3>
                      </div>
                      <div className="table-type-td">
                        <span className="type-th">Total Amount</span>
                        <h3 className="type-td">
                          QAR {orderDetails?.total_amount.toFixed(2)}
                        </h3>
                      </div>
                      <div className="table-type-td">
                        <h3
                          className="type-td download-invoice"
                          onClick={downloadInvoice}
                        >
                          <img src={DownloadIcon} className="icon20" alt="" />{" "}
                          Download Invoice
                        </h3>
                      </div>
                    </div>
                    <div className="table-type-body">
                      <div className="table-type-body-inner-flex">
                        <div className="left-detail-box">
                          <h6 className="h6">Product Details</h6>
                          <Table className="product-table">
                            <TableBody>
                              {/* <TableRow>M
                                <TableCell>
                                  <div className="flex">
                                    <img
                                      src={foodImg}
                                      alt=""
                                      className="img32"
                                    />
                                    <span className="product-title">
                                      Margherita Pizza
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  1 x QAR 20.00{" "}
                                  <span className="gray-through">
                                    QAR 20.00
                                  </span>
                                </TableCell>
                                <TableCell align="right">QAR 20.00</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <div className="flex">
                                    <img
                                      src={foodImg}
                                      alt=""
                                      className="img32"
                                    />
                                    <span className="product-title">
                                      Farmhouse Pizza
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  2 x QAR 20.00{" "}
                                  <span className="gray-through">
                                    QAR 20.00
                                  </span>
                                </TableCell>
                                <TableCell align="right">QAR 40.00</TableCell>
                              </TableRow> */}
                              {productDetails &&
                                productDetails.length > 0 &&
                                productDetails?.map((item, index) => (
                                  <OrderItem key={index} orderItem={item} storeId={store?.id}/>
                                ))}
                            </TableBody>
                          </Table>
                          <Divider style={{ marginTop: "15px" }} />
                          <h6 className="h6 gray" style={{ marginTop: "20px" }}>
                            Order Tracking
                          </h6>
                          <TrackingComponent
                            status= {orderDetails?.status}
                            tracking={tracking}
                            orderTracker={track}
                          />
                          {/* <>
                            {tracking == currentBaladiInfoObj.BALADI_STORE_SLUG ? (
                              <div className="order-tracking-box">
                                {orderTracker &&
                                  orderTracker.length > 0 &&
                                  orderTracker?.map((item, index) => (
                                    <div
                                      key={index}
                                      className={`order-status position-relative ${
                                        item?.class ? item.class : ""
                                      }`}
                                    >
                                      {index == 1 && <span>Pick</span>}
                                      {index == 2 && <span>IN-QC</span>}
                                      <p className="p2">
                                        {index == 2 && (
                                          <b style={{ opacity: 0 }}>test</b>
                                        )}
                                        {item?.status}
                                      </p>
                                      <p className="p4">{item?.updated_at}</p>
                                    </div>
                                  ))}
                              </div>
                            ) : tracking == "" ? (
                              <></>
                            ) : (
                              <div className="order-tracking-box">
                                {orderTracker &&
                                  orderTracker.length > 0 &&
                                  orderTracker?.map((item, index) => (
                                    <div
                                      key={index}
                                      className={`order-status position-relative ${
                                        item?.class ? item.class : ""
                                      }`}
                                    >
                                      <p className="p2">{item?.status}</p>
                                      <p className="p4">{item?.updated_at}</p>
                                    </div>
                                  ))}
                              </div>
                            )}
                          </> */}
                          <Divider style={{ marginTop: "15px" }} />
                          {rating.length ? (
                            <Box display={"flex"} alignItems={"center"}>
                              <Box pr={2} className="raing-50">
                                <h6
                                  className="h6 gray"
                                  style={{ marginTop: "20px" }}
                                >
                                  Rating & Review
                                </h6>
                                {rating.length ? (
                                  <Rating
                                    name="rating-read"
                                    defaultValue={Number(
                                      getRating("store", "rating")
                                    )}
                                    readOnly
                                    emptyIcon={
                                      <StarIcon
                                        style={{ opacity: 0.55 }}
                                        fontSize="inherit"
                                      />
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                                <p className="p2">
                                  {getRating("store", "review")}
                                </p>
                              </Box>
                              <Box pl={2}>
                                <h6
                                  className="h6 gray"
                                  style={{ marginTop: "20px" }}
                                >
                                  Driver Rating & Review
                                </h6>
                                {rating.length ? (
                                  <Rating
                                    name="rating-read"
                                    defaultValue={Number(
                                      getRating("driver", "rating")
                                    )}
                                    readOnly
                                    emptyIcon={
                                      <StarIcon
                                        style={{ opacity: 0.55 }}
                                        fontSize="inherit"
                                      />
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                                <p className="p2">
                                  {getRating("driver", "review")}
                                </p>
                              </Box>
                            </Box>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="right-detail-box">
                          <h6 className="h6 mb-4">Payment Details</h6>
                          <div className="payment-value">
                            <span className="p2">MRP</span>
                            <span
                              className={`p2-bold ${
                                orderDetails?.MRP !== orderDetails?.sub_total
                                  ? "through-text"
                                  : ""
                              }`}
                            >
                              {orderDetails?.MRP !== orderDetails?.sub_total ? (
                                <strike>
                                  QAR {orderDetails?.MRP?.toFixed(2)}
                                </strike>
                              ) : (
                                <span>QAR {orderDetails?.MRP?.toFixed(2)}</span>
                              )}
                            </span>
                          </div>
                          {orderDetails?.MRP !== orderDetails?.sub_total ? (
                            <div className="payment-value">
                              <span className="p2">Discounted Price</span>
                              <span className="p2-bold">
                                QAR {orderDetails?.sub_total?.toFixed(2)}
                              </span>
                            </div>
                          ) : null}
                          {orderDetails?.delivery_charges > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Delivery Charges</span>
                              <span className="p2-bold">
                                QAR {orderDetails?.delivery_charges?.toFixed(2)}
                              </span>
                            </div>
                          ) : null}
                          {orderDetails?.tax > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Tax</span>
                              <span className="p2-bold">
                                QAR {orderDetails?.tax.toFixed(2)}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {orderDetails?.discount > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Coupon Discount</span>
                              <span className="p2-bold">
                                - QAR {orderDetails?.discount.toFixed(2)}
                              </span>
                            </div>
                          ) : null}
                          {orderDetails?.offer_amount > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Offer/Discount</span>
                              <span className="p2-bold">
                                - QAR {orderDetails?.offer_amount.toFixed(2)}
                              </span>
                            </div>
                          ) : null}
                          {wallet > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Wallet</span>
                              <span className="p2-bold">
                                - QAR {wallet.toFixed(2)}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          <Divider />
                          <div className="payment-value-total">
                            <span className="p2">
                              {wallet > 0 ? "Net Pay" : "Total Amount"}
                            </span>
                            <span className="p2">
                              QAR{" "}
                              {(orderDetails?.total_amount - (wallet || 0))?.toFixed(
                                2
                              )}
                            </span>
                          </div>
                          
                          <Divider className="my-5" />
                          {orderDetails?.specialRequest && (
                                <Boxed
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"flex-end"}
                                >
                                  <h6 className="h6 mb-4">Special Request: {orderDetails.specialRequest}</h6>
                                </Boxed>
                              )}
                          <Boxed
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"flex-end"}
                          >
                            <h6 className="h6 mb-4">Other Details</h6>
                          </Boxed>
                          <div>
                            <div className="relative mb-4">
                              <p className="p3">Order Date</p>
                              <p className="p2">
                                {moment(orderDetails?.createdAt).format(
                                  "DD MMMM YYYY hh:mm a"
                                )}
                              </p>
                            </div>
                            <div className="relative mb-4">
                              <p className="p3">Payment Method</p>
                              <p className="p2">
                                {orderDetails?.payment_method}
                              </p>
                            </div>
                            <div className="relative">
                              <p className="p3">Customer Details</p>
                              <p className="p2">
                                {addressData?.full_name} |{" "}
                                {`+${orderDetails?.user?.country_code} ${addressData?.contact_number}`}{" "}
                                | {orderDetails?.user?.email}
                              </p>
                              <Button
                                variant="contained"
                                size="small"
                                className="secondary"
                                onClick={() => {
                                  navigate(
                                    `/manage-customer-Details/${orderDetails?.user?.id}`
                                  );
                                }}
                              >
                                {" "}
                                View Customer{" "}
                              </Button>
                            </div>
                            <Divider />
                            <div className="relative mb-4">
                              <p className="p3">Vendor Details</p>
                              <p className="p2">
                                  {store?.stores_locales[0].business_name}
                                  {store?.country_code !== null && store?.contact_number !==null?<>
                                    {" "} | {" "}
                                  {`${store?.country_code !== null?'+'+store?.country_code:''} ${store?.contact_number !==null?store?.contact_number:''}`}{" "}
                                  </>: null}
                                  {" "} | {store?.business_address}
                                </p>
                            </div>
                            <Divider />
                              <div className="relative">
                                <p className="p3">Delivery Partner</p>
                                {store?.market_place === 1?
                                  <p className="p2">MarketPlace</p>:
                                  <p className="p2 capitalize">{store?.deliveryPartner}</p>
                                }
                              </div>
                            {orderDetails?.driverId > 0 ? (
                              <div className="relative">
                                <p className="p3">Driver Details</p>
                                <p className="p2">{`${orderDetails?.driver?.first_name} ${orderDetails?.driver?.last_name}`}</p>
                                <p className="p2">
                                  {orderDetails?.driver?.phone}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}

                            <Divider className="my-2" />
                            <div className="relative">
                              <h1> </h1>
                              <p className="p3">Order Marked Completed By:</p>
                              {stats ? (
                                <div>
                                  <p className="p2">
                                    Name|
                                    {stats?.user?.full_name}
                                    <br />
                                    Role|{stats?.user?.role}
                                  </p>
                                </div>
                              ) : (
                                <p>No data found.</p>
                              )}
                            </div>

                            {picker?.length > 0 && picker.map((item, index) => {
                              return (
                                <>
                                  <Divider className="my-2" />
                                  <div className="relative">
                                    <p className="p3">Picker Details</p>
                                    <p className="p2">
                                      Picker Name - {item?.first_name}{" "}
                                      {item?.last_name} <br />
                                      Picker Email - {item?.email}
                                    </p>
                                  </div>
                                  <Divider />
                                </>
                              );
                            })}
                            {qc?.length > 0 && qc.map((item, index) => {
                              return (
                                <>
                                  <Divider className="my-2" />
                                  <div className="relative">
                                    <p className="p3">QC Details</p>
                                    <p className="p2">
                                      QC Name - {item?.first_name}{" "}
                                      {item?.last_name} <br />
                                      QC Email - {item?.email}
                                    </p>
                                  </div>
                                  <Divider />
                                </>
                              );
                            })}
                            {/* <Divider className="my-2" />
                            <p className="p3"> Time</p>
                            <p className="p2">Ideal Time - {idealTIme} Min</p>
                            <p className="p2">
                              Actual Time - {(lastValue / 1000 / 60) | 0} Min
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={refundPopUp}
        onClose={() => {
          setRefundPopUp(false);
        }}
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => {
            setRefundPopUp(false);
          }}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Refund Order
          </Typography>
          <form onSubmit={refundOrder}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Refund Reason*"
                  multiline
                  rows={2}
                  value={refundReason}
                  inputProps={{
                    maxLength: 250,
                  }}
                  
                  onChange={(e) => {
                    setRefundReason(e.target.value);
                    setRefundReasonError("");
                  }}
                />
                {refundReasonError !== "" ? (
                  <div className="error">{refundReasonError}</div>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={refundCustomerAmount}
                  label="Customer Refund Amount*"
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  onChange={(e) => {
                    setRefundCustomerAmount(e.target.value);
                    setRefundCustomerAmountError("");
                  }}
                />
                {refundCustomerAmountError !== "" ? (
                  <div className="error">{refundCustomerAmountError}</div>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Store Payout Amount*"
                  value={refundStoreAmount}
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  onChange={(e) => {
                    setRefundStoreAmount(e.target.value);
                    setRefundStoreAmountError("");
                  }}
                />
                {refundStoreAmountError !== "" ? (
                  <div className="error">{refundStoreAmountError}</div>
                ) : null}
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CompletedTab;
