import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {getStoreNameFromLocale} from "../../helper/custom"
import { Button, Typography, TextField, Grid, Divider, Alert } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Checkbox from "@mui/material/Checkbox";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import updateStateWithValidation from "../../helper/Validation";
import services from "../../../services";
import uploadImg from "../../../assets/images/structure/upload-img.png";
import constant from "../../../../src/constant";
import { blobToBase64, base64toFile } from "../../../pages/helper/custom";
import imageCompression from 'browser-image-compression';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  alwaysKeepResolution: true
}

export default function AddStore() {

  const navigate = useNavigate();
  const [companyRegIdList, setCompanyRegIdList] = useState([]);
  const [businessLogo, setBusinessLogo] = useState({
    file: "",
    isValid: false,
    err: "Please upload business logo",
  });

  const [bannerImage, setBannerImage] = useState({
    file: "",
    isValid: false,
    err: "Please upload business logo",
  });
  const [businesstypeList, setBusinesstypeList] = useState({
    data: [],
    selectedBusinessId: null,
    err: "select store type",
    isValid: false,
  });
  const [toaster, setToaster] = useState({
    visibilty: false,
    status: "",
    text: "",
  });
  const [vendorList, setVendorLIst] = useState([]);

  useEffect(() => {
    getBusinessCategory();
    getAllVendors();
  }, []);

  // states
  const [isValidate, setIsValidate] = useState(false);
  const [business, setBusiness] = useState({
    name: {
      val: "",
      err: "enter store name",
      isValid: false,
    },
    nameArabic: {
      val: "",
      err: "enter arabic store name",
      isValid: false,
    },
    address: {
      val: "",
      err: "enter store address",
      isValid: false,
    },
    zone: {
      val: "",
      err: "enter zone number",
      isValid: false,
    },
    street: {
      val: "",
      err: "enter street number",
      isValid: false,
    },
    building: {
      val: "",
      err: "enter building number",
      isValid: false,
    },
    country: {
      val: "Qatar",
      err: "",
      isValid: true,
    },
    isOnline: true,
  });
  const [personalDetail, setPersonalDetail] = useState({
    user: {
      val: "",
      err: "select vendor ",
      isValid: false,
    },
    fullName: {
      val: "",
      err: "enter ",
      isValid: false,
    },
    contact: {
      val: "",
      err: "enter ",
      isValid: false,
    },
    email: {
      val: "",
      err: "enter ",
      isValid: false,
    },
  });
  const [payoutDetails, setPayoutDetails] = useState({
    benificiaryName: {
      val: "",
      err: "enter benificiary Name ",
      isValid: false,
    },
    accountNumber: {
      val: "",
      err: "enter account Number",
      isValid: false,
    },
    iban: {
      val: "",
      err: "enter IBAN",
      isValid: false,
    },
    swiftCode: {
      val: "",
      err: "enter swiftCode",
      isValid: false,
    },
  });
  const [system, setSystem] = useState({
    commission: {
      val: "",
      err: "enter commission",
      isValid: false,
    },
    rejectionTime: {
      val: "",
      err: "enter rejectionTime",
      isValid: false,
    },
    transectionFee: {
      val: 100,
      err: "",
      isValid: true,
      checked: true,
    },
  });
  const [workingHoursList, setWorkingHoursList] = useState([
    {
      day: "sunday",
      open: true,
      starttime: 0,
      endtime: 16,
      isValid: true,
    },
    {
      day: "monday",
      open: true,
      starttime: 0,
      endtime: 16,
      isValid: true,
    },
    {
      day: "tuesday",
      open: true,
      starttime: 0,
      endtime: 16,
      isValid: true,
    },
    {
      day: "wednesday",
      open: true,
      starttime: 0,
      endtime: 16,
      isValid: true,
    },
    {
      day: "thursday",
      open: true,
      starttime: 0,
      endtime: 16,
      isValid: true,
    },
    {
      day: "friday",
      open: true,
      starttime: 0,
      endtime: 16,
      isValid: true,
    },
    {
      day: "saturday",
      open: true,
      starttime: 0,
      endtime: 16,
      isValid: true,
    },
  ]);
  
  const getBusinessCategory = async () => {
    try {
      const data = (
        await services.BusinessType.GET_ALL_BUSINESS_TYPE_BY_QUERY()
      ).data.businesstype.rows;
      if (data) {
        setBusinesstypeList({ ...businesstypeList, data });
      }
    } catch (error) {
      console.log("Error_In_store_type", error);
    }
  };

  const getAllVendors = async () => {
    try {
      const data = (await services.Vendors.GET_ALL_VENDORS()).data.vendors;
      if (data) {
        setVendorLIst(data);
      }
    } catch (error) {
      console.log("Error_In_store_type", error);
    }
  };

  function removeCompanyReg(index) {
    let arr = [...companyRegIdList];
    arr.splice(index, 1);
    setCompanyRegIdList(arr);
  }

  const routerStoreListing = () => {
    const path = `/store-listing`;
    navigate(path);
  };

  const onSaveHandler = async () => {
    if (
      businesstypeList.isValid &&
      business.name.isValid &&
      business.zone.isValid &&
      business.street.isValid &&
      business.building.isValid &&
      business.country.isValid &&
      payoutDetails.accountNumber.isValid &&
      payoutDetails.benificiaryName.isValid &&
      payoutDetails.iban.isValid &&
      payoutDetails.swiftCode.isValid
    ) {
      let updatedWorkingHours = [];
      workingHoursList.map((item) => {
        updatedWorkingHours.push({
          day: item.day,
          open: item.open,
          starttime: storeTime[item.starttime].finalVal,
          endtime: storeTime[item.endtime].finalVal,
        });
      });
      const updatedData = {
        store: {

          businessTypeId: businesstypeList.selectedBusinessId,
          business_name: business.name.val,
          business_name_arabic: business.nameArabic.val,
          business_address: business.address.val,
          zone_number: business.zone.val,
          street_number: business.street.val,
          building_number: business.building.val,
          latitude: "",
          longitude: "",
          is_approved: true,
          country: business.country.val,
          admin_commisiion:system.transectionFee.val,
          order_accept_time: system.rejectionTime.val,
        },
        user: {
          userId: personalDetail.user.val,
          commission:  system.commission.val,
        },
        payoutDetail: {
          beneficiaryName: payoutDetails.benificiaryName.val,
          bankAccountNumber: payoutDetails.accountNumber.val,
          iban: payoutDetails.iban.val,
          swiftCode: payoutDetails.swiftCode.val,

        },
        workinghoursdata: updatedWorkingHours,
        workingholiday: [],
      };
      try {
        const bodyFormData = new FormData();
        Object.keys(updatedData).forEach((key) => {
          bodyFormData.append(key, JSON.stringify(updatedData[key]));
        });
        if (companyRegIdList && companyRegIdList.length) {
          for (let index = 0; index < companyRegIdList.length; index++) {
            const element = companyRegIdList[index];
            bodyFormData.append("image", element.file);
          }
        }
        if (businessLogo && businessLogo.file) {
          bodyFormData.append("business_logo", businessLogo.file);
        }
        if (bannerImage && bannerImage.file) {
          bodyFormData.append("banner_image", bannerImage.file);
        }
        const response = await services.Stores.ADD_STORE(bodyFormData);
        if (response.data.ack==1) {
          setToaster({
            status: "success",
            text: `Store Added Successfuly`,
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({
              status: "",
              text: ``,
              visibilty: false,
            });
            navigate("/store-listing");
          }, 2500);
        } else {
          setToaster({
            status: "error",
            text: `Something went wrong`,
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({
              status: "",
              text: ``,
              visibilty: false,
            });
            navigate("/store-listing");
          }, 2500);
        }
      } catch (error) {
        console.log(error, "error in  ADD store");
      }
    } else {
      setIsValidate(true);
    }
  };

  useEffect(() => {
    getBusinessCategory();
    getAllVendors();
  }, []);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty && (
            <Alert severity={toaster.status}>{toaster.text}</Alert>
          )}
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Add Store
                </Typography>
              </Grid>
              <Grid item xs={4} align="right">
                {/* <Button variant="outlined" size="small" color='primary' sx={{marginRight: '10px'}}>Approve</Button>
                            <Button variant="outlined" size="small" color="error" onClick={OpenRejectReason}>Reject</Button> */}
              </Grid>
              <Grid item xs={4}>
                <div className="left-side">

                  <div className="upload-img">
                    {!(businessLogo && businessLogo.isValid) ? (
                      <div className="before-upload">
                        <img src={uploadImg} alt=".." className="upload-icon" />
                        <p className="upload-text">Upload Image</p>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          // multiple
                          onChange={async (e) => {
                            const imageType = e.target.files[0].type;
                            if (
                              imageType === "image/png" ||
                              imageType === "image/jpeg" ||
                              imageType === "image/jpg" ||
                              imageType === "application/msword" ||
                              imageType ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              imageType === "application/pdf"
                            ) {
                              const file = e.target.files[0];
                              const compressedFile = await imageCompression(file, options);
                              const imageUrl = await blobToBase64(compressedFile);
                              setBusinessLogo({
                                file: base64toFile(imageUrl),
                                isValid: true,
                                err: "",
                              });
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="after-upload">
                        <CancelIcon
                          className="delete-icon"
                          onClick={() => {
                            setBusinessLogo({
                              file: "",
                              isValid: false,
                              err: "Please upload business logo",
                            });
                          }}
                        />
                        <img
                          src={URL.createObjectURL(businessLogo.file)}
                          alt=".."
                          className="upload-icon"
                        />
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          // multiple
                          onChange={async (e) => {
                            const imageType = e.target.files[0].type;
                            if (
                              imageType === "image/png" ||
                              imageType === "image/jpeg" ||
                              imageType === "image/jpg" ||
                              imageType === "application/msword" ||
                              imageType ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              imageType === "application/pdf"
                            ) {
                              const file = e.target.files[0];
                              const compressedFile = await imageCompression(file, options);
                              const imageUrl = await blobToBase64(compressedFile);
                              setBusinessLogo({
                                file: base64toFile(imageUrl),
                                isValid: true,
                                err: "",
                              });
                            }
                          }}
                        />
                      </div>
                    )}
                    {!businessLogo.isValid && isValidate && (
                      <div className="error">{businessLogo.err}</div>
                    )}
                  </div>

                  <div className="owner-detail" align="center" style={{ margin: "20px 0px" }}>
                    <p className="name-text mb-2">{business?.name.val}</p>
                    <p className="number-text">{`${
                      business.zone.val ? business.zone.val + "," : ""
                    } ${
                      business.building.val ? business.building.val + "," : ""
                    } ${business.street.val ? business.street.val + "," : ""} ${
                      business.country.val
                    }`}</p>
                  </div>

                  <div className="upload-img">
                    {!(bannerImage && bannerImage.isValid) ? (
                      <div className="before-upload">
                        <img src={uploadImg} alt=".." className="upload-icon" />
                        <p className="upload-text">Upload Image</p>
                        <input type="file" accept="image/png, image/jpeg" onChange={async (e) => {
                            const imageType = e.target.files[0].type;
                            if (
                              imageType === "image/png" ||
                              imageType === "image/jpeg" ||
                              imageType === "image/jpg" ||
                              imageType === "application/msword" ||
                              imageType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              imageType === "application/pdf"
                            ) {
                              const file = e.target.files[0];
                              const compressedFile = await imageCompression(file, options);
                              const imageUrl = await blobToBase64(compressedFile);
                              setBannerImage({
                                file: base64toFile(imageUrl),
                                isValid: true,
                                err: "",
                              });
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="after-upload">
                        <CancelIcon
                          className="delete-icon"
                          onClick={() => {
                            setBannerImage({
                              file: "",
                              isValid: false,
                              err: "Please upload business logo",
                            });
                          }}
                        />
                        <img
                          src={URL.createObjectURL(bannerImage.file)}
                          alt=".."
                          className="upload-icon"
                        />
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          // multiple
                          onChange={async (e) => {
                            const imageType = e.target.files[0].type;
                            if (
                              imageType === "image/png" ||
                              imageType === "image/jpeg" ||
                              imageType === "image/jpg" ||
                              imageType === "application/msword" ||
                              imageType ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              imageType === "application/pdf"
                            ) {
                              const file = e.target.files[0];
                              const compressedFile = await imageCompression(file, options);
                              const imageUrl = await blobToBase64(compressedFile);
                              setBannerImage({
                                file: base64toFile(imageUrl),
                                isValid: true,
                                err: "",
                              });
                            }
                          }}
                        />
                      </div>
                    )}
                    {!bannerImage.isValid && isValidate && (
                      <div className="error">{bannerImage.err}</div>
                    )}
                  </div>

                  <Box className="alert-setting" mt={1} mb={2}>
                    <FormControlLabel
                      value="Status"
                      control={
                        <Switch
                          color="primary"
                          checked={business.isOnline}
                          onChange={(e) => {
                            setBusiness({
                              ...business,
                              isOnline: e.currentTarget.checked,
                            });
                          }}
                        />
                      }
                      label="Online"
                      labelPlacement="start"
                      className="switch-row"
                    />
                  </Box>
                </div>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  mb={3}
                  fontWeight={500}
                >
                  Business Detail
                </Typography>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Store Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                          businesstypeList?.selectedBusinessId
                            ? businesstypeList?.selectedBusinessId
                            : 0
                        }
                        label="Store Type"
                        onChange={(e) => {
                          // setIsValidate(true);
                          if (e.target.value && e.target.value !== 0) {
                            setBusinesstypeList({
                              ...businesstypeList,
                              err: "",
                              selectedBusinessId: e.target.value,
                              isValid: true,
                            });
                          } else {
                            setBusinesstypeList({
                              ...businesstypeList,
                              err: "Required",
                              selectedBusinessId: e.target.value,
                              isValid: false,
                            });
                          }
                        }}
                      >
                        <MenuItem value={0}>Select Business Type</MenuItem>
                        {businesstypeList.data.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                             {getStoreNameFromLocale(item?.business_type_locales)} 
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {!businesstypeList.isValid && isValidate && (
                      <div className="error">{businesstypeList.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Store Name"
                      value={business.name.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setBusiness({
                          ...business,
                          name: updateStateWithValidation(e, business.name),
                        });
                      }}
                    />
                    {!business.name.isValid && isValidate && (
                      <div className="error">{business.name.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Store Name Arabic"
                      value={business.nameArabic.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setBusiness({
                          ...business,
                          nameArabic: updateStateWithValidation(
                            e,
                            business.nameArabic
                          ),
                        });
                      }}
                    />
                    {!business.nameArabic.isValid && isValidate && (
                      <div className="error">{business.nameArabic.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Business Address"
                      value={business.address.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setBusiness({
                          ...business,
                          address: updateStateWithValidation(
                            e,
                            business.address,
                            "address"
                          ),
                        });
                      }}
                    />
                    {!business.address.isValid && isValidate && (
                      <div className="error">{business.address.err}</div>
                    )}
                  </Grid>
                  {/* <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Business Email"
                      value={business.email.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setBusiness({
                          ...business,
                          email: updateStateWithValidation(
                            e,
                            business.email,
                            "email"
                          ),
                        });
                      }}
                    />
                    {!business.email.isValid && isValidate && (
                      <div className="error">{business.email.err}</div>
                    )}
                  </Grid> */}
                  {/* <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled={true}
                        label="Register Date"
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid> */}
                </Grid>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  mb={3}
                  fontWeight={500}
                >
                  Business Address
                </Typography>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Enter Zone Number"
                      value={business.zone.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setBusiness({
                          ...business,
                          zone: updateStateWithValidation(e, business.zone),
                        });
                      }}
                    />
                    {!business.zone.isValid && isValidate && (
                      <div className="error">{business.zone.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Street Number"
                      value={business.street.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setBusiness({
                          ...business,
                          street: updateStateWithValidation(e, business.street),
                        });
                      }}
                    />
                    {!business.street.isValid && isValidate && (
                      <div className="error">{business.street.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Building Number"
                      value={business.building.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setBusiness({
                          ...business,
                          building: updateStateWithValidation(
                            e,
                            business.building
                          ),
                        });
                      }}
                    />
                    {!business.building.isValid && isValidate && (
                      <div className="error">{business.building.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Country"
                      value={business.country.val}
                      disabled={true}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setBusiness({
                          ...business,
                          country: updateStateWithValidation(
                            e,
                            business.country
                          ),
                        });
                      }}
                    />
                    {!business.country.isValid && isValidate && (
                      <div className="error">{business.country.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      component="h6"
                      fontSize={"16px"}
                      mt={1}
                      fontWeight={500}
                    >
                      Company Registration ID
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="uploads-row">
                      {companyRegIdList && companyRegIdList.length < 3 && (
                        <div className="upload-multiple">
                          <img
                            src={uploadImg}
                            alt=".."
                            className="upload-icon"
                          />
                          <p className="upload-text">Company registration ID</p>
                          <span>JPEG or PNG (3 files)</span>
                          <input
                            type="file"
                            accept=".png,.jpeg,.jpg,.png,.doc,.docx,.pdf"
                            onChange={(e) => {
                              const imageType = e.target.files[0].type;
                              if (
                                imageType === "image/png" ||
                                imageType === "image/jpeg" ||
                                imageType === "image/jpg" ||
                                imageType === "application/msword" ||
                                imageType ===
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                imageType === "application/pdf"
                              ) {
                                setCompanyRegIdList((companyRegIdList) => [
                                  ...companyRegIdList,
                                  {
                                    index: parseInt(Math.random() * 1000),
                                    file: e.target.files[0],
                                  },
                                ]);
                              }
                            }}
                          />
                        </div>
                      )}
                      {companyRegIdList &&
                        companyRegIdList.map((item, index) => {
                          return (
                            <div className="upload-multiple" key={index}>
                              <CancelIcon
                                key={index}
                                className="delete-icon"
                                onClick={() => {
                                  removeCompanyReg(index);
                                }}
                              />
                              {item.image && (
                                <img
                                  src={`${constant.IMG_BASE_URL}${item.image}`}
                                  alt=".."
                                  className="id-img"
                                />
                              )}
                              {item.file && (
                                <img
                                  src={URL.createObjectURL(item.file)}
                                  // src={`${constant.IMG_BASE_URL}${item.image}`}
                                  alt=".."
                                  className="id-img"
                                />
                              )}
                            </div>
                          );
                        })}
                      {companyRegIdList && companyRegIdList.length > 3 && (
                        <div className="error">Maximum 3 files allowed</div>
                      )}
                    </div>
                  </Grid>
                </Grid>

                {/* <Divider /> */}
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  mb={3}
                  mt={3}
                  fontWeight={500}
                >
                  {/* Personal Detail */}
                </Typography>
                <Grid container spacing={3} mb={4}>
                  {/* <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Owner Full Name"
                      value={personalDetail.fullName.val}
                      onChange={(e) => {
                        setIsValidate(true);
                        setPersonalDetail({
                          ...personalDetail,
                          fullName: updateStateWithValidation(
                            e,
                            personalDetail.fullName
                          ),
                        });
                      }}
                    />
                    {!personalDetail.fullName.isValid && isValidate && (
                      <div className="error">{personalDetail.fullName.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Owner Contact Number"
                      value={personalDetail.contact.val}
                      onChange={(e) => {
                        setIsValidate(true);
                        setPersonalDetail({
                          ...personalDetail,
                          contact: updateStateWithValidation(
                            e,
                            personalDetail.fullName,
                            "contact"
                          ),
                        });
                      }}
                    />
                    {!personalDetail.contact.isValid && isValidate && (
                      <div className="error">{personalDetail.contact.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Owner Email Address"
                      value={personalDetail.email.val}
                      onChange={(e) => {
                        setIsValidate(true);
                        setPersonalDetail({
                          ...personalDetail,
                          email: updateStateWithValidation(
                            e,
                            personalDetail.email,
                            "email"
                          ),
                        });
                      }}
                    />
                    {!personalDetail.email.isValid && isValidate && (
                      <div className="error">{personalDetail.email.err}</div>
                    )}
                  </Grid> */}
                  {/* <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select User
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={personalDetail.user.val}
                        label="Select User"
                        onChange={(e) => {
                          if (e.target.value) {
                            setPersonalDetail({
                              ...personalDetail,
                              user: {
                                val: e.target.value,
                                err: "",
                                isValid: true,
                              },
                            });
                          } else {
                            setPersonalDetail({
                              ...personalDetail,
                              user: {
                                val: e.target.value,
                                err: "Required",
                                isValid: false,
                              },
                            });
                          }
                        }}
                      >
                        <MenuItem value={0}>Select User</MenuItem>
                        {vendorList.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            {item.full_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {!personalDetail.user.isValid && isValidate && (
                      <div className="error">{personalDetail.user.err}</div>
                    )}
                  </Grid> */}
                </Grid>

                <Divider />
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  mb={3}
                  mt={3}
                  fontWeight={500}
                >
                  Working Hours
                </Typography>
                <Grid container spacing={3} mb={2}>
                  <Grid item xs={8}>
                    {workingHoursList &&
                      workingHoursList?.length > 0 &&
                      workingHoursList.map((item, index) => {
                        return (
                          <>
                          <div className="time-row-working" key={index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={item.open}
                                  onChange={(e) => {
                                    const list = [...workingHoursList];
                                    list[index].open = e.target.checked;
                                    setWorkingHoursList(list);
                                  }}
                                />
                              }
                              label={item.day}
                            />
                            <FormControl
                              fullWidth
                              sx={{ marginLeft: 2 }}
                              disabled={!item.open}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Open Time *
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={item.starttime}
                                label="Open Time *"
                                onChange={(e) => {
                                  if (
                                    e.target.value >=
                                    workingHoursList[index].endtime
                                  ) {
                                    setWorkingHoursList((prevState) => {
                                      const updatedWorkingHours = [
                                        ...prevState,
                                      ];
                                      updatedWorkingHours[index].starttime =
                                        e.target.value;
                                      updatedWorkingHours[
                                        index
                                      ].isValid = false;
                                      return updatedWorkingHours;
                                    });
                                  } else {
                                    setWorkingHoursList((prevState) => {
                                      const updatedWorkingHours = [
                                        ...prevState,
                                      ];
                                      updatedWorkingHours[index].starttime =
                                        e.target.value;
                                      updatedWorkingHours[index].isValid = true;
                                      return updatedWorkingHours;
                                    });
                                  }
                                }}
                                required
                              >
                                {storeTime.map((item, index) => (
                                  <MenuItem value={item.id} key={index}>
                                    {item.val}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl
                              fullWidth
                              sx={{ marginLeft: 2 }}
                              disabled={!item.open}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Close Time *
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={item.endtime}
                                label="Close Time *"
                                onChange={(e) => {
                                  setWorkingHoursList((prevState) => {
                                    const updatedWorkingHours = [...prevState];
                                    updatedWorkingHours[index].endtime =
                                      e.target.value;
                                    updatedWorkingHours[index].isValid =
                                      e.target.value <=
                                      workingHoursList[index].starttime
                                        ? false
                                        : true;
                                    return updatedWorkingHours;
                                  });
                                }}
                                required
                              >
                                {storeTime.map((item, index) => (
                                  <MenuItem value={item.id} key={index}>
                                    {item.val}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>  
                          </div>
                          <div className="time-error">
                                    {!item.isValid && (
                                      <div className="error">
                                        Please select time less than close time
                                      </div>
                                    )}
                          </div>
                          </>
                        );
                      })}
                  </Grid>
                </Grid>

                <Divider />
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  mb={3}
                  mt={3}
                  fontWeight={500}
                >
                  Payout Detail
                </Typography>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Beneficiary Name"
                      value={payoutDetails.benificiaryName.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setPayoutDetails({
                          ...payoutDetails,
                          benificiaryName: updateStateWithValidation(
                            e,
                            payoutDetails.benificiaryName
                          ),
                        });
                      }}
                    />
                    {!payoutDetails.benificiaryName.isValid && isValidate && (
                      <div className="error">
                        {payoutDetails.benificiaryName.err}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Bank Account Number"
                      value={payoutDetails.accountNumber.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setPayoutDetails({
                          ...payoutDetails,
                          accountNumber: updateStateWithValidation(
                            e,
                            payoutDetails.accountNumber
                          ),
                        });
                      }}
                    />
                    {!payoutDetails.accountNumber.isValid && isValidate && (
                      <div className="error">
                        {payoutDetails.accountNumber.err}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="IBAN"
                      value={payoutDetails.iban.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setPayoutDetails({
                          ...payoutDetails,
                          iban: updateStateWithValidation(
                            e,
                            payoutDetails.iban
                          ),
                        });
                      }}
                    />
                    {!payoutDetails.iban.isValid && isValidate && (
                      <div className="error">{payoutDetails.iban.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Swift code"
                      value={payoutDetails.swiftCode.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setPayoutDetails({
                          ...payoutDetails,
                          swiftCode: updateStateWithValidation(
                            e,
                            payoutDetails.swiftCode
                          ),
                        });
                      }}
                    />
                    {!payoutDetails.swiftCode.isValid && isValidate && (
                      <div className="error">{payoutDetails.swiftCode.err}</div>
                    )}
                  </Grid>
                </Grid>
                <Divider />
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  mb={3}
                  mt={3}
                  fontWeight={500}
                >
                  System setting
                </Typography>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={5}>
                    <TextField
                      required
                      label="Commission"
                      id="outlined-start-adornment"
                      value={system.commission.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setSystem({
                          ...system,
                          commission: updateStateWithValidation(
                            e,
                            system.commission,
                            "commission"
                          ),
                        });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                    {!system.commission.isValid && isValidate && (
                      <div className="error">{system.commission.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      required
                      label="Rejection time for order"
                      id="outlined-start-adornment"
                      value={system.rejectionTime.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setSystem({
                          ...system,
                          rejectionTime: updateStateWithValidation(
                            e,
                            system.rejectionTime,
                            "rejectionTime"
                          ),
                        });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Minute</InputAdornment>
                        ),
                      }}
                    />
                    {!system.rejectionTime.isValid && isValidate && (
                      <div className="error">{system.rejectionTime.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={system.transectionFee.checked}
                          onChange={(e) => {
                            setSystem({
                              ...system,
                              transectionFee: {
                                ...system.transectionFee,
                                checked: e.currentTarget.checked,
                              },
                            });
                          }}
                        />
                      }
                      label="Vendor Will Pay Transaction Fee"
                      sx={{ marginTop: 1, fontSize: "14px" }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      disabled={!system.transectionFee.checked}
                      label="Transaction Fee Charge From Vendor"
                      id="outlined-start-adornment"
                      value={system.transectionFee.val}
                      onChange={(e) => {
                        // setIsValidate(true);
                        setSystem({
                          ...system,
                          transectionFee: {
                            ...system.transectionFee,
                            ...updateStateWithValidation(
                              e,
                              system.transectionFee,
                              "commission"
                            ),
                          },
                        });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                    {system.transectionFee.checked &&
                      !system.transectionFee.isValid &&
                      isValidate && (
                        <div className="error">{system.transectionFee.err}</div>
                      )}
                  </Grid>
                </Grid>

                <Divider />
                <div className="bottom-btn-row">
                  <Button
                    variant="standard"
                    size="large"
                    sx={{ marginRight: "10px" }}
                    onClick={routerStoreListing}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={
                      (!businesstypeList?.isValid ||
                        !business.name.isValid ||
                        !business.nameArabic.isValid ||
                        !business.zone.isValid ||
                        !business.street.isValid ||
                        !business.building.isValid ||
                        !business.country.isValid ||
                        !payoutDetails.accountNumber.isValid ||
                        !payoutDetails.benificiaryName.isValid ||
                        !payoutDetails.iban.isValid ||
                        !payoutDetails.swiftCode.isValid ||
                        !system.commission.isValid ||
                        companyRegIdList?.length > 3 ||
                        !system.rejectionTime.isValid) &&
                      isValidate
                    }
                    onClick={() => onSaveHandler()}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
