import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Boxed } from "../../../../Styles-Elements/Box";
import { stateWithValidation } from "../../../helper/Validation";
import { changeSnackbar } from "../../../../redux/reducers/snackbar";
import { currentPermission } from "../../../../redux/reducers/permission";
import deliverIcon from "../../../../assets/images/structure/deliver-icon.svg";
import {
  Box,
  Button,
  Divider,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Table,
  TableBody,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import modalClose from "../../../../assets/images/structure/close-modal.png";
import BackIcon from "../../../../assets/images/structure/arrow-left-black.svg";
import Header from "../../../layout/header/Header";
import Aside from "../../../layout/aside/Aside";
import RejectOrder from "../../../../dialog/RejectOrder";
import services from "../../../../services";
import OrderItem from "./OrderItem";
import moment from "moment";
import GlobalService from "../../../../services/GlobalService";
import { API } from "../../../ENUM";
import constant from "../../../../constant";
import socket from "../../../../socket";
import { currentSocket, currentSocketObject } from "../../../../redux/reducers/socket";
import TrackingComponent from  './TrackingComponent';

const Orders = ({ data, type = "new" }) => {
  const statusRedirect = {
    placed: "orders",
    processing: "processing",
    readytopick: "ready-to-pick",
    shipped: "shipped",
    completed: "completed",
    rejected: "rejected",
    cancelled: "cancelled",
    pending: "pending"
  }
  const { orderId } = useParams();
  const currentSocketObj = useSelector(currentSocket);
  const currentSocketObjectObj = useSelector(currentSocketObject);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permission = useSelector(currentPermission);
  const [picker, setPicker] = useState("");
  const [qc, setQc] = useState([]);
  const [idealTIme,setIdealTIme] = useState("");
  const [ButtonBlock,setButtonBlock] = useState(false)

  // const [lastValue, setLastValue] = useState(null);
  let [canConfirm, setCanConfirm] = useState(true);
  let [canCancel, setCanCancel] = useState(true);

  const [productDetails, setProductDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState();
  const [vendortiming, setVendortiming] = useState([]);
  
  const [rejection, setRejection] = useState({ isOpen: false });
  const [wallet, setWallet] = useState(0);
  const [cancelOrder, setCancelOrder] = useState({
    isOpen: false,
    reason: { val: "", isValid: false, err: "" },
    department:{ val: "", isValid: false, err: "" },
    refund: { val: "", isValid: false, err: "" },
    storePayout: { val: "", isValid: false, err: "" },
  });
  let [addressData, setAddressData] = useState(null);
  let [store, setStore] = useState(null);
  let [cancelReasons, setCancelReasons] = useState([]);
  let [cancelReasonOther, setCancelReasonOther] = useState("");
  let [cancelDepartment, setCancelDepartment] = useState([]);
  let [cancelDepartmentOther, setCancelDepartmentOther] = useState("");
  let [isBaladi, setIsBaladi] = useState(null);
  let timingArray, formattedTiming;
  const [track, setTrack] = useState();

  const getall = async () => {
    try {
      const response = await GlobalService(API.TRAFFIC_LIGHT_SYSTEM.GET_ALL);
      const placedOrder = response?.results.find((item) => item.order_status === "placed");
      if (placedOrder) {setIdealTIme(placedOrder.time);}
    } catch (error) {setIdealTIme("Error in get:", error);}
  };
  useEffect(() => {
    getall();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlStoreType = urlSearchParams.get('tabtype');
    setIsBaladi(urlStoreType)
  },[]);

  const getCancelReasons = async () => {
    const response = await GlobalService(API.GET_ALL_CANCELLATION_REASON);
    setCancelReasons(response.results);
  };

  const getCancelDepartment = async () => {
    const response = await GlobalService(API.GET_ALL_CANCELLATION_DEPARTMENT);
    setCancelDepartment(response.results);
  };
  const getOrderDetails = async (orderId) => {
    try {
      const response = await services.Orders.GET_ORDER_BY_ID(orderId);
      if (response?.data.orders.status !== 'placed') {
        navigate(`/order-manage-detail/${statusRedirect[response?.data.orders.status]}/${orderId}`);
      }
      const map = response?.data?.orderLogs.map(
        (item) => item.updated_at
      );
      setTrack(response?.data?.orderLogs)
  //  setLastValue(map);
      setPicker(response?.pickers);
      setQc(response?.qcs);
      if (response.data.transaction.length > 0) {
        const walletAmount = response.data.transaction.find(
          (item) => item.method === "wallet"
        );
        setWallet(walletAmount?.amount);
      }
      setProductDetails(response.data.order_details);
      let total_MRP = 0;
      response.data.order_details?.map((item) => {
        total_MRP = total_MRP + item.price * +item.quantity;
        return total_MRP;
      });
      addressData =
        response.data.orders?.addressJson &&
        response.data.orders?.addressJson !== ""
          ? JSON.parse(response.data.orders?.addressJson)
          : null;
      setAddressData(addressData);
      store = response.data.orders?.store;
      setStore(store);
      const placesData = response?.data?.orderLogs?.filter((item) => item.status === "placed");
      setOrderDetails({
        ...response.data.orders,
        MRP: total_MRP,
        createdAt: placesData[0]?.updated_at
      });
      setVendortiming(JSON.parse(response.data?.ManageWorkingHour?.timejson || '[]'))
  // Format the timing for display
 
    } catch (error) {}
  };
  
  const handleClose = () => {
    setCancelOrder({
      isOpen: false,
      department:{ val: "", isValid: false, err: "" },
      reason: {
        val: "",
        isValid: false,
        err: "",
      },
      refund: {
        val: "",
        isValid: false,
        err: "",
      },
      storePayout: {
        val: "",
        isValid: false,
        err: "",
      },
    });
  };

  const handleOrderReject = async (orderId, message) => {
    if (message !== "") {
      try {
        let updatedData = {
          status: "rejected",
          reason_of_reject: message,
        };
        const response = await services.Orders.UPDATE_ORDER(
          orderId,
          updatedData
        );
        if (response) {
          setRejection({ isOpen: false });
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: `Order Rejected Successfuly`,
              state: "success",
            })
          );
          setTimeout(() => {
            dispatch(
              changeSnackbar({
                isOpen: false,
                message: ``,
                state: "success",
              })
            );
          }, 7000);
          getOrderDetails(orderId);
          // navigate("/order-listing");
        }
      } catch (error) {
        setRejection({ isOpen: false });
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: `Something Went Wrong`,
            state: "error",
          })
        );
        setTimeout(() => {
          dispatch(
            changeSnackbar({
              isOpen: false,
              message: ``,
              state: "success",
            })
          );
        }, 7000);
      }
    }
  };

  const handleOrderAccept = async () => {
    try {
      const response = await services.Orders.UPDATE_ORDER(orderId, {
        status: "processing",
      });
      if (response) {

        setButtonBlock(false);
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: `Order Accepted Successfuly`,
            state: "success",
          })
        );
        
        setTimeout(() => {
          dispatch(
            changeSnackbar({
              isOpen: false,
              message: ``,
              state: "success",
            })
          );
        }, 7000);
        getOrderDetails(orderId);
        // navigate("/order-listing");
      }
    } catch (error) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: `Something Went Wrong`,
          state: "error",
        })
      );
      setTimeout(() => {
        dispatch(
          changeSnackbar({
            isOpen: false,
            message: ``,
            state: "success",
          })
        );
      }, 7000);
    }
  };

  const handleOrderCancel = async () => {
    try {
      if (cancelOrder.reason.isValid) {
        const updatedData = {
          reason_department: cancelOrder.department.val === "other" ? cancelDepartmentOther : cancelOrder.department.val,
          reason_of_reject:
            cancelOrder.reason.val === "other"
              ? cancelReasonOther
              : cancelOrder.reason.val,
          refund_amount:
            orderDetails?.payment_method !== "cash_on_delivery"
              ? cancelOrder.refund.val
              : 0,
          store_payout:
            orderDetails?.payment_method !== "cash_on_delivery"
              ? cancelOrder.storePayout.val
              : 0,
          status: "cancelled",
        };
        if (updatedData.reason_of_reject === "") {
          setCancelOrder(
            {
              ...cancelOrder,
              reason: {
                ...cancelOrder.reason,
                isValid: false,
                err: "Please select reason",
              }
            },
          );
        } else {
          const response = await services.Orders.UPDATE_ORDER(
            orderId,
            updatedData
          );
          if (response) {
            handleClose();
            dispatch(
              changeSnackbar({
                isOpen: true,
                message: `Order Cancel Successfuly`,
                state: "success",
              })
            );
            setTimeout(() => {
              dispatch(
                changeSnackbar({
                  isOpen: false,
                  message: ``,
                  state: "success",
                })
              );
            }, 2500);
            navigate("/order-listing");
          }
        }
      }
    } catch (error) {
      // setCancelOrder({ ...cancelOrder, isOpen: false });
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: `Something Went Wrong`,
          state: "success",
        })
      );
      setTimeout(() => {
        dispatch(
          changeSnackbar({
            isOpen: false,
            message: ``,
            state: "success",
          })
        );
      }, 7000);
      handleClose();
    }
  };

  const goBack = () => navigate(`/order-listing?isBaladi=${isBaladi}`);

  const downloadInvoice = async () => {
    try {
      const response = (await services.Orders.GET_ORDER_INVOICE(orderId)).data;
      if (response.ack == 1) {
        window.open(constant.IMG_BASE_URL + response.invoice, "_blank");
      }
    } catch (error) {}
  };

  useEffect(() => {
    getOrderDetails(orderId);
    getCancelReasons();
    getCancelDepartment();
  }, [orderId]);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanConfirm(
        permission?.orderListing?.actions?.acceptOrRejectOrder === 1
      );
      setCanCancel(permission?.orderListing?.actions?.cancelOrder === 1);
    } else {
      setCanConfirm(true);
      setCanCancel(true);
    }
  }, [permission]);

  useEffect(() => {
    try {
      socket.on("orderStatusChanged", (response) => {
        if (response.order.id === orderId) {
          navigate(`/order-manage-detail/${response.status}/${orderId}`);
        }
      });
    } catch (error) {
      console.error("error in socket at my orders", error);
    }
  }, [socket]);

  // useEffect(() => {
  //     if  (currentSocketObj && currentSocketObjectObj?.order?.id === orderId) {
  //       navigate(`/order-manage-detail/${currentSocketObjectObj.status}/${orderId}`);
  //     }
  //   }, [ currentSocketObj ]);
   
  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <div className="order-listing-wrapper">
              <Boxed
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                mb={"20px"}
              >
                <div
                  className="store-detail-head"
                  style={{ marginBottom: "0px" }}
                >
                  <img
                    src={BackIcon}
                    alt="back-icon"
                    style={{ cursor: "pointer" }}
                    onClick={goBack}
                  />
                  <Typography
                    variant="h6"
                    component="h6"
                    mb={0}
                    fontWeight={500}
                  >
                    Orders Detail
                  </Typography>
                </div>
                <div className="search-box">
                  {canCancel ? (
                    <Button
                      fullWidth
                      variant="contained"
                      size="large"
                      className="secondary-gray mx-0"
                      onClick={() => {
                        // setCancelOrder({ ...cancelOrder, isOpen: true });
                        setCancelOrder({
                          ...cancelOrder,
                          department: {
                            val: cancelDepartment[0]?.department_name,
                            isValid: false,
                            err: "",
                          } 
                          , isOpen: true
                        });
                      }}
                    >
                      {" "}
                      Cancel Order{" "}
                    </Button>
                  ) : null}
                </div>
              </Boxed>
              <div className="">
                <div className="tabs-wrap">
                  <div className="">
                    <div className="table-type-parent">
                      <Box>
                        <Typography
                          variant="h6"
                          component="h6"
                          mb={2}
                          fontSize={"14px"}
                          fontWeight={500}
                        >
                          New Orders 
                         

                        </Typography>
                       
                        {vendortiming && vendortiming.length > 0 && (
                              <Typography
                                variant="h6"
                                component="h6"
                                mb={2}
                                fontSize={"14px"}
                                fontWeight={500}
                              >
                                <span> Vendor Timing: </span><br />
                                {vendortiming.map((timing, index) => (
                                  <div key={index}>
                                    <span>Opening: {timing.starttime} , </span> 
                                    <span>Closing: {timing.endtime} </span><br />
                                  </div>
                                ))}
                              </Typography>
                            )}


                        
                      </Box>
                      <div className="table-type-box">
                        <div className="table-type-td flex">
                          <img
                            src={deliverIcon}
                            alt="deliver"
                            className="icon24"
                          />
                          <div className="relative">
                            <span className="type-th">Order Type</span>
                            <h3 className="type-td">
                              Deliver {orderDetails?.deliver}
                            </h3>
                          </div>
                        </div>
                        <div className="table-type-td">
                          <span className="type-th">Order ID</span>
                          <h3 className="type-td width120">
                            #{orderDetails?.order_id}
                          </h3>
                        </div>
                        <div className="table-type-td">
                          <span className="type-th">Customer Name</span>
                          <h3 className="type-td width120">
                            {orderDetails?.user?.full_name}
                          </h3>
                        </div>
                        <div className="table-type-td">
                          <span className="type-th">No. of Items</span>
                          <h3 className="type-td">
                            {orderDetails?.total_quantity} Items
                          </h3>
                        </div>
                        <div className="table-type-td">
                          <span className="type-th">Total Amount</span>
                          <h3 className="type-td">
                            QAR {orderDetails?.total_amount?.toFixed(2)}
                          </h3>
                        </div>
                        <div className="table-type-td"></div>
                      </div>
                      <div className="table-type-body">
                        <div className="table-type-body-inner-flex">
                          <div className="left-detail-box">
                            <h6 className="h6">Product Details</h6>
                            <Table className="product-table">
                              <TableBody>
                                {productDetails &&
                                  productDetails.length > 0 &&
                                  productDetails?.map((item, index) => (
                                    <OrderItem key={index} orderItem={item} storeId={store?.id}/>
                                  ))}
                              </TableBody>
                            </Table>
                          <Divider style={{ marginTop: "15px" }} />
                            <h6
                              className="h6 gray"
                              style={{ marginTop: "20px" }}
                            >
                              Order Tracking
                            </h6>
                          <TrackingComponent
                            status= {orderDetails?.status}
                            tracking={'tracking'}
                            orderTracker={track} />
                          </div>
                          <div className="right-detail-box">
                            <h6 className="h6 mb-4">Payment Details</h6>
                            <div className="payment-value">
                              <span className="p2">MRP</span>
                              <span
                                className={`p2-bold ${
                                  orderDetails?.MRP !== orderDetails?.sub_total
                                    ? "through-text"
                                    : ""
                                }`}
                              >
                                {orderDetails?.MRP !==
                                orderDetails?.sub_total ? (
                                  <strike>
                                    QAR {orderDetails?.MRP?.toFixed(2)}
                                  </strike>
                                ) : (
                                  <span>
                                    QAR {orderDetails?.MRP?.toFixed(2)}
                                  </span>
                                )}
                              </span>
                            </div>
                            {orderDetails?.MRP !== orderDetails?.sub_total ? (
                              <div className="payment-value">
                                <span className="p2">Discounted Price</span>
                                <span className="p2-bold">
                                  QAR {orderDetails?.sub_total?.toFixed(2)}
                                </span>
                              </div>
                            ) : null}
                            {orderDetails?.delivery_charges > 0 ? (
                              <div className="payment-value">
                                <span className="p2">Delivery Charges</span>
                                <span className="p2-bold">
                                  QAR{" "}
                                  {orderDetails?.delivery_charges?.toFixed(2)}
                                </span>
                              </div>
                            ) : null}
                            {orderDetails?.tax > 0 ? (
                              <div className="payment-value">
                                <span className="p2">Tax</span>
                                <span className="p2-bold">
                                  QAR {orderDetails?.tax.toFixed(2)}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            {orderDetails?.discount > 0 ? (
                              <div className="payment-value">
                                <span className="p2">Coupon Discount</span>
                                <span className="p2-bold">
                                  - QAR {orderDetails?.discount.toFixed(2)}
                                </span>
                              </div>
                            ) : null}
                            {orderDetails?.offer_amount > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Offer/Discount</span>
                              <span className="p2-bold">
                                - QAR {orderDetails?.offer_amount.toFixed(2)}
                              </span>
                            </div>
                          ) : null}
                            {wallet > 0 ? (
                              <div className="payment-value">
                                <span className="p2">Wallet</span>
                                <span className="p2-bold">
                                  - QAR {wallet.toFixed(2)}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            <Divider />
                            <div className="payment-value-total">
                              <span className="p2">
                                {wallet > 0 ? "Net Pay" : "Total Amount"}
                              </span>
                              <span className="p2">
                                QAR{" "}
                                {(orderDetails?.total_amount - (wallet || 0)).toFixed(
                                  2
                                )}
                              </span>
                            </div>

                            

                            {canConfirm ? (
                              <div className="btn-row">
                                <Button
                                  fullWidth
                                  variant="contained"
                                  size="large"
                                  className="mx-5 secondary-gray"
                                  onClick={() => setRejection({ isOpen: true })}
                                >
                                  {" "}
                                  Reject{" "}
                                </Button>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  size="large"
                                  className="mx-5 secondary"
                                  // onClick={() => handleOrderAccept()}
                                  disabled={ButtonBlock}
                                  onClick={() => {
                                    setButtonBlock(true)
                                    setTimeout(() => {
                                      handleOrderAccept();
                                    }, 100);
                                  }}
                                >
                                  {" "}
                                  Accept{" "}
                                </Button>
                              </div>
                            ) : null}

                            <Divider className="my-5" />
                            <Boxed
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"flex-end"}
                            >
                              <h6 className="h6 mb-4">Special Request: { orderDetails?.specialRequest }</h6>
                            </Boxed>
                            {orderDetails?.specialRequest && (
                                <Boxed
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"flex-end"}
                                >
                                  <h6 className="h6 mb-4">Special Request: {orderDetails.specialRequest}</h6>
                                </Boxed>
                              )}
                            <div className="collapsible">
                              <div className="relative mb-4">
                                <p className="p3">Order Date</p>
                                <p className="p2">
                                  {moment(orderDetails?.createdAt).format(
                                    "DD MMMM YYYY hh:mm a"
                                  )}
                                </p>
                              </div>
                              <div className="relative mb-4">
                                <p className="p3">Payment Method</p>
                                <p className="p2">
                                  {orderDetails?.payment_method}
                                </p>
                              </div>
                              <div className="relative">
                                <p className="p3">Customer Details</p>
                                <p className="p2">
                                  {addressData?.full_name} |{" "}
                                  {`+${orderDetails?.user?.country_code} ${addressData?.contact_number}`}{" "}
                                  | {orderDetails?.user?.email}
                                </p>
                                <Button
                                  variant="contained"
                                  size="small"
                                  className="secondary"
                                  onClick={() => {
                                    navigate(
                                      `/manage-customer-Details/${orderDetails?.user?.id}`
                                    );
                                  }}
                                >
                                  {" "}
                                  View Customer{" "}
                                </Button>
                              </div>
                              <Divider />
                              <div className="relative">
                                <p className="p3">Vendor Details</p>
                                
                                <p className="p2">
                                  {store?.stores_locales[0].business_name}
                                  {store?.country_code !== null && store?.contact_number !==null?<>
                                    {" "} | {" "}
                                  {`${store?.country_code !== null?'+'+store?.country_code:''} ${store?.contact_number !==null?store?.contact_number:''}`}{" "}
                                  </>: null}
                                  {" "} | {store?.business_address}
                                </p>
                              </div>
                              <Divider />
                              <div className="relative">
                                <p className="p3">Delivery Partner</p>
                                {store?.market_place === 1?
                                  <p className="p2">MarketPlace</p>:
                                  <p className="p2 capitalize">{store?.deliveryPartner}</p>
                                }
                              </div>
                              {picker?.length > 0 && picker.map((item, index) => {
                                return (
                                  <>
                                    <Divider className="my-2" />
                                    <div className="relative">
                                      <p className="p3">Picker Details</p>
                                      <p className="p2">
                                        Picker Name - {item?.first_name}{" "}
                                        {item?.last_name} <br />
                                        
                                        Picker Email - {item?.email}
                                      </p>
                                    </div>
                                    <Divider />
                                  </>
                                );
                              })}
                              {qc?.length > 0 && qc.map((item, index) => {
                                return (
                                  <>
                                    <Divider className="my-2" />
                                    <div className="relative">
                                      <p className="p3">QC Details</p>
                                      <p className="p2">
                                        QC Name - {item?.first_name}{" "}
                                        {item?.last_name} <br />
                                        QC Email - {item?.email}
                                      </p>
                                    </div>
                                    <Divider />
                                  </>
                                );
                              })}
                              <Divider className="my-2" />
                              <p className="p3"> Time</p>
                              <p className="p2">Ideal Time - {idealTIme} Min</p>
                              {/* Actual Time - {(lastValue / 1000 / 60) | 0} Min */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <RejectOrder
                      isOpen={rejection.isOpen}
                      cancelReasons={cancelReasons}
                      handleClose={() => {
                        setRejection({ isOpen: false });
                      }}
                      submit={(message) => {
                        handleOrderReject(orderId, message);
                        setRejection({ isOpen: false });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>

        <Dialog
          open={cancelOrder.isOpen}
          onClose={() => {
            setCancelOrder({ ...cancelOrder, isOpen: false });
          }}
        >
          <img
            src={modalClose}
            alt="..."
            onClick={() => {
              setCancelOrder({ ...cancelOrder, isOpen: false });
            }}
            className="modal-close"
          />
          <DialogContent className="w-385">
            <Typography variant="h6" component="h6" mb={3}>
              Cancel Order
            </Typography>

            <form>
              <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Department</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={cancelOrder.department.val}
                    label="Department"
                    onChange={(e) => {
                      setCancelOrder({
                        ...cancelOrder,
                        department: stateWithValidation(
                          e.target.value,
                          "rejectReason"
                        ),
                      });
                    }}
                  >
                    {cancelDepartment?.map((item, index) =>
                      item.status === "active" ? (
                        <MenuItem key={index} value={item.department_name}>
                          {item.department_name}
                        </MenuItem>
                      ) : null
                    )}
                     <MenuItem key={"other"} value={"other"}>
                      Other
                    </MenuItem>
                  </Select>
                </FormControl>

                {cancelOrder.department.val === "other" ? (
                  <TextField
                    label="Rejection Department"
                    style={{ marginTop: "10px" }}
                    multiline={true}
                    rows={2}
                    value={cancelDepartmentOther}
                    onChange={(e) => {
                      setCancelDepartmentOther(e.target.value);
                    }}
                  />
                ) : null}
                {!cancelOrder.department.isValid && (
                  <div className="error">{cancelOrder.department.err}</div>
                )}
              </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Reason
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={cancelOrder.reason.val}
                      label="Reason"
                      onChange={(e) => {
                        setCancelOrder({
                          ...cancelOrder,
                          reason: stateWithValidation(
                            e.target.value,
                            "rejectReason"
                          ),
                        });
                      }}
                    >
                      {/* {cancelReasons?.map((item, index) =>
                        item.status === "active" ? (
                          <MenuItem key={index} value={item.reason}>
                            {item.reason}
                          </MenuItem>
                        ) : null
                      )} */}
                      {
                  cancelReasons &&
                  (
                    cancelReasons.some(item => 
                      item.order_cancellation_department &&
                      item.order_cancellation_department.department_name === cancelOrder.department.val
                    ) ?
                    cancelReasons
                      .filter(item => 
                        item.order_cancellation_department &&
                        item.order_cancellation_department.department_name === cancelOrder.department.val
                      )
                      .map((item, index) => (
                        <MenuItem key={index} value={item.reason}>
                          {item.reason} 
                        </MenuItem>
                      )) :
                    cancelReasons
                      .map((item, index) => (
                        <MenuItem key={index} value={item.reason}>
                          {item.reason} 
                        </MenuItem>
                      ))
                  )
                }
                      <MenuItem key={"vendorClosed"} value={"vendor closed"}>
                        Vendor closed
                      </MenuItem>
                      <MenuItem key={"other"} value={"other"}>
                        Other
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {cancelOrder.reason.val === "other" ? (
                    <TextField
                      label="Rejection Reason"
                      style={{ marginTop: "10px" }}
                      multiline={true}
                      rows={2}
                      value={cancelReasonOther}
                      onChange={(e) => {
                        setCancelReasonOther(e.target.value);
                      }}
                    />
                  ) : null}
                  {!cancelOrder.reason.isValid && (
                    <div className="error">{cancelOrder.reason.err}</div>
                  )}
                </Grid>
                {orderDetails?.payment_method !== "cash_on_delivery" ? (
                  <Grid item xs={12}>
                    <TextField
                      required
                      label="Customer Refund Amount"
                      value={cancelOrder.refund.val}
                      onChange={(e) => {
                        setCancelOrder({
                          ...cancelOrder,
                          refund: stateWithValidation(e.target.value, "price"),
                        });
                      }}
                    />
                    {!cancelOrder.refund.isValid && (
                      <div className="error">{cancelOrder.refund.err}</div>
                    )}
                  </Grid>
                ) : null}
                {orderDetails?.payment_method !== "cash_on_delivery" ? (
                  <Grid item xs={12}>
                    <TextField
                      required
                      label="Store Payout Amount"
                      value={cancelOrder.storePayout.val}
                      onChange={(e) => {
                        setCancelOrder({
                          ...cancelOrder,
                          storePayout: stateWithValidation(
                            e.target.value,
                            "price"
                          ),
                        });
                      }}
                    />
                    {!cancelOrder.storePayout.isValid && (
                      <div className="error">{cancelOrder.storePayout.err}</div>
                    )}
                  </Grid>
                ) : null}

                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ width: 1, marginTop: "16px" }}
                    disabled={
                      !cancelOrder.reason.isValid ||
                      (orderDetails?.payment_method !== "cash_on_delivery" &&
                        !cancelOrder.refund.isValid) ||
                      (orderDetails?.payment_method !== "cash_on_delivery" &&
                        !cancelOrder.storePayout.isValid)
                    }
                    onClick={() => {
                      handleOrderCancel();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};
export default Orders;
