import { createSlice } from "@reduxjs/toolkit";

export const faq = createSlice({
  name: "faq",
  initialState: {
    method: null,
    role: "vendor",
    id: null,
  },
  reducers: {
    changeFaq: (state, action) => {
      state.method = action.payload;
    },
    changeRole: (state, action) => {
      state.role = action.payload;
    },
    changeId: (state, action) => {
      state.id = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeFaq } = faq.actions;
export const { changeRole } = faq.actions;
export const { changeId } = faq.actions;
export const currentFaq = (state) => state.faq.method;
export const currentRole = (state) => state.faq.role;
export const currentId = (state) => state.faq.id;
export default faq.reducer;
