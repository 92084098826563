import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, 
  Typography, TextField, Button, Grid, Alert, Tooltip, DialogContent, Dialog
} from "@mui/material";
import {
  DeleteForever,
  BorderColor as BorderColorIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  Search as SearchIcon,
  Clear as ClearIcon,
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon
} from "@mui/icons-material";
import {
  getStoreNameFromLocale, getStoreDescriptionFromLocale, checkIfImageExists
} from "../../helper/custom";
import { changeLoader } from "../../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import { currentPermission } from "../../../redux/reducers/permission";
import constant from "../../../constant";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import services from "../../../services";
import modalClose from "../../../assets/images/structure/close-modal.png";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import { API } from "../../ENUM";
import GlobalService from "../../../services/GlobalService";

export default function BusinessCategoryManagement() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permission = useSelector(currentPermission);
  const limit = 100;
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [businesstypeData, setBusinesstypeData] = useState([]);
  const [deleteModel, setdeleteModel] = useState({ isOpen: false, id: null });
  const [search, setSearch] = useState("");
  const [toaster, setToaster] = useState({ visibilty: false, status: "", text: "", });
  let [canCreate, setCanCreate] = useState(true);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [page, setPage] = useState(1);
  let [count, setCount] = useState(0);
  
  const drop = async (e) => {
    const copyListItems = [...businesstypeData];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setBusinesstypeData(copyListItems);
    
    const data = copyListItems.map((item, index) => {
      return {
        id: item?.id,
        order: index + 1,
      }
    }
    )
    const response = await GlobalService(API.BUSINESS_TYPE.SORT_BUSINESS_TYPE, {order_type : data} , "put");
    if(response.ack === 1){
      dispatch(changeLoader(false));
      setToaster({
        status: "success",
        text: response?.msg,
        visibilty: true,
      });
    }else{
      dispatch(changeLoader(false));
      setToaster({
        status: "error",
        text: `Something went wrong`,
        visibilty: true,
      });
    }
  };

  const GetAllBusinessType = async () => {
    try {
      const query = new URLSearchParams({ page: page ? page : 1, limit: limit, search, sort_by : "sort_id" , order_by : 'asc'}).toString();
      dispatch(changeLoader(true));
      const response = await services.BusinessType.GET_ALL_BUSINESS_TYPE_BY_QUERY(query);
      if (response) {
        dispatch(changeLoader(false));
        setBusinesstypeData(response.data.businesstype.rows);
        Math.ceil(count / limit);
        count = Math.ceil(response.data.businesstype.count / limit); 
        setCount(count);
      }
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
    }
  };

  const handleClose = () => {
    setdeleteModel({ ...deleteModel, isOpen: false });
  };

  const DeleteHandler = async () => {
    try {
      dispatch(changeLoader(true));
      const response = await services.BusinessType.DELETE_BUSINESS_TYPE(deleteModel.id);
      if (response) {
        setdeleteModel({ isOpen: false, id: null });
        setToaster({
          status: "success",
          text: `Business Category Deleted Successfuly`,
          visibilty: true,
        });
        GetAllBusinessType();
      }
      dispatch(changeLoader(false));
    } catch (error) {
      if (error.response.status === 403) {
        setdeleteModel({ isOpen: false, id: null });
        setToaster({
          status: "error",
          text: `${error.response.data.msg}!`,
          visibilty: true,
        });
        return;
      }
    }
    setTimeout(() => {
      setToaster({ ...toaster, visibilty: false });
    }, 6000);
  };

  const paginationHandler = (e, value) => {
    page = value; setPage(page);
    GetAllBusinessType(); 
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanCreate(permission?.storeType?.create === 1)
      setCanEdit(permission?.storeType?.edit === 1)
      setCanDelete(permission?.storeType?.delete === 1)
    } else {
      setCanCreate(true)
      setCanEdit(true)
      setCanDelete(true)
    }
  }, [permission]);

  useEffect(() => {
    const getData = setTimeout(() => {
      page = 1; setPage(page);
      GetAllBusinessType();
    }, 500);
    return () => clearTimeout(getData); 
  }, [search]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty && <Alert severity={toaster.status}>{toaster.text}</Alert>}
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>Store Type Listing</Typography>
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small" size="small" value={search} placeholder="Search Store Type"
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      startAdornment: <SearchIcon sx={{ marginRight: "3px" }} />,
                      endAdornment:
                        search && search.length > 0 ? 
                          <ClearIcon sx={{ marginLeft: "8px" }} onClick={() => setSearch("")} />: null,
                    }}
                  />
                </div>
                {canCreate ? <Button
                  variant="contained" size="medius" onClick={() =>  navigate(`/add-business-category`)}
                  startIcon={<AddCircleOutlineIcon />}
                >Add Type</Button>: null}
              </div>
            </div>
            {businesstypeData.length > 0 ?
            <TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
                        Business Type Name
                        <div className="short-box">
                          {/* <ArrowDropUpIcon />
                          <ArrowDropDownIcon /> */}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Category Level
                        <div className="short-box">
                          {/* <ArrowDropUpIcon />
                          <ArrowDropDownIcon /> */}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Description
                        <div className="short-box">
                          {/* <ArrowDropUpIcon />
                          <ArrowDropDownIcon /> */}
                        </div>
                      </div>
                    </TableCell>
                    { canEdit || canDelete?
                    <TableCell className="nowrap" align="center">Action</TableCell>: null}
                  </TableRow>
                </TableHead>
                <TableBody>
                {businesstypeData.length>0? businesstypeData.map((businessData, index) => 
                  <TableRow
                    key={`business-${index}`}
                    onDragStart={(e) => dragItem.current = index}
                    onDragEnter={(e) => dragOverItem.current = index}
                    onDragEnd={drop}
                    draggable
                  >
                    <TableCell component="th" scope="row">
                      <div className="table-img-text">
                        <img
                          src={checkIfImageExists(constant.IMG_BASE_URL + businessData?.image )}
                          alt=".." className="round36"
                        />{getStoreNameFromLocale(businessData?.business_type_locales, "en")}</div>
                    </TableCell>
                    <TableCell>{businessData.category_level}</TableCell>
                    <TableCell>
                      <div className="max-width300">
                        {getStoreDescriptionFromLocale( businessData?.business_type_locales, "en" )}
                      </div>
                    </TableCell>
                    {canEdit || canDelete?<TableCell align="center" className="nowrap">
                    {canEdit?
                      <Tooltip title="Edit" placement="top" sx={{ marginRight: 1 }}
                        onClick={() => navigate(`/update-business-type/${businessData.id}`)}
                      > <BorderColorIcon color="action" fontSize="small" /> </Tooltip>: null}
                      
                      {/**
                      {canDelete?<Tooltip title="Delete" placement="top">
                        <DeleteForever color="error" fontSize="small"
                          onClick={() => setdeleteModel({ isOpen: true, id: businessData.id })}
                        /></Tooltip>: null}

                      **/}

                    </TableCell>: null}
                  </TableRow>) :null}
                </TableBody>
              </Table>
            </TableContainer>: <NoDataFound text="No data found" />}
            {count > 1 ?
            <Pagination count={count} color="primary" page={page} onChange={paginationHandler} />: null}
          </Box>
        </Box>
      </Box>
      <Dialog open={deleteModel.isOpen} onClose={handleClose} className="alert-delete" >
        <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>Alert</Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6" component="h6" fontSize={"16px"} fontWeight={500} mb={0} textAlign={"left"}>
                  Are you sure you want to delete this Store Type?
                </Typography>
              </Grid>
              <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"end"}>
                <Button variant="contained" size="medium" sx={{ marginTop: "16px" }} onClick={handleClose} >No</Button>
                <Button
                  variant="contained" size="medium" sx={{ marginTop: "16px", marginLeft: "16px" }}
                  onClick={() => DeleteHandler()}>Yes</Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
