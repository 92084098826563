import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  TextField,
  Button,
  Chip,
  Autocomplete,
  Select,
  MenuItem,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { PAYOUT } from "../../../services/Payout";
import { MONTH } from "../../ENUM";
import { getdataByKey, getFormatedDateDb } from "../../helper/custom";
import { currentPermission } from "../../../redux/reducers/permission";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import constant from "../../../constant";
let timer = null;

export default function PayoutManagementListing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const date = new Date();
  const limit = 10;
  const permission = useSelector(currentPermission);
  const [valueDatepickerStart, setValueDatepickerStart] = useState(null);
  const [valueDatepickerEnd, setValueDatepickerEnd] = useState(null);
  const [selectedStore, setSelectedStore] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [data, setData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  let [order_by, setOrder_by] = useState("");
  let [order_type, setOrder_type] = useState("");
  let [canExportPayout, setCanExportPayout] = useState(true);
  let [storeId, setStoreId] = useState(0);
  let [startDate, setStartDate] = useState("");
  let [endDate, setEndDate] = useState("");
  let [count, setCount] = useState(0);
  let [page, setPage] = useState(1);

  const changeOrderBy = (value, order) => {
    if (order === "asc") setOrder_type("asc");
    else if (order === "desc") setOrder_type("desc");
    setOrder_by(value);
  };
  const handlePagination = (e, value) => {
    page = value;
    setPage(page);
    getList();
  };
  const resetFilter = () => {
    setStoreId(0);
    setSelectedStore([]);
    setStartDate("");
    setValueDatepickerStart(null);
    setPaymentStatus("");
    getList();
  };

  const storeList = () => {

    PAYOUT.GET_STORE(order_by, order_type)
      .then((res) => {
        let data = res.data;
        if (data?.ack == 1) {
          let a = [];
          let i = 1;
          data?.data?.rows
            ?.sort((a, b) =>
              a.stores_locales[0]?.business_name.localeCompare(
                b.stores_locales[0]?.business_name
              )
            )
            .forEach((obj) => {
              // const business_name = `${obj.stores_locales[0]?.business_name} - ${obj.stores_locales[1]?.business_name}`
              const business_name = getdataByKey(
                obj.stores_locales,
                "en",
                "business_name"
              );
              a.push({ label: `${i}. ${business_name}`, value: obj?.id });
              i++;
            });
          setStoreData(a);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    storeList();
  }, []);
  useEffect(() => {
    if (valueDatepickerStart) {
      setStartDate(getFormatedDateDb(valueDatepickerStart));
    }
  }, [valueDatepickerStart]);

  useEffect(() => {
    if (valueDatepickerEnd) {
      setEndDate(getFormatedDateDb(valueDatepickerEnd));
    }
  }, [valueDatepickerEnd]);

  const getList = () => {
    setData([]);
    count = 0;
    setCount(count);
    PAYOUT.GET_PAYOUT_LISTING(
      startDate,
      endDate,
      paymentStatus,
      limit,
      page,
      selectedStore.map((obj) => {
        obj.code = obj.value;

        return obj;

      }),
      order_type,
      order_by
    )
      .then((res) => {
        let response = res.data;
        if (response.ack == 1) {
          setData(response?.data?.rows);

          count = Math.ceil(response?.data?.count / limit);
          setCount(count);
        } else {
          setData([]);
          count = 0;
          setCount(count);
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      page = 1;
      setPage(page);
      getList();
    }, 500);
  }, [startDate, endDate, paymentStatus, selectedStore, order_type, order_by]);

  const dates = (d) => {
    let date = new Date(d);
    let month = MONTH[date.getMonth()];
    return `${date.getDate()} ${month} ${date.getFullYear()}`;
  };
  const getStoreName = (data) => {
    let newData = "";
    data?.forEach((item) => {
      if (item?.locale == "en") newData = item?.business_name;
    });
    return newData;
  };
  const GetTable = ({ obj, index }) => {
    return (
      <TableRow
        key={index}
        onClick={() => {
          navigate(`/payout-management-details/${obj.id}`);
        }}
      >
        <TableCell>
          {obj.period_start_date ? dates(obj.period_start_date) : null} {" - "}
          {obj.period_end_date ? dates(obj.period_end_date) : null}
        </TableCell>
        <TableCell>
          {/* {getdataByKey(obj?.store?.stores_locales, "en", "business_name")} */}
          {getStoreName(obj?.store?.stores_locales)}

          {/* {obj?.store?.stores_locales[0].business_name ? obj?.store?.stores_locales[0].business_name : null}  */}
        </TableCell>
        <TableCell>
          {obj.expected_payout_date ? dates(obj.expected_payout_date) : null}
        </TableCell>
        <TableCell>
          {obj.actual_payout_date ? dates(obj.actual_payout_date) : "-"}
        </TableCell>
        <TableCell>QAR {obj?.sales_amount?.toFixed(2)} Till Date</TableCell>
        <TableCell>
          {(obj?.sales_amount?.toFixed(2) *
            obj?.store?.admin_commission?.toFixed(2)) /
            100}
        </TableCell>

        <TableCell align="center">
          {" "}
          QAR {obj?.expected_payout_amount?.toFixed(2)} Till Date{" "}
        </TableCell>

        <TableCell align="center">
          {obj?.actual_payout_amount?.toFixed(2)}
        </TableCell>
        <TableCell align="center">
          <Chip label={obj?.status} />
        </TableCell>
      </TableRow>
    );
  };

  const tableToCSV = () => {
    dispatch(changeLoader(true));
    PAYOUT.EXPORT_PAYOUT(startDate, paymentStatus, storeId)
      .then((res) => {
        if (res.data.ack == 1) {
          window.open(constant.IMG_BASE_URL + res.data.path, "_blank");
          dispatch(changeLoader(false));
        } else {
          dispatch(changeLoader(false));
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: "error",
              message: "Error in downloading file",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: "Error in downloading file",
          })
        );
      });
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanExportPayout(permission?.payoutManagement?.actions.exportCSV === 1);
    } else {
      setCanExportPayout(true);
    }
  }, [permission]);
  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
                Payout Listing
              </Typography>
              <div className="right-filter">
                <Autocomplete
                  id="combo-box-demo"
                  className="w-150"
                  options={storeData}
                  multiple={true}
                  value={selectedStore}
                  onChange={(event, newValue) => {
                    setSelectedStore(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Select store"
                      variant="outlined"
                    />
                  )}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="width160"
                    value={valueDatepickerStart}
                    onChange={(newValue) => {
                      setValueDatepickerStart(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    inputFormat="YYYY-MM-DD"
                  />
                </LocalizationProvider>
                {valueDatepickerStart ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="width160"
                      value={valueDatepickerEnd}
                      minDate={valueDatepickerStart}
                      inputFormat="YYYY-MM-DD"
                      onChange={(newValue) => {
                        setValueDatepickerEnd(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                ) : null}
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  defaultValue={"Payment Status"}
                  value={paymentStatus}
                  size="small"
                  className="status-drop"
                  onChange={(e) => setPaymentStatus(e.target.value)}
                >
                  <MenuItem value={""}>All</MenuItem>
                  <MenuItem value={"settled"}>Settled</MenuItem>
                  <MenuItem value={"planned"}>Planned</MenuItem>
                </Select>
                {canExportPayout ? (
                  <Button
                    variant="contained"
                    size="medius"
                    onClick={tableToCSV}
                  >
                    Export CSV
                  </Button>
                ) : null}
                <Button variant="contained" size="medius" onClick={resetFilter}>
                  {" "}
                  Reset Filter{" "}
                </Button>
              </div>
            </div>
            <TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
                        Sales Period
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("period_start_date", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("period_start_date", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Store Name
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("business_name", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("business_name", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Expected Payout Date
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("expected_payout_date", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("expected_payout_date", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="short-div">
                        Actual Payout Date
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("actual_payout_date", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("actual_payout_date", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="short-div">
                        Sales Amount
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("sales_amount", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("sales_amount", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className="short-div">
                        Commission
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("admin_commission", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("admin_commission", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="short-div">
                        Expected Payout Amount
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("expected_payout_amount", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("expected_payout_amount", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="short-div">
                        Actual Payout Amount
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("actual_payout_amount", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("actual_payout_amount", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">Payout Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((obj, index) => (
                    <GetTable obj={obj} index={index} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {count > 1 ? (
              <Pagination
                onChange={handlePagination}
                page={page}
                count={count}
                color="primary"
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
}
