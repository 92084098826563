import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";

import TopPickerPerformance from "./TrackingTabs/TopPickerPerformance";
import OtherPickerPerformance from "./TrackingTabs/OtherPickerPerformance";

const PickerListingPage = () => {
  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <TopPickerPerformance />

            <OtherPickerPerformance />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PickerListingPage;
