import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  Typography,
  TextField,
  Grid,
  Divider,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import Aside from "../../../layout/aside/Aside";
import Header from "../../../layout/header/Header";
import TrendingExclusive from "./trendingExclusive";
import Popular from "./Popular";
import Featured from "./Featured";
import Love from "./Love";
import services from "../../../../services";
import { getdataByKey } from "../../../helper/custom";
import { API } from "../../../ENUM";
import GlobalService from "../../../../services/GlobalService";

export default function StoreRanking() {
  const navigate = useNavigate();
  const [selectedBusinessType, setSelectedBusinessType] = useState("Food");
  const [businesstypeList, setBusinesstypeList] = useState([]);
  let [filter, setFilter] = useState(0);
  const [allBrands, setAllBrands] = useState([]);
  const [allRankingBrands, setAllRankingBrands] = useState([]);
  const [featuredBrands, setFeaturedBrands] = useState([]);
  const [loveBrands, setLoveBrands] = useState([]);
  const [popularBrands, setPopularBrands] = useState([]);
  const [trendingBrands, setTrendingBrands] = useState([]);

  const GetAllBusinessType = async () => {
    try {
      const response =
        await services.BusinessType.GET_ALL_BUSINESS_TYPE_BY_QUERY();
      if (response) {
        setBusinesstypeList(response.data.businesstype.rows);
      }
      filter = response.data.businesstype.rows[0].id;
      setFilter(filter);
      GetAllBrands();
      GetAllBrandsRanking();
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllBrands = async () => {
    try {
      const response = await GlobalService(
        API.STORE_RANKING.GET_ALL_BRANDS(filter)
      );
      if (response?.ack === 1) {
        setAllBrands(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllBrandsRanking = async (type) => {
    try {
      const response = await GlobalService(
        API.STORE_RANKING.GET_ALL_BRANDS_RANKING(filter)
        + `?type=${type ? type : ""}`
      );
      // console.log("response", response);
      if (response?.ack === 1) {
        // setAllRankingBrands(response?.data);
        if(!type){
        setFeaturedBrands(response?.data?.featured);
        setLoveBrands(response?.data?.love);
        setPopularBrands(response?.data?.popular);
        setTrendingBrands( response?.data?.trending);
      }else{
        if(type === "featured"){
          setFeaturedBrands(response?.data);
        }else if(type === "love"){
          setLoveBrands(response?.data);
        }else if(type === "popular"){
          setPopularBrands(response?.data);
        }else if(type === "trending"){
          setTrendingBrands(response?.data);
        }
      }
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    GetAllBusinessType();
  }, []);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Store Ranking
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Change Business Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    size="small"
                    value={filter}
                    onChange={(e) => {
                      filter = e.target.value;
                      setFilter(filter)
                      GetAllBrands();
                      GetAllBrandsRanking();
                    }}
                    fullWidth
                    label="Change Business Type"
                  >
                    {/* <MenuItem value={"0"} id={"typeName.id"}>
                      Food
                    </MenuItem>
                    <MenuItem value={"1"} id={"typeName.id"}>
                      test 2
                    </MenuItem> */}
                    {/* <MenuItem value={0}>{"All"}</MenuItem> */}
                    {businesstypeList &&
                      businesstypeList?.length > 0 &&
                      businesstypeList.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {getdataByKey(
                            item?.business_type_locales,
                            "en",
                            "name"
                          )}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <TrendingExclusive
                name="TRENDING OR EXCLUSIVE"
                type="trending"
                columnNames="Trending Brands"
                selectedBrands={trendingBrands}
                allBrands={allBrands}
                GetAllBrandsRanking = {GetAllBrandsRanking}
                GetAllBrands = {GetAllBrands}
              />
              <TrendingExclusive
                allBrands={allBrands}
                name="Popular"
                type="popular"
                columnNames="Popular Brands"
                GetAllBrandsRanking = {GetAllBrandsRanking}
                selectedBrands={popularBrands}
                GetAllBrands = {GetAllBrands}
              />
              <TrendingExclusive
                allBrands={allBrands}
                name="Featured"
                GetAllBrandsRanking = {GetAllBrandsRanking}
                type="featured"
                columnNames="Featured Brands"
                selectedBrands={featuredBrands}
                GetAllBrands = {GetAllBrands}
              />
              <TrendingExclusive
                allBrands={allBrands}
                GetAllBrandsRanking = {GetAllBrandsRanking}
                name="Love"
                type="love"
                columnNames="Love Brands"
                selectedBrands={loveBrands}
                GetAllBrands = {GetAllBrands}
              />
              {/* <Popular />
              <Featured />
              <Love /> */}
            </Grid>
            <div className="bottom-btn-row">
              <Button
                variant="standard"
                size="large"
                sx={{ marginRight: "10px" }}
              >
                Back
              </Button>
              <Button variant="contained" size="large" color="primary">
                Save
              </Button>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
}
