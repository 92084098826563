import { createSlice } from "@reduxjs/toolkit";

export const socket = createSlice({
  name: "socket",
  initialState: {
    value: false,
    pickerSocket: false,
    Object: {},
  },
  reducers: {
    changeSocket: (state, action) => {
      state.value = action.payload;
    },
    changePickerSocket: (state, action) => {
      state.pickerSocket = action.payload;
    },
    changeSocketObject: (state, action) => {
      state.Object = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeSocket } = socket.actions;
export const { changeSocketObject } = socket.actions;
export const { changePickerSocket } = socket.actions;
export const currentSocket = (state) => state.socket.value;
export const currentSocketObject = (state) => state.socket.Object;
export const currentPickerSocket = (state) => state.socket.pickerSocket;
export default socket.reducer;
